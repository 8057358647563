import React, {useState, useCallback, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { FaSearch, FaRegUser, FaRegHeart, FaCartArrowDown, FaAngleDown, FaTimes } from "react-icons/fa"

function Navmain() {
    

    // async function getsubmenu(){
    //     formData.append('page', JSON.stringify(requestdatap));
    //     requestOptions = {
    //         method: 'POST',
           
    //     };
    
    
    //     var resp = await fetch("http://188.166.226.236/api/server/getsubmenu",requestOptions);
    //     var data = await resp.json();
    // }
    // useEffect(()=>{
    //     getsubmenu();
    // },[])

 

    return (
    <div id="header" className="header header-1">
        <div className="header-main">
            <div className="menu-outer">
                {/* <nav className="container"> */}
                    <div className="row">
                        <div className="col-1 col-sm-12 col-md-12 col-lg-12 align-self-center d-menu-col">
                            <nav className="grid__item" id="AccessibleNav">
                                <ul id="siteNav" className="site-nav medium center hidearrow">
                                    <li className="lvl1 parent megamenu">
                                        <Link className="site-nav parent" to="/our-engagement-rings">engagement rings <i
                                            className="an an-angle-down-l"></i></Link>
                                        <div className="megamenu style1">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-8 col-lg-8">
                                                        <div className="row">
                                                            <div className="lvl-1 col-md-5 col-lg-4">
                                                                <div>
                                                                    <a href="#" className="site-nav lvl-1 menu-title">Design
                                                                        Your Engagement Ring</a>
                                                                    <ul className="subLinks">
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/gem.png')"}}>
                                                                            <Link to="/settings-diamond/all"
                                                                                  className="site-nav lvl-2"
                                                                                  onClick={(e) => localStorage.setItem("ChooseEng", "1")}>Start
                                                                                with Diamond</Link></li>
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/gem.png')"}}>
                                                                            <Link
                                                                                onClick={(e) => localStorage.setItem("ChooseEng", "2")}
                                                                                to="/settings-ring/all"
                                                                                className="site-nav lvl-2">Start with
                                                                                Setting</Link></li>
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/gem.png')"}}>
                                                                            <Link to="/settings-gemstone"
                                                                                  className="site-nav lvl-2">Start with
                                                                                a Gemstone</Link></li>

                                                                    </ul>
                                                                </div>
                                                                <div className="mt-3">
                                                                    <a href="#" className="site-nav lvl-1 menu-title">Shop
                                                                        by Metal</a>
                                                                    <ul className="subLinks doubleCol">
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/metal_rg_dropdown.png')"}}>
                                                                            <Link
                                                                                to="/settings-ring/metal/18K Rose Gold"
                                                                                className="site-nav lvl-2">Rose
                                                                                Gold </Link></li>
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/metal_wg_dropdown.png')"}}>
                                                                            <Link
                                                                                to="/settings-ring/metal/18K White Gold"
                                                                                className="site-nav lvl-2">White
                                                                                Gold</Link></li>
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/metal_yg_dropdown.png')"}}>
                                                                            <Link
                                                                                to="/settings-ring/metal/18K Yellow Gold"
                                                                                className="site-nav lvl-2">Yellow
                                                                                Gold</Link></li>
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/metal_pt_dropdown.png')"}}>
                                                                            <Link to="/settings-ring/metal/Platinum"
                                                                                  className="site-nav lvl-2">Platinum</Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                            </div>
                                                            <div className="lvl-1 col-md-5 col-lg-4">
                                                                <div>
                                                                    <a href="#" className="site-nav lvl-1 menu-title">Shop
                                                                        by Shape</a>
                                                                    <ul className="subLinks doubleCol">
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/round.png')"}}>
                                                                            <Link to="/settings-diamond/shape/round"
                                                                                  className="site-nav lvl-2">Round</Link>
                                                                        </li>
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/princess.png')"}}>
                                                                            <Link to="/settings-diamond/shape/princess"
                                                                                  className="site-nav lvl-2">Princess</Link>
                                                                        </li>
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/cushion.png')"}}>
                                                                            <Link to="/settings-diamond/shape/cushion"
                                                                                  className="site-nav lvl-2">Cushion</Link>
                                                                        </li>

                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/emerald.png')"}}>
                                                                            <Link to="/settings-diamond/shape/emerald"
                                                                                  className="site-nav lvl-2">Emerald</Link>
                                                                        </li>
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/marquise.png')"}}>
                                                                            <Link to="/settings-diamond/shape/marquise"
                                                                                  className="site-nav lvl-2">Marquise</Link>
                                                                        </li>

                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/pear.png')"}}>
                                                                            <Link to="/settings-diamond/shape/pear"
                                                                                  className="site-nav lvl-2">Pear</Link>
                                                                        </li>
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/oval.png')"}}>
                                                                            <Link to="/settings-diamond/shape/oval"
                                                                                  className="site-nav lvl-2">Oval</Link>
                                                                        </li>
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/rediant.png')"}}>
                                                                            <Link to="/settings-diamond/shape/heart"
                                                                                  className="site-nav lvl-2">Radiant</Link>
                                                                        </li>

                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/asscher.png')"}}>
                                                                            <Link to="/settings-diamond/shape/asscher"
                                                                                  className="site-nav lvl-2">Asscher</Link>
                                                                        </li>
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/heart.png')"}}>
                                                                            <Link to="/settings-diamond/shape/heart"
                                                                                  className="site-nav lvl-2">Heart</Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                            </div>

                                                            <div className="lvl-1 col-md-5 col-lg-4">

                                                                <div className="">
                                                                    <a href="#" className="site-nav lvl-1 menu-title">Shop
                                                                        by Setting Styles</a>
                                                                    <ul className="subLinks doubleCol">
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/engagement_ring_icon_solitaire.png')"}}>
                                                                            <Link to="/settings-ring/style/Solitaire"
                                                                                  className="site-nav lvl-2">Solitaire</Link>
                                                                        </li>
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/engagement_ring_icon_pave.png')"}}>
                                                                            <Link to="/settings-ring/style/Pave"
                                                                                  className="site-nav lvl-2">Pave</Link>
                                                                        </li>

                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/engagement_ring_icon_channel_set.png')"}}>
                                                                            <Link to="/settings-ring/style/Channel-Set"
                                                                                  className="site-nav lvl-2">Channel-Set</Link>
                                                                        </li>
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/engagement_ring_icon_sidestones.png')"}}>
                                                                            <Link to="/settings-ring/style/Side-Stones"
                                                                                  className="site-nav lvl-2">Side-Stones</Link>
                                                                        </li>

                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/engagement_ring_icon_tension.png')"}}>
                                                                            <Link to="/settings-ring/style/Tension"
                                                                                  className="site-nav lvl-2">Tension</Link>
                                                                        </li>
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/engagement_ring_icon_vintage.png')"}}>
                                                                            <Link to="/settings-ring/style/Vintage"
                                                                                  className="site-nav lvl-2">Vintage</Link>
                                                                        </li>
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/engagement_ring_icon_halo.png')"}}>
                                                                            <Link to="/settings-ring/style/Halo"
                                                                                  className="site-nav lvl-2">Halo</Link>
                                                                        </li>
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/engagement_ring_icon_three_stones.png')"}}>
                                                                            <Link to="/settings-ring/style/Three-Stone"
                                                                                  className="site-nav lvl-2">Three
                                                                                Stone</Link></li>
                                                                    </ul>
                                                                </div>
                                                                <div className="mt-3">
                                                                    <Link to="/custom-design"
                                                                          className="site-nav lvl-1 menu-title">Submit
                                                                        Your Customize Design</Link>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-lg-4">
                                                        <div className="row mm-Banners">
                                                            <div className="col-md-12 col-lg-12 imageCol text-center">
                                                                <div className="menubox position-relative">
                                                                    <Link to="/" className="mb-0"><img
                                                                        className="blur-up lazyload"
                                                                        src="/assets/img/cate-1.jpg"
                                                                        data-src="assets/img/cate-1.jpg" alt="image"
                                                                        width="400" height="342"/></Link>
                                                                    <p className="position-absolute bottom-0 start-50 translate-middle-x">
                                                                        <Link to="/" className="title text-uppercase">ENGAGEMENT
                                                                            RINGS</Link></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="lvl1 parent megamenu">
                                        <Link className="site-nav parent" to="/our-wedding-bands">WEDDING BANDS<i
                                            className="an an-angle-down-l"></i></Link>
                                        <div className="megamenu style1">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-8 col-lg-8">
                                                        <div className="row">
                                                            <div className="lvl-1 col-md-4 col-lg-4">
                                                                <div>
                                                                    <a href="#" className="site-nav lvl-1 menu-title">Shop
                                                                        For Women</a>
                                                                    <ul className="subLinks">
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/wedding_band_icon_women_classic.png')"}}>
                                                                            <Link to="/wedding-bands/female/Classic"
                                                                                  className="site-nav lvl-2">Classic
                                                                                Wedding Bands</Link></li>

                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/menu_header_icon_women_eternity_band.png')"}}>
                                                                            <Link to="/wedding-bands/female/Eternity"
                                                                                  className="site-nav lvl-2">Eternity
                                                                                Bands</Link></li>
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/menu_header_icon_women_diamond_band.png')"}}>
                                                                            <Link to="/wedding-bands/female/Diamond"
                                                                                  className="site-nav lvl-2">Diamond
                                                                                Bands</Link></li>
                                                                    </ul>
                                                                </div>

                                                            </div>

                                                            <div className="lvl-1 col-md-4 col-lg-4">
                                                                <div className="">
                                                                    <a href="#" className="site-nav lvl-1 menu-title">Shop
                                                                        For Men</a>
                                                                    <ul className="subLinks">
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/wedding_band_icon_men_classic.png')"}}>
                                                                            <Link to="/wedding-bands/male/Classic"
                                                                                  className="site-nav lvl-2">Classic
                                                                                Wedding Bands</Link></li>
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/wedding_band_icon_men_s_diamond.png')"}}>
                                                                            <Link to="/wedding-bands/male/Diamond"
                                                                                  className="site-nav lvl-2">Diamond
                                                                                Bands</Link></li>
                                                                    </ul>
                                                                </div>

                                                            </div>
                                                            <div className="lvl-1 col-md-4 col-lg-4">
                                                                <div>
                                                                    <a href="#" className="site-nav lvl-1 menu-title">SHOP
                                                                        BY METAL</a>
                                                                    <ul className="subLinks">
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/metal_rg_dropdown.png')"}}>
                                                                            <Link
                                                                                to="/wedding-bands/metal/18K Rose Gold"
                                                                                className="site-nav lvl-2">Rose
                                                                                Gold </Link></li>
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/metal_wg_dropdown.png')"}}>
                                                                            <Link
                                                                                to="/wedding-bands/metal/18K White Gold"
                                                                                className="site-nav lvl-2">White
                                                                                Gold</Link></li>
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/metal_yg_dropdown.png')"}}>
                                                                            <Link
                                                                                to="/wedding-bands/metal/18K Yellow Gold"
                                                                                className="site-nav lvl-2">Yellow
                                                                                Gold</Link></li>
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/metal_pt_dropdown.png')"}}>
                                                                            <Link to="/wedding-bands/metal/Platinum"
                                                                                  className="site-nav lvl-2">Platinum</Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="mt-3">
                                                                    <Link className="site-nav lvl-1 menu-title"
                                                                          to="/custom-design">Submit Your Customize
                                                                        Design</Link>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-lg-4">
                                                        <div className="row mm-Banners">
                                                            <div className="col-md-12 col-lg-12 imageCol text-center">
                                                                <div className="menubox position-relative">
                                                                    <Link to="/" className="mb-0"><img
                                                                        className="blur-up lazyload"
                                                                        src="/assets/img/cate-1.jpg"
                                                                        data-src="assets/img/cate-1.jpg" alt="image"
                                                                        width="400" height="342"/></Link>
                                                                    <p className="position-absolute bottom-0 start-50 translate-middle-x">
                                                                        <Link to="/" className="title text-uppercase">ENGAGEMENT
                                                                            RINGS</Link></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="lvl1 parent megamenu">
                                        <Link className="site-nav parent" to="/our-fine-jewelry">Fine Jewellery<i
                                            className="an an-angle-down-l"></i></Link>
                                        <div className="megamenu style1">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-8 col-lg-8">
                                                        <div className="row">
                                                            <div className="lvl-1 col-md-4 col-lg-4">
                                                                <div>
                                                                    <a href="#" className="site-nav lvl-1 menu-title">Jewellery
                                                                        Type</a>
                                                                    <ul className="subLinks">
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/menu_header_earrings_drop.png')"}}>
                                                                            <Link to="/fine-jewellery/type/Earrings"
                                                                                  className="site-nav lvl-2">Earrings</Link>
                                                                        </li>
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/menu_header_necklace.png')"}}>
                                                                            <Link to="/fine-jewellery/type/Necklaces"
                                                                                  className="site-nav lvl-2">Necklaces</Link>
                                                                        </li>
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/menu_header_bracelet_1.png')"}}>
                                                                            <Link to="/fine-jewellery/type/Bracelets"
                                                                                  className="site-nav lvl-2">Bracelets</Link>
                                                                        </li>
                                                                        <li className="lvl-2"
                                                                            style={{"--iconImg": "url('/assets/img/icon/spirit/menu_header_icon_women_diamond_band.png')"}}>
                                                                            <Link to="/fine-jewellery/type/Rings"
                                                                                  className="site-nav lvl-2">Rings</Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="lvl-1 col-md-4 col-lg-4">
                                                                <div>
                                                                    <a href="#" className="site-nav lvl-1 menu-title">Shop
                                                                        By Metal</a>
                                                                    <ul className="subLinks">
                                                                        <ul className="subLinks">
                                                                            <li className="lvl-2"
                                                                                style={{"--iconImg": "url('/assets/img/icon/spirit/metal_rg_dropdown.png')"}}>
                                                                                <Link
                                                                                    to="/fine-jewellery/metal/18K Rose Gold"
                                                                                    className="site-nav lvl-2">Rose
                                                                                    Gold </Link></li>
                                                                            <li className="lvl-2"
                                                                                style={{"--iconImg": "url('/assets/img/icon/spirit/metal_wg_dropdown.png')"}}>
                                                                                <Link
                                                                                    to="/fine-jewellery/metal/18K White Gold"
                                                                                    className="site-nav lvl-2">White
                                                                                    Gold</Link></li>
                                                                            <li className="lvl-2"
                                                                                style={{"--iconImg": "url('/assets/img/icon/spirit/metal_yg_dropdown.png')"}}>
                                                                                <Link
                                                                                    to="/fine-jewellery/metal/18K Yellow Gold"
                                                                                    className="site-nav lvl-2">Yellow
                                                                                    Gold</Link></li>
                                                                            <li className="lvl-2"
                                                                                style={{"--iconImg": "url('/assets/img/icon/spirit/metal_pt_dropdown.png')"}}>
                                                                                <Link
                                                                                    to="/fine-jewellery/metal/Platinum"
                                                                                    className="site-nav lvl-2">Platinum</Link>
                                                                            </li>
                                                                        </ul>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="lvl-1 col-md-4 col-lg-4">
                                                                <Link className="site-nav lvl-1 menu-title"
                                                                      to="/custom-design">Submit Your Customize Design<i
                                                                    className="an an-angle-down-l"></i></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-lg-4">
                                                        <div className="row mm-Banners">
                                                            <div className="col-md-12 col-lg-12 imageCol text-center">
                                                                <div className="menubox position-relative">
                                                                    <Link to="/" className="mb-0"><img
                                                                        className="blur-up lazyload"
                                                                        src="/assets/img/cate-1.jpg"
                                                                        data-src="assets/img/cate-1.jpg" alt="image"
                                                                        width="400" height="342"/></Link>
                                                                    <p className="position-absolute bottom-0 start-50 translate-middle-x">
                                                                        <Link to="/" className="title text-uppercase">ENGAGEMENT
                                                                            RINGS</Link></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <li className="lvl1 parent megamenu">
                                        <Link className="site-nav parent" to="/promos">Promos</Link>
                                    </li>
                                        <li className="lvl1 parent megamenu">
                                            <Link className="site-nav parent" to="/custom-design">Customize<i
                                                className="an an-angle-down-l"></i></Link>
                                        </li>
                                        <li className="lvl1 parent megamenu">
                                            <Link className="site-nav parent" to="/learn">Learn<i
                                                className="an an-angle-down-l"></i></Link>

                                        </li>
                                        <li className="lvl1 parent megamenu">
                                            <Link className="site-nav parent" to="/faqs">FAQ<i
                                                className="an an-angle-down-l"></i></Link>
                                        </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                {/* </nav> */}
            </div>
        </div>
    </div>
)
}

export default Navmain
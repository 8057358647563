import React, {useEffect, useState} from "react";
import { FaSearchPlus, FaRegHeart, FaHeart} from "react-icons/fa";

import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const ProductBlocksList = (props) => {

    let [isActive, setActive] = useState("false");
    let [isLoading, setLoading] = useState(false);
    const user_login_check = JSON.parse(localStorage.getItem("customer_id"));
    const currency = localStorage.getItem("currency");
    //const buttons = props.buttons ?? "";
    /*let wishToggle = () => {
        setActive(!isActive);
    };*/



    let [isWishlist, setWishlist] = useState(false);
    const wishListAdd = async (item) => {
        console.log("Added " + item);
        setLoading(true);
        var formData = new FormData();

        var requestData = {
            user_id: user_login_check,
            product_id: item,
            product_type: props.type,
            "action": "add"
        };
        formData.append("request", JSON.stringify(requestData));
        var requestOptions = {
            method: "POST",
            body: formData
        };
        var resp = await fetch(process.env.REACT_APP_API_URL + "/api/wishlist", requestOptions);

        var data = await resp.json();

        setLoading(false);
        if (data.success === true) {
            toast.info(data.data.msg);
            product_refresh();
        }

    };

    const wishListRemove = async (item) => {
        console.log("Remove " + item);
        setLoading(true);
        var formData = new FormData();

        var requestData = {
            user_id: user_login_check,
            product_id: item,
            "action": "remove"
        };
        formData.append("request", JSON.stringify(requestData));
        var requestOptions = {
            method: "POST",
            body: formData
        };
        var resp = await fetch(process.env.REACT_APP_API_URL + "/api/wishlist", requestOptions);


        var data = await resp.json();
        if(data.success === true) {
            setLoading(false);
            toast.info(data.data.msg);
            //alert("Remove Successfully");
            product_refresh();
        }
    };


    const [jd, setProductItem] = useState(props.product_id);


    const [compareList, setCompareList] = useState();

    const localcompare_list = JSON.parse(localStorage.getItem("compare_items")) ?? [];
    const compareAdd = (item, toggle) => {
        console.log("Added " + item);
 
        if(localStorage.getItem("compare_items") == null) {
            localStorage.setItem("compare_items", JSON.stringify([item]));
        } else{
            const localcompare_list = JSON.parse(localStorage.getItem("compare_items"));
            localStorage.setItem("compare_items", JSON.stringify([...localcompare_list, item]));
        }
        setLoading(false);
        product_refresh();
    };

    const compareRemove = (item, toggle) => {
        console.log("Remove " + item);

        if(localStorage.getItem("compare_items") == null) {
            localStorage.setItem("compare_items", JSON.stringify([]));
        } else{
            const localcompare_list = JSON.parse(localStorage.getItem("compare_items"));
            let remove_item = localcompare_list.splice(localcompare_list.indexOf(item), 1);
            let newList = localcompare_list.filter((item) => (item !== remove_item[0]));
            localStorage.setItem("compare_items", JSON.stringify(newList));
        }


        setLoading(false);
        product_refresh();
    };

    async function get_product(product_id) {

        var formData = new FormData();

        var requestdata = {
            id: product_id,
            type: props.type,
            currency: currency,
        };
        formData.append("request", JSON.stringify(requestdata));
        var requestOptions = {
            method: "POST",
            body: formData
        };

        var resp = await fetch(process.env.REACT_APP_API_URL + "/api/product_details", requestOptions);

        var data = await resp.json();
        if (data.data && data.data !== 0) {
            setProductItem(data.data);
        }

    }

    async function get_wishlist() {
        setLoading(true);
        var formData = new FormData();

        var requestData = {
            "user_id": user_login_check,
            "product_id": props.product_id,
            "action": "check"
        };
        formData.append("request", JSON.stringify(requestData));
        var requestOptions = {
            method: "POST",
            body: formData
        };
        var resp = await fetch(process.env.REACT_APP_API_URL + "/api/wishlist", requestOptions);

        var data = await resp.json();

        if (data.success == true) {
            setWishlist("fill");
        } else {
            setWishlist("unfill");
        }
        setLoading(false);
    }

    const [showQuickview, setShowQuickview] = useState(false);

    const quickviewClose = function(){
        setShowQuickview(false);

    }
    const quickviewShow = function(){
        setShowQuickview(true);

    }


    const renderWishlistTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Please login for wishlisting
        </Tooltip>
    );

    function product_refresh(){
        get_product(props.product_id);
        if(user_login_check) {
            //console.log(user_login_check);
            get_wishlist(user_login_check);

        }
    }

    useEffect(() => {
        get_product(props.product_id);
        if (user_login_check) {
            //console.log(user_login_check);
            get_wishlist(user_login_check);
        }


    }, []);


    return (
<>


    {jd ? (
        <>
            {props.type === "diamond" || props.type === "lab_diamond" ? (
                <>
                    <tr className='position-relative'>
                        <td >
                            {isLoading == true ? (
                                <div className="loader-container">
                                    <div className="loader"></div>
                                </div>
                            ):''}
                            <div className="grid-item-img  d-flex">

                                <img className="img-fluid"
                                     src={jd.front_image_url != "" ? jd.front_image_url : "/assets/img/static/product/baemont.png"} alt=""/>
                                <div className="grid-product-detail">

                                    <a
                                        className="text-dark" href={"/product-diamond/" + jd.stone_id}> {jd.shape} | {jd.carat}ct
                                        | {jd.color} | {jd.clarity} {jd.cut} cut    </a>
                                    <div className="d-block">
                                        <span className="fw-bold price-block">  {jd.total_price}</span></div>
                                    <div className="btnAction">
                                        {user_login_check ? (
                                            <>
                                                {(isWishlist == "fill" ? (
                                                    <button className="btn btn-addWishlist"
                                                            onClick={(e) => { wishListRemove(jd.stone_id);
                                                            }}  >
                                                        <FaHeart color={"red"}/>
                                                    </button>

                                                ):(
                                                    <button className="btn btn-addWishlist"
                                                            onClick={(e) => { wishListAdd(jd.stone_id);
                                                            }} >
                                                        <FaRegHeart/>

                                                    </button>
                                                ))}
                                            </>



                                        ):(
                                            <OverlayTrigger
                                                placement="bottom"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderWishlistTooltip}
                                            >
                                                <button className="btn btn-addWishlist">
                                                    <FaRegHeart/>
                                                </button>
                                            </OverlayTrigger>


                                        )}

                                        {(localcompare_list && localcompare_list.length > 0 ? (
                                                <div className="d-inline-block">

                                                    {(localcompare_list.filter((val) => val.includes(jd.stone_id)).length > 0 ? (
                                                            <button className="btn"
                                                                    onClick={(e) => {
                                                                        compareRemove(jd.stone_id, "remove");
                                                                    }}><span
                                                                className="compareok-icon"></span>
                                                            </button>) : (
                                                            <button className="btn"
                                                                    onClick={(e) => {
                                                                        compareAdd(jd.stone_id, "add");
                                                                    }}><span
                                                                className="compare-icon"></span>
                                                            </button>)
                                                    )}
                                                </div>
                                            )
                                            : (
                                                <button className="btn"
                                                        onClick={(e) => {
                                                            compareAdd(jd.stone_id, "add");
                                                        }}><span
                                                    className="compare-icon"></span></button>
                                            ))}



                                        <button className="btn btn-quickView" onClick={quickviewShow}>
                                            <FaSearchPlus/></button>
                                    </div>

                                    <Modal show={showQuickview} onHide={quickviewClose} className="quickView"
                                           dialogClassName="modal-xl"
                                           animation={false}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>{jd.shape} | {jd.carat}ct
                                                | {jd.color} | {jd.clarity} {jd.cut} cut </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <div className="section overview">
                                                            <div className="media">

                                                                {jd.media_video !== "" ? (
                                                                    <iframe title="360" className="quickview-frame"
                                                                            src={jd.media_video}></iframe>
                                                                ) : (
                                                                    <img src={jd.media_default}
                                                                         className="quickview-frame-img img-fluid"/>
                                                                )}

                                                            </div>


                                                            <div className="section px-4 pb-3">
                                                                <div className="details">
                                                                    <div className="item m-0 justify-content-center mb-3"><p
                                                                        className="label text-uppercase mr-3">Total
                                                                        Price</p>
                                                                        <p className="font-weight-bold value ms-2">{jd.total_price}</p>
                                                                    </div>

                                                                    <div className="item m-0"><p className="label">Location </p>
                                                                        <p className="value">{jd.location}</p></div>
                                                                    <div className="item m-0"><p className="label">Origin</p>
                                                                        <p className="value">{jd.origin}</p></div>
                                                                    <div className="item m-0"><p
                                                                        className="label">Measurement</p>
                                                                        <p className="value">{jd.measurement_width} X {jd.measurement_height} X {jd.measurement_length}</p>
                                                                    </div>
                                                                    <div className="item m-0"><p className="label">Depth</p>
                                                                        <p className="value">{jd.parameter_depth}</p></div>
                                                                    <div className="item m-0"><p className="label">Table</p>
                                                                        <p className="value">{jd.parameter_table}</p></div>
                                                                    <div className="item m-0"><p className="label">Girdle </p>
                                                                        <p className="value">{jd.girdle}</p></div>

                                                                    <div className="item m-0"><p className="label"> Key to
                                                                        symbols </p>
                                                                        <p className="value">{jd.key_to_symbols}</p></div>

                                                                    <div className="item m-0"><p className="label">Fluorescence
                                                                        Intensity</p>
                                                                        <p className="value">{jd.fancy_intensity},{jd.fancy_overtone}</p></div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 pb-5">
                                                        <div className="section">
                                                            <div className="details">
                                                                <div className="item"><p className="label">Report Type</p>
                                                                    <p className="value">Diamond Grading Report</p></div>
                                                                <div className="item"><p className="label">GIA Report No</p>
                                                                    <p className="value">{jd.lab_certificate_number}</p></div>

                                                                <div className="item"><p className="label">Shape</p>
                                                                    <p className="value">{jd.shape}</p></div>


                                                                <div className="item"><p className="label">Carat Weight</p>
                                                                    <p className="value">{jd.carat} Carat</p></div>
                                                                <div className="item"><p className="label">Color</p>
                                                                    <p className="value">{jd.color} </p></div>
                                                                <div className="item"><p className="label">Clarity</p>
                                                                    <p className="value"> {jd.clarity} </p></div>

                                                                <div className="item"><p className="label">Cut</p>
                                                                    <p className="value">{jd.cut}</p></div>

                                                                <div className="item"><p className="label">Polish</p>
                                                                    <p className="value">{jd.polish}</p></div>
                                                                <div className="item"><p className="label">Symmetry</p>
                                                                    <p className="value">{jd.symmetry}</p></div>
                                                                <div className="item"><p className="label">Fluorescence</p>
                                                                    <p className="value">{jd.fluorescence}</p></div>

                                                                <div className="item"><p className="label"> Side White </p>
                                                                    <p className="value">{jd.inc_crown_white}</p></div>


                                                                <div className="item"><p className="label"> Luster </p>
                                                                    <p className="value">{jd.luster}</p></div>
                                                                <div className="item"><p className="label"> Milky</p>
                                                                    <p className="value">{jd.parameter_milky}</p></div>

                                                                <div className="item"><p className="label"> Shades </p>
                                                                    <p className="value">{jd.shade}</p></div>
                                                                <div className="item"><p className="label"> Eye clean</p>
                                                                    <p className="value">{jd.parameter_eye_clean}</p></div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>

                                    </Modal>

                            </div>

                            </div>
                        </td>
                        <td>{jd.shape}</td>
                        <td>{jd.carat}</td>
                        <td>{jd.color}</td>
                        <td>{jd.clarity}</td>
                        <td>{jd.cut}</td>
                        <td>{jd.lab_company}</td>

                    </tr>
                </>

            ):''}

            {props.type === "ring" ? (
                <>
                    <tr className='position-relative'>
                        <td >
                            {isLoading == true ? (
                                <div className="loader-container">
                                    <div className="loader"></div>
                                </div>
                            ):''}
                            <div className="grid-item-img  d-flex">


                                <img className="img-fluid"  id={"ring_img_" + jd.sku}
                                     src={jd.front_pic} alt={jd.sku} />
                                <div className="grid-product-detail">

                                    <a
                                        className="text-dark" href={"/product-diamond/" + jd.sku}> {jd.product_name}   </a>
                                    <div className="d-block">
                                        <span className="fw-bold price-block">{jd.sell_price}</span></div>
                                    <div className="btnAction">
                                        {user_login_check ? (
                                            <>
                                                {(isWishlist == "fill" ? (
                                                    <button className="btn btn-addWishlist"
                                                            onClick={(e) => { wishListRemove(jd.sku);
                                                            }}  >
                                                        <FaHeart color={"red"}/>
                                                    </button>

                                                ):(
                                                    <button className="btn btn-addWishlist"
                                                            onClick={(e) => { wishListAdd(jd.sku);
                                                            }} >
                                                        <FaRegHeart/>

                                                    </button>
                                                ))}
                                            </>



                                        ):(
                                            <OverlayTrigger
                                                placement="bottom"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderWishlistTooltip}
                                            >
                                                <button className="btn btn-addWishlist">
                                                    <FaRegHeart/>
                                                </button>
                                            </OverlayTrigger>


                                        )}

                                        <button className="btn btn-quickView" onClick={quickviewShow}>
                                            <FaSearchPlus/></button>
                                    </div>

                                    <Modal show={showQuickview} onHide={quickviewClose} className="quickView"
                                           dialogClassName="modal-xl"
                                           animation={false}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>{jd.product_name}  </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <div className="section overview">

                                                            <div
                                                                className="align-items-center d-flex justify-content-center media">
                                                                <img className="img-fluid" id={"ring_img_quickview_" + jd.sku}
                                                                     src={jd.front_pic} alt={jd.sku}/>

                                                            </div>


                                                            <div className="section px-4 pb-3">
                                                                <div className="details">
                                                                    <div className="item m-0 justify-content-center mb-3"><p
                                                                        className="label text-uppercase mr-3">Total
                                                                        Price</p>
                                                                        <p className="font-weight-bold value ms-2">{jd.sell_price}</p>
                                                                    </div>


                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 pb-5">
                                                        <div className="section">
                                                            <div className="details">

                                                                <h5>Detail</h5>
                                                                <div className="item"><p className="label">SKU</p>
                                                                    <p className="value">{jd.sku_code}</p></div>
                                                                <div className="item"><p className="label">Metal</p>
                                                                    <p className="value">{jd.metal_type}</p></div>
                                                                <div className="item"><p className="label">Width</p>
                                                                    <p className="value">{jd.width}</p></div>
                                                                <div className="item"><p className="label">Weight</p>
                                                                    <p className="value">{jd.weight}</p></div>
                                                                {/*<hr/>*/}
                                                                {/*    <h5>Can be set with</h5>

                                                        <div className="item"><p className="label">Round</p>
                                                            <p className="value">0.50 - 6.00</p></div>
                                                        <div className="item"><p className="label">Oval</p>
                                                            <p className="value">0.50 - 6.00</p></div>
*/}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>

                                    </Modal>
                                </div>

                            </div>
                        </td>
                        <td>{jd.metal_type}</td>
                        <td>{jd.gender}</td>
                        <td>{jd.width}</td>
                        <td>{jd.weight}</td>

                    </tr>
                </>

            ):''}
            {props.type === "weddingband" ? (
                <>
                    <tr className='position-relative'>
                        <td >
                            {isLoading == true ? (
                                <div className="loader-container">
                                    <div className="loader"></div>
                                </div>
                            ):''}
                            <div className="grid-item-img  d-flex">


                                <img className="img-fluid"  id={"ring_img_" + jd.sku}
                                     src={jd.front_pic} alt={jd.sku} />
                                <div className="grid-product-detail">

                                    <a
                                        className="text-dark" href={"/product-diamond/" + jd.sku}> {jd.product_name}   </a>
                                    <div className="d-block">
                                        <span className="fw-bold price-block">{jd.sell_price}</span></div>
                                    <div className="btnAction">
                                        {user_login_check ? (
                                            <>
                                                {(isWishlist == "fill" ? (
                                                    <button className="btn btn-addWishlist"
                                                            onClick={(e) => { wishListRemove(jd.sku);
                                                            }}  >
                                                        <FaHeart color={"red"}/>
                                                    </button>

                                                ):(
                                                    <button className="btn btn-addWishlist"
                                                            onClick={(e) => { wishListAdd(jd.sku);
                                                            }} >
                                                        <FaRegHeart/>

                                                    </button>
                                                ))}
                                            </>



                                        ):(
                                            <OverlayTrigger
                                                placement="bottom"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderWishlistTooltip}
                                            >
                                                <button className="btn btn-addWishlist">
                                                    <FaRegHeart/>
                                                </button>
                                            </OverlayTrigger>


                                        )}

                                        <button className="btn btn-quickView" onClick={quickviewShow}>
                                            <FaSearchPlus/></button>
                                    </div>

                                    <Modal show={showQuickview} onHide={quickviewClose} className="quickView"
                                           dialogClassName="modal-xl"
                                           animation={false}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>{jd.product_name}  </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <div className="section overview">

                                                            <div
                                                                className="align-items-center d-flex justify-content-center media">
                                                                <img className="img-fluid" id={"ring_img_quickview_" + jd.sku}
                                                                     src={jd.front_pic} alt={jd.sku}/>

                                                            </div>


                                                            <div className="section px-4 pb-3">
                                                                <div className="details">
                                                                    <div className="item m-0 justify-content-center mb-3"><p
                                                                        className="label text-uppercase mr-3">Total
                                                                        Price</p>
                                                                        <p className="font-weight-bold value ms-2">{jd.sell_price}</p>
                                                                    </div>


                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 pb-5">
                                                        <div className="section">
                                                            <div className="details">

                                                                <h5>Detail</h5>
                                                                <div className="item"><p className="label">SKU</p>
                                                                    <p className="value">{jd.sku_code}</p></div>
                                                                <div className="item"><p className="label">Metal</p>
                                                                    <p className="value">{jd.metal_type}</p></div>
                                                                <div className="item"><p className="label">Width</p>
                                                                    <p className="value">{jd.width}</p></div>
                                                                <div className="item"><p className="label">Weight</p>
                                                                    <p className="value">{jd.weight}</p></div>
                                                                {/*<hr/>*/}
                                                                {/*    <h5>Can be set with</h5>

                                                        <div className="item"><p className="label">Round</p>
                                                            <p className="value">0.50 - 6.00</p></div>
                                                        <div className="item"><p className="label">Oval</p>
                                                            <p className="value">0.50 - 6.00</p></div>
*/}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>

                                    </Modal>
                                </div>

                            </div>
                        </td>
                        <td>{jd.metal_type}</td>
                        <td>{jd.gender}</td>
                        <td>{jd.width}</td>
                        <td>{jd.weight}</td>

                    </tr>
                </>

            ):''} {props.type === "finejewellery" ? (
                <>
                    <tr className='position-relative'>
                        <td >
                            {isLoading == true ? (
                                <div className="loader-container">
                                    <div className="loader"></div>
                                </div>
                            ):''}
                            <div className="grid-item-img  d-flex">


                                <img className="img-fluid"  id={"ring_img_" + jd.sku}
                                     src={jd.front_pic} alt={jd.sku} />
                                <div className="grid-product-detail">

                                    <a
                                        className="text-dark" href={"/product-diamond/" + jd.sku}> {jd.product_name}   </a>
                                    <div className="d-block">
                                        <span className="fw-bold price-block">{jd.sell_price}</span></div>
                                    <div className="btnAction">
                                        {user_login_check ? (
                                            <>
                                                {(isWishlist == "fill" ? (
                                                    <button className="btn btn-addWishlist"
                                                            onClick={(e) => { wishListRemove(jd.sku);
                                                            }}  >
                                                        <FaHeart color={"red"}/>
                                                    </button>

                                                ):(
                                                    <button className="btn btn-addWishlist"
                                                            onClick={(e) => { wishListAdd(jd.sku);
                                                            }} >
                                                        <FaRegHeart/>

                                                    </button>
                                                ))}
                                            </>



                                        ):(
                                            <OverlayTrigger
                                                placement="bottom"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderWishlistTooltip}
                                            >
                                                <button className="btn btn-addWishlist">
                                                    <FaRegHeart/>
                                                </button>
                                            </OverlayTrigger>


                                        )}

                                        <button className="btn btn-quickView" onClick={quickviewShow}>
                                            <FaSearchPlus/></button>
                                    </div>

                                    <Modal show={showQuickview} onHide={quickviewClose} className="quickView"
                                           dialogClassName="modal-xl"
                                           animation={false}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>{jd.product_name}  </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <div className="section overview">

                                                            <div
                                                                className="align-items-center d-flex justify-content-center media">
                                                                <img className="img-fluid" id={"ring_img_quickview_" + jd.sku}
                                                                     src={jd.front_pic} alt={jd.sku}/>

                                                            </div>


                                                            <div className="section px-4 pb-3">
                                                                <div className="details">
                                                                    <div className="item m-0 justify-content-center mb-3"><p
                                                                        className="label text-uppercase mr-3">Total
                                                                        Price</p>
                                                                        <p className="font-weight-bold value ms-2">{jd.sell_price}</p>
                                                                    </div>


                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 pb-5">
                                                        <div className="section">
                                                            <div className="details">

                                                                <h5>Detail</h5>
                                                                <div className="item"><p className="label">SKU</p>
                                                                    <p className="value">{jd.sku_code}</p></div>
                                                                <div className="item"><p className="label">Metal</p>
                                                                    <p className="value">{jd.metal_type}</p></div>
                                                                <div className="item"><p className="label">Width</p>
                                                                    <p className="value">{jd.width}</p></div>
                                                                <div className="item"><p className="label">Weight</p>
                                                                    <p className="value">{jd.weight}</p></div>
                                                                {/*<hr/>*/}
                                                                {/*    <h5>Can be set with</h5>

                                                        <div className="item"><p className="label">Round</p>
                                                            <p className="value">0.50 - 6.00</p></div>
                                                        <div className="item"><p className="label">Oval</p>
                                                            <p className="value">0.50 - 6.00</p></div>
*/}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>

                                    </Modal>
                                </div>

                            </div>
                        </td>
                        <td>{jd.metal_type}</td>
                        <td>{jd.gender}</td>
                        <td>{jd.width}</td>
                        <td>{jd.weight}</td>

                    </tr>
                </>

            ):''}
            {props.type === "promos" ? (
                <>
                    <tr className='position-relative'>
                        <td >
                            {isLoading == true ? (
                                <div className="loader-container">
                                    <div className="loader"></div>
                                </div>
                            ):''}
                            <div className="grid-item-img  d-flex">


                                <img className="img-fluid"  id={"ring_img_" + jd.sku}
                                     src={jd.front_pic} alt={jd.sku} />
                                <div className="grid-product-detail">

                                    <a
                                        className="text-dark" href={"/product-diamond/" + jd.sku}> {jd.product_name}   </a>
                                    <div className="d-block">
                                        <span className="fw-bold price-block">{jd.sell_price}</span></div>
                                    <div className="btnAction">
                                        {user_login_check ? (
                                            <>
                                                {(isWishlist == "fill" ? (
                                                    <button className="btn btn-addWishlist"
                                                            onClick={(e) => { wishListRemove(jd.sku);
                                                            }}  >
                                                        <FaHeart color={"red"}/>
                                                    </button>

                                                ):(
                                                    <button className="btn btn-addWishlist"
                                                            onClick={(e) => { wishListAdd(jd.sku);
                                                            }} >
                                                        <FaRegHeart/>

                                                    </button>
                                                ))}
                                            </>



                                        ):(
                                            <OverlayTrigger
                                                placement="bottom"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderWishlistTooltip}
                                            >
                                                <button className="btn btn-addWishlist">
                                                    <FaRegHeart/>
                                                </button>
                                            </OverlayTrigger>


                                        )}

                                        <button className="btn btn-quickView" onClick={quickviewShow}>
                                            <FaSearchPlus/></button>
                                    </div>

                                    <Modal show={showQuickview} onHide={quickviewClose} className="quickView"
                                           dialogClassName="modal-xl"
                                           animation={false}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>{jd.product_name}  </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <div className="section overview">

                                                            <div
                                                                className="align-items-center d-flex justify-content-center media">
                                                                <img className="img-fluid" id={"ring_img_quickview_" + jd.sku}
                                                                     src={jd.front_pic} alt={jd.sku}/>

                                                            </div>


                                                            <div className="section px-4 pb-3">
                                                                <div className="details">
                                                                    <div className="item m-0 justify-content-center mb-3"><p
                                                                        className="label text-uppercase mr-3">Total
                                                                        Price</p>
                                                                        <p className="font-weight-bold value ms-2">{jd.sell_price}</p>
                                                                    </div>


                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 pb-5">
                                                        <div className="section">
                                                            <div className="details">

                                                                <h5>Detail</h5>
                                                                <div className="item"><p className="label">SKU</p>
                                                                    <p className="value">{jd.sku_code}</p></div>
                                                                <div className="item"><p className="label">Metal</p>
                                                                    <p className="value">{jd.metal_type}</p></div>
                                                                <div className="item"><p className="label">Width</p>
                                                                    <p className="value">{jd.width}</p></div>
                                                                <div className="item"><p className="label">Weight</p>
                                                                    <p className="value">{jd.weight}</p></div>
                                                                {/*<hr/>*/}
                                                                {/*    <h5>Can be set with</h5>

                                                        <div className="item"><p className="label">Round</p>
                                                            <p className="value">0.50 - 6.00</p></div>
                                                        <div className="item"><p className="label">Oval</p>
                                                            <p className="value">0.50 - 6.00</p></div>
*/}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>

                                    </Modal>
                                </div>

                            </div>
                        </td>
                        <td>{jd.metal_type}</td>
                        <td>{jd.gender}</td>
                        <td>{jd.width}</td>
                        <td>{jd.weight}</td>

                    </tr>
                </>

            ):''}
        </>
    ): ""}
</>

    )

}
export default ProductBlocksList;
import React, {useState, useEffect, useRef} from "react";
import {useParams, useNavigate} from "react-router-dom";

import {FaRegHeart, FaHeart, FaTimes} from "react-icons/fa";
import GoToTop from "../parts/GoToTop";

import Modal from "react-bootstrap/Modal";


import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import {PropTypes} from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";


import FilterSteps from "../parts/filter-steps";

import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Btooltip from "react-bootstrap/Tooltip";

import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./Prodslide.css";

import {
    Magnifier,
    GlassMagnifier,
    SideBySideMagnifier,
    PictureInPictureMagnifier,
    MOUSE_ACTIVATION,
    TOUCH_ACTIVATION
} from "react-image-magnifiers";

// import required modules
import {FreeMode, Navigation, Thumbs} from "swiper";


import {ToastContainer, toast} from "react-toastify";


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function useOutsideAlerter(ref) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                if (document.querySelector(".inscription-close")) {
                    document.querySelector(".inscription-close").click();
                }
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

function RFRModal(props) {
    return (
        <Modal
            {...props}
            className="RFRModal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <button type="button" onClick={props.onHide} className="close">
                <svg viewBox="0 0 1792 1792">
                    <path
                        d="M1490 1322q0 40-28 68l-136 136q-28 28-68 28t-68-28l-294-294-294 294q-28 28-68 28t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294 294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68l-294 294 294 294q28 28 28 68z"></path>
                </svg>
            </button>
            <Modal.Body>
                <div>
                    <h2>The BAEMONT Guarantee</h2>
                    <div className="para-modal">
                        <h3>20/7 Customer Service</h3>
                        <p>We strive to provide the best possible response to our customers as our team of industry
                            experts stand by to serve with care.</p>
                    </div>

                    <div className="para-modal">
                        <h3>Product Lifetime Warranty</h3>
                        <p> All of our products are manufactured with the highest of quality and is carefully inspected
                            prior to shipment. We stand behind our products and warrant that all items will be free from
                            manufacturing defects at the time of delivery. </p>
                    </div>

                    <div className="para-modal">
                        <h3>Price-match Guarantee</h3>
                        <p> Our diamond prices are very competitive in our markets and we will offer to match the price
                            for a similar or identical piece of diamond sold elsewhere with lower pricing. </p>
                    </div>

                    <a href="/contact-us/"><em>Learn More...</em></a>
                </div>
            </Modal.Body>

        </Modal>
    );
}

function ProdFinejewellery(props) {


    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const [modalShowRFT, setModalShowRFT] = React.useState(false);
    const {id} = useParams();
    let [responsedata, setResponsedata] = useState([]);
    let [parentdata, setParentdata] = useState([]);
    let [variationdata, setVariationdata] = useState([]);
    let [stock, setStock] = useState(true);
    // let [metalres, setMetalres] = useState([]);

    let [slug, setSlug] = useState([]);
    let [itext, setItext] = useState(null);
    var formData = new FormData();
    let [thumbsSwiper, setThumbsSwiper] = useState();
    const [ringsize, setRingsize] = React.useState("");
    const [ringprice, setRingprice] = React.useState();

    let navigate = useNavigate();
    const handleChange = (event) => {
        setRingsize(event.target.value);
        localStorage.setItem("ringsize", event.target.value);
        getproductdetails();
    };

    let [rating, setRating] = useState(null);
    let [hover, setHover] = useState(null);
    let [isActive, setActive] = useState("false");


    const user_login_check = JSON.parse(localStorage.getItem("customer_id")) ?? "";

    let [isWishlist, setWishlist] = useState(false);

    const wishListAdd = async (item) => {
        console.log("Added " + item);
        props.loader(true);
        var formData = new FormData();

        var requestData = {
            user_id: user_login_check,
            product_id: item,
            product_type: "finejewellery",
            "action": "add"
        };
        formData.append("request", JSON.stringify(requestData));
        var requestOptions = {
            method: "POST",
            body: formData
        };
        var resp = await fetch(process.env.REACT_APP_API_URL + "/api/wishlist", requestOptions);

        var data = await resp.json();


        if (data.success == true) {
            toast.info(data.data.msg);
            props.loader(false);
        }
        if (user_login_check) {
            //console.log(user_login_check);
            get_wishlist(user_login_check);

        }
    };

    const wishListRemove = async (item) => {
        console.log("Remove " + item);
        props.loader(true);
        var formData = new FormData();

        var requestData = {
            user_id: user_login_check,
            product_id: item,
            "action": "remove"
        };
        formData.append("request", JSON.stringify(requestData));
        var requestOptions = {
            method: "POST",
            body: formData
        };
        var resp = await fetch(process.env.REACT_APP_API_URL + "/api/wishlist", requestOptions);


        var data = await resp.json();
        if (data.success == true) {
            props.loader(false);
            toast.info(data.data.msg);
        }
        if (user_login_check) {
            //console.log(user_login_check);
            get_wishlist(user_login_check);

        }
    };

    async function get_wishlist() {
        props.loader(true);
        var formData = new FormData();

        var requestData = {
            "user_id": user_login_check,
            "product_id": id,
            "action": "check"
        };
        formData.append("request", JSON.stringify(requestData));
        var requestOptions = {
            method: "POST",
            body: formData
        };
        var resp = await fetch(process.env.REACT_APP_API_URL + "/api/wishlist", requestOptions);

        var data = await resp.json();

        if (data.success == true) {
            setWishlist("fill");
        } else {
            setWishlist("unfill");
        }
        props.loader(false);
    }

    const renderWishlistTooltip = (props) => (
        <Btooltip id="button-tooltip" {...props}>
            Please login for wishlisting
        </Btooltip>
    );
    let wishToggle = () => {
        setActive(!isActive);
    };
    // let [tabtext2, setTabtext2] = useState([]);
    let [tabcss2, setTabcss2] = useState([]);

    let [diamond, setDiamond] = useState("");
    let [metal, setMetal] = useState("");
    let [carat, setCarat] = useState("");

    let [tabtext1, setTabtext1] = useState([]);
    let [tabcss1, setTabcss1] = useState([]);
    const [error, setError] = useState(false);

    let [ringpasram, setRingpasram] = useState([]);


    //Tabs
    function getvariationsku(metal,carat,diamond,diamondsku) {
        props.loader(true);
        setTimeout(async function (){
            if(diamondsku == 1) {
                var requestdata = {
                    id: id,
                    type: "finejewellery",
                    metal: metal,
                    carat: carat,
                    diamond: diamond,
                    diamonds_sku: 1,
                    currency: localStorage.getItem("currency") ?? "RM"
                };
            } else{
                var requestdata = {
                    id: id,
                    type: "finejewellery",
                    metal: metal,
                    carat: carat,
                    diamonds_sku: 0,
                    currency: localStorage.getItem("currency") ?? "RM"
                };
            }
            formData.append("request", JSON.stringify(requestdata));
            var requestOptions = {
                method: "POST",
                body: formData
            };


            var resp = await fetch(process.env.REACT_APP_API_URL + "/api/getvariation", requestOptions);
            var data = await resp.json();

            if(data.data){
                setStock(true)
                navigate("/product-jewellery/"+data.data.sku )
                props.loader(false);
            }else{
                setStock(false)
                props.loader(false);
            }
        },3000);


        //
        //getproductdetails();
    }
    async function getproductdetails() {
        props.loader(true);
        var requestdata = {
            id: id,
            type: "finejewellery",
            currency: localStorage.getItem("currency") ?? "RM",
            size: localStorage.getItem("ringsize") ?? "",
        };
        formData.append("request", JSON.stringify(requestdata));
        var requestOptions = {
            method: "POST",
            body: formData
        };


        var resp = await fetch(process.env.REACT_APP_API_URL + "/api/product_details", requestOptions);
        var data = await resp.json();

        setResponsedata(data.data);
        setMetal(data.data.metal_type);
        setDiamond(data.data.id);
        setCarat(data.data.total_carat);
        setSlug(data.data.sku);
        setParentdata(data.data.parent);
        setVariationdata(data.data.variation_data);
        setRingpasram(data.data.sizes);
        props.loader(false);
        if (data.success == false) {

            navigate("/fine-jewellery/all");
        }


    }


    async function postRecentview() {
        var customer_id = localStorage.getItem("customer_id");
        let uuid;
        if (customer_id) {
            uuid = customer_id;
        } else {
            uuid = "-1";
        }

        var requestdata = {
            product_id: id,
            uuid: uuid,
            type: "finejewellery",
            action: "add"
        };
        formData.append("request", JSON.stringify(requestdata));
        var requestOptions = {
            method: "POST",
            body: formData
        };

        var resp = await fetch(process.env.REACT_APP_API_URL + "/api/recent_view", requestOptions);

        var data = await resp.json();


    }

    const [value, setValue] = React.useState(0);

    const onTabChange = (event, newValue) => {
        setValue(newValue);
    };
    // end Tabs

    // Start Custom Tooltip inscription
    let [inscriptionInput, setInscriptionInput] = useState();
    const [openTooltip, setTooltipOpen] = React.useState(false);
    let [fontInscrip, setFontInscrip] = useState(false);
    const handleTooltipClose = (e) => {
        e.stopPropagation();
        setTooltipOpen(!openTooltip);
    };
    const handleTooltipOpen = () => {
        setTooltipOpen(!openTooltip);
    };
    let addFontOne = () => {
        var element = document.querySelector(".inscriptionText");
        element.classList.add("font1");
        element.classList.remove("font2");
    };
    let addFontTwo = () => {
        setFontInscrip(!fontInscrip);
        var element = document.querySelector(".inscriptionText");
        element.classList.add("font2");
        element.classList.remove("font1");
    };
    const getValue = () => {
        var ringincriptionval = document.getElementById("ringData").innerHTML;
        localStorage.setItem("ringText", ringincriptionval);
        console.log(ringincriptionval);
        let classValue = Array.from(document.getElementById("inscriptionId").classList);
        //console.log(classValue['1']);
        //if(classValue['1'] == ) {
        localStorage.setItem("ringfont", classValue["1"]);
        //}
        setTooltipOpen(!openTooltip);
        setItext(ringincriptionval);
    };
    var injectlenght = 20;
    let injectcalc;
    const injectTxt = (ev) => {

        ev.stopPropagation();
        if (ev.target.value.length < 21) {
            injectcalc = Number(20) - ev.target.value.length;
            document.getElementById("injectTxtLenght").innerHTML = injectcalc;
            document.getElementById("ringData").innerHTML = ev.target.value;
        }


    };
    const HtmlTooltip = styled(({className, ...props}) => (
        <Tooltip {...props} classes={{popper: className}}/>
    ))(({theme}) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#f5f5f9",
            color: "rgba(0, 0, 0, 0.87)",
            maxWidth: 50,
            minWidth: 25 + "em",
            fontSize: theme.typography.pxToRem(12),
            border: "1px solid #dadde9",
        },
    }));
    // End Custom Tooltip inscription


    //Start Modal
    const [open, setModalstate] = React.useState(false);
    const modalOpen = () => {
        setModalstate(true);
    };
    const modalClose = () => {
        setModalstate(false);
    };
    //End Modal


    const errorMessage = () => {
        return (
            <>
                {localStorage.getItem("ringsize") ? "" : (
                    <>
                        {error ?
                            <div className="mt-3 py-2 error alert d-flex align-items-center alert-danger">
                                Please select Size
                            </div>
                            :
                            ""}
                    </>
                )}

            </>
        );
    };


    const addToCart = (finejewellery) => {

        console.log(finejewellery);
        // if (ringsize === "") {
           // setError(true);
        // } else {
            //setError(false);
          /*  let selectedChkbox = {
                product_type: "simple",
                type: "finejewellery",
                product_data: finejewellery,
                slug: "/product-jewellery/",
                ring_size: localStorage.getItem("ringsize"),
                ring_price: localStorage.getItem("ringprice"),
                ring_font: localStorage.getItem("ringfont"),
                ring_text: localStorage.getItem("ringText")
            };*/
            let selectedChkbox = {
                product_type: "simple",
                type: "finejewellery",
                product_data: finejewellery,
                slug: "/product-jewellery/",
                ring_size: '',
                ring_price: '',
                ring_font: localStorage.getItem("ringfont"),
                ring_text: localStorage.getItem("ringText")
            };

            //props.setCartItem([...props.cartVal, selectedChkbox])
            var upindex = 0;
            var existItem = localStorage.getItem("productsbag") == null ? [] : JSON.parse(atob(localStorage.getItem("productsbag")));

            if (existItem.length > 0) {
                upindex = parseFloat(existItem.length);
            } else {

                upindex = 0;
            }
            console.log(existItem.length);
            existItem[upindex] = selectedChkbox;
            localStorage.setItem("productsbag", btoa(JSON.stringify(existItem)));
            localStorage.removeItem("indexvalue");
            console.log("add");

            props.setCartItem([""]);
            console.log(existItem.length);
            localStorage.removeItem("ringsize");
            localStorage.removeItem("ringprice");
            localStorage.removeItem("ringText");
            localStorage.removeItem("ringfont");
            localStorage.removeItem("diamondId");
            localStorage.removeItem("ringId");
            navigate("/cart", {replace: true});
        // }

    };

    const get_variation_slug = async (e,type) => {


        console.log(type);
        console.log(e);
        //console.log(e);
                if(type == 'metal'){
                    setMetal(e);
                    getvariationsku(e,carat,diamond,0);
                }

                if(type == 'carat'){
                      setCarat(e);
                    getvariationsku(metal,e,diamond,0);
                }
        if(type == 'diamond'){
            setDiamond(e);
            getvariationsku(metal,carat,e,1);
        }


              /*  console.log('metal',metal)
                console.log('carat',carat)
                console.log('diamond',diamond)*/

    };

    const get_variation_sku = async (e,type) => {


        console.log(type);
        console.log(e);
        //console.log(e);
        if(type == 'metal'){
            setMetal(e);
            getvariationsku(e,carat,diamond);
        }

        if(type == 'carat'){
            setCarat(e);
            getvariationsku(metal,e,diamond);
        }
        if(type == 'diamond'){
            setDiamond(e);
            getvariationsku(metal,carat,e);
        }


        /*  console.log('metal',metal)
          console.log('carat',carat)
          console.log('diamond',diamond)*/

    };

    useEffect(() => {

        localStorage.removeItem("ringsize");
        localStorage.removeItem("ringprice");
        localStorage.removeItem("ringText");
        localStorage.removeItem("ringfont");
        localStorage.removeItem("diamondId");
        localStorage.removeItem("ringId");
        getproductdetails();
        postRecentview();
    }, [navigate]);
    useEffect(() => {


        var inceptionText = localStorage.getItem("ringText");
        // console.log(inceptionText);
        // console.log(inceptionText == ' ');
        // console.log(inceptionText == null);

        setItext(inceptionText);
        // var diamondId = localStorage.getItem("diamondId");
        // var gemsId = localStorage.getItem("gemsId");

        /* if(diamondId!=null || gemsId!=null){
             var textto = 'DIAMOND';
             if(gemsId!=null){
                 var textto = 'GEMSTONE';
             }
             setTabtext2("SETTING");
             setTabtext1(textto);
             setTabcss2("active--bcso0");
             setTabcss1("");
         }else{
             var textto = 'DIAMOND';
             if(gemsId!=null){
                 var textto = 'GEMSTONE';
             }
             setTabtext1("SETTING");
             setTabtext2(textto);
             setTabcss2("");
             setTabcss1("active--bcso0");
         }*/
    }, [slug]);

    useEffect(() => {
        getvariationsku(metal,carat,diamond,1);
    },[metal,carat,diamond]);

    return (
        <>
            <section className="filter-sec fj-single-page mt-0">

                <div className="productdetail-inner">
                    <div className="container-fluid  py-5">

                        <div className="row g-4">
                            <div className="col-lg-8">

                                <div className="row g-1">


                                    {responsedata.is_variation == 1 ? (
                                        <div className='col-12 '>
                                            <div className='col-12 d-none d-lg-block '>
                                            <div className="row  ">
                                            {responsedata.gallery && responsedata.gallery.map((jd, i) => (

                                                <div key={i} className={"col-md-12 col-lg-6"}>
                                                    <div className=" magnifier-group-img bg-white">
                                                        <SideBySideMagnifier
                                                            imageSrc={jd}
                                                            imageAlt="Example"
                                                            cursorStyle={"zoom-in"}
                                                            alwaysInPlace={true}
                                                            mouseActivation={"click"}
                                                        />
                                                    </div>
                                                </div>

                                            ))}
                                        </div>
                                        </div>
                                            <div className="col-12  d-block d-lg-none">


                                                <Swiper
                                                    spaceBetween={10}
                                                    navigation={true}
                                                    modules={[FreeMode, Navigation, Thumbs]}
                                                    className="mySwiper2"
                                                >
                                                    {responsedata.gallery && responsedata.gallery.map((jd, i) => (

                                                        <SwiperSlide key={i}>
                                                            <img src={jd} alt=""/>
                                                        </SwiperSlide>


                                                    ))}


                                                </Swiper>
                                            </div>
                                        </div>






                                    ) : (
                                        <div>
                                            <div className="col-12">


                                                <Swiper
                                                    spaceBetween={10}
                                                    navigation={true}
                                                    thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}}
                                                    modules={[FreeMode, Navigation, Thumbs]}
                                                    className="mySwiper2"
                                                >

                                                    <SwiperSlide>
                                                        <img src={responsedata.front_pic} alt=""/>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <img src={responsedata.side_pic} alt=""/>
                                                    </SwiperSlide>


                                                </Swiper>
                                            </div>
                                        </div>

                                    )}

                                </div>

                                {responsedata.is_variation !== 1 ? (
                                    <Swiper
                                        onSwiper={setThumbsSwiper}
                                        spaceBetween={10}
                                        slidesPerView={4}
                                        freeMode={true}
                                        watchSlidesProgress={true}
                                        modules={[FreeMode, Navigation, Thumbs]}
                                        className="mySwiperBottom">
                                        <SwiperSlide>
                                            <img src={responsedata.front_pic} alt=""/>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src={responsedata.side_pic} alt=""/>
                                        </SwiperSlide>

                                    </Swiper>
                                ) : ""}
                            </div>
                            <div className="col-lg-4 prod-dtlCont">
                            <div className="fw-sticky position-sticky">
                                <h4 className="fw-normal diamond-heading">
                                    {responsedata.product_name}
                                                        

                                </h4>
                                <hr/>
                                <div className="pricePack d-block">
                                    {/*<div className="d-block">Total</div>*/}
                                    <div className="total">
                                        <span className="now-price">{responsedata.sell_price}</span>
                                    </div>
                                </div>
                               {/* <p className="text-muted mb-0">
                                    SKU: {responsedata.sku_code}
                                </p>*/}
                                <Button variant="default"
                                        className="riskfreeretail border-0 bg-transparent px-0 small"
                                        onClick={() => setModalShowRFT(true)}>
                                    Risk-free Retail
                                </Button>

                                <RFRModal
                                    show={modalShowRFT}
                                    onHide={() => setModalShowRFT(false)}
                                />


                                <div className="row">
                                    <div className="col-md-12">
                                        <label className="me-2" htmlFor="">{metal}</label>
                                        <div className="fw-metal fw-diamond filter-iconList">

                                            {variationdata.metal && variationdata.metal.map((jd, i) => (
                                                <div key={i} className="iconList-item">
                                                    <input type="radio" className="d-none"  onChange={(e) => {
                                                        get_variation_slug(jd.type,'metal')
                                                    }} value={jd.type} id={"metal_" + i}
                                                           checked={jd.type === metal ? true : false}/>

                                                    <label htmlFor={"metal_" + i}><img
                                                        className="img-fluid list-img" src={jd.icon}
                                                        alt=""/><span
                                                        className="ring-metal_label text-center">{jd.label}</span></label>
                                                </div>
                                            ))}

                                        </div>


                                    </div>

                                    <div className="col-md-12">
                                        <label className="me-2" htmlFor="">{carat} Total Carat Weight</label>
                                        <div className="fw-carat fw-diamond filter-iconList">

                                            {variationdata.carat && variationdata.carat.map((jd, i) => (
                                                <div key={i} className="iconList-item">
                                                    <input type="radio" className="d-none" onChange={(e) => {
                                                        get_variation_slug(jd,'carat')
                                                    }} value={jd} id={"carat_" + i}
                                                           checked={jd === carat ? true : false}/>

                                                    <label htmlFor={"carat_" + i}><span
                                                        className="ring-carat_label text-center">{jd}</span></label>
                                                </div>
                                            ))}

                                        </div>


                                    </div>

                                    <div className="col-md-12">
                                        <label className="me-2" data-diamond={diamond} htmlFor="">Select Your Diamond's Characteristics </label>
                                        <div className="fw-diamond ring-metal filter-iconList">
                                            {variationdata.diamond && variationdata.diamond.map((jd, i) => (

                                               <div key={i} className="iconList-item">
                                                            <input type="radio" className="d-none" onChange={(e) => {
                                                                get_variation_slug(jd.id,'diamond')
                                                            }} value={jd.id} id={"dimaond_" + i}
                                                                   checked={jd.id === diamond ? true : false}/>

                                                            <label htmlFor={"dimaond_" + i} className='d-block'>
                                                                <span className="diamond_p_box ring-carat_label">
                                                                <i className="diamond_p_icon fa-regular fa-gem"></i>
                                                                    <span className='diamond_p_text'>
                                                                         <span className='diamond_p_type'>{jd.diamond_type}</span>
                                                                        {jd.avg_color} color  {jd.avg_clarity} clarity
                                                                    </span>
                                                                   <span className='diamond_p_price'> {jd.sell_price} </span>

                                                            </span></label>
                                                        </div>




                                            ))}

                                        </div>


                                    </div>
                                </div>

                                <div className="row align-items-center">

{/*
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <select className="form-select" id="floatingSelect"
                                                    value={localStorage.getItem("ringsize") ?? ""}
                                                    onChange={handleChange}
                                            >
                                                <option disabled value="">Choose Size</option>
                                                {ringpasram.length > 0 ?
                                                    ringpasram.map((ring, index) =>
                                                        <option key={index} value={ring.size}>{ring.size}</option>
                                                    ) : ""}
                                            </select>
                                            <label htmlFor="floatingSelect">Choose Size</label>
                                        </div>


                                        {errorMessage()}
                                    </div>*/}
                                    <div className="col-12" ref={wrapperRef}>
                                        <HtmlTooltip
                                            title={
                                                <React.Fragment>


                                                    <div className="mb-2 d-flex flex-column">

                                                        <div className="row mb-2">
                                                            <div className="col">
                                                                <label>Enter Inscription</label>
                                                            </div>

                                                            <div className="col text-end">
                                                                <button type="button"
                                                                        className="p-0 lh-1 btn inscription-close"
                                                                        onClick={handleTooltipClose}>
                                                                    <FaTimes/>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <input className="form-control" type="text" name="" id=""
                                                               defaultValue={itext != null ? itext : ""}
                                                               placeholder="Engrave Text" maxLength={20}
                                                               onChange={injectTxt}/>
                                                        <small className="text-muted ms-auto">Characters Left:
                                                            <span id="injectTxtLenght">{injectlenght}</span>
                                                        </small>
                                                    </div>
                                                    <div className="mb-2">
                                                        <span className="d-block">Choose Font</span>
                                                        <div className="btn-group" role="group"
                                                             aria-label="Basic example">

                                                            <input type="radio" className="btn-check" name="font_style"
                                                                   id="font_style_one" autoComplete="off"
                                                                   value={"font1"} onClick={(e) => {
                                                                addFontOne(e.currentTarget.value);
                                                            }}/>
                                                            <label className="btn btn-outline-light p-0"
                                                                   htmlFor="font_style_one"> <img
                                                                src={window.location.origin + "/assets/img/inscription/font_1.gif"}
                                                                alt="" className="img-fluid"/></label>

                                                            <input type="radio" className="btn-check" name="font_style"
                                                                   id="font_style_two" autoComplete="off"
                                                                   value={"font2"} onClick={(e) => {
                                                                addFontTwo(e.currentTarget.value);
                                                            }}/>
                                                            <label className="btn btn-outline-light p-0"
                                                                   htmlFor="font_style_two"><img
                                                                src={window.location.origin + "/assets/img/inscription/font_2.gif"}
                                                                alt="" className="img-fluid"/></label>


                                                        </div>


                                                    </div>
                                                    <div className="d-block">
                                                        <div className="inscription-wrapper">
                                                            <svg viewBox="0 0 248 120">
                                                                <image className="inscriptionImg" width="246"
                                                                       height="119"
                                                                       href={window.location.origin + "/assets/img/inscription/Inscription.png"}
                                                                       transform="matrix(1 0 0 1 -0.22 0)">
                                                                </image>
                                                                <path className="inscriptionPath" id="SVGID_x5F_2_x5F_"
                                                                      d="M-0.2,75.8c29.3-9.4,73.3-19.9,127.3-19.4c49.7,0.4,90.5,9.9,118.7,18.9"
                                                                      fill="transparent"></path>
                                                                <text id="inscriptionId"
                                                                      className={fontInscrip ? "inscriptionText font2" : "inscriptionText font1"}
                                                                      textAnchor="middle">
                                                                    <textPath href="#SVGID_x5F_2_x5F_"
                                                                              startOffset="50%">
                                                                        <tspan className="font_1--xSr3A"
                                                                               id="ringData"> {itext}</tspan>
                                                                    </textPath>
                                                                </text>
                                                            </svg>
                                                        </div>
                                                        <small className="text-muted mt-2 mb-3 d-block">A 10x magnifying
                                                            glass may be required to clearly read the inscription on
                                                            your jewelry.</small>
                                                        <div className="d-flex justify-content-end">
                                                            <button className="btn btn-sm btn-primary text-uppercase"
                                                                    onClick={getValue}>Save
                                                            </button>
                                                        </div>
                                                    </div>

                                                </React.Fragment>
                                            }
                                            //open={setTooltipOpen}
                                            open={openTooltip}
                                            PopperProps={{
                                                disablePortal: true
                                            }}
                                            disableFocusListener={true}
                                            disableHoverListener={true}
                                            disableTouchListener

                                        >
                                            <button className="btn text-primary border-0 mt-2 px-0"
                                                    onClick={handleTooltipOpen}>
                                                <i className="fa-solid fa-plus me-1"></i>
                                                {itext ? itext : "Add free inscription"}
                                                {/*{itext ==null && itext == ""  ? itext:" Add free inscription"}*/}


                                            </button>
                                        </HtmlTooltip>
                                        {/* <button className='btn mt-2'><FaPlus size={10} onClick={() => handleTooltipOpen(!openTooltip)} /> Add free inscription</button> */}
                                    </div>
                                </div>

                                <div className="d-flex">

                                    {stock ? (
                                        <button className="btn btn-primary text-uppercase flex-fill text-uppercase me-2"
                                                value={responsedata.length > 0 ? responsedata.sku : ""}
                                                onClick={() => addToCart(responsedata)}>Add to cart
                                        </button>
                                    ):(
                                        <button className="btn btn-primary text-uppercase flex-fill text-uppercase me-2" disabled>
                                                                                                                                   Unavailable
                                        </button>
                                    )}


                                    {/*
                                    <button className="btn btn-default text-uppercase flex-fill text-uppercase me-2" value={responsedata ?responsedata.sku:''} onClick={e => selectRing(e.target.value)}>Select this Ring</button>*/}


                                    {user_login_check ? (
                                        <>
                                            {(isWishlist == "fill" ? (
                                                <button className="rounded-0 btn btn-default btn-addWishlist"
                                                        onClick={(e) => {
                                                            wishListRemove(responsedata.sku);
                                                        }}>
                                                    <FaHeart/>
                                                </button>

                                            ) : (
                                                <button className="rounded-0 btn btn-outline-default btn-addWishlist"
                                                        onClick={(e) => {
                                                            wishListAdd(responsedata.sku);
                                                        }}>
                                                    <FaRegHeart/>

                                                </button>
                                            ))}
                                        </>


                                    ) : (
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{show: 250, hide: 400}}
                                            overlay={renderWishlistTooltip}
                                        >
                                            <button className="rounded-0 btn btn-outline-default btn-addWishlist">
                                                <FaRegHeart/>
                                            </button>
                                        </OverlayTrigger>


                                    )}
                                </div>
                                {/* <div className="d-block mt-3">
                        <Link to='/product-details'>Go to review</Link>
                    </div> */}

                            </div>
                            </div>
                        </div>

                        {/*   <div className="row mt-4">
                <div className="col-12">
                    <h5>Product Description</h5>
                    <span className="sku text-muted mb-3 d-block"></span>

                </div>
            </div>*/}

                        <div className="row mt-4">
                            <div className="col-12">
                                <Box className="infos" sx={{width: "100%"}}>
                                   
                                    <div className="info-dtls" >
                                        <div className="container-fluid MuiBox-root">
                                        <div className="row">
                                            <div className="col-md-8 col-lg-6">
                                                <span className="d-block mb-2 fs-6 fw-bold text-uppercase" style={{color:'#1b1b1b'}}>Product Description</span>
                                                <div className='mb-3 text-muted '>SKU {responsedata.sku_code}</div>

                                                <p className='text-black'>{responsedata.description}</p>
                                            </div>
                                            <div className="col-md-12 mb-5">
                                            </div>
                                            <div className="col-md-6">

                                                <span className="d-block mb-2 text-black text-uppercase">{responsedata.cat} Information</span>
                                                <ul className="dtlInfo">
                                                    <li>
                                                        <div className="cell">Metal</div>
                                                        <div className="cell text-capitalize">{
                                                            responsedata.metal_type ?
                                                                responsedata.metal_type
                                                                : ""
                                                        }</div>
                                                    </li>
                                                    <li>
                                                        <div className="cell">Width / Length</div>
                                                        <div className="cell">{
                                                            responsedata.width ?
                                                                responsedata.width
                                                                : ""
                                                        }</div>
                                                    </li>
                                                    <li>
                                                        <div className="cell">Weight</div>
                                                        <div className="cell">{
                                                            responsedata.weight ?
                                                                responsedata.weight
                                                                : ""
                                                        }</div>
                                                    </li>
                                                    <li>
                                                        <div className="cell">Clasp Type</div>
                                                        <div className="cell">{
                                                            responsedata.clasp_type ?
                                                                responsedata.clasp_type
                                                                : ""
                                                        }</div>
                                                    </li>
                                                    <li>
                                                        <div className="cell">Rhodium Finish</div>
                                                        <div className="cell">{
                                                            responsedata.rhodium_finish ?
                                                                responsedata.rhodium_finish
                                                                : ""
                                                        }</div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-md-6">

                                                <span className="d-block mb-2 text-black text-uppercase">{responsedata.diamond_type}</span>
                                                <ul className='dtlInfo'>
                                                    <li>
                                                        <div className="cell">Shape</div>
                                                        <div className="cell">{
                                                            responsedata.diamond_shape ?
                                                                responsedata.diamond_shape
                                                                : ""
                                                        }</div>
                                                    </li>
                                                    <li>
                                                        <div className="cell">Quantity</div>
                                                        <div className="cell">{
                                                            responsedata.diamond_quantity ?
                                                                responsedata.diamond_quantity
                                                                : ""
                                                        }</div>

                                                    </li>
                                                    <li>
                                                        <div className="cell">Average Total Carat</div>
                                                        <div className="cell">{
                                                            responsedata.total_carat ?
                                                                responsedata.total_carat
                                                                : ""
                                                        }</div>

                                                    </li>
                                                    <li>
                                                        <div className="cell">Average Color</div>
                                                        <div className="cell">{
                                                            responsedata.avg_color ?
                                                                responsedata.avg_color
                                                                : ""
                                                        }</div>

                                                    </li>
                                                    <li>
                                                        <div className="cell">Average Clarity</div>
                                                        <div className="cell">{
                                                            responsedata.avg_clarity ?
                                                                responsedata.avg_clarity
                                                                : ""
                                                        }</div>

                                                    </li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                    </div>
                                    {/* <TabPanel className='info-dtls' value={value} index={1}>
                            <div className="row">
                                <div className="col-md-6">
                                     <span className="d-block mb-2">Ring Information</span>
                                    <ul className='dtlInfo'>
                                        <li>
                                            <div className="cell">Round</div>
                                            <div className="cell">0.50 - 6.00</div>
                                        </li>
                                        <li>
                                            <div className="cell">Oval</div>
                                            <div className="cell">0.50 - 6.00</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </TabPanel>*/}
                                </Box>
                            </div>
                        </div>
                        <div className="offer-banner">
                            <div className="row">
                                <div className="col-md-8 offer-text">
                                    <h4>Your Order</h4>
                                    <h1>Includes</h1>
                                    <div className="d-flex offer-points">
                                        <ul>
                                            <li>BAEMONT Exclusive Gift Packaging</li>
                                            <li>Diamond Certificate of Authenticity</li>
                                            <li>Product Lifetime Warranty</li>
                                        </ul>
                                        <ul className="mx-5">
                                            <li>Lifetime Upgrade</li>
                                            <li>Free Engraving</li>
                                            <li>Free Shipping (Local Orders)</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-4 offer-img">
                                    <img className="img-fluid" src="/assets/img/ring_engagement.png" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <GoToTop/>
        </>
    );
}

export default ProdFinejewellery;
import React, {useEffect, useState} from "react";
import { Link,useNavigate } from 'react-router-dom'


import CompareProduct from "./CompareProduct";

//localStorage.removeItem("indexvalue")


function CompareProductList(props) {
    const localcompare_list = JSON.parse(localStorage.getItem('compare_items')) ?? [];


    const [items, setItems] = useState(localcompare_list);


    const compareRemove = (item, toggle) => {
        console.log("Remove " + item);
        let remove_item = localcompare_list.splice(localcompare_list.indexOf(item), 1);
        let newList = localcompare_list.filter((item) => (item !== remove_item[0]));
        localStorage.setItem('compare_items', JSON.stringify(newList));

        const items = JSON.parse(localStorage.getItem('compare_items'));
        if (items) {
            setItems(items);
        }
    };
 
    useEffect(() => {

    }, [localcompare_list]);

    return (
        <>
     
            { items && items != "" ? (
                <>
                    <div className="compare-product-tr">
                        <div className="compare-table">
                            <div className="img-wrapper" style={{height: 392}}>

                            </div>
                            <ul>
                                <li>SKU</li>
                                <li>AVAILABILITY</li>
                                <li>CARAT</li>
                                <li>CLARITY</li>
                                <li>COLOUR</li>
                                <li>CUT</li>
                                <li>POLISH</li>
                                <li>SYMMERTRY</li>
                                <li>FLUORESCENCE</li>
                            </ul>
                        </div>
                    </div>
                    <div className="compare-product-list">

                        <div className="table-responsive">
                            <div className="compare-table table">
                                {
                                    localcompare_list.map((stone_id, i) => (

                                        <div className="compare-product-col  text-center"
                                             key={i}>
                                            <button data-id={stone_id} className="btn"
                                                    onClick={(e) => { compareRemove(stone_id, "remove");
                                                    }} style={{fontSize: 13}}
                                                    className="btn rounded-0 fw-bold text-uppercase px-3 text-uppercase mb-2">Remove
                                                X
                                            </button> 

                                            <CompareProduct key={i} {...{stone_id}}/>
                                        </div>


                                    ))
                                }
                            </div>
                        </div>

                    </div>
                </>
            ) : (
                <div>
                    <p
                        className="empty-compare empty-page"> Compare list is empty.</p>
                    <div className="empty-page-text">No products added in the compare list.
                        You
                        must add some products to compare them.
                    </div>
                    <p className="return-to-shop"><a className="btn btn-primary"
                                                     href="/"> Return to
                        Home </a></p>
                </div>
            )}
        </>
    )




}

export default CompareProductList
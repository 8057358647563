import React  from 'react'
import { useState, useEffect } from 'react';



import { useLocation } from 'react-router-dom';


import {ToastContainer, toast} from "react-toastify";
    
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';



function useLocationHash() {
    const [hash, setHash] = useState(window.location.hash);

    if(hash){
    window.addEventListener("hashchange", () => setHash(window.location.hash));
    }else{
        setHash("customer247")
    }
    return hash;
}
function Contact() {
    const hash = useLocationHash();
    const [key, setKey] = useState('home');

    const handleOnClick = () => {
        window.location.hash = Math.random();
    };

    useEffect(() => {
        loadCaptchaEnginge(6);
    }, [hash]);
    const reloadCaptch = async  (e) => {
        loadCaptchaEnginge(6);
    }

    const  ContactTabChange = function (e){
        window.location.hash =e;
    }

    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [phoneno, setPhoneno] = useState('');
    const [message, setMessage] = useState('');
    const [captcha, setCaptcha] = useState('');


    const handleSubmit = async  (e) => {
        e.preventDefault();

        let user_captcha_value = document.getElementById('user_captcha_input').value;

        if (validateCaptcha(user_captcha_value, false)==true) {
            var formData = new FormData();
            formData.append('request', JSON.stringify({
                name: fullname,
                email: email,
                phone: phoneno,
                captcha: captcha,
                message: message,
                event: "contact",
            }));

            var resp = await fetch(process.env.REACT_APP_API_URL+"/api/contact",{
                method: 'POST',
                body: formData
            });
            var data = await resp.json();

            if (data.success === true) {
                toast.success(data.msg);
                setFullname('');
                setPhoneno('');
                setMessage('');
                setEmail('');
                setCaptcha('');
                loadCaptchaEnginge(6);
            } else{
                toast.error(data.msg);
            }
        }

        else {
            toast.error('Captcha does not match');
        }

        //  console.log('Email submitted:', email);
    };

    return (
    <div>

  <section className="common-page-header">

       <div className="container">
    <div className="row pageTitle m0">
      <div className="col-12  text-center">
          <h1 className="display-3 text-white">THE BAEMONT GUARANTEE</h1>
          <ul className="breadcrumb text-center d-block">
             <li className="d-inline-block "><a className="text-white" href="index.html">HOME</a><span className="mx-2">/</span></li>
             <li className="d-inline-block"> THE BAEMONT GUARANTEE</li>
          </ul>

      </div>
       </div>
    </div>
 </section>
        <section>
            <div className="container py-5">
                <div className="row">
                    <div className="col-12">

        <Tab.Container id="contact_tab"       onSelect={(e) => ContactTabChange(e)} activeKey={hash.replace("#","")} defaultActiveKey="customer247">
            <Row>
                <Col sm={4}>
                    <Nav variant="pills" className="common-page-tab flex-column">

                        <Nav.Item><Nav.Link eventKey="customer247">20/7 Customer Service</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="lifeTimeWarranty">Product Lifetime Warranty</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="lifetimeUpgrade">Lifetime Upgrade</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="freeShipping">Free Domestic Shipping</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="freeResizing">Free Resizing</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="freeEngraving">Free Engraving</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="priceMatch">Price-match Guarantee</Nav.Link></Nav.Item>

                    </Nav>
                </Col>
                <Col sm={8}>
                    <Tab.Content>
                        <Tab.Pane eventKey="customer247">
                            <div id="GuaranteeRightPane">
                                <div className="RiskFreeContent">

                                    <h2>
                                        20/7 Customer Service
                                    </h2>

                                    <div>
                                        <p>
                                            We strive to provide the best possible response to our customers as our team
                                            of industry experts stand by to serve with care.
                                        </p>
                                        
                                        <table className="cs-table-padding">
                                            <tbody>
                                            <tr>
                                                <td className="circle-ja-container">
                                                    <div className="icon-container">
                                                        <i className="fas fa-phone"></i>
                                                    </div>
                                                </td>
                                                <td className="text-v-align">
                                                    <div className="header-font"><a href="tel:+60103630381">CALL or
                                                        WHATSAPP US</a></div>
                                                    <div>Tel: <u><a href="tel:+60103630381">+6010-363 0381</a></u>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr data-feature-hide="CloseService">
                                                <td className="circle-ja-container">
                                                    <div className="icon-container">
                                                        <i className="fas fa-comment"></i>
                                                    </div>
                                                </td>
                                                <td className="text-v-align">
                                                    <div id="chat-now-cs" className="header-underline header-font">Chat
                                                        Now
                                                    </div>
                                                    <div>Get instant answers to your questions.</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="circle-ja-container">
                                                    <div className="icon-container">
                                                        <i className="fas fa-envelope"></i>
                                                    </div>
                                                </td>
                                                <td className="text-v-align">
                                                    <div className="header-underline header-font"><a  
                                                                                                     href="mailto:hello@baemont.com">Email
                                                        Us</a></div>
                                                    <div>Email us at <a ajaxmode="disabled"
                                                                        href="mailto:hello@baemont.com">hello@baemont.com</a> and
                                                        we’ll try our best to address all the questions within two
                                                        business days.
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="circle-ja-container">
                                                    <div className="icon-container">
                                                        <i className="fas fa-comment-alt"></i>
                                                    </div>
                                                </td>
                                                <td className="text-v-align">
                                                    <div className="header-font">Send a Message</div>
                                                    <div>All questions will be answered within two business days.</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="circle-ja-container">
                                                </td>
                                                <td className="text-v-align">
                                                    <form  className="row g-4 contactForm" onSubmit={handleSubmit}>
                                                        <div className="col-sm-6">
                                                            <label htmlFor="name">Your Name *</label>
                                                            <input type="text" className="form-control" name="name" id="name" value={fullname}
                                                                   onChange={(e) => setFullname(e.target.value)}
                                                                   required  />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <label htmlFor="email">Your Email *</label>
                                                            <input type="email" className="form-control" name="email" id="email" value={email}
                                                                   onChange={(e) => setEmail(e.target.value)}
                                                                   required  />
                                                        </div>
                                                        <div className="col-12">
                                                            <label htmlFor="phone">Your Phone (Optional)</label>
                                                            <input type="tel" className="form-control" name="phone" id="phone" value={phoneno}
                                                                   onChange={(e) => setPhoneno(e.target.value)}
                                                                     />
                                                        </div>
                                                     {/*   <div className='col-12 col-sm-6'>
                                                            <label htmlFor="subject">subject *</label>
                                                            <input type="text" className="form-control" name="subject" id="subject"/>
                                                        </div>*/}
                                                        <div className='col-12 '>
                                                            <label htmlFor="message">Message</label>
                                                            <textarea name="message"  cols="40" rows="10"  id="message" className="form-control" value={message}
                                                                      onChange={(e) => setMessage(e.target.value)}
                                                                      required  ></textarea>
                                                        </div>

                                                        <div className='col-12'>
                                                            <div className='captcha'> <LoadCanvasTemplateNoReload />
                                                                     <button className='btn btn-sm p-0' type="button" onClick={(e)=> reloadCaptch()}>Reload Captcha</button>
                                                                <div>      <input  id="user_captcha_input" name="user_captcha_input" className='form-control' value={captcha}  onChange={(e) => setCaptcha(e.target.value)} type="text" required/>
                                                                </div>
                                                                </div>

                                                        </div>
                                                        <div className='col-12 d-flex'>
                                                            <button className="btn btn-primary btn-lg" type="submit">send message</button>
                                                        </div>


                                                    </form>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                                     
                                    </div>

                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="lifeTimeWarranty">
                            <div className="RiskFreeContent">

                                <h2 className="risk-free-header">
                                    Product Lifetime Warranty
                                </h2>

                                <div className="risk-free-inner-content">
                                    <p>
                                        All of our products are manufactured with the highest of quality and is
                                        carefully inspected prior to shipment. We stand behind our products and warrant
                                        that all items will be free from manufacturing defects at the time of delivery.
                                    </p>
                                    <p>
                                        If you believe your item has a manufacturing defect, you may return it to us for
                                        inspection. If we determine the damage is due to a manufacturing defect, we will
                                        repair it accordingly or, if we deem appropriate, replace the item. If the item
                                        is no longer available, we are happy to offer a replacement of similar value
                                        item or in our discretion allow for a refund of equal to the selling price of
                                        the original item.
                                    </p>
                                    <p>
                                        Our lifetime warranty does not apply to items subjected to excessive wear, abuse
                                        or physical damage whether accidental or otherwise. If we determine that the
                                        damage is not caused by a manufacturing defect, then we will notify you and let
                                        you know if repair services are available at cost or otherwise.
                                    </p>
                                    <p>
                                        Please contact us at <a ajaxmode="disabled"
                                                                href="mailto:hello@baemont.com">hello@baemont.com</a> if
                                        you have any questions or would like to take advantage of our free lifetime
                                        warranty to service your BAEMONT jewellery.
                                    </p>
                                </div>

                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="lifetimeUpgrade">
                            <div className="RiskFreeContent">

                                <h2 className="risk-free-header">
                                    Lifetime Upgrade
                                </h2>

                                <div className="risk-free-inner-content">
                                    <p>
                                        For our loyal customers, we are happy to provide a lifetime upgrade (or
                                        exchange) of diamonds purchased from BAEMONT at 100% of its purchased value in
                                        replacement of one that is at least 2x or greater value.
                                    </p>
                                    <p>
                                        The diamond in exchange must be in its original condition and accompanied by the
                                        original laboratory grading certification.
                                    </p>
                                    <p>
                                        Please contact us at <a ajaxmode="disabled"
                                                                href="mailto:hello@baemont.com">hello@baemont.com</a> so
                                        our team can further assist you in your requests.
                                    </p>
                                </div>

                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="freeShipping">
                            <div className="RiskFreeContent">
                                <h2 className="risk-free-header">
                                    Free Domestic Shipping
                                </h2>

                                <p>
                                    We provide free delivery for all local orders. For international orders, please
                                    refer to our international shipping policy <a
                                    href="/assets/Malaysia-Export-Rates-for-FedEx.pdf"
                                    target="_blank">here</a>.
                                </p>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="freeResizing">
                            <div className="RiskFreeContent">
                                <h2 className="risk-free-header">
                                    Free Resizing
                                </h2>

                                <div className="risk-free-inner-content">
                                    <p>
                                        We are pleased to offer a one-time free resizing for any BAEMONT ring purchased
                                        within the first 90-days, including free return shipping.
                                    </p>
                                    <p>
                                        Please take note that most rings are usually re-sizable but there are
                                        limitations on certain designs and styles that may not be suitable for
                                        re-sizing.
                                    </p>
                                    <p>
                                        Rings can only be resized 3 sizes each way for free. Any resizing outside of
                                        that range will incur additional fee.
                                    </p>
                                    <p>
                                        If you need to resize a BAEMONT ring outside of the 90-day period, a small fee
                                        will apply.
                                    </p>
                                    <p>
                                        Please contact us at <a ajaxmode="disabled"
                                                                href="mailto:hello@baemont.com">hello@baemont.com</a> if
                                        you have any questions or if you would like to request for resizing. Our team
                                        will gladly assist on the next step required.
                                    </p>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="freeEngraving">
                            <div className="RiskFreeContent">

                                <h2 className="risk-free-header">
                                    Free Engraving
                                </h2>

                                <div className="risk-free-inner-content">
                                    <p>
                                        We offer free engraving for most of our engagement rings and wedding bands! If
                                        available for your chosen ring, please let us know what you would like to
                                        engrave during checkout.
                                    </p>
                                    <p>
                                        You can engrave anything with a combination of alphanumeric up to 20 characters
                                        long.
                                    </p>
                                    <p>
                                        Engraving is commonly done for wedding bands to commemorate the event together
                                        with your partner.
                                    </p>
                                    <p>
                                        Please contact us at <a ajaxmode="disabled"
                                                                href="mailto:hello@baemont.com">hello@baemont.com</a> if
                                        you have any questions or would like us to cater for a special request. Our team
                                        will gladly assist.
                                    </p>
                                </div>

                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="priceMatch">
                            <div className="RiskFreeContent">

                                <h2 className="risk-free-header">
                                    Price-match Guarantee
                                </h2>

                                <div className="risk-free-inner-content">
                                    <p>
                                        We believe that our diamond prices are very competitive in our markets. Should
                                        you encounter a similar or identical piece of diamond that is of lower price
                                        than which is sold on our website, we are happy to review and offer to match the
                                        price.
                                    </p>
                                    <p>
                                        Please contact us at <a ajaxmode="disabled"
                                                                href="mailto:hello@baemont.com">hello@baemont.com</a> and
                                        our team conduct a review and assist you with the process.
                                    </p>
                                </div>

                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
                    </div>
                </div>
                </div>
        </section>

 
    </div>
  )
}

export default Contact
import React, { useState, useEffect } from 'react'
import { Link,useLocation } from 'react-router-dom'

import { config, library } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { FaSearch, FaRegUser, FaRegHeart, FaCartArrowDown, FaTrash, FaAngleDown, FaTimes } from "react-icons/fa"
import { BsCart2} from "react-icons/bs"
import { CgMenuLeft, IconName } from "react-icons/cg";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import Navmain from './Navmain'
import './NavStyle.css'
import MobileNav from './MobileNav'
import SideLoginRegister from './Side-login-register'

import CurrencySwitch from './currency-switch'
function Header(props) {
    var customer_id = localStorage.getItem("customer_id");
    var firstname = localStorage.getItem("firstname");
    var lastname = localStorage.getItem("lastname");
    const currency = localStorage.getItem("currency") ?? "$";
    //const [isDesktop, setDesktop] = useState("false")
    const [width, setWidth] = React.useState(window.innerWidth);
    const [isActive, setActive] = useState("false")
    let[navstate, setNavstate] = useState("false")
    const handleToggle = () => {
        setActive(!isActive);
    };

    const location = useLocation();


    const navOpen = () => {
        setNavstate(!navstate);
    };
    /* if (window.innerWidth < 800){
        setDesktop(true);
    } */
    const sessout=()=>{
        localStorage.removeItem("customer_id");
        localStorage.removeItem("firstname");
        localStorage.removeItem("lastname");
        localStorage.removeItem("mobile");
        localStorage.removeItem("payment_data");
        localStorage.removeItem("billing_country");
        localStorage.removeItem("availableCartProductIds");
        localStorage.removeItem("email");
        window.location.href = window.location.origin + '/login';
    }
    // let [ringsizeprice,setRingsizeprice] = useState([0]);
    let [ringsizes,setRingsizes] = useState([0]);
    var ringsize = localStorage.getItem("ringsize");
    var ringsizeprice = localStorage.getItem("ringprice");

    const removecart = (index) => {

        var existItem = localStorage.getItem('productsbag') == null ? [] : JSON.parse(atob(localStorage.getItem('productsbag')));

        existItem.splice(index, 1);
        localStorage.setItem("productsbag",btoa(JSON.stringify(existItem)));


        props.setCartItem(['']);

        localStorage.removeItem("coupon_val");
    }
 

    function empty(str)
    {
        return str == null || str == undefined || str == "";
    }

    var existing_cart = localStorage.getItem('productsbag') == null ? [] : JSON.parse(atob(localStorage.getItem('productsbag')));

//    console.log(existing_cart);
    const existing_cart_length =  existing_cart.length;
    var total_price = 0;
    for(var i = 0; i <  existing_cart.length; i++)
    {


        if(existing_cart[i]['product_type'] == "compound"){

            total_price += ((existing_cart[i]['ring']['sell_price_val'])+(existing_cart[i]['diamond']['total_price_val']));

        }else if(existing_cart[i]['product_type']=="simple"){

            console.log(existing_cart[i]);
            total_price +=  (existing_cart[i]['product_data']['sell_price_val']);
        }
    }

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);


        return () => window.removeEventListener("resize", handleWindowResize);
    },[existing_cart]);

    const formatter = new Intl.NumberFormat('en-us', {
        minimumFractionDigits: 2,
    });

    return (
        <>
            <div className="banTop">
                {width > 991? (
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <ul className="casa-pagina-top-list">
                                    <li><Link to="/contact-us#customer247">20/7 CUSTOMER SERVICE</Link></li>
                                    <li><Link to="/contact-us#freeShipping">FREE SHIPPING</Link></li>
                                    <li><Link to="/contact-us#lifetimeUpgrade">LIFETIME UPGRADE</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                ):(
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <Swiper
                                    className='banTop-slide'
                                    spaceBetween={50}
                                    slidesPerView={3}
                                    loop={true}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    onSlideChange={() => console.log('slide change')}
                                    onSwiper={(swiper) => console.log(swiper)}
                                    breakpoints={{
                                        "@0.00": {
                                            slidesPerView: 1,
                                        },
                                        "@0.75": {
                                            slidesPerView: 2,
                                        },
                                        "@1.00": {
                                            slidesPerView: 2,
                                        },
                                        "@1.50": {
                                            slidesPerView: 3,
                                        },
                                    }}
                                >
                                    <SwiperSlide><Link to="">20/7 CUSTOMER SERVICE</Link></SwiperSlide>
                                    <SwiperSlide><Link to="">FREE SHIPPING</Link></SwiperSlide>
                                    <SwiperSlide><Link to="">LIFETIME UPGRADE</Link></SwiperSlide>

                                </Swiper>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <header className="header_area">
                <div className="header_middel sticky-header">
                    <div className="container-fluid">
                        <div className="row ">
                            <div className="col-12 col-lg-11 mx-auto d-flex align-items-center justify-content-between ">

                                <div className="siteLogo me-2">
                                    <div className="navOn">
                                        <button className='btn border' onClick={navOpen}><CgMenuLeft /></button>
                                    </div>
                                    <div className="logo  d-inline-block p-0">
                                        <Link to="/"><img src={window.location.origin + "/assets/img/logo.png"} alt="" /></Link>
                                    </div>
                                </div>

                                <div className=" ">
                                    <Navmain />
                                </div>
                                <div className=" rightAction">
                                    <div className="middel_right">
                                        <CurrencySwitch/>
                                        {( customer_id!=null)?
                                            (    <div className="dropdown">
                                                <button className="btn"  role="button" id="userPan" data-bs-toggle="dropdown" aria-expanded="false"><FaRegUser /></button>
                                                <ul className="dropdown-menu mt-4" aria-labelledby="userPan">
                                                    <li><h6 className="dropdown-header">
                                                        {( customer_id!=null)?firstname+' '+lastname:''}
                                                    </h6></li>
                                                    <li><hr className="dropdown-divider" /></li>
                                                    {
                                                        ( customer_id!=null) ? <li><Link className="dropdown-item" to="/account">My Account</Link></li>:
                                                            <li><Link className="dropdown-item" to="/login">Login</Link></li>
                                                    }

                                                    {
                                                        ( customer_id!=null) ? <li><Link className="dropdown-item" to="/account?tab=1">My Order</Link></li>:
                                                            ''
                                                    }
                                                    {
                                                        ( customer_id!=null) ? <li><hr className="dropdown-divider" /></li>:
                                                            ''
                                                    }

                                                    {
                                                        ( customer_id!=null) ? <li><Link className="dropdown-item" onClick={sessout} to="#">Logout</Link></li>:
                                                            ''
                                                    }

                                                </ul>
                                            </div>)
                                            : (
                                                <>
                                                    {location.pathname !== "/login" ? (
                                                        <SideLoginRegister loader={props.loader}></SideLoginRegister>
                                                    ):""}

                                                </>
                                            )}


                                        <Link className="btn" to="/account?tab=2"><FaRegHeart /></Link>

                                        {width > 767? (
                                            <div  className="cart_link">
                                                <button to="#"  onClick={handleToggle}>
                                                <span className="position-relative">
                                                <BsCart2 className='cartIcon' fontSize="2.2em" />
                                                      <span  className="cart_quantity">{existing_cart_length}</span>
                                                </span>


                                                    <span className="cart_text_quantity"> {currency} {formatter.format(total_price)}</span> </button>

                                                <div className={ isActive ? "mini_cart" : 'mini_cart active'}>
                                                    <div className="cart_close">
                                                        <div className="cart_text">
                                                            <h3>cart</h3>
                                                        </div>
                                                        <div className="mini_cart_close">
                                                            <a href="#" className='link-dark'  onClick={handleToggle} >
                                                                <i className="fa-solid fa-xmark"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {

                                                        //total_price =props.cartitem.product_type=="compound" ?
                                                        existing_cart.length>0?
                                                            existing_cart.map( (item,index) => (
                                                                <div key={index}>
                                                                    {item.product_type=="compound" ?
                                                                        <div  className="cart_item">

                                                                            <div className="cart_img">
                                                                                <Link to={"/product-ring/"+item.ring.sku}><img className='img-fluid' src={item.ring.front_pic} alt="" /></Link>
                                                                            </div>

                                                                            <div className="cart_info">
                                                                                <Link to={"/product-ring/"+item.ring.sku}>{item.ring.product_name + " " }</Link>
                                                                                <span className="quantity">
                                                                                     {item.diamond.diamond_type} {item.diamond.shape} | {item.diamond.carat}ct
                                                | {item.diamond.color} | {item.diamond.clarity} {item.diamond.cut} cut
                                                Diamond </span>
                                            <span className="price_cart">{currency} {(parseFloat(item.ring.sell_price_val)+ parseFloat(item.diamond.total_price_val)).toFixed(2)}
{/*{new Intl.NumberFormat('en-us', {}).format(item.product_type=="compound"? ((item.ring.sell_price_val)+(item.diamond.total_price_val)).toFixed(2):0) }*/}
                                                                </span>
                                                                            </div>
                                                                            <div className="cart_remove">
                                                                                {/* <Link to="#" onClick={()=>removecart(index)}><FaTrash /></Link> */}
                                                                                <button className="btn btn-sm btn-white" onClick={()=>removecart(index)}>
                                                                                    <i className="fa-solid fa-trash"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div   className="cart_item">

                                                                            <div className="cart_img">
                                                                                <Link to="#"><img className='img-fluid' src={item.product_data.front_pic} alt="" /></Link>
                                                                            </div>

                                                                            <div className="cart_info">
                                                                                <span className="quantity">{item.product_data.product_name }</span>

                                                                                <span className="price_cart">{item.product_data.sell_price }</span>
                                                                            </div>
                                                                            <div className="cart_remove">
                                                                                {/* <Link to="#" onClick={()=>removecart(index)}><FaTrash /></Link> */}
                                                                                <button className="btn btn-sm btn-white" onClick={()=>removecart(index)}>
                                                                                    <i className="fa-solid fa-trash"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>

                                                                    }
                                                                </div>
                                                            )):''

                                                    }
                                                    {
                                                        existing_cart.length>0 ? (
                                                   <>
                                                       <div className="cart_total">
                                                           <span>Subtotal:</span>
                                                           <span>{currency} {total_price.toFixed(2)}</span>
                                                       </div>
                                                       <div className="mini_cart_footer px-0">
                                                           <div className="cart_button view_cart">
                                                               <Link className="btn btn-default d-block mb-3 rounded-0 text-uppercase" to="cart" onClick={handleToggle} >View cart</Link>
                                                           </div>
                                                           <div className="cart_button checkout">
                                                               <Link className="btn btn-default d-block mb-3 rounded-0 text-uppercase bg-dark text-white" to="checkout" onClick={handleToggle} >Checkout</Link>
                                                           </div>
                                                       </div>
                                                   </>
                                                        ): (
                                                         <>
                                                             <div className="py-5 text-center"><i
                                                                 className="fa-cart-shopping fa-solid fs-1 mb-3 mt-5 opacity-50"></i>
                                                                 <p>Your cart is currently empty.</p></div>
                                                         </>
                                                        )

                                                    }
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="cart_link ms-0">
                                                <button onClick={(ev)=>{ev.preventDefault();window.location.href='cart'}} >
                                                    <BsCart2 className='cartIcon m-0 p-0 border-0' fontSize="1.5em" />
                                                    <span className="cart_quantity">{existing_cart_length}</span>
                                                </button>

                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header_bottom sticky-header">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <div className="main_menu_inner">

                                    <div className="main_menu">


                                        <div className="mobile-nav-wrapper" role="navigation">
                                            <aside className={ navstate ? "off-canvas-wrapper" : "off-canvas-wrapper active"}>
                                                <div className="off-canvas-inner">
                                                    <div className="off-canvas-overlay"></div>
                                                    <div className="off-canvas-content">
                                                        <div className="off-canvas-header">
                                                            <div className="close-action">
                                                                <button className="btn-menu-close d-flex align-items-center justify-content-between" onClick={navOpen}>Close menu<FaTimes /></button>
                                                            </div>
                                                        </div>

                                                        <MobileNav />
                                                    </div>
                                                </div>
                                            </aside>
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </header>
        </>
    )

}

export default Header

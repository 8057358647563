import React from 'react'

import './staticPages.css'

function FineJewelryPage() {
    return (
        <section className='sp-page'>
            <div className='d-block d-md-block d-lg-none p-0'>
                <img className='img-fluid fj-header-img' src="assets/img/static/fine-jewelry/FJ-Header.png" alt="" style={{objectFit: 'cover'}} />
            </div>
            <div id='fj-header' className='text-center'  style={{backgroundImage: `url("assets/img/static/fine-jewelry/FJ-Header.png")`}}>
                <h2 className='sp-subheading'>Fine</h2>
                <h2 className='sp-heading'>JEWELRY</h2>
                <div className='sp-para'>May our hand-crafted jewelry pieces be part of your daily
                    essentials<br/>– cater for as your go-to pieces, to your unique stylized selection and most of all,
                    the special ocassions.<br/>
                    Choose our range of extensive collection of diamond, gemstone, and pearl jewelry gifts.
                </div>
                <a className="sp-button btn" href='/fine-jewellery/all'>SHOP ALL</a>
            </div>


            <div className='container' id='jewelry-type'>
                <div className='row'>
                    <div className='col-md-6 mb-0 mb-md-4'>
                        <div className='fn-box fn-box-earrings text-end' style={{backgroundImage: `url("assets/img/static/fine-jewelry/FJ-Earrings.png")`}}>
                            <h2 className="">Earrings</h2>
                            <p>Let it rings, let it glitters,<br/>May this pair of shimmering gifts,<br/>Be the one that
                                you or someone special deserved.</p>
                            <a className="sp-button" href='/fine-jewellery/type/Earrings'>Shop Earrings</a>
                        </div>
                    </div>
                    <div className='col-md-6 mb-0 mb-md-4'>
                        <div className='fn-box fn-box-rings text-start' style={{backgroundImage: `url("assets/img/static/fine-jewelry/FJ-Rings.png")`}}>
                            <h2 className="">Rings</h2>
                            <p>Be it solo, be it a team,<br/>Let them slide with all their sight,<br/>As their class of
                                act, will naturally shine.</p>
                            <a className="sp-button" href='/fine-jewellery/type/Rings'>Shop Rings</a>
                        </div>
                    </div>
                    <div className='col-md-6 mb-0 mb-md-4'>
                        <div className='fn-box fn-box-bracelets text-end' style={{backgroundImage: `url("assets/img/static/fine-jewelry/FJ-Bracelets.png")`}}>
                            <h2>Bracelets</h2>
                            <p>Hold them loose or hold them tight,<br/>The strength within them should blend with your
                                might,<br/>The best of bang and brace is on this site.</p>
                            <a className="sp-button" href='/fine-jewellery/type/Bracelets'>Shop Bracelets</a>
                        </div>
                    </div>
                    <div className='col-md-6 mb-0 mb-md-4'>
                        <div className='fn-box fn-box-necklaces text-start' style={{backgroundImage: `url("assets/img/static/fine-jewelry/FJ-Necklaces.png")`}}>
                            <h2>Necklaces</h2>
                            <p>There are no one size fits all,<br/>As the unique fit comes from within,<br/>Whenever in
                                doubt, just accesorize</p>
                            <a className="sp-button" href='/fine-jewellery/type/Necklaces'>Shop Necklaces</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container py-5' id='material-type'>
                <div className='row'>
                    <div className='col-12 text-center'>
                        <h2 className='sp-heading mb-3'>Find Your Mantra Material</h2>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 px-1 mb-2">
                        <div className='fn-box fn-box-white text-center' style={{backgroundImage: `url("assets/img/static/fine-jewelry/FJ-4-Row-Metal-Cluster-WG.v2-01.png")`}}>
                            <h2>WHITE GOLD</h2>
                            <div className='fn-box-spacer'></div>
                            <a className="sp-button" href="/fine-jewellery/metal/18K White Gold">SHOP WHITE GOLD</a>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 px-1 mb-2">
                        <div className='fn-box fn-box-rose text-center' style={{backgroundImage: `url("assets/img/static/fine-jewelry/FJ-4-Row-Metal-Cluser-RGv3-01.png")`}}>
                            <h2>ROSE GOLD</h2>
                            <div className='fn-box-spacer'></div>
                            <a className="sp-button" href="/fine-jewellery/metal/18K Rose Gold">SHOP ROSE GOLD</a>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 px-1 mb-2">
                        <div className='fn-box fn-box-yellow text-center' style={{backgroundImage: `url("assets/img/static/fine-jewelry/FJ-4-Row-Metal-Cluser-YGv2-01.png")`}}>
                            <h2>YELLOW GOLD</h2>
                            <div className='fn-box-spacer'></div>
                            <a className="sp-button" href="/fine-jewellery/metal/18K Yellow Gold">SHOP YELLOW GOLD</a>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 px-1 mb-2">
                        <div className='fn-box fn-box-platinum text-center' style={{backgroundImage: `url("assets/img/static/fine-jewelry/FJ-4-Row-Metal-Cluser-PT.v2-01.png")`}}>
                            <h2>PLATINUM</h2>
                            <div className='fn-box-spacer'></div>
                            <a className="sp-button" href="/fine-jewellery/metal/Platinum">SHOP PLATINUM</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FineJewelryPage
import React from 'react'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

const CoupleBands = [ "40002RG18K-Couples-Psyche-300x169.png", "40002WG18K-Couples-Psyche-300x169.png", "40002YG18K-Couples-Psyche-300x169.png", "40003RG18K-Couples-Helios-300x169.png", "40003WG18K-Couples-Helios-300x169.png", "40003YG18K-Couples-Helios-300x169.png", "40004RG18K-Couples-Chiron-300x169.png", "40004WG18K-Couples-Chiron-300x169.png", "40005WG18K-Couples-Juno-300x169.png","40001PT-Couples-Origin-Belief-300x169.png", "40001RG18K-Couples-Origin-Belief-300x169.png", "40001YG18K-Couples-Origin-Belief-300x169.png"];

const EternityBands = ["20005PT-Eternity-Half-Ballad-150x150.jpg", "20005RG18K-Eternity-Half-Ballad-150x150.jpg", "20005YG18K-Eternity-Half-Ballad-150x150.jpg", "20007WG18K-Eternity-Half-Petite-Shared-150x150.jpg", "20007YG18K-Eternity-Half-Petite-Shared-150x150.jpg", "20008RG18K-Eternity-Full-Petite-Shared-150x150.jpg", "20009RG18K-Diamond-Flair-150x150.jpg", "20009WG18K-Diamond-Flair-150x150.jpg", "20009YG18K-Diamond-Flair-150x150.jpg"];


const CoupleBandsOptions = {
    responsive: {
        0: {items: 1}, 480: {items: 2}, 768: {items: 3}, 980: {items: 3}, 1200: {items: 3}, 1500: {items: 3}
    },
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    smartSpeed: 2000,
    fluidSpeed: 2000,
    autoplaySpeed: 200,
    navSpeed: 2000,
    dotsSpeed: 2000,
    loop: true,
    nav: true,
    dots: true,
    responsiveRefreshRate: 200,
    slideBy: 1,
    mergeFit: true,
    mouseDrag: true,
    touchDrag: true
}


const EternityBandsOptions = {
    responsive: {
        0: {items: 1}, 480: {items: 2}, 768: {items: 3}, 980: {items: 3}, 1200: {items: 3}, 1500: {items: 3}
    },
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    smartSpeed: 2000,
    fluidSpeed: 2000,
    autoplaySpeed: 200,
    navSpeed: 2000,
    dotsSpeed: 2000,
    loop: true,
    nav: true,
    dots: true,
    responsiveRefreshRate: 200,
    slideBy: 1,
    mergeFit: true,
    mouseDrag: true,
    touchDrag: true
}

const forherOptions = {
    responsive: {
        0: {items: 1}, 480: {items: 2}, 768: {items: 3}, 980: {items: 3}, 1200: {items: 3}, 1500: {items: 3}
    },
    autoplay: true,
    // autoplayTimeout: 5000,
    autoplayHoverPause: true,
    smartSpeed: 2000,
    fluidSpeed: 5000,
    autoplaySpeed: 2000,
    navSpeed: 2000,
    dotsSpeed: 2000,
    loop: true,
    nav: false,
    dots: true,
    responsiveRefreshRate: 200,
    slideBy: 1,
    mergeFit: true,
    mouseDrag: true,
    touchDrag: true
}
const forhimOptions = {
    responsive: {
        0: {items: 1}, 480: {items: 2}, 768: {items: 3}, 980: {items: 3}, 1200: {items: 3}, 1500: {items: 3}
    },
    autoplay: true,
    // autoplayTimeout: 5000,
    autoplayHoverPause: true,
    smartSpeed: 2000,
    fluidSpeed: 5000,
    autoplaySpeed: 2000,
    navSpeed: 2000,
    dotsSpeed: 2000,
    loop: true,
    nav: false,
    dots: true,
    responsiveRefreshRate: 200,
    slideBy: 1,
    mergeFit: true,
    mouseDrag: true,
    touchDrag: true
}


function WeddingBandsPage() {
    return (<section className='sp-page'>
            <div className='container-outter' id='wedding-header'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12 col-lg-5 col-xl-7 p-0'>
                            <img className='img-fluid w-100 h-100' src="assets/img/static/wedding-bands/wb-header.jpg" alt=""
                                 style={{objectFit: 'cover'}}/>
                        </div>
                        <div className='col-12 col-lg-7  col-xl-5 px-0 py-5 align-self-center text-center'>
                            <div className='container-content'>
                                <h2>Wedding Bands</h2>
                                <h6 className='mb-4'>SAY "I DO" WITH A UNIQUELY CRAFTED WEDDING BAND</h6>
                                <p>Marking a memorable moment with your very own wedding band measured to fit your size
                                    and style whilst complementing your engagement ring</p>
                                <a className="btn sp-button mt-4" href='/wedding-bands/all'>SHOP NOW</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            <div className='container' id='wedding-bands'>
                <div className='row'>
                    <div className='col-12 col-lg-6 py-5 text-center'>
                        <h2>Couple Bands</h2>
                        <p>Discover our featured collection of couples wedding bands. Show your love and devotion with a
                            matching wedding band.</p>
                        <p>Find a pair that matches both you and your partner’s style and taste from the classics to
                            contemporary bands.</p>


                        <OwlCarousel id='CoupleBands' className='owl-theme'  {...CoupleBandsOptions}>
                            {CoupleBands.map(function (CoupleBand, i) {

                                return <div className='item' key={i}>
                                    <img className='img-fluid'
                                         src={'assets/img/static/wedding-bands/couple-bands/' + CoupleBand} alt=""
                                         />

                                </div>;

                            })}


                        </OwlCarousel>

                        <a className="btn sp-button" href='/wedding-bands/female/Couples'>EXPLORE NOW</a>
                    </div>
                    <div className='col-12 col-lg-6 py-5 text-center'>
                        <h2>Eternity Bands</h2>
                        <p>Eternity bands to celebrate the unity of your love, with diamonds laced all around the ring
                            in a continuous loop.</p>
                        <p>A perfect style to match with your diamond centrepiece making it your very own signature and
                            personalized way of saying “I do”.</p>

                        <OwlCarousel id='EternityBands' className='owl-theme'  {...EternityBandsOptions}>
                            {EternityBands.map(function (EternityBand, i) {
                                return <div className='item' key={i}>
                                    <img className='img-fluid'
                                         src={'assets/img/static/wedding-bands/eternity-bands/' + EternityBand} alt=""/>

                                </div>
                            })}
                        </OwlCarousel>
                        
                        <a className="btn sp-button" href='/wedding-bands/female/Eternity'>EXPLORE NOW</a>
                    </div>
                </div>
            </div>
            <div className='container' id='forher'>
                <div className='row'>
                    <div className='col-12 col-lg-5  order-lg-2 p-0'>
                        <div className='forhim-image' style={{backgroundImage: `url("assets/img/static/wedding-bands/iPrimoHK2.jpg")`}}></div>
                    </div>
                    <div className='col-12 col-lg-7  order-lg-1 p-0 position-relative'>

                        <div className='forher-bg text-center h-100 p-5'>
                            <div className='forher-overlay' style={{backgroundImage: `url("assets/img/static/wedding-bands/01740-30-Pre-HH-0159-scaled.jpg")`}}></div>
                      <div className='forher-inner'>
                        <h2>For Her...</h2>
                        <p>Explore and choose from our collections of wedding bands that are unique in style with
                            options to customize in white, yellow or rose gold. All available in 18K gold. Find that
                            perfect wedding band to celebrate your bond.</p>

                        <OwlCarousel  className='owl-theme'  {...forherOptions}>
                            <div className='item'>
                                <img className='img-fluid'
                                     src="assets/img/static/wedding-bands/for-her/20002WG18K-Classic-Petite-Comfort-Fit-2mm-150x150.png"
                                     alt=""/>
                                <h3>Classic</h3>
                                <p>Classic wedding bands are an evergreen choice for weddings. A sure decision for the
                                    newlyweds.</p>
                            </div>
                            <div className='item'>
                                <img className='img-fluid '
                                     src="assets/img/static/wedding-bands/for-her/20009WG18K-Diamond-Flair-150x150.png"
                                     alt=""/>
                                <h3>Diamond</h3>
                                <p>Diamond wedding bands are suitable for those who likes bright and sparkly jewellery
                                    on their ring finger.</p>
                            </div>
                            <div className='item'>
                                <img className='img-fluid '
                                     src="assets/img/static/wedding-bands/for-her/20006WG18K-Eternity-Full-Ballad-150x150.png"
                                     alt=""/>
                                <h3>Eternity</h3>
                                <p>Eternity wedding bands, also known as infinity band, are perfect for special
                                    occasions or anniversary celebrations.</p>
                            </div>
                        {/*   Duplicate*/}
                            <div className='item'>
                                <img className='img-fluid '
                                     src="assets/img/static/wedding-bands/for-her/20002WG18K-Classic-Petite-Comfort-Fit-2mm-150x150.png"
                                     alt=""/>
                                <h3>Classic</h3>
                                <p>Classic wedding bands are an evergreen choice for weddings. A sure decision for the
                                    newlyweds.</p>
                            </div>
                            <div className='item'>
                                <img className='img-fluid'
                                     src="assets/img/static/wedding-bands/for-her/20009WG18K-Diamond-Flair-150x150.png"
                                     alt=""/>
                                <h3>Diamond</h3>
                                <p>Diamond wedding bands are suitable for those who likes bright and sparkly jewellery
                                    on their ring finger.</p>
                            </div>
                            <div className='item'>
                                <img className='img-fluid '
                                     src="assets/img/static/wedding-bands/for-her/20006WG18K-Eternity-Full-Ballad-150x150.png"
                                     alt=""/>
                                <h3>Eternity</h3>
                                <p>Eternity wedding bands, also known as infinity band, are perfect for special
                                    occasions or anniversary celebrations.</p>
                            </div>
                        </OwlCarousel>
                      </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container pb-5' id='forhim'>

                <div className='row'>

                    <div className='col-12 col-lg-5  p-0'>
                        <div className='forhim-image' style={{backgroundImage: `url("assets/img/static/wedding-bands/photo-1489370603040-dc6c28a1d37a.jpg")`}}></div>
                    </div>
                    <div className='col-12 col-lg-7  p-0 position-relative'>

                        <div className='forhim-bg text-center h-100 p-5'>
                            <div className='forhim-inner'>
                            <div className='forhim-overlay' style={{backgroundImage: `url("assets/img/static/wedding-bands/01740-30-Pre-HH-0242-scaled.jpg")`}}></div>
                            <h2>For Him...</h2>
                            <p>Find a bespoke piece that fits your style from our Men’s classic wedding band collection.
                                Whether it’s a timeless piece or one that is outstanding to the eye, you can decide on a
                                complimenting pair with your beloved’s wedding band.</p>

                            <OwlCarousel className='owl-theme'  {...forhimOptions}>
                                <div className='item'>
                                    <img className='img-fluid'
                                         src="assets/img/static/wedding-bands/for-him/30002WG18K-Classic-Comfort-Fit-5mm-1-150x150.png"
                                         alt=""/>
                                    <h3>Classic</h3>
                                    <p>Men’s classic wedding bands are perfect for a simple and traditional choice for the
                                        newlyweds</p>
                                </div>

                                <div className='item'>
                                    <img className='img-fluid'
                                         src="assets/img/static/wedding-bands/for-him/30017WG18K-Classic-Bennett-1-150x150.png"
                                         alt=""/>
                                    <h3>Carved</h3>
                                    <p>Men’s carved wedding bands are popular for those who seeks a subtle change to the
                                        classic wedding band.</p>
                                </div>

                                <div className='item'>
                                    <img className='img-fluid'
                                         src="assets/img/static/wedding-bands/for-him/30017WG18K-Classic-Bennett-2-150x150.png"
                                         alt=""/>
                                    <h3>Diamond</h3>
                                    <p>Men’s diamond wedding bands are suited for those who wants to match with their
                                        partners for a flashier but subtle outlook.</p>
                                </div>
                            {/*    Duplicate */}
                                <div className='item'>
                                    <img className='img-fluid '
                                         src="assets/img/static/wedding-bands/for-him/30002WG18K-Classic-Comfort-Fit-5mm-1-150x150.png"
                                         alt=""/>
                                    <h3>Classic</h3>
                                    <p>Men’s classic wedding bands are perfect for a simple and traditional choice for the
                                        newlyweds</p>
                                </div>

                                <div className='item'>
                                    <img className='img-fluid '
                                         src="assets/img/static/wedding-bands/for-him/30017WG18K-Classic-Bennett-1-150x150.png"
                                         alt=""/>
                                    <h3>Carved</h3>
                                    <p>Men’s carved wedding bands are popular for those who seeks a subtle change to the
                                        classic wedding band.</p>
                                </div>

                                <div className='item'>
                                    <img className='img-fluid '
                                         src="assets/img/static/wedding-bands/for-him/30017WG18K-Classic-Bennett-2-150x150.png"
                                         alt=""/>
                                    <h3>Diamond</h3>
                                    <p>Men’s diamond wedding bands are suited for those who wants to match with their
                                        partners for a flashier but subtle outlook.</p>
                                </div>
                            </OwlCarousel>
                        </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>)
}

export default WeddingBandsPage
import React, {useState, useEffect, useCallback, useRef} from "react";
import {Link, useParams} from "react-router-dom";
import {FaThList, FaTh, FaSearchPlus, FaRegHeart, FaHeart, FaTimes, FaGripLines, FaRedoAlt} from "react-icons/fa";
import "./Setting.css";
import {Slider, InputNumber, Radio} from "antd";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Dropdown from "react-bootstrap/Dropdown";

import ReactPaginate from "react-paginate";

import ProductItem from "../parts/product_grid";
import ProductItemList from "../parts/product_list";

import RecentProducts from "../parts/recently-products";
import NumericInput from "react-numeric-input";
//import { Slider, Range } from 'rc-slider'
import "rc-slider/assets/index.css";
import FilterSteps from "../parts/filter-steps";

const style = {margin: "40px 8px"};
const style1 = {margin: "40px 8px"};
const labelStyle = {minWidth: "60px", display: "inline-block"};


const marks = {
    0: {
        style: {
            transform: "translateX(-3px)",
        },
        label: "Excellent"
    },
    33.333333: "Very Good",
    66.6667: "Good",
    100: {
        style: {
            right: 0,
            left: "unset",
            transform: "translateX(3px)",
        },
        label: "Fair"
    },
};


/*== Start ResponsiveFilter ==*/

function addClass(arr, element) {
    if (arr.length > 0) {
        for (var i = 0; i < arr.length; i++) {
            document.querySelector(`${element}`).classList.add(arr[i]);
        }
    }
}

function removeClass(arr, element) {
    if (arr.length > 0) {
        for (var i = 0; i < arr.length; i++) {
            document.querySelector(`${element}`).classList.remove(arr[i]);
        }
    }
}


var angle = 0;
var half_of_height = (window.innerHeight / 2);
var pop_up = document.querySelector(".pop-up");

// $(pop_up).draggable();

function btn_click() {
    if (window.matchMedia("(max-width: 767px)").matches) {
        var postModalBody = document.querySelector(".popModal-body");
        //*******************//
        addClass(["filterPopMobile"], "body");
        var pop_up = document.querySelector(".pop-up");
        pop_up.classList.remove("pop-up-top-100");
        pop_up.classList.add("pop-up-bottom-0");
    } else {
        alert("your device width to high");
    }
}

function close_popup() {
    var pop_up = document.querySelector(".pop-up");
    pop_up.removeAttribute("style");
    pop_up.removeAttribute("data-x");
    pop_up.removeAttribute("data-y");
    pop_up.classList.add("pop-up-top-100");
    pop_up.classList.remove("pop-up-bottom-0");
    removeClass(["filterPopMobile"], "body");
}

/*== End ResponsiveFilter ==*/


/*****Filter Button mobile******/


function FineJwellery(props) {


    const mobile_filter_btn = ["Category", "Stone", "Pearl", "Birthstone", "Metal", "Price"];
    const enabled_stone = ["Aquamarine", "Blue Sapphire"];
    const enabled_peral = [];
    const enabled_birthstone = [];
    const enabled_metal = ["18K White Gold", "18K Yellow Gold", "18K Rose Gold", "Platinum"];
    const currency = localStorage.getItem("currency") ?? "RM";
    const [mobilePopup, setMobilePopup] = useState("");

    const [offset, setOffset] = useState(0);
    const [perPage] = useState(20);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    let [orderby, setOrderby] = useState("id");

    let [catres, setCatre] = useState([]);
    let [subcatres, setSubcatres] = useState([]);
    let [metalres, setMetalres] = useState([]);
    let [stoneres, setStoneres] = useState([]);
    let [peralres, setPeralres] = useState([]);
    let [birthstoneres, setBirthstoneres] = useState([]);


    let [jesdada, setJesdata] = useState([]);
    let [jescnt, setJescnt] = useState([]);

    //Sub Category
    let [category, setCategory] = useState("");
    const {metaltype} = useParams();
    const params = useParams();
    let metal_params;
    if (params.metaltype) {
        metal_params = [params.metaltype];
    }  else{
        metal_params = [];
    }
    //Metal
    let [metal, setMetal] = useState(metal_params);
    let [stone, setStone] = useState([]);
    let [peral, setPeral] = useState([]);
    let [birthstone, setBirthstone] = useState([]);
    //Set Price
    const [isPrice, setPrice] = useState([200, 1000000]);
    const [minPrice, setMinPrice] = useState("200");
    const [maxPrice, setMaxPrice] = useState("1000000");
    const PriceMin = (value: number) => {
        setMinPrice(value);
        // filterTrigger();
    };
    const PriceMax = (value: number) => {
        setMaxPrice(value);
        // filterTrigger();
    };

    const PriceRangeChange = (value: number | [number, number]) => {
        setMinPrice(value[0]);
        setMaxPrice(value[1]);
    };

    const handlePageClick = async (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        //  console.log(selectedPage * perPage)
        setOffset(selectedPage * perPage);
        const scroll_top = document.getElementById("filter-slide");
        if (scroll_top) {

            scroll_top.scrollIntoView({block: "start", behavior: "smooth"});
        }
    };


    function resetpagination(){
        setCurrentPage(0)
        setOffset(0)
    }



    const [isStyleClicked, settyleClicked] = useState(false);
    const [isMetalClicked, setMetalClicked] = useState(false);
    const [isStoneClicked, setStoneClicked] = useState(false);
    const [isPeralClicked, setPeralClicked] = useState(false);
    const [isBirthstoneClicked, setBirthstoneClicked] = useState(false);

    async function getRingdatas() {
        var flag = 0;
        props.loader(true);
        // var querystr = id.split("=");
        var formData = new FormData();
        var request_data = new FormData();


        if (isStyleClicked == false) {
            if (params.type) {
                setCategory(params.type);
            }
        }

//        console.log(isMetalClicked)

        /*if (isMetalClicked == false) {
            if (params.metaltype) {
                setMetal([params.metaltype]);
            }
        }
*/

        if (category) {
            flag = 1;

            request_data.categories = category;
        }

        if (stone) {
            flag = 1;

            request_data.diamond_shape = stone;
        }
        if (peral) {
            flag = 1;

            request_data.peral = peral;
        }
        if (birthstone) {
            flag = 1;

            request_data.birthstone = birthstone;
        }
        if (metal) {
            flag = 1;

            request_data.metal_type = metal;
        }


        if (isPrice.length > 0) {
            flag = 1;
            request_data.sell_price = isPrice;

        }

        request_data.currency = localStorage.getItem("currency");
        request_data.offset = offset;
        request_data.orderby = orderby;

        //console.log(request_data);


        //Main Categories
        var SubCatMaleData = new FormData();
        SubCatMaleData.append("request", JSON.stringify({
            action: "finejewellery_cat"
        }));

        var ring_subcatmale_res = await fetch(process.env.REACT_APP_API_URL + "/api/setting", {
            method: "POST",
            body: SubCatMaleData
        });
        var ring_subcatmale_json = await ring_subcatmale_res.json();
        if (ring_subcatmale_json.success == true) {
            setCatre(ring_subcatmale_json.data);
        }
        //Stone Type
        var StoneData = new FormData();
        StoneData.append("request", JSON.stringify({
            action: "finejewellery_stone"
        }));
        var ring_stone_res = await fetch(process.env.REACT_APP_API_URL + "/api/setting", {
            method: "POST",
            body: StoneData
        });
        var ring_stone_json = await ring_stone_res.json();
        if (ring_stone_json.success == true) {
            setStoneres(ring_stone_json.data);
        }

        //Peral
        var PeralData = new FormData();
        PeralData.append("request", JSON.stringify({
            action: "finejewellery_peral"
        }));
        var ring_peral_res = await fetch(process.env.REACT_APP_API_URL + "/api/setting", {
            method: "POST",
            body: PeralData
        });
        var ring_peral_json = await ring_peral_res.json();
        if (ring_peral_json.success == true) {
            setPeralres(ring_peral_json.data);
        }

        //Birthstone
        var BirthstoneData = new FormData();
        BirthstoneData.append("request", JSON.stringify({
            action: "finejewellery_birthstone"
        }));
        var ring_birthstone_res = await fetch(process.env.REACT_APP_API_URL + "/api/setting", {
            method: "POST",
            body: BirthstoneData
        });
        var ring_birthstone_json = await ring_birthstone_res.json();
        if (ring_birthstone_json.success == true) {
            setBirthstoneres(ring_birthstone_json.data);
        }

        //Metal Type
        var MetalData = new FormData();
        MetalData.append("request", JSON.stringify({
            action: "finejewellery_metal"
        }));
        var ring_metal_res = await fetch(process.env.REACT_APP_API_URL + "/api/setting", {
            method: "POST",
            body: MetalData
        });
        var ring_metal_json = await ring_metal_res.json();
        if (ring_metal_json.success == true) {
            setMetalres(ring_metal_json.data);
        }

        //Ring
        request_data.type = "finejewellery";
        formData.append("request", JSON.stringify(request_data));

        if (flag == 1) {
            let requestOptions = {
                method: "POST",
                body: formData
            };
            var response = await fetch(process.env.REACT_APP_API_URL + "/api/getproducts", requestOptions);
            var resp = await response.json();
            setJesdata(resp.data);
            setJescnt(resp.count);
            setPageCount(Math.ceil(jescnt / perPage));
            props.loader(false);
        }
    }

    const resetFilter = (e) => {
        setCategory("");
        setMetal([]);
        setPeral([]);
        setBirthstone([]);
        setPrice(200, 1000000);
        setMinPrice(200);
        setMaxPrice(1000000);
        setOffset(0);

    };

    useEffect(() => {
        getRingdatas();
        var diamondId = localStorage.getItem("diamondId");
        var gemsId = localStorage.getItem("gemsId");

    }, [category, stone, peral, birthstone, metal, isPrice, jescnt, offset]);


    const handleMetal = (e) => {
        const metal_val = e.target.value;
        var metal_index = metal.indexOf(metal_val);
        console.log(metal_index);
        if (metal_index !== -1) {

            setMetal([
                ...metal.slice(0, metal_index),
                ...metal.slice(metal_index + 1)
            ]);
        } else {
            setMetal(current => [...current, e.target.value]);
        }
    };
    const handleStone = (e) => {
        const stone_val = e.target.value;
        var stone_index = stone.indexOf(stone_val);
        console.log(stone_index);
        if (stone_index !== -1) {

            setStone([
                ...stone.slice(0, stone_index),
                ...stone.slice(stone_index + 1)
            ]);
        } else {
            setStone(current => [...current, e.target.value]);
        }

    };
    const handlePeral = (e) => {
        const peral_val = e.target.value;
        var peral_index = peral.indexOf(peral_val);
        console.log(peral_index);
        if (peral_index !== -1) {

            setPeral([
                ...peral.slice(0, peral_index),
                ...peral.slice(peral_index + 1)
            ]);
        } else {
            setPeral(current => [...current, e.target.value]);
        }

    };

    const handleBirthstone = (e) => {
        const birthstone_val = e.target.value;
        var birthstone_index = peral.indexOf(birthstone_val);
        console.log(birthstone_index);
        if (birthstone_index !== -1) {

            setBirthstone([
                ...birthstone.slice(0, birthstone_index),
                ...birthstone.slice(birthstone_index + 1)
            ]);
        } else {
            setBirthstone(current => [...current, e.target.value]);
        }

    };
    let all_diamond_tab
    let comparison_tab
    let recently_view_tab
    if(window.innerWidth < 768){
        all_diamond_tab = "All"
        comparison_tab = "Compare"
        recently_view_tab = "Recent"
    }  else{
        all_diamond_tab =  "ALL Fine Jewellery  (" + jescnt + ")"
        // all_diamond_tab =  "ALL Wedding Bands"
        comparison_tab =  "Comparison"
        recently_view_tab =  "Recently Viewed"
    }

    window.addEventListener('resize', function (){
        if(window.innerWidth < 768){
            all_diamond_tab = "All"
            //all_diamond_tab = "All (" + jescnt + ")"
            comparison_tab = "Compare"
            recently_view_tab = "Recent"
        }  else{
            //all_diamond_tab =  "ALL Diamonds (" + jescnt + ")"
            all_diamond_tab =  "ALL Fine Jewellery"
            comparison_tab =  "Comparison"
            recently_view_tab =  "Recently Viewed"
        }
    })
    return (
        <>

            <section className="filter-sec">

                <div className="container desktop-filter">
                    <div className="row " id="filter-settings">

                        <div className="col-md-12">

                            <div className="ringType filter-iconList justify-content-center gap-3">

                                {catres && catres.map(function (jd, i) {


                                    return <div className="iconList-item finejwellery-iconList ring_item" key={i}>
                                        <input onChange={(e) => {
                                            setCategory(e.target.value);
                                            setOrderby("cat");
                                            resetpagination();

                                        }} value={jd.type} className="d-none" type="radio" id={"cat_" + jd.type}
                                               checked={jd.type == category ? "checked" : ""}
                                        />

                                        <label
                                            className=" p-3 align-items-center border-2  d-flex flex-column justify-content-center rounded-4 h-100"
                                            htmlFor={"cat_" + jd.type}>
                                            <div className="ring_item_icon">
                                                <img className="img-fluid list-img"
                                                     src={jd.icon}
                                                     alt={jd.type}/>
                                            </div>
                                            <div className="ring_item_label  text-center">{jd.type}</div>
                                        </label>
                                    </div>
                                        ;
                                })}


                            </div>
                        </div>

                        <div className="col-lg-12 mb-5">
                            <div className="row justify-content-center">
                                <div className="col-auto">
                                    <Dropdown className="finejwellery-dropdown">
                                        <Dropdown.Toggle variant="light" id="stone_dropdown">
                                            Stone
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {stoneres && stoneres.map(function (jd, i) {

                                                const stone_found = enabled_stone.find(element => {
                                                    return element === jd;
                                                });


                                                return <li key={i} className="iconList-item form-check">
                                                    <input type="checkbox" className="form-check-input"
                                                           id={jd + "_stone"}
                                                           // disabled={stone_found ? "" : true}
                                                           onChange={(e) => {
                                                               handleStone(e);
                                                               setOrderby("diamond_shape");
                                                               setStoneClicked(true);
                                                               resetpagination();
                                                           }} value={jd}/>
                                                    <label htmlFor={jd + "_stone"}
                                                           className="form-check-label">    <span
                                                        className="ring-metal_label text-center">{jd}</span></label>
                                                </li>;
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>

                                <div className="col-auto">
                                    <Dropdown className="finejwellery-dropdown">
                                        <Dropdown.Toggle variant="light" id="peral_dropdown">
                                            Pearl
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {peralres && peralres.map(function (jd, i) {
                                                const peral_found = enabled_peral.find(element => {
                                                    return element === jd;
                                                });
                                                return <li key={i} className="iconList-item form-check">
                                                    <input type="checkbox" className="form-check-input"
                                                           id={jd + "_peral"}

                                                        // disabled={peral_found ? "" : true}
                                                           onChange={(e) => {
                                                               handleStone(e);
                                                               setOrderby("peral");
                                                               setStoneClicked(true);
                                                               resetpagination();
                                                           }} value={jd}/>
                                                    <label htmlFor={jd + "_peral"}
                                                           className="form-check-label">    <span
                                                        className="ring-peral_label text-center">{jd}</span></label>
                                                </li>;
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>

                                <div className="col-auto">
                                    <Dropdown className="finejwellery-dropdown">
                                        <Dropdown.Toggle variant="light" id="birthstone_dropdown">
                                            Birthstone
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {birthstoneres && birthstoneres.map(function (jd, i) {
                                                const birthstone_found = enabled_birthstone.find(element => {
                                                    return element === jd;
                                                });
                                                return <li key={i} className="iconList-item form-check">
                                                    <input type="checkbox" className="form-check-input"
                                                           id={jd + "_birthstone"}
                                                           // disabled={birthstone_found ? "" : true}
                                                           onChange={(e) => {
                                                               handleBirthstone(e);
                                                               setOrderby("birthstone");
                                                               setBirthstoneClicked(true);
                                                               resetpagination();
                                                           }} value={jd}/>
                                                    <label htmlFor={jd + "_birthstone"} className="form-check-label">    <span
                                                        className="ring-birthstone_label text-center">{jd}</span></label>
                                                </li>;
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>

                                <div className="col-auto">

                                    <Dropdown className="finejwellery-dropdown">
                                        <Dropdown.Toggle variant="light" id="peral_dropdown">
                                            Metal
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {metalres && metalres.map(function (jd, i) {

                                                const metal_found = enabled_metal.find(element => {
                                                    return element === jd.type;
                                                });

                                                const metal_selected = metal.find(element => {
                                                    return element === jd.type;
                                                });

                                                return <li key={i} className="iconList-item form-check">
                                                    <input type="checkbox" className="form-check-input"
                                                           id={jd.type + "_metal"}
                                                           // disabled={metal_found ? "" : true}
                                                           checked={metal_selected ? true : ""}
                                                       onChange={(e) => {
                                                               handleMetal(e);
                                                               setOrderby("metal_type");
                                                               setMetalClicked(true);
                                                           resetpagination();
                                                           }} value={jd.type}/>
                                                    <label htmlFor={jd.type + "_metal"} className="form-check-label">    <span
                                                        className="ring-metal_label text-center">{jd.type}</span></label>
                                                </li>;
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>

                                <div className="col-auto">

                                    <div className="dropdown finejwellery-dropdown">
                                        <button className="btn btn-light dropdown-toggle d-block w-100 text-start"
                                                type="button"
                                                id="price_dropdown" data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                            Price
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="price_dropdown">
                                            <div className="w-100 filter_control price_main" id="price_data">
                                                <label>Price</label>
                                                <div className="price_control_list">
                                                    <div className="row">
                                                        <div className="col-6 text-start">
                                                            <InputNumber className="price_control_input"
                                                                         controls={false} min={200} step={1}
                                                                         max={1000000}
                                                                         prefix={localStorage.getItem("currency")}
                                                                         formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                         value={minPrice}
                                                                         onStep={(e) => {
                                                                             setPrice([e.target.value, maxPrice]);      
                                                                             resetpagination();
                                                                         }}
                                                                         onPressEnter={(e) => {
                                                                             setPrice([e.target.value, maxPrice]);
                                                                             setOrderby("sell_price");
                                                                             resetpagination();
                                                                         }}
                                                                         onChange={PriceMin}/>

                                                        </div>
                                                        <div className="col-6 text-end">
                                                            <InputNumber className="price_control_input"
                                                                         controls={false} min={200} step={1}
                                                                         max={1000000}
                                                                         prefix={localStorage.getItem("currency")}
                                                                         formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                         value={maxPrice}
                                                                         onStep={(e) => {
                                                                             setPrice([minPrice, e.target.value]);
                                                                             resetpagination();
                                                                         }}
                                                                         onPressEnter={(e) => {
                                                                             setPrice([minPrice, e.target.value]);
                                                                             setOrderby("sell_price");
                                                                             resetpagination();
                                                                         }}
                                                                         onChange={PriceMax}/>
                                                        </div>
                                                    </div>
                                                    <Slider range value={[minPrice, maxPrice]} step={1} min={200}
                                                            max={1000000}
                                                            tooltip={{formatter: null}} draggableTrack={true}
                                                            onChange={PriceRangeChange}
                                                            onAfterChange={(e: number | [number, number]) => {
                                                                setPrice(e);
                                                                setOrderby("sell_price");
                                                                resetpagination();

                                                            }}/>

                                                </div>
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>


                <div className="container mobile-filter">
                    <div className="row">

                        {(mobile_filter_btn.length > 0) ? mobile_filter_btn.map((mobile_filter_btn, index) => (
                            <div className="col-6 col-sm-4 mb-2" key={index}>
                                <button type="button" onClick={(e) => {
                                    setMobilePopup(mobile_filter_btn);
                                }}
                                        className="btn btn-block w-100 btn-white border-1 border-dark border-opacity-25 btn_filter">
                                    <span className="filter_name">{mobile_filter_btn}</span>
                                    <div className="filter_selected">


                                        {mobile_filter_btn == "Price" ? currency + minPrice + " - " + currency + maxPrice : ""}


                                    </div>
                                </button>
                            </div>
                        )) : ""}

                        {mobilePopup !== "" ? (
                            <>
                                <div className="mobilefilterPop">
                                    <div className="mobilefilterPop-overlay" onClick={(e) => {
                                        setMobilePopup("");
                                    }}></div>
                                    <div className="mobilefilterPop-inner">
                                        <div className="row mb-4">
                                            <div className="col-6 text-start">
                                                <button className="mobilef_close_btn" onClick={(e) => {
                                                    setMobilePopup("");
                                                }}><i className="fas fa-times"></i> Close
                                                </button>
                                            </div>
                                            <div className="col-6 text-end">
                                                <button className="mobilef_reset_btn" onClick={(e) => {
                                                    resetFilter("");
                                                    setMobilePopup("");
                                                }}><i
                                                    className="fas fa-redo-alt"></i> Reset
                                                </button>
                                            </div>
                                        </div>

                                        {mobilePopup == "Category" ? (
                                            <>
                                                <div className="filter_control metal_main" id="metal_data">
                                                    <label>Category</label>
                                                    <div className="py-3 row">


                                                        {catres && catres.map(function (jd, i) {


                                                            return <div className="col-6 mb-2 iconList-item ring_item"
                                                                        key={i}>
                                                                <input onChange={(e) => {
                                                                    setCategory(e.target.value);
                                                                    setOrderby("sub_cat");
                                                                    resetpagination();
                                                                }} value={jd.type} className="d-none" type="radio"
                                                                       id={"male_" + jd.type}
                                                                       checked={jd.type === category ? true : false}
                                                                />

                                                                <label
                                                                    className=" p-3 align-items-center border border-dark border-opacity-50 d-flex flex-column justify-content-center rounded-4 h-100"
                                                                    htmlFor={"cat_" + jd.type}>
                                                                    <div className="ring_item_icon">
                                                                        <img className="img-fluid list-img"
                                                                             src={jd.icon}
                                                                             alt={jd.type}/>
                                                                    </div>
                                                                    <div
                                                                        className="ring_item_label  text-center">{jd.type}</div>
                                                                </label>
                                                            </div>
                                                                ;
                                                        })}


                                                    </div>
                                                </div>
                                            </>
                                        ) : ""} {mobilePopup == "Stone" ? (
                                        <>
                                            <div className="filter_control metal_main" id="metal_data">
                                                <label>Stone</label>
                                                <div className="py-3 row">

                                                    {stoneres && stoneres.map(function (jd, i) {
                                                        const stone_found = enabled_stone.find(element => {
                                                            return element === jd;
                                                        });

                                                        return <div key={i} className="iconList-item form-check col-6">
                                                            <input type="checkbox" className="form-check-input"
                                                                   // disabled={stone_found ? "" : true}
                                                                   id={jd + "_stone"}
                                                                   onChange={(e) => {
                                                                       handleStone(e);
                                                                       setOrderby("diamond_shape");
                                                                       setStoneClicked(true);
                                                                       resetpagination();
                                                                   }} value={jd}/>
                                                            <label htmlFor={jd + "_stone"}
                                                                   className="p-0 form-check-label">    <span
                                                                className="ring-metal_label text-start d-block">{jd}</span></label>
                                                        </div>;
                                                    })}


                                                </div>
                                            </div>
                                        </>
                                    ) : ""} {mobilePopup == "Pearl" ? (
                                        <>
                                            <div className="filter_control metal_main" id="metal_data">
                                                <label>Pearl</label>
                                                <div className="py-3 row">

                                                    {peralres && peralres.map(function (jd, i) {
                                                        const peral_found = enabled_peral.find(element => {
                                                            return element === jd;
                                                        });
                                                        return <div key={i} className="iconList-item form-check col-6">
                                                            <input type="checkbox" className="form-check-input"
                                                                   id={jd + "_peral"}
                                                                   // disabled={peral_found ? "" : true}
                                                                   onChange={(e) => {
                                                                       handleStone(e);
                                                                       setOrderby("peral");
                                                                       setStoneClicked(true);
                                                                       resetpagination();
                                                                   }} value={jd}/>
                                                            <label htmlFor={jd + "_peral"} className="form-check-label">    <span
                                                                className="ring-peral_label text-center">{jd}</span></label>
                                                        </div>;
                                                    })}


                                                </div>
                                            </div>
                                        </>
                                    ) : ""}

                                        {mobilePopup == "Birthstone" ? (
                                            <>
                                                <div className="filter_control metal_main" id="metal_data">
                                                    <label>Birthstone</label>
                                                    <div className="py-3 row">

                                                        {birthstoneres && birthstoneres.map(function (jd, i) {
                                                            const birthstone_found = enabled_birthstone.find(element => {
                                                                return element === jd;
                                                            });
                                                            return <div key={i}
                                                                        className="iconList-item form-check col-6">
                                                                <input type="checkbox" className="form-check-input"
                                                                       id={jd + "_birthstone"}
                                                                       // disabled={birthstone_found ? "" : true}
                                                                       onChange={(e) => {
                                                                           handleBirthstone(e);
                                                                           setOrderby("birthstone");
                                                                           setBirthstoneClicked(true);
                                                                           resetpagination();
                                                                       }} value={jd}/>
                                                                <label htmlFor={jd + "_birthstone"}
                                                                       className="form-check-label">    <span
                                                                    className="ring-birthstone_label text-center">{jd}</span></label>
                                                            </div>;
                                                        })}


                                                    </div>
                                                </div>
                                            </>
                                        ) : ""}

                                        {mobilePopup == "Metal" ? (
                                            <>
                                                <div className="filter_control metal_main" id="metal_data">
                                                    <label>Metal</label>
                                                    <div className="py-3 row">
                                                        {metalres && metalres.map(function (jd, i) {
                                                            const metal_found = enabled_metal.find(element => {
                                                                return element === jd.type;
                                                            });
                                                            return <div key={i}
                                                                        className="iconList-item form-check col-6">
                                                                <input type="checkbox" className="form-check-input"
                                                                       id={jd.type + "_metal"}
                                                                       // disabled={metal_found ? "" : true}
                                                                       onChange={(e) => {
                                                                           handleMetal(e);
                                                                           setOrderby("metal_type");
                                                                           setMetalClicked(true);
                                                                           resetpagination();
                                                                       }} value={jd.type}/>
                                                                <label htmlFor={jd.type + "_metal"}
                                                                       className="form-check-label">    <span
                                                                    className="ring-metal_label text-center">{jd.type}</span></label>
                                                            </div>;
                                                        })}


                                                    </div>
                                                </div>
                                            </>
                                        ) : ""}

                                        {mobilePopup == "Price" ? (
                                            <>
                                                <div className="filter_control price_main" id="price_data">
                                                    <label>Price</label>
                                                    <div className="price_control_list">
                                                        <div className="row">
                                                            <div className="col-6 text-start">
                                                                <InputNumber className="price_control_input"
                                                                             controls={false} min={200} step={1}
                                                                             max={1000000} prefix="RM"
                                                                             formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                             value={minPrice}
                                                                             onStep={(e) => {
                                                                                 setPrice([e.target.value, maxPrice]);
                                                                                 resetpagination();
                                                                             }}
                                                                             onPressEnter={(e) => {
                                                                                 setPrice([e.target.value, maxPrice]);
                                                                                 resetpagination();
                                                                             }}
                                                                             onChange={PriceMin}/>

                                                            </div>
                                                            <div className="col-6 text-end">
                                                                <InputNumber className="price_control_input"
                                                                             controls={false} min={200} step={1}
                                                                             max={1000000} prefix="RM"
                                                                             formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                             value={maxPrice}
                                                                             onStep={(e) => {
                                                                                 setPrice([minPrice, e.target.value]);
                                                                                 resetpagination();
                                                                             }}
                                                                             onPressEnter={(e) => {
                                                                                 setPrice([minPrice, e.target.value]);
                                                                                 resetpagination();
                                                                             }}
                                                                             onChange={PriceMax}/>
                                                            </div>
                                                        </div>
                                                        <Slider range value={[minPrice, maxPrice]} step={1} min={200}
                                                                max={1000000}
                                                                tooltip={{formatter: null}} draggableTrack={true}
                                                                onChange={PriceRangeChange}
                                                                onAfterChange={(e: number | [number, number]) => {
                                                                    setPrice(e);


                                                                }}/>

                                                    </div>
                                                </div>

                                            </>
                                        ) : ""}
                                    </div>
                                </div>
                            </>

                        ) : ""}
                    </div>

                </div>
                <div className="container">
                    <div className="row mt-3">
                        <div className="col-md-9 mx-auto text-center">
                            <h5 className="main-title-text fw-normal">
                                Engagement Fine Jewellery Settings <b>- Viewable In 360° HD</b>
                            </h5>
                            <small data-qa="description_results-gallery" className="description">
                                <p>Our selection of engagement ring settings includes every metal and every style.
                                    Yellow gold, white gold, platinum, and rose gold; vintage, modern, classic or
                                    trendy, Baemont has the perfect engagement ring setting for you. Whether you are
                                    choosing a timeless diamond or a colorful gemstone, we have the ideal engagement
                                    ring setting that will shine as bright. A momentous moment deserves nothing less
                                    than pure excellence, our collection of engagement ring settings ensures that you
                                    are able to present the ideal ring.</p>
                            </small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 fltrPlod-list position-relative">
                            <Tabs
                                defaultActiveKey="all"
                                id="settings-diamond-tabs"
                                className="mb-3"
                                mountOnEnter unmountOnExit
                            >
                                <Tab eventKey="all" title={all_diamond_tab}>

                                    <Tabs mountOnEnter unmountOnExit defaultActiveKey="grid"
                                          id="diamond-tabs"
                                          className="grid-list-btn float-end mb-3 nav nav-tabs position-absolute end-0 top-0">
                                        <Tab eventKey="grid" title={(<FaTh/>)}>
                                            <div className="row">
                                                {(jesdada && jesdada.length > 0) ? jesdada.map((jd) => (

                                                    <div key={jd.sku}
                                                         className="col-12 col-sm-6 col-md-3 position-relative">
                                                        <ProductItem loader={props.loader} buttons={true}
                                                                     type="finejewellery" product_id={jd.sku}/>
                                                    </div>

                                                )) : (
                                                    <>
                                                        <div className="col-md-12 compare-page">
                                                            <div className="entry-content text-black text-center">
                                                                <div><i
                                                                    className="display-2 fa-regular fa-rings-wedding opacity-25 text-muted"></i>
                                                                    <h3 className="display-5"> We are sorry !</h3>
                                                                    <div className="empty-page-text text-muted">
                                                                        Do drop us a message and let us source the
                                                                        wedding band for you.
                                                                    </div>
                                                                    <p className="return-to-shop"><a
                                                                        className="btn btn-primary"
                                                                        href="/contact-us"> GET IN TOUCH WITH US</a></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                <div className="col-12 mt-5">
                                                    <nav className="d-flex justify-content-center">
                                                        <ReactPaginate previousLabel={"<"} nextLabel={"→"}
                                                                       breakLabel={"..."} breakClassName={"break-me"}
                                                                       pageCount={pageCount} marginPagesDisplayed={2}
                                                                       pageRangeDisplayed={5}
                                                                       onPageChange={handlePageClick}
                                                                       forcePage={currentPage}
                                                                       containerClassName={""}
                                                                       pageClassName={"page-item"}
                                                                       pageLinkClassName={"page-link"}
                                                                       className={"pagination"}
                                                                       previousClassName={"page-item prev-page"}
                                                                       nextClassName={"page-item next-page"}
                                                                       subContainerClassName={"pages pagination"}
                                                                       activeClassName={"active"}
                                                                       activeLinkClassName={"active"}
                                                                       previousLinkClassName={"page-link"}
                                                                       nextLinkClassName={"page-link"}/>
                                                    </nav>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="list" title={(<FaThList/>)}>
                                            <div className="table-responsive">
                                                <table className="table ring-list-table">
                                                    <thead>
                                                    <tr>
                                                        <th>Actual Photo</th>
                                                        <th>Metal Type</th>
                                                        <th>Gender</th>
                                                        <th>Width</th>
                                                        <th>Weight</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {jesdada && jesdada.map((jd) => (
                                                        <ProductItemList loader={props.loader} key={jd.sku}
                                                                         type="finejewellery" product_id={jd.sku}/>
                                                    ))}
                                                    </tbody>

                                                </table>
                                                <div className="col-12 mt-5">
                                                    <nav className="d-flex justify-content-center">
                                                        <ReactPaginate previousLabel={"←"} nextLabel={"→"}
                                                                       breakLabel={"..."} breakClassName={"break-me"}
                                                                       pageCount={pageCount} marginPagesDisplayed={2}
                                                                       pageRangeDisplayed={5}
                                                                       onPageChange={handlePageClick}
                                                                       forcePage={currentPage}
                                                                       containerClassName={""}
                                                                       pageClassName={"page-item"}
                                                                       pageLinkClassName={"page-link"}
                                                                       className={"pagination"}
                                                                       previousClassName={"page-item prev-page"}
                                                                       nextClassName={"page-item next-page"}
                                                                       subContainerClassName={"pages pagination"}
                                                                       activeClassName={"active"}
                                                                       activeLinkClassName={"active"}
                                                                       previousLinkClassName={"page-link"}
                                                                       nextLinkClassName={"page-link"}/>
                                                    </nav>
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </Tab>

                                <Tab eventKey="recent_view" title={recently_view_tab}>
                                    <div className="row itemList-cont g-2">
                                        <RecentProducts loader={props.loader} type="finejewellery"/>
                                    </div>
                                </Tab>
                            </Tabs>

                        </div>
                    </div>

                </div>
            </section>
        </>
    );
}

export default FineJwellery;

import React from 'react'

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import GuaranteeService from './GuaranteeService';
import GuaranteeWarranty from './GuaranteeWarranty';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className='col-md-9'
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
    className: 'align-items-start',
  };
}

/* function Guarantee() { */
const Guarantee = props => {
  const { match } = props;
  const { params } = match;
  const { page } = params;
  const [selectedTab, setSelectedTab] = React.useState(0);
  console.log(page);
  
  const [value, setValue] = React.useState(0);
  //const routes = ["/GuaranteeService", "/GuaranteeWarranty"];

  const handleChange = (event, newValue) => {
    //setValue(newValue);
    setSelectedTab(newValue);
  };
  

  return (
    <div>
      
  <section className="bg-light py-2">
    <div className="container">
      <div className="row pageTitle">
        <div className="col-12">
          
          <nav aria-label="breadcrumb ">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a className="theme-color text-uppercase d-block" href="/">Home</a></li>
              <li className="breadcrumb-item active" aria-current="page">Guarantee</li>
            </ol>
          </nav>
          <h4 className="text-uppercase mb-0">The Baemont Guarantee</h4>

        </div>
      </div>
    </div>
  </section>

<section className="guaranteeList py-5">
  <div className="container">
    <div className="row">
      <div className="col-12">
            <Tabs value={selectedTab} onChange={handleChange}>
              <Tab label="Service" />
              <Tab label="Warranty" />
            </Tabs>
            {selectedTab === 0 && <GuaranteeService />}
            {selectedTab === 1 && <GuaranteeWarranty />}

      </div>
      
    </div>
    
    
  </div>
</section>

    </div>
  )
}

export default Guarantee
import React from 'react'
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

function Learn() {
    return (<> <Tab.Container id="left-tabs-example" defaultActiveKey="looseDiamonds"> <Container
        className='py-5 learn-page'> <Row>
        <div className="col-md-3"><Nav variant="pills" className="flex-column"> <Nav.Item> <Nav.Link
            eventKey="looseDiamonds">Loose Diamonds</Nav.Link> </Nav.Item> <Nav.Item> <Nav.Link
            eventKey="engagementRings">Engagement Rings</Nav.Link> </Nav.Item> <Nav.Item className="d-none"> <Nav.Link
            eventKey="naturalGemstones">Natural Gemstones</Nav.Link> </Nav.Item> <Nav.Item> <Nav.Link
            eventKey="weddingRings">Wedding Rings</Nav.Link> </Nav.Item> <Nav.Item className="d-none"> <Nav.Link
            eventKey="fineJewelry">Fine Jewelry</Nav.Link> </Nav.Item> </Nav></div>
        <div className="col-md-9"><Tab.Content> <Tab.Pane eventKey="looseDiamonds">
            <div>
                <div className="content categoryDetails" categoryid="1" subcategoryid="1" secondsubcategoryid="1">
                    <div className="CategoryText"><h1 className="top_pad_h2">Learn from BAEMONT</h1><h3>Diamond 4
                        C’s</h3><p>The 4Cs (Cut, Clarity, Colour and Carat) of a diamond is a universally accepted and
                        known method for assessing the quality and value of any diamond. It was minted in the twentieth
                        century by GIA (Gemological Institute of America) and has since been a common language for both
                        consumers and producers in the diamond industry as an International Diamond Grading System.</p>
                        <p>Today, there are various, well-recognized institutions out there with advanced and complex
                            grading system for evaluating the quality of a diamond. BAEMONT recommends diamonds grading
                            by GIA for their prominent grading standards.</p><p>Purchasing a diamond can be complicated.
                            Therefore, it is important to learn from a trusted source to understand the basic anatomy of
                            a diamond. This will help you in deciding which parameters of a diamond are important for
                            you as an individual and ultimately choosing the ideal piece for you or your beloved
                            partner.</p><p><img className="img-fluid" src="/assets/img/static/learn/diamond-detail.jpg"
                                                alt="diamond clarity"/> <i>Figure: Anatomy of a Diamond</i></p>
                        <h3>Cut</h3><p>A diamond’s cut determines the “shininess” it produces. It is commonly overlooked
                            by most buyers when choosing a diamond to purchase but in fact, probably the most important
                            factor to consider. A diamond’s beauty and value come from how well its facets reflects the
                            light therefore making the cut an immensely complex and technically challenging tasks to
                            do.</p><p>Basically, a well-cut diamond will give it the ability to reflect white and
                            coloured light back to your eyes and is luminous. It impacts the diamond’s brilliance and
                            fire.</p><p>A diamond’s brilliance is the brightness of a white light reflection whereas the
                            fire is the amount of coloured light that reflects off the table and facets.</p><p>BAEMONT
                            recommends a grading of <strong>Excellent</strong> cut from GIA.</p>
                        <p>
                            <iframe scrolling="no" width="100%" height="600" className="overflow-hidden" src='https://4cs.gia.edu/interactive-4cs//standalone/cut.html'></iframe>
                        </p>
                        <table className="cut-detail-table">
                            <tbody>
                            <tr>
                                <td>Excellent</td>
                                <td><a href="https://www.diamonds.pro/education/triple-excellent-diamond/"
                                       target="_blank">Excellent Cut Diamonds</a> provide the highest level of fire and
                                    brilliance. Because almost all of the incoming light is reflected through the table,
                                    the diamond radiates with magnificent sparkle.
                                </td>
                            </tr>
                            <tr>
                                <td>Very Good</td>
                                <td>Very Good Cut Diamonds offer exceptional brilliance and fire. A large majority of
                                    the entering light reflects through the diamond’s table. To the naked eye, Very Good
                                    diamonds provide similar sparkle to those of Excellent grade.
                                </td>
                            </tr>
                            <tr>
                                <td>Good</td>
                                <td>Good Cut Diamonds showcase brilliance and sparkle, with much of the light reflecting
                                    through the table to the viewer’s eye. These diamonds provide beauty at a lower
                                    price point.
                                </td>
                            </tr>
                            <tr>
                                <td>Fair</td>
                                <td>Fair Cut Diamonds offer little brilliance, as light easily exits through the bottom
                                    and sides of the diamond. Diamonds of a Fair Cut may be a satisfactory choice for
                                    smaller carats and those acting as side stones.
                                </td>
                            </tr>
                            <tr>
                                <td>Poor</td>
                                <td>Poor Cut Diamonds yield nearly no sparkle, brilliance or fire. Entering light
                                    escapes from the sides and bottom of the diamond.
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <i>Reference: diamonds.pro</i><p><a href="https://4cs.gia.edu/en-us/diamond-carat-weight/"
                                                            target="_blank">Learn more about Diamond Cut.</a></p>
                        <h3>Clarity</h3><p>A diamond’s clarity determines the “cleanliness” of the stone. The term
                            “eye-clean” is widely used when it comes to choosing the clarity of a diamond to ensure that
                            inclusions are not visible to the naked eye. It shows if a diamond has any inclusions or
                            blemishes that will impact the quality of a diamond.</p><p> The GIA grades a diamond’s
                            clarity as follows:</p>
                        <ul>
                            <li>FL (Flawless)</li>
                            <li>IF (Internally Flawless)</li>
                            <li>VVS1 (Very, Very Slightly Included 1)</li>
                            <li>VVS2 (Very, Very Slightly Included 2)</li>
                            <li>VS1 (Very Slightly Included 1)</li>
                            <li>VS2 (Very Slightly Included 2)</li>
                            <li>SI1 (Slightly Included 1)</li>
                            <li>SI2 (Slightly Included 2)</li>
                            <li>I1 (Inclusions 1)</li>
                            <li>I2 (Inclusions 2)</li>
                        </ul>
                        <p>Generally, a diamond with clarity of VS2 and above is said to not have any inclusions or
                            blemishes visible to the naked eye.</p><p>BAEMONT recommends choosing diamonds with clarity
                            VS2 and above.</p>
                        <p>
                            <iframe scrolling="no" width="100%" height="600" className="overflow-hidden" src='https://4cs.gia.edu/interactive-4cs//standalone/clarity.html'></iframe>
                        </p>
                        <p><a href="https://4cs.gia.edu/en-us/diamond-clarity/" target="_blank">Learn more about Diamond
                            Clarity.</a></p><h3>Colour</h3><p>A diamond’s colour determines how “white” the stone is. To
                            the untrained eye, it can be challenging to determine the exact colour of a diamond unless
                            being directly compared to.</p><p>The difference between one colour grade and the other is
                            very small and almost impossible to differentiate with the naked eye. Best value diamonds
                            are in the near colourless range ie. G or H where it is still “white” and appear colourless
                            when cut excellently.</p><p> Generally, there are 3 groupings of diamond colours:</p>
                        <ol>
                            <li>Colourless (D-F)</li>
                            <li>Near Colourless (G-J)</li>
                            <li>Faint (K-M)</li>
                        </ol>
                        <table className="color-information-table">
                            <thead>
                            <tr>
                                <th>Diamond Colour Grade:</th>
                                <th>Description:</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>D</td>
                                <td><p>D is the highest colour grade, meaning it has nearly no colour. Under
                                    magnification and to the naked eye, a D colour diamond will appear colourless.</p>
                                    <p><a href="https://www.diamonds.pro/education/d-color-diamonds/" target="_blank">D
                                        colour diamonds</a> are usually set in platinum or white gold, as yellow gold
                                        and other jewellery settings detract from the diamond’s uncoloured beauty.
                                        Diamonds with a D colour grade are the most rare and expensive on the market,
                                        with a significant price premium over other colour grades.</p></td>
                            </tr>
                            <tr>
                                <td>E</td>
                                <td><p>E colour diamonds look almost identical to D colour diamonds. Most of the time,
                                    the differences in colour between a D and E diamond are only visible to an expert
                                    gemologist when the two diamonds are viewed under magnification.</p><p>Like D colour
                                    diamonds, <a href="https://www.diamonds.pro/education/e-color-diamonds/"
                                                 target="_blank">E colour diamonds</a> are usually set in platinum or
                                    white gold to avoid the colour of the jewellery detracting from their near flawless
                                    colour. Although these diamonds are less expensive than D colour diamonds, they
                                    still command a hefty premium.</p></td>
                            </tr>
                            <tr>
                                <td>F</td>
                                <td><p><a href="https://www.diamonds.pro/education/f-color-diamonds/" target="_blank">F
                                    colour diamonds</a> are almost identical to D and E colour diamonds, with nearly no
                                    visible colour. Even under magnification and side by side, a D, E and F diamond will
                                    look almost identical to anyone other than an expert gemologist.</p></td>
                            </tr>
                            <tr>
                                <td>G</td>
                                <td><p><a href="https://www.diamonds.pro/education/g-color-diamonds/" target="_blank">G
                                    colour diamonds</a> exhibit nearly no colour and appear primarily colourless to the
                                    naked eye. The G colour grade is the highest, best grade in the “Near Colourless”
                                    range of the GIA’s scale, which covers diamonds graded G to J.</p> <p>Although G
                                    colour diamonds have some tints of colour, they are almost impossible to detect with
                                    the naked eye. Like D-F diamonds, these diamonds should be set in platinum or white
                                    gold to reduce any effects of colour reflection from yellow or rose gold.</p></td>
                            </tr>
                            <tr>
                                <td>H</td>
                                <td><p><a href="https://www.diamonds.pro/education/h-color-diamonds/" target="_blank">H
                                    colour diamonds</a> appear primarily colourless to the naked eye but have a faint
                                    yellow hue that’s often visible under magnification in bright lighting, especially
                                    when they’re compared to diamonds of a higher colour grade. </p><p>Like G colour
                                    diamonds, these can be set in platinum or white gold without any issues. H colour
                                    diamonds are slightly less expensive than G colour diamonds and significantly more
                                    affordable than diamonds in the colourless range. This is the minimum colour grade
                                    we recommend for diamond shapes with a large table, such as the <a
                                        href="https://www.diamonds.pro/education/radiant-cut/"
                                        target="_blank">radiant</a> and <a
                                        href="https://www.diamonds.pro/education/cushion-cut/"
                                        target="_blank">cushion</a> cuts.</p></td>
                            </tr>
                            <tr>
                                <td>I</td>
                                <td><p><a href="https://www.diamonds.pro/education/i-color-diamonds/" target="_blank">I
                                    colour diamonds</a> offer a great combination of near colourless looks and good
                                    value for money. These diamonds have a slight yellow tint that’s usually only
                                    visible when they’re viewed next to diamonds of a higher colour grade.</p><p>An I
                                    colour round brilliant cut diamond can look fantastic in a platinum or white gold
                                    setting, as well as alongside metals such as yellow or rose gold. As you would
                                    expect, I colour diamonds are less expensive than G or H colour diamonds. This is
                                    the minimum colour grade we recommend for <a
                                        href="https://www.diamonds.pro/education/princess-cut/" target="_blank">princess
                                        cut diamonds</a>.</p></td>
                            </tr>
                            <tr>
                                <td>J</td>
                                <td><p><a href="https://www.diamonds.pro/education/j-color-diamonds/" target="_blank">J
                                    colour diamonds</a> look mostly colourless to the naked eye, but usually have a
                                    faint yellow tint that is easy to notice under bright lights and magnification. In
                                    diamonds with a large table, the colour might also be visible with the naked eye in
                                    certain lighting conditions.</p><p>From a value for money perspective, J colour
                                    diamonds can be fantastic choices. We recommend this colour for round brilliant cut
                                    diamonds set in platinum or white gold (solitaire setting), as the cut of the round
                                    diamond is great at concealing colour. However, the J colour grade is not
                                    recommended for diamond shapes with a larger table and fewer facets.</p></td>
                            </tr>
                            </tbody>
                        </table>
                        <i>Reference: diamonds.pro</i><p>BAEMONT recommends choosing diamond in the near colourless
                            range and above.</p>
                        <p>

                            <iframe scrolling="no" width="100%" height="600" className="overflow-hidden" src='https://4cs.gia.edu/interactive-4cs//standalone/color.html'></iframe>
                        </p>
                        <p><a href="https://4cs.gia.edu/en-us/diamond-color/" target="_blank">Learn more</a> about
                            Diamond Colour.</p><h3>Carat</h3><p>A diamond’s carat determines the weight (and size) of a
                            diamond. The word carat originated from carob seeds where it was historically used to
                            measure the weight of a diamond.</p><p>The carat is commonly perceived as the most
                            sought-after decision factor when choosing a diamond and quite often mis-leading the value
                            of a diamond due to its sizing factor.</p><p>Fact: A single 2 ct. diamond will be more
                            expensive than 2 x 1ct. diamond. The reason simply because a single large rough diamond is
                            much rarer than a smaller rough diamond of the same quality.</p>
                        <p>
                            <iframe scrolling="no" width="100%" height="600" className="overflow-hidden" src='https://4cs.gia.edu/interactive-4cs//standalone/carat-weight.html'></iframe>
                        </p>
                        <p><a href="https://4cs.gia.edu/en-us/diamond-carat-weight/" target="_blank">Learn
                            more</a> about Diamond Carat.</p></div>
                </div>
            </div>
        </Tab.Pane> <Tab.Pane eventKey="engagementRings">
            <div>
                <div><h1 className="top_pad_h2">Learn from BAEMONT</h1><h3>Engagement Rings</h3> <p>You are probably
                    wondering how much you should spend on an engagement ring. What is a reasonable budget (without
                    breaking your bank)?</p><p>Choosing an engagement ring can be nerve-wrecking but it does not have to
                    be. There are a few components, but it requires little effort to understand them. With a little time
                    and “googling”, your decision can be a simple one.</p><p>An engagement ring is probably one of the
                    most significant purchases one will ever make. It symbolizes your love to the person you are
                    presenting it to and making an official statement (or an announcement) to everyone!</p><p>It needs
                    to be personal, it needs to be practical and more importantly it needs to stand out with
                    a <strong>WOW!</strong> factor.</p><p>We’re here to help. One of the most important first step is
                    understanding ring style. <strong>Solitaire, Pave,</strong> and <strong>Channel Set</strong> are
                    some of the favourites for an engagement ring style. Read on to learn more about these design style.
                </p><h3>Solitaire</h3><p><img className="img-fluid" src="/assets/img/static/learn/e1.png"
                                              alt="Engagement Rings"/></p><p>A solitaire engagement ring represents a
                    single stone (ie. diamonds or other gemstones) used in a simple ring setting. It is the most common
                    style used in an engagement ring appealing to those who seeks a classic yet elegant look.</p><p>It
                    does not have any stones on the shank of the band and its design purpose is to bring attention and
                    focus to the centrepiece stone. The prongs, sometimes known as claws and usually in a set of 4 or 6,
                    helps stand out the centrepiece stone making it appear more prominent. The width of the band is also
                    a factor to consider as narrower width has the effect of making a smaller diamond appear larger.</p>
                    <p>Solitaires are amongst one of the most popular of choices for decades and is traditionally paired
                        with a round-shaped diamond.</p><h3>Pavé</h3><p><img className="img-fluid"
                                                                             src="/assets/img/static/learn/ep.png"
                                                                             alt="Engagement Rings"/></p><p>Pavé is a
                        French word, translated to “paved”. The design of the ring is literally paved with small
                        diamonds, usually in the size of 0.01 to 0.02 ct. along the band of the ring. It resembles a
                        paved or cobblestone road that is rather common in France.</p><p>The small diamonds are held
                        together by prongs or beads in a continuous line on the band, hiding the metal whilst creating
                        extra sparkles to the overall ring. Pavé design can go around the entire band or half-way,
                        giving the ring a solid diamond-encrusted surface with a grandeur look.</p> <p>Apart from the
                        standard Pavé band, there are multiple variations or combinations such as a Pavé Halo, French
                        Pavé or Micro-Pavé. Each style has its own unique enticing and captivating outlook that fits any
                        shape of diamond or gemstones though it is mostly used with a brilliant round or princess
                        cut.</p> <h3>Channel-Set</h3><p><img className="img-fluid"
                                                             src="/assets/img/static/learn/ecs.png"
                                                             alt="Engagement Rings"/></p><p>A channel-set ring is
                        increasingly becoming popular in this era for its formidable design style. Small diamonds are
                        embedded into the groove of the ring, creating a river-like layout of sparkles surrounded by
                        metal. There are no prongs or beads in the design and the stones are held on the shank of the
                        ring between two walls of the metal. Since the design flushes with the band, the small diamonds
                        remain in a protective enclosure and does not protrude. Therefore, they are less likely to snag
                        on clothes, in comparison to a Pavé.</p><p>This classic yet sophisticated design is most sought
                        after by those who seeks a rugged and sturdy timeless piece. Its design matches almost any shape
                        of diamonds or gemstones as if brings out both the fire and brilliance of the centrepiece.</p>
                    <h3>Three-Stone</h3><p><img className="img-fluid" src="/assets/img/static/learn/ets.png"
                                                alt="Engagement Rings"/></p><p>Three-stone engagement ring, also known
                        as “Trinity” or “Trilogy”, consists of three stones that are placed next to each other with the
                        centre stone usually larger than the other two stones on each side. It is held in place by three
                        sets of prongs with the centre stone placed higher. Generally, the two side stones can be of any
                        shape and its purpose is to highlight the centrepiece by adding depth to the ring.</p>
                    <p>Three-stone was first introduced as a first-year anniversary gift and later became popular for
                        engagement rings. The three stones are said to symbolize “the past, the present and the future”
                        with the centrepiece as the biggest stone representing loving memories in the current and as a
                        reminder for all live in the present.</p><p>It is unique in any ways possible as there can be
                        endless combinations of stones to be paired with the centrepiece, bringing out its
                        prominence.</p> <h3>Halo</h3><p><img className="img-fluid" src="/assets/img/static/learn/eh.png"
                                                             alt="Engagement Rings"/></p><p>The Halo engagement ring
                        design typically consists of pavé (or micro-pavé) diamonds surrounding the centrepiece. The
                        effect is a larger looking centre stone complimented by the encircling small diamonds. Its
                        effect is even more significant if the centre stone and encircling stone are of two different
                        colours. It accentuates and enhances its visual appeal through the contrasting colours, drawing
                        attention the prominent centre stone. The halo settings are generally more expensive due to its
                        design requiring more metal and pave diamonds.</p><p>Apart from diamonds as centrepiece, Halo
                        design is very commonly paired with other previous gemstones such as tanzanite or sapphire
                        making it more interesting. With this, there are abundance of choices to go with a Halo ring
                        setting creating a classic to contemporary to vintage design exuding elegance and class.</p>
                </div>
            </div>
        </Tab.Pane> <Tab.Pane eventKey="naturalGemstones">
            <div>
                <div className="show-md" id="educationLeft"><img className="img-fluid"
                                                                 src="/assets/img/static/learn/Img.Gal_304X240.jpg"
                                                                 alt="1.02 Carat D-IF Marquise Cut Diamond" id="Img7"
                                                                 width="304" height="240"/></div>
                <div s>
                    <div><h1 className="top_pad_h2">Blue Sapphires</h1>
                        <div className="content categoryDetails" categoryid="3" subcategoryid="1"
                             secondsubcategoryid="1">
                            <div className="CategoryText"><p>Blue sapphire is considered a primary color gemstone; the
                                purer the primary hue, the more valuable the gemstone. Blue sapphires are colored by
                                trace amounts of titanium and iron. (By increasing the level of titanium and iron the
                                color saturation is also increased.) The most common secondary hues found in blue
                                sapphires are purple, violet, and green. Violet and purple can contribute to the overall
                                beauty of the color, while green is considered to be the bane of a blue sapphire. The
                                problem is that all blue sapphires will have a greenish component when viewed at the
                                right angle. It is the cutter that makes sure that the green is not part of the face up
                                position in a sapphire. When gray is included in the overall color of the gemstone,
                                often referred to as a gray mask, the sapphire will have a cool or steely quality as
                                opposed to the normal warm velvety hue of a sapphire. Gray is the normal saturation
                                modifier or mask found in blue sapphires and will often mix with green.</p><p>Blue
                                sapphires that are eye-clean or entirely free of inclusions are uncommon, especially in
                                larger sizes. However, blue sapphires with some internal inclusions are still highly
                                valued, as long as the inclusions do not reduce brilliance, obscure color, or otherwise
                                detract from the gemstone’s beauty. Blue sapphires of one-carat weight or more are not
                                usually cut to calibrated sizes or standard shapes. Each gemstone is shaped to maximize
                                the color and weight of the gemstone.</p><p>In blue sapphires, cutting can influence
                                color in surprising ways. Sapphires are dichroic gemstone, meaning their color varies
                                depending on the angle from which it is viewed. Viewed in one direction, most blue
                                sapphires appear blue to violet-blue. From another direction, they will appear slightly
                                greenish blue. A skilled cutter will orient their gemstone so that the blue to
                                violet-blue color shows through the finished gem’s crown.</p><p>Silk inclusions are also
                                acceptable in blue sapphires, as long as they are not so dense as to compromise color or
                                brilliance. In fact, silk inclusions can increase the value of a sapphire. The heat
                                treatments used to alter color and clarity in blue sapphires break down rutile silk, so
                                the presence of intact silk indicates that a sapphire is unheated. Certain other solid
                                and liquid inclusions burst or deform under extreme heat and are also useful indicators
                                of heat treatment.</p><p>Heat treatment and diffusion make attractive blue sapphires out
                                of common corundum that would have little or no value without treatment. Heat treatment
                                is widely used in the international sapphire market, and it is estimated that well over
                                90 percent of all blue sapphire in the market today has been subject to heat treatment.
                                Diffusion treatment is far more controversial, and diffusion-treated blue sapphires sell
                                for much less than those which have only been heat-treated.</p><p>Blue sapphires are
                                found in a number of locations around the world including Kashmir, Myanmar, Sri Lanka,
                                Madagascar, Thailand, Australia, Tanzania, and the state of Montana in the United
                                States.</p></div>
                        </div>
                    </div>
                </div>
            </div>
        </Tab.Pane> <Tab.Pane eventKey="weddingRings">
            <div>
                <div><h1 className="top_pad_h2">Learn from BAEMONT</h1><h3>Wedding Bands</h3><p><img
                    className="img-fluid" src="/assets/img/static/learn/wcb.jpg" alt="Wedding Bands"/></p><p>Wedding
                    bands signifies the unity of love and commitment between a married couple. The band is a symbol
                    representing eternity, with no defined beginning or end. It is therefore a significant piece of your
                    ring aesthetic for your everyday-wear and comfort. From the design to engraving to choosing the
                    right metal, there is a considerable amount of component pieced together to form the perfect wedding
                    band fit to your persona.</p><p>BAEMONT has a large collection of wedding bands for you to choose
                    from classics to the exclusive standout piece with or without diamonds or gemstones. One of the
                    fastest ways to help you get started is to use your engagement ring as a guide to find your matching
                    bands. Read on to learn more about different wedding band styles.</p> <h3>Classic Bands</h3><p>A
                    piece that will not go out of style, the Classic bands usually have a plain or a single pavé band
                    design. It is a minimalist style and quite often the preferred choice for newlyweds. The focus is on
                    comfort whilst still maintaining beauty and elegance of the band. Wedding bands are worn all year
                    round and therefore important to get the right fit. By understanding the width of a ring, you can
                    choose one that is comfortable enough to be always worn no matter the condition.</p><p>Classic bands
                    have a wide range of width given its minimalist design and it can accommodate width ranges from
                    2mm-8mm. Depending on the wearer, the choice of width is a personal style and most will find great
                    comfort fit between 2mm-5mm with a slightly curved design. Some prefer wider bands while others opt
                    for narrower ones. Traditionally, wedding bands are often chosen to match with engagement rings and
                    they can both complement each other even with different width.</p><h3>Eternity Bands</h3><p><img
                    className="img-fluid" src="/assets/img/static/learn/web.jpg" alt="Wedding Bands"/></p><p>In this
                    21st century, Eternity Bands have become a trending and sought-after style for wedding bands. It is
                    loaded up with diamonds or gemstones and boasts plenty of sparkles to match with an engagement ring.
                    Also known as infinity band, it consists of precious stones (normally diamonds) encircling the band,
                    completing a loop. It is highly symbolic, representing not only eternal love but equality,
                    completion and perfection of a relationship.</p><p>Various shapes of diamond or gemstone can be used
                    in an eternity band and some of the most common ones are squared shape cuts ie. Emerald, Radiant,
                    Cushion or Asscher. The sky is the limit when it comes to design of custom eternity bands.</p>
                    <p>Generally, there are two eternity categories namely full eternity or the half eternity. Full
                        eternity is more expensive as they have more diamonds surrounding the band, hiding the metal and
                        are usually harder to resize. The half eternity only has stones encircling half the
                        circumference of the band. Depending on design and shape of gemstones used, eternity bands are
                        great for adding to your stack of rings. It complements the engagement ring and together brings
                        subtle attention to the centrepiece.</p><p>Apart from being associated with wedding bands,
                        eternity bands are also perfect gifts for celebratory occasions such as anniversaries or
                        Valentine’s day. Regardless of purpose of the band, it is customary to always wear an eternity
                        band on the ring finger of your left hand as a symbol of lifelong commitment and union.</p>
                    <h3>Diamond Bands</h3><p><img className="img-fluid" src="/assets/img/static/learn/wdb.jpg"
                                                  alt="Wedding Bands"/></p><p>Diamond bands are suitable for those who
                        wants to add a flashier yet subtle look and feel to their everyday wedding band. Some design
                        caters for a matching pair with the partner’s ring to symbolize undying love and unity in their
                        relationship.</p><p>It is becoming a trend, even for men to have diamonds on their wedding band
                        as it brings a unique style in comparison to a classic band. Diamonds relate to eternal love but
                        also reflects hardness and strength making it a perfect symbol for men’s jewellery. For women,
                        diamond wedding bands are still a great choice for pairing with engagement rings or even for
                        everyday wear. Most styles are diamond studded flushing together with the metal, giving it a
                        small sparkle.</p></div>
            </div>
        </Tab.Pane> <Tab.Pane eventKey="fineJewelry">
            <div>
                <div className="show-md" id="educationLeft"><img className="img-fluid"
                                                                 src="/assets/img/static/learn/diamond-studs-1.jpg"
                                                                 alt="Diamond Studs" title="Diamond Studs"/></div>
                <div s>
                    <div><h1>Diamond Studs</h1>
                        <div className="content categoryDetails" categoryid="5" subcategoryid="1">
                            <div className="CategoryText"><p>If you are looking to purchase the perfect diamond studs
                                and give them as a gift to that special someone, consider a fabulous pair of diamond
                                stud earrings. <a href="/loose-diamonds/"
                                                  className="pointer_underline">Diamonds</a> have long been considered
                                one of the best gifts to give someone and stud earrings are a classic piece of jewelry.
                                Over the years, they have become a great gift for both males and females. When it comes
                                to diamond stud earrings, there are many great choices. You can find earrings to suit
                                every taste and style.</p><h3>Tips for Finding and Selecting Diamond Studs Earrings</h3>
                                <p>Diamond studs are an attractive gift - one that is sure to get any lady noticed.
                                    Classy and elegant, diamond studs earrings are the perfect gift for any occasion.
                                    Before you head off to your local jeweler to buy a pair for the lady in your life,
                                    consider the following tips for finding attractive white gold diamond studs for a
                                    great price.</p><p>There are a variety of diamond studs earrings on the market.
                                    Consider the following when making a purchase:</p><p><b>What is the shape of her
                                    face?</b> If her face is thin, then a round cut diamond studs will look best. If her
                                    face is broader in appearance, then princess cut diamond studs will complement her
                                    look. You'll want to match up the right diamond for the right face to give her the
                                    right look.</p><p><b>What is her skin tone?</b> The metal setting for the diamond
                                    should match her skin tone. White gold or platinum sets off a cool skin tone while
                                    yellow gold looks great on a woman with a warm skin tone. Don't settle for a cheap
                                    setting either - no diamond should be mounted on anything but a quality background.
                                </p><p><b>Did you find the right setting?</b> The snugger the diamond is against the
                                    ear, the better. Crown-style settings push the diamond too far out and away from the
                                    ear, lending a gangly appearance, while three- and four-prong martini or basket
                                    settings look elegant and refined.</p><p>When it comes time to shop for diamond
                                    studs, visiting your local jeweler is one choice to help you find a pair that she
                                    likes. However, the internet is also a place many men like to shop as nothing beats
                                    the convenience or value of shopping online.</p><p>Key jewelers, including Baemont,
                                    have a strong online presence and offer a wide selection of jewelry choices at
                                    competitive prices. Low overhead costs mean that the savings are passed on to you.
                                    In some cases you will find that the savings can allow you to step up to the next
                                    level and purchase earrings with a greater carat weight. The advantage of using a
                                    jewelry site like Baemont makes perfect sense: you save money and you get more for
                                    the money. Besides, you can even create her pair of <a
                                        href="fine-jewelry/design-your-own-studs/" className="pointer_underline">diamond
                                        studs</a> right online! </p><p>No matter whether you shop Baemont or another
                                    retailer, diamond studs are a special gift, one that complements her beauty and
                                    something that she will certainly cherish for many years to come.</p> <p>A favorite
                                    gift to give for most any special occasion are diamond studs, earrings which look
                                    great and can be worn around the house or while attending a wedding or dinner party.
                                    Elegant, refined, and stylish, most women already own a pair of diamond studs but a
                                    second or third pair is always welcome. Thanks to the internet, you can shop for
                                    diamond studs online and save a tremendous amount of money for the same quality
                                    diamonds sold by your mall's jeweler. Please read on for some tips on how to safely
                                    shop and save money on your next pair of diamond studs.</p> <p>The internet has
                                    changed the way that people shop as the availability of goods has expanded from
                                    local selling to a global marketplace. Truly, you can be living in New York and do
                                    your online shopping with merchants who are located in Hong Kong, Abu Dhabi, Los
                                    Angeles and elsewhere. Lower overhead and stiffer competition has forced prices
                                    down, bringing deals to consumers as never seen before.</p><p>Even though a website
                                    may be selling diamond studs, not all merchants and not all diamond studs are equal.
                                    Some things to keep in mind when shopping for diamond studs include:</p><p><b>Types
                                    of diamond studs</b> - studs - Princess cut diamonds look great on a woman with a
                                    broader face while <a href="/loose-diamonds/round-cut/"
                                                          className="pointer_underline">round diamonds</a> complement
                                    the woman whose face is narrow. Choose the setting based on your skin tone, with
                                    white gold or platinum enhancing the look of a woman with a cool skin tone and
                                    yellow gold matching well with the woman who has a warm skin tone. Visit this skin
                                    tone page to determine what your tone is if you are uncertain.</p><p><b>Choice of
                                    settings</b> - settings - You'll find plenty of retailers selling <a
                                    href="/loose-diamonds/round-cut/" className="pointer_underline">diamond studs</a> in
                                    a variety of settings, but some settings look much better than others. For example,
                                    a crown setting pushes the diamond too far out, giving it a hanging look.
                                    Three-prong pave or martini, four-prong basket, or six-prong coronets are
                                    well-fitting and look beautiful. Every wedding bride wants the best look on her
                                    special day.</p><p><b>Previewing diamond studs</b> - One concern online shoppers
                                    have about diamond shopping is previewing the product before making a purchase.
                                    Clearly, you cannot hold the studs in your hand but an online merchant such as
                                    Baemont has 3D images of all of their products. Click on any image and the diamond
                                    studs will rotate giving you the same view you would see if you were at your local
                                    jewelry store.</p><p><b>Buying your studs</b> - Some online merchants are
                                    experienced jewelers, having gained practice working with customers face to face
                                    while others are resellers who are simply trying to capitalize on the marketplace.
                                    You'll want to deal directly with a professional jeweler who knows diamonds, stocks
                                    his own inventory, and offers generous insurance, shipping and return policies.
                                    Never purchase jewelry from an unsecured site and be careful when purchasing your
                                    diamonds overseas as you may not be able to enjoy the consumer protection you do
                                    here. </p><p>Diamond studs make every woman feel and look beautiful, offering
                                    brilliance and clarity unlike anything else she will wear. Whether purchased for
                                    engagement, wedding, birthday or anniversary, she'll always remember your gift and
                                    your warm expression of love!</p></div>
                        </div>
                    </div>
                </div>
            </div>
        </Tab.Pane> </Tab.Content></div>
    </Row> </Container> </Tab.Container> </>)
}

export default Learn
import React, {useState, useCallback, useEffect} from 'react'
import { Link,useParams ,useNavigate, useSearchParams} from 'react-router-dom'

import { FaEye, FaEyeSlash } from "react-icons/fa"
import {ToastContainer, toast} from "react-toastify";




function ResetPass(props) {
    let [passState, setPassState] = useState('false');
    let [tokenStatus, setTokenStatus] = useState('false');
    let [tokenError, setTokenError] = useState('');


    const [searchParams, setSearchParams] = useSearchParams();

    const token_data = searchParams.get("token");
    const email_data = searchParams.get("email");

    let navigate = useNavigate()
 
  async  function check_token(){

        var formData = new FormData();
        formData.append('request', JSON.stringify({
            token : token_data,
            email : email_data,
            action: "check_token"
        }));

        var resp = await fetch(process.env.REACT_APP_API_URL+"/api/forgotpass",{
            method: 'POST',
            body: formData
        });

        var data = await resp.json();
        if(data.success == true){
            setTokenStatus('true');
        } else{
            setTokenStatus('false');
            setTokenError(data.msg)

        }
        /*if(data.success == true){
            //setTokenStatus
            /!*document.getElementById("forgot_email").value = "";
            toast.success(data.msg);
            props.loader(false);*!/
        }
        if(data.success == false){
           /!* toast.error(data.msg);
            props.loader(false);*!/
        }*/
    }

    let [validationError, setValidationError] = useState(false);
    let [password, setPassword] = useState("");
    let [passConfirm, setPassConfirm] = useState("");
    const handleSubmit = async (e) => {
        e.preventDefault();
        props.loader(true);

            var formData = new FormData();
            formData.append('request', JSON.stringify({
                token: token_data,
                email: email_data,
                pass: password,
                pass_confirm: passConfirm,
                action: "reset_token"
            }));

            var resp = await fetch(process.env.REACT_APP_API_URL + "/api/forgotpass", {
                method: 'POST',
                body: formData
            });

            var data = await resp.json();

        if(data.success == true){
            props.loader(false);

            toast.success(data.msg);
            setPassword("");
            setPassConfirm("");
           setTimeout(function (){
               navigate('/login');
           },2000);
        }

        if(data.success == false){
            toast.error(data.msg);
            props.loader(false);
        }

        /*if(data.success == true){
            //setTokenStatus
            /!*document.getElementById("forgot_email").value = "";
            toast.success(data.msg);
            props.loader(false);*!/
        }
        if(data.success == false){
           /!* toast.error(data.msg);
            props.loader(false);*!/
        }*/
        console.log("Submit") ;
    };
    useEffect(() => {
        check_token();

    }, []);
    
   const loginstyle = "<style>#userPan{display: none}</style>";
  return (
    <>
        <div
            dangerouslySetInnerHTML={{__html: loginstyle}}
        />
        <div className="position-relative">
            <div className="row g-0">
              <div className="col-xl-6 d-xl-block d-none position-relative overflow-hidden log-image1">
                <div className="login-cover cover-image "></div>
                <div className="cont-main w-100 position-relative">
                    <div className="customlogin-imgcontent">
                        <h2 className="mb-3 fs-35 text-white">Welcome To Baemont</h2>

                    </div>
                </div>
              </div>
                
            <div className="col-xl-6 bg-white log-image1">
                <div className="w-75 m-auto">

                    {tokenStatus == 'true'? (
                        <>
                            <form autoComplete="off" id="reset_form" className=" customlogin-content py-5 pt-xl-9">
                                <div className="card-body">
                                    <a className="header-brand" href="index.html">
                                        <img src={window.location.origin +"/assets/img/logo.png"}  className="header-brand-img custom-logo" alt="Beamontlogo" />
                                    </a>
                                </div>
                                <div className="card-body">
                                    <h2 className="mb-2">Reset Password</h2>
                                    <p className="text-muted">Enter a new password to reset the password on your
                                        account.<br/>We'll ask for this password whenever you log in.</p>


                                </div>
                                <div className="card-body" id="login" name="login">
                                    <div className="row">

                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-label">New password</label>
                                                <div className="input-group mb-4">
                                                    <div className="input-group" id="Password-toggle">
                                                        <button type='button'  className="pass input-group-text" onClick={(e)=>{  setPassState(!passState)}}>
                                                            { passState ?
                                                                <FaEye />
                                                                :
                                                                <FaEyeSlash />
                                                            }
                                                        </button>
                                                        <input  autoComplete="new-password"  id="pass" className="form-control"  onChange={(e)=> {setPassword(e.currentTarget.value)}}  type={ passState ? "password" : 'text' } placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Confirm new password</label>
                                                <div className="input-group mb-4">
                                                    <div className="input-group" id="Password-toggle">
                                                        <button type='button' className="pass input-group-text"  onClick={(e)=>{  setPassState(!passState)}}>
                                                            { passState ?
                                                                <FaEye />
                                                                :
                                                                <FaEyeSlash />
                                                            }
                                                        </button>
                                                        <input  autoComplete="new-password" id="confirm_pass" className="form-control"  onChange={(e)=> {setPassConfirm(e.currentTarget.value)}}  type={ passState ? "password" : 'text' } placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">

                                            <div className="submit">
                                                <button onClick={handleSubmit} className="btn btn-default mt-3 btn-block" type="button">
                                                    Reset Password
                                                </button>
                                            </div>
                                        </div>

                                    </div>



                                </div>

                            </form>

                        </>
                    ): (
                        <div className='text-danger fs-5 flex-column vh-100 d-flex justify-content-center'>
                            <h2>Invalid/Expired Link</h2>
                            {tokenError}
                            <div>
                                <Link className='btn btn-default mt-4' to={'/login'}>Back to login</Link>
                            </div>
                        </div>

                    )}







                </div>
            </div>
            </div>
        </div>

    </>
  )
}

export default ResetPass
import React, {useState, useCallback, useEffect} from "react";
import {Link} from "react-router-dom";
import {FaEnvelopeOpenText, FaEye, FaEyeSlash} from "react-icons/fa";
import axios from 'axios';
import {ToastContainer, toast} from "react-toastify";
import {
    LoginSocialGoogle,
    LoginSocialFacebook
} from "reactjs-social-login";


import {
    FacebookLoginButton,
    GoogleLoginButton
} from "react-social-login-buttons";
import "./StyleAuth.css";


function Login(props) {
    let [passState, setPassState] = useState("false");
    let passToggle = () => {
        setPassState(!passState);
    };
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    let [responsedata, setResponsedata] = useState([]);
    let [responsestatus, setResponsestatus] = useState([]);
    let [loginforget, setLoginforget] = useState(true);
    const [error, setError] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        if (email === "" || password === "") {
            setError(true);
        } else {

            setSubmitted(true);
            submitdata();
            setError(false);
        }
    };

    async function submitdata() {

        var formData = new FormData();
        formData.append("request", JSON.stringify({
            email: email,
            password: password,

        }));

        var resp = await fetch(process.env.REACT_APP_API_URL + "/api/auth/signin", {
            method: "POST",
            body: formData
        });

        var data = await resp.json();
        console.log(data);
        setResponsedata(data.msg);
        setResponsestatus(data.success);
        if (data.success === true) {
            localStorage.setItem("customer_id", data.data.id);
            localStorage.setItem("firstname", data.data.firstname ?? "");
            localStorage.setItem("lastname", data.data.lastname ?? "");
            localStorage.setItem("email", data.data.email ?? "");
            localStorage.setItem("mobile", data.data.mobile_no ?? "");
            var checkcart = localStorage.getItem("availableCartProductIds");
            if (checkcart == null) {
                window.location.href = window.location.origin + "/account";
            } else {
                window.location.href = window.location.origin + "/checkout";
            }
        }
    }


    async function handleForgot() {
        props.loader(true);
        const domain_url = window.location.protocol + "//" + window.location.host;
        var formData = new FormData();
        formData.append("request", JSON.stringify({
            forgot_email: document.getElementById("forgot_email").value ?? "",
            domain: domain_url ?? "",
            action: "forgot_pass"
        }));

        var resp = await fetch(process.env.REACT_APP_API_URL + "/api/forgotpass", {
            method: "POST",
            body: formData
        });

        var data = await resp.json();
        if (data.success == true) {
            document.getElementById("forgot_email").value = "";
            toast.success(data.msg);
            props.loader(false);
        }
        if (data.success == false) {
            toast.error(data.msg);
            props.loader(false);
        }

    }

    const handleEmail = (e) => {
        setEmail(e.target.value);
        //setSubmitted(false);
    };
    const handlePassword = (e) => {
        setPassword(e.target.value);
    };
    const errorMessage = () => {
        return (
            <>
                {error ?
                    <div className="error alert d-flex align-items-center alert-danger">
                        Please enter all the fields
                    </div>
                    :
                    ""}
            </>
        );
    };

//Social login
    const REDIRECT_URI = window.location.href;

    const [provider, setProvider] = useState("");
    const [profile, setProfile] = useState("");

    const onLoginStart = useCallback(() => {
        console.log("login start"); 
    }, []);

    async function googleLogin(data,provider){


        axios.get("https://www.googleapis.com/oauth2/v3/userinfo?access_token="+data.access_token)
            .then(response => {
              const profile_data = response.data;
                const res_data = gsingin(profile_data,data.access_token);
                console.log(res_data)
            });

        async function gsingin(profile_data,access_token){

            var formData = new FormData();
            formData.append('request', JSON.stringify({
                first_name: profile_data.given_name,
                last_name: profile_data.family_name,
                email: profile_data.email,
                password: access_token,
            }));

            var resp = await fetch(process.env.REACT_APP_API_URL+"/api/auth/gsignin",{
                method: 'POST',
                body: formData
            });
            var data = await resp.json();
            if (data.success === true) {
                localStorage.setItem("customer_id", data.data.id);
                localStorage.setItem("firstname", data.data.firstname ?? "");
                localStorage.setItem("lastname", data.data.lastname ?? "");
                localStorage.setItem("email", data.data.email ?? "");
                localStorage.setItem("mobile", data.data.mobile_no ?? "");
                var checkcart = localStorage.getItem("availableCartProductIds");
                if (checkcart == null) {
                    window.location.href = window.location.origin + "/account";
                } else {
                    window.location.href = window.location.origin + "/checkout";
                }
            }
        }
    


     /*   fetch(", requestOptions)
            .then(response => response.text())
            .then(result => setProfile(result))
            .catch(error => console.log('error', error));
      /!*  setProvider(provider);
        setProfile(data);*!/
        console.log(profile);*/
    }
    ////////////////////////////////

    const loginstyle = "<style>#userPan{display: none}</style>";

    function loginforgetswitch(e) {
        setLoginforget(e);
    }


    return (
        <>
            {/*  <div
            dangerouslySetInnerHTML={{__html: loginstyle}}
        />*/}
            <div className="position-relative">
                <div className="row g-0">
                    <div className="col-xl-6 d-xl-block d-none position-relative overflow-hidden log-image1">
                        <div className="login-cover cover-image "></div>
                        <div className="cont-main w-100 position-relative">
                            <div className="customlogin-imgcontent">
                                <h2 className="mb-3 fs-35 text-white">Welcome To Baemont</h2>

                            </div>
                        </div>
                    </div>

                    <div className="col-xl-6 bg-white log-image1">
                        <div className="w-75 m-auto">


                            {loginforget === true ? (
                                <>
                                    {/*Login*/}
                                    <form autoComplete="off" id="login_form"
                                          className=" customlogin-content py-5 pt-xl-9">
                                        <div className="card-body">
                                            <a className="header-brand" href="index.html">
                                                <img src={window.location.origin + "/assets/img/logo.png"}
                                                     className="header-brand-img custom-logo" alt="Beamontlogo"/>
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <h2 className="mb-2">Login</h2>
                                            <p className="text-muted">Sign In to your account</p>

                                            {responsestatus == true ? (
                                                <>
                                                    {responsedata != "" ? (<div
                                                        className="alert d-flex align-items-center alert-success">{responsedata}</div>) : ""}
                                                </>

                                            ) : (
                                                <>
                                                    {responsedata != "" ? (<div
                                                        className="alert d-flex align-items-center alert-danger">{responsedata}</div>) : ""}
                                                </>
                                            )}
                                            <div className="messages">

                                                {/* {emailError} */}
                                                {errorMessage()}

                                            </div>
                                        </div>
                                        <div className="card-body" id="login" name="login">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Email address</label>
                                                        <div className="input-group mb-4">
                                                            <div className="input-group">
                                        <span className="input-group-text">
                                            <FaEnvelopeOpenText/>
                                        </span>
                                                                <input type="email"
                                                                       className="form-control" placeholder=""
                                                                       onChange={handleEmail} defaultValue={email}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Password</label>
                                                        <div className="input-group mb-4">
                                                            <div className="input-group" id="Password-toggle">
                                                                <button type="button" className="pass input-group-text"
                                                                        onClick={(e) => {
                                                                            setPassState(!passState);
                                                                        }}>
                                                                    {passState ?
                                                                        <FaEye/>
                                                                        :
                                                                        <FaEyeSlash/>
                                                                    }
                                                                </button>
                                                                <input autoComplete="new-password"
                                                                       className="form-control"
                                                                       onChange={handlePassword} defaultValue={password}
                                                                       type={passState ? "password" : "text"}
                                                                       placeholder=""/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value=""
                                                                   name="remeber_me" id="remeber_me"/>
                                                            <label className="form-check-label" htmlFor="remeber_me">
                                                                Remember me
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="submit">
                                                        <button onClick={handleSubmit}
                                                                className="btn btn-default mt-3 btn-block"
                                                                type="submit">
                                                            Login
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="text-end my-3">
                                                        <p className="mb-2">
                                                            <button className="btn p-0 border-0" onClick={(e) => {
                                                                loginforgetswitch(false);
                                                            }}>Forgot Password
                                                            </button>
                                                        </p>
                                                        <p className="text-dark mb-0">Don't have account?<Link
                                                            className="text-primary ms-1"
                                                            to="/registration">Register</Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="social-login-title d-none">Or login with</div>
                                            <div className="loginpage-social-login text-center">
                                                {/* <span>
                                   <LoginSocialFacebook
                                       appId={process.env.REACT_APP_FB_APP_ID || ''}
                                       isOnlyGetToken={true}
                                       fieldsProfile={
                                           'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                                       }
                                       onLoginStart={onLoginStart}
                                       onLogoutSuccess={onLogoutSuccess}
                                       redirect_uri={REDIRECT_URI}
                                       onResolve={({ provider, data }: IResolveParams) => {
                                           setProvider(provider);
                                           setProfile(data);
                                       }}
                                       onReject={err => {
                                           console.log(err);
                                       }}
                                   >
                                   <FacebookLoginButton text=""/>
                               </LoginSocialFacebook>
                             </span>*/}

                                                <span>
                                   <LoginSocialGoogle
                                       client_id={process.env.REACT_APP_GG_APP_ID || ""}
                                       onLoginStart={onLoginStart}
                                       isOnlyGetToken={true}
                                       redirect_uri={REDIRECT_URI}
                                       scope="openid profile email"
                                       discoveryDocs="claims_supported"
                                       access_type="offline"

                                       onResolve={({provider, data}: IResolveParams) => {
                                           googleLogin(data);
                                       }}
                                       onReject={err => {
                                           toast.error(err);
                                       }}
                                   >
                                   <GoogleLoginButton text=""/>
                               </LoginSocialGoogle>
                              </span>
                                            </div>


                                        </div>

                                    </form>

                                </>
                            ) : (
                                <>

                                    {/*Forget Password*/}

                                    <form autoComplete="off" id="forgot_form"
                                          className="customlogin-content py-5 pt-xl-9">
                                        <div className="card-body">
                                            <a className="header-brand" href="index.html">
                                                <img src={window.location.origin + "/assets/img/logo.png"}
                                                     className="header-brand-img custom-logo" alt="Beamontlogo"/>
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <h2 className="mb-2">Forgot Password </h2>
                                            <p className="text-muted">Enter your email id and we will send you
                                                instructions to reset your password</p>

                                        </div>
                                        <div className="card-body" id="forgotpass" name="forgotpass">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Email address</label>
                                                        <div className="input-group mb-4">
                                                            <div className="input-group">
                                        <span className="input-group-text">
                                            <FaEnvelopeOpenText/>
                                        </span>
                                                                <input type="email" autoComplete="off"
                                                                       role="presentation"
                                                                       className="form-control" placeholder=""
                                                                       name="forgot_email" id="forgot_email"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-6">

                                                    <div className="submit">
                                                        <button onClick={handleForgot}
                                                                className="btn btn-default mt-3 btn-block"
                                                                type="submit">
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="text-end my-3">
                                                        <p className="mb-2">
                                                            <button className="btn p-0 border-0" onClick={(e) => {
                                                                loginforgetswitch(true);
                                                            }}>Back to login
                                                            </button>
                                                        </p>
                                                        <p className="text-dark mb-0">Don't have account?<Link
                                                            className="text-primary ms-1"
                                                            to="/registration">Register</Link></p>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                    </form>

                                </>
                            )}


                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Login;
import React, {useState, useEffect, useRef} from "react";
import { useParams,useNavigate} from 'react-router-dom'

import { FaRegHeart, FaHeart, FaTimes } from "react-icons/fa"
import GoToTop from '../parts/GoToTop'

import Modal from "react-bootstrap/Modal";


import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { PropTypes } from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


import FilterSteps from "../parts/filter-steps";

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Btooltip from "react-bootstrap/Tooltip";

import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/navigation"
import "swiper/css/thumbs"
import "./Prodslide.css"

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper"



import {ToastContainer, toast} from "react-toastify";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function useOutsideAlerter(ref) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                if(document.querySelector(".inscription-close")) {
                    document.querySelector(".inscription-close").click();
                }
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}
function RFRModal(props) {
    return (
        <Modal
            {...props}
            className="RFRModal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <button type="button" onClick={props.onHide} className="close">
                <svg viewBox="0 0 1792 1792">
                    <path
                        d="M1490 1322q0 40-28 68l-136 136q-28 28-68 28t-68-28l-294-294-294 294q-28 28-68 28t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294 294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68l-294 294 294 294q28 28 28 68z"></path>
                </svg>
            </button>
            <Modal.Body>
                <div>
                    <h2>The BAEMONT Guarantee</h2>
                    <div className="para-modal">
                        <h3>20/7 Customer Service</h3>
                        <p>We strive to provide the best possible response to our customers as our team of industry
                            experts stand by to serve with care.</p>
                    </div>

                    <div className="para-modal">
                        <h3>Product Lifetime Warranty</h3>
                        <p> All of our products are manufactured with the highest of quality and is carefully inspected
                            prior to shipment. We stand behind our products and warrant that all items will be free from
                            manufacturing defects at the time of delivery. </p>
                    </div>

                    <div className="para-modal">
                        <h3>Price-match Guarantee</h3>
                        <p> Our diamond prices are very competitive in our markets and we will offer to match the price
                            for a similar or identical piece of diamond sold elsewhere with lower pricing. </p>
                    </div>

                    <a href="/contact-us/"><em>Learn More...</em></a>
                </div>
            </Modal.Body>

        </Modal>
    );
}

function ProdWeddingBand(props) {


    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
    
    const [modalShowRFT, setModalShowRFT] = React.useState(false);
    const {id} = useParams();
    let [responsedata,setResponsedata] = useState([]);
    let [parentdata,setParentdata] = useState([]);
    // let [metalres, setMetalres] = useState([]);

    let [slug,setSlug] = useState([]);
    let [itext, setItext] = useState(null);
    var formData = new FormData();
    let [thumbsSwiper, setThumbsSwiper] = useState();
    const [ringsize, setRingsize] = React.useState('');
    const [ringprice, setRingprice] = React.useState();

    let navigate = useNavigate();
    const handleChange = (event) => {
        setRingsize(event.target.value);
        localStorage.setItem("ringsize",event.target.value);
        getproductdetails();
    };

    let [rating, setRating] = useState(null);
    let [hover, setHover] = useState(null);
    let [isActive, setActive] = useState("false");



    const user_login_check = JSON.parse(localStorage.getItem("customer_id")) ?? "";

    let [isWishlist, setWishlist] = useState(false);

    const wishListAdd = async (item) => {
        console.log("Added " + item);
        props.loader(true)
        var formData = new FormData();

        var requestData = {
            user_id: user_login_check,
            product_id: item,
            product_type: 'weddingband',
            "action": "add"
        };
        formData.append("request", JSON.stringify(requestData));
        var requestOptions = {
            method: "POST",
            body: formData
        };
        var resp = await fetch(process.env.REACT_APP_API_URL + "/api/wishlist", requestOptions);

        var data = await resp.json();


        if (data.success == true) {
            toast.info(data.data.msg);
            props.loader(false)
        }
        if (user_login_check) {
            //console.log(user_login_check);
            get_wishlist(user_login_check);

        }
    };

    const wishListRemove = async (item) => {
        console.log("Remove " + item);
        props.loader(true)
        var formData = new FormData();

        var requestData = {
            user_id: user_login_check,
            product_id: item,
            "action": "remove"
        };
        formData.append("request", JSON.stringify(requestData));
        var requestOptions = {
            method: "POST",
            body: formData
        };
        var resp = await fetch(process.env.REACT_APP_API_URL + "/api/wishlist", requestOptions);


        var data = await resp.json();
        if(data.success == true) {
            props.loader(false)
            toast.info(data.data.msg);
        }
        if (user_login_check) {
            //console.log(user_login_check);
            get_wishlist(user_login_check);

        }
    };

    async function get_wishlist() {
        props.loader(true)
        var formData = new FormData();

        var requestData = {
            "user_id": user_login_check,
            "product_id": id,
            "action": "check"
        };
        formData.append("request", JSON.stringify(requestData));
        var requestOptions = {
            method: "POST",
            body: formData
        };
        var resp = await fetch(process.env.REACT_APP_API_URL + "/api/wishlist", requestOptions);

        var data = await resp.json();

        if (data.success == true) {
            setWishlist("fill");
        } else {
            setWishlist("unfill");
        }
        props.loader(false)
    }

    const renderWishlistTooltip = (props) => (
        <Btooltip id="button-tooltip" {...props}>
            Please login for wishlisting
        </Btooltip>
    );
    let wishToggle = () => {
        setActive(!isActive);
    }
    // let [tabtext2, setTabtext2] = useState([]);
    let [tabcss2, setTabcss2] = useState([]);

    let [metal, setMetal] = useState('18K White Gold');
    let [tabtext1, setTabtext1] = useState([]);
    let [tabcss1, setTabcss1] = useState([]);
    const [error, setError] = useState(false);

    let [ringpasram, setRingpasram] = useState([]);


    //Tabs
    async function getproductdetails(){
        props.loader(true)
        var requestdata = {
            id: id,
            type:'weddingband',
            currency: localStorage.getItem('currency') ?? "RM",
            size: localStorage.getItem("ringsize") ?? "",
        };
        formData.append("request", JSON.stringify(requestdata));
        var requestOptions = {
            method: "POST",
            body: formData
        };


        var resp = await fetch(process.env.REACT_APP_API_URL+"/api/product_details", requestOptions);
        var data = await resp.json();

        setResponsedata(data.data);
        setSlug(data.data.sku);
        setParentdata(data.data.parent);
        setRingpasram(data.data.sizes);
        props.loader(false);
        if(data.success ==  false) {

            navigate("/settings-ring/all");
        }


    }


    async function postRecentview() {
        var customer_id =  localStorage.getItem("customer_id");
        let uuid;
        if(customer_id){
            uuid = customer_id;
        } else{
            uuid = "-1";
        }

        var requestdata = {
            product_id: id,
            uuid: uuid,
            type: 'weddingband',
            action:'add'
        };
        formData.append("request", JSON.stringify(requestdata));
        var requestOptions = {
            method: "POST",
            body: formData
        };

        var resp = await fetch(process.env.REACT_APP_API_URL+"/api/recent_view", requestOptions);

        var data = await resp.json();


    }
    const [value, setValue] = React.useState(0);

    const onTabChange = (event, newValue) => {
        setValue(newValue);
    };
    // end Tabs

    // Start Custom Tooltip inscription
    let [inscriptionInput, setInscriptionInput] = useState();
    const [openTooltip, setTooltipOpen] = React.useState(false);
    let [fontInscrip, setFontInscrip] = useState(false);
    const handleTooltipClose = (e) => {
        e.stopPropagation();
        setTooltipOpen(!openTooltip);
    };
    const handleTooltipOpen = () => {
        setTooltipOpen(!openTooltip);
    };
    let addFontOne = () => {
        var element = document.querySelector(".inscriptionText");
        element.classList.add('font1');
        element.classList.remove('font2');
    }
    let addFontTwo = () => {
        setFontInscrip(!fontInscrip);
        var element = document.querySelector(".inscriptionText");
        element.classList.add('font2');
        element.classList.remove('font1');
    }
    const getValue=() => {
        var ringincriptionval = document.getElementById("ringData").innerHTML;
        localStorage.setItem("ringText",ringincriptionval);
        console.log(ringincriptionval);
        let classValue = Array.from(document.getElementById("inscriptionId").classList);
        //console.log(classValue['1']);
        //if(classValue['1'] == ) {
        localStorage.setItem("ringfont", classValue['1']);
        //}
        setTooltipOpen(!openTooltip);
        setItext(ringincriptionval);
    }
    var injectlenght = 20;
    let injectcalc;
    const injectTxt = (ev) => {

        ev.stopPropagation();
        if(ev.target.value.length < 21){
            injectcalc = Number(20) - ev.target.value.length
            document.getElementById("injectTxtLenght").innerHTML = injectcalc;
            document.getElementById("ringData").innerHTML = ev.target.value;
        }


    }
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 50,
            minWidth: 25 + 'em',
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));
    // End Custom Tooltip inscription



    //Start Modal
    const [open, setModalstate] = React.useState(false);
    const modalOpen = () => {
        setModalstate(true);
    };
    const modalClose = () => {
        setModalstate(false);
    };
    //End Modal


    const errorMessage = () => {
        return (
            <>
                {localStorage.getItem('ringsize') ? "": (
                    <>
                        {error ?
                            <div className="mt-3 py-2 error alert d-flex align-items-center alert-danger">
                                Please select Size
                            </div>
                            :
                            ''}
                    </>
                )}

            </>
        );
    };


    const addToCart=(weddingband) => {

        console.log(weddingband);
        if(ringsize===''){
            setError(true);
        }else{
            setError(false); 
            let selectedChkbox = {
                product_type : "simple",
                type : 'weddingband',
                product_data : weddingband,
                slug : '/wedding-band/',
                ring_size : localStorage.getItem("ringsize"),
                ring_price : localStorage.getItem("ringprice"),
                ring_font : localStorage.getItem("ringfont"),
                ring_text : localStorage.getItem("ringText"),

            };

            //props.setCartItem([...props.cartVal, selectedChkbox])
            var upindex = 0;
            var existItem = localStorage.getItem('productsbag') == null ? [] : JSON.parse(atob(localStorage.getItem('productsbag')));

            if(existItem.length > 0) {
                upindex = parseFloat(existItem.length);
            } else{

                 upindex = 0;
            }
            console.log(existItem.length);
            existItem[upindex] = selectedChkbox;
            localStorage.setItem("productsbag",btoa(JSON.stringify(existItem)));
            localStorage.removeItem("indexvalue");
             console.log("add");

            props.setCartItem(['']);
             console.log(existItem.length);
            localStorage.removeItem("ringsize")
            localStorage.removeItem("ringprice")
            localStorage.removeItem("ringText")
            localStorage.removeItem("ringfont")
            localStorage.removeItem("diamondId")
            localStorage.removeItem("ringId")
            navigate('/cart', { replace: true })
        }

    }
    const [vision_Show, setVisionShow] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const visionShow = () => setVisionShow(true);
    const visionClose = function () {
        setVisionShow(false);
        setLoaded(false);
    };
    useEffect(()=>{
  
        localStorage.removeItem("ringsize")
        localStorage.removeItem("ringprice")
        localStorage.removeItem("ringText")
        localStorage.removeItem("ringfont")
        localStorage.removeItem("diamondId")
        localStorage.removeItem("ringId")
        getproductdetails();
        postRecentview();
    },[navigate]);
    useEffect(()=>{


        var inceptionText = localStorage.getItem("ringText");
        // console.log(inceptionText);
        // console.log(inceptionText == ' ');
        // console.log(inceptionText == null);

        setItext(inceptionText);
        // var diamondId = localStorage.getItem("diamondId");
        // var gemsId = localStorage.getItem("gemsId");

        /* if(diamondId!=null || gemsId!=null){
             var textto = 'DIAMOND';
             if(gemsId!=null){
                 var textto = 'GEMSTONE';
             }
             setTabtext2("SETTING");
             setTabtext1(textto);
             setTabcss2("active--bcso0");
             setTabcss1("");
         }else{
             var textto = 'DIAMOND';
             if(gemsId!=null){
                 var textto = 'GEMSTONE';
             }
             setTabtext1("SETTING");
             setTabtext2(textto);
             setTabcss2("");
             setTabcss1("active--bcso0");
         }*/
    },[metal,slug])


    return (
        <>
            <section className='filter-sec mt-0'>
               
                <div className="productdetail-inner">
                    <div className="container  py-5">

                        <div className="row g-4">
                            <div className="col-md-7">
                                <div className="row g-1">

                                    <div className="col-12" >

                                        <Swiper
                                            spaceBetween={10}
                                            navigation={true}
                                            thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                                            modules={[FreeMode, Navigation, Thumbs]}
                                            className="mySwiper2"
                                        >

                                            <SwiperSlide>
                                                <img src={responsedata.front_pic} alt=""/>
                                                <div className="product-additional-galleries">
                                                    {responsedata.media_video ? (
                                                        <button className="product-video-button wd-gallery-btn"
                                                                onClick={visionShow}><span>View Video</span>
                                                        </button>
                                                    ) : ""}


                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img src={responsedata.side_pic} alt=""/>
                                            </SwiperSlide>


                                        </Swiper>
                                    </div>
                                </div>
                                <Modal size={'lg'} className='video-modal' show={vision_Show} onHide={visionClose} centered>
                                    <button type="button" onClick={visionClose}
                                            className="btn-close btn-close-white end-0 opacity-100 p-4 position-fixed top-0"
                                            aria-label="Close"></button>
                                    <Modal.Body className={"text-center"}>
                                        <video
                                            width="400"
                                            height="400"
                                            controls
                                            onLoadedData={() => {
                                                setLoaded(true);
                                            }}
                                        >
                                            <source src={responsedata.media_video} type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>
                                    </Modal.Body>

                                </Modal>
                                <Swiper
                                    onSwiper={setThumbsSwiper}
                                    spaceBetween={10}
                                    slidesPerView={4}
                                    freeMode={true}
                                    watchSlidesProgress={true}
                                    modules={[FreeMode, Navigation, Thumbs]}
                                    className="mySwiperBottom">
                                    <SwiperSlide>
                                        <img src={responsedata.front_pic} alt='' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={responsedata.side_pic} alt='' />
                                    </SwiperSlide>

                                </Swiper>
                            </div>
                            <div className="col-md-5 prod-dtlCont">
                                <h4 className='fw-normal diamond-heading'>
                                    {responsedata.product_name}
                                </h4>
                                <hr/>
                                <p className="text-muted mb-0">
                                    SKU: {responsedata.sku_code}
                                </p>
                                <Button variant="default"
                                        className="riskfreeretail border-0 bg-transparent px-0 small"
                                        onClick={() => setModalShowRFT(true)}>
                                    Risk-free Retail
                                </Button>

                                <RFRModal
                                    show={modalShowRFT}
                                    onHide={() => setModalShowRFT(false)}
                                />


                                <div className="row">
                                    <div className="col-md-12">
                                        <label className='me-2'   htmlFor="">Metal:
                                            {responsedata.metal_type}</label>
                                        <div className="ring-metal filter-iconList">

                                            {parentdata && parentdata.map((jd,i) => (
                                                <div key={i}>
                                                    <>
                                                        <div className='iconList-item'>
                                                            <input type="radio"  className='d-none' onChange={(e)=>{setMetal(e.currentTarget.value);navigate("/wedding-band/"+jd.sku)}} value={jd.metal_type} id={jd.metal_type}   checked={jd.metal_type === responsedata.metal_type ? true  : false } />
                                                            <label htmlFor={jd.metal_type} ><img className='img-fluid list-img' src= {jd.metal_type_icon} alt="" /><span className="ring-metal_label text-center">{jd.metal_type}</span></label>
                                                        </div>
                                                    </>

                                                </div>
                                            ))}    </div>


                                    </div>
                                </div>

                                <div className="row align-items-center">


                                    <div className='col-12'>
                                        <div className="form-floating">
                                            <select className="form-select" id="floatingSelect"   value={localStorage.getItem('ringsize') ?? ""}
                                                    onChange={handleChange}
                                            >
                                                <option disabled value="">Choose Size</option>
                                                {ringpasram.length > 0 ?
                                                    ringpasram.map((ring,index) =>
                                                        <option key={index} value={ring.size}>{ring.size}</option>
                                                    ):''}
                                            </select>
                                            <label htmlFor="floatingSelect">Choose Size</label>
                                        </div>


                                        {errorMessage()}
                                    </div>
                                    <div className='col-12'      ref={wrapperRef}>
                                        <HtmlTooltip
                                            title={
                                                <React.Fragment>


                                                    <div className='mb-2 d-flex flex-column'>

                                                        <div className="row mb-2">
                                                            <div className="col">
                                                                <label>Enter Inscription</label>
                                                            </div>

                                                            <div className="col text-end">
                                                                <button type='button' className='p-0 lh-1 btn inscription-close' onClick={handleTooltipClose}>
                                                                    <FaTimes />
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <input className='form-control' type="text" name="" id="" defaultValue={itext!=null?itext:""} placeholder='Engrave Text' maxLength={20}  onChange={injectTxt}/>
                                                        <small className='text-muted ms-auto'>Characters Left:
                                                            <span id='injectTxtLenght'>{injectlenght}</span>
                                                        </small>
                                                    </div>
                                                    <div className="mb-2">
                                                        <span className="d-block">Choose Font</span>
                                                        <div className="btn-group" role="group" aria-label="Basic example">

                                                            <input type="radio" className="btn-check" name="font_style"
                                                                   id="font_style_one" autoComplete="off" value={'font1'}   onClick={(e)=>{addFontOne(e.currentTarget.value)}} />
                                                            <label className="btn btn-outline-light p-0"
                                                                   htmlFor="font_style_one">  <img src={window.location.origin + "/assets/img/inscription/font_1.gif"} alt="" className='img-fluid' /></label>

                                                            <input type="radio" className="btn-check" name="font_style"
                                                                   id="font_style_two" autoComplete="off" value={'font2'} onClick={(e)=>{addFontTwo(e.currentTarget.value)}}/>
                                                            <label className="btn btn-outline-light p-0"
                                                                   htmlFor="font_style_two"><img src={window.location.origin + "/assets/img/inscription/font_2.gif"} alt="" className='img-fluid'  /></label>


                                                        </div>


                                                    </div>
                                                    <div className="d-block">
                                                        <div className="inscription-wrapper">
                                                            <svg viewBox="0 0 248 120">
                                                                <image className='inscriptionImg' width="246" height="119" href={window.location.origin + "/assets/img/inscription/Inscription.png"}  transform="matrix(1 0 0 1 -0.22 0)">
                                                                </image>
                                                                <path className="inscriptionPath" id="SVGID_x5F_2_x5F_" d="M-0.2,75.8c29.3-9.4,73.3-19.9,127.3-19.4c49.7,0.4,90.5,9.9,118.7,18.9" fill="transparent"></path>
                                                                <text id="inscriptionId" className={ fontInscrip ? 'inscriptionText font2' : 'inscriptionText font1' } textAnchor="middle">
                                                                    <textPath href="#SVGID_x5F_2_x5F_" startOffset="50%">
                                                                        <tspan className="font_1--xSr3A" id="ringData"> {itext}</tspan>
                                                                    </textPath>
                                                                </text>
                                                            </svg>
                                                        </div>
                                                        <small className="text-muted mt-2 mb-3 d-block">A 10x magnifying glass may be required to clearly read the inscription on your jewelry.</small>
                                                        <div className="d-flex justify-content-end">
                                                            <button className="btn btn-sm btn-primary text-uppercase" onClick={getValue}>Save</button>
                                                        </div>
                                                    </div>

                                                </React.Fragment>
                                            }
                                            //open={setTooltipOpen}
                                            open={openTooltip}
                                            PopperProps={{
                                                disablePortal: true
                                            }}
                                            disableFocusListener={true}
                                            disableHoverListener={true}
                                            disableTouchListener

                                        >
                                            <button className='btn text-primary border-0 mt-2 px-0' onClick={handleTooltipOpen}>
                                                <i className="fa-solid fa-plus me-1"></i>
                                                {itext ? itext : "Add free inscription"}
                                                {/*{itext ==null && itext == ""  ? itext:" Add free inscription"}*/}



                                            </button>
                                        </HtmlTooltip>
                                        {/* <button className='btn mt-2'><FaPlus size={10} onClick={() => handleTooltipOpen(!openTooltip)} /> Add free inscription</button> */}
                                    </div>
                                </div>
                                <div className="pricePack d-block">
                                    <div className='d-block'>Total</div>
                                    <div className="total">
                                        <span className="now-price">{responsedata.sell_price}</span>
                                    </div>
                                </div>
                                <div className="d-flex">

                                    <button className="btn btn-primary text-uppercase flex-fill text-uppercase me-2"  value={responsedata.length > 0 ?responsedata.sku:''} onClick={()=>addToCart(responsedata)} >Add to cart</button>

{/*
                                    <button className="btn btn-default text-uppercase flex-fill text-uppercase me-2" value={responsedata ?responsedata.sku:''} onClick={e => selectRing(e.target.value)}>Select this Ring</button>*/}


                                    {user_login_check ? (
                                        <>
                                            {(isWishlist == "fill" ? (
                                                <button className="rounded-0 btn btn-default btn-addWishlist"
                                                        onClick={(e) => {
                                                            wishListRemove(responsedata.sku);
                                                        }}>
                                                    <FaHeart/>
                                                </button>

                                            ) : (
                                                <button className="rounded-0 btn btn-outline-default btn-addWishlist"
                                                        onClick={(e) => {
                                                            wishListAdd(responsedata.sku);
                                                        }}>
                                                    <FaRegHeart/>

                                                </button>
                                            ))}
                                        </>


                                    ) : (
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{show: 250, hide: 400}}
                                            overlay={renderWishlistTooltip}
                                        >
                                            <button className="rounded-0 btn btn-outline-default btn-addWishlist">
                                                <FaRegHeart/>
                                            </button>
                                        </OverlayTrigger>


                                    )}
                                </div>
                                {/* <div className="d-block mt-3">
                        <Link to='/product-details'>Go to review</Link>
                    </div> */}

                            </div>
                        </div>

                        {/*   <div className="row mt-4">
                <div className="col-12">
                    <h5>Product Description</h5>
                    <span className="sku text-muted mb-3 d-block"></span>

                </div>
            </div>*/}

                        <div className="row mt-4">
                            <div className="col-12">
                                <Box className='infos' sx={{ width: '100%' }}>
                                    <Box className='info-tabs' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value} onChange={onTabChange} aria-label="basic tabs example">
                                            <Tab label="Product Details" {...a11yProps(0)} />
                                            {/*<Tab label="Can Be Set With" {...a11yProps(1)} />*/}
                                        </Tabs>
                                    </Box>
                                    <TabPanel className='info-dtls' value={value} index={0}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <span className="d-block mb-2 fw-bold">Wedding Band Information</span>
                                                <ul className='dtlInfo'>
                                                    <li>
                                                        <div className="cell">Metal</div>
                                                        <div className="cell text-capitalize">{
                                                            responsedata.metal_type?
                                                                responsedata.metal_type
                                                                :''
                                                        }</div>
                                                    </li>
                                                    <li>
                                                        <div className="cell">Width</div>
                                                        <div className="cell">{
                                                            responsedata.width ?
                                                                responsedata.width
                                                                :''
                                                        }</div>
                                                    </li>
                                                    <li>
                                                        <div className="cell">Weight</div>
                                                        <div className="cell">{
                                                            responsedata.weight ?
                                                                responsedata.weight
                                                                :''
                                                        }</div>
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* <div className="col-md-6">
                                    <span className="d-block mb-2">Diamond</span>
                                    <ul className='dtlInfo'>
                                        <li>
                                            <div className="cell">Shape</div>
                                            <div className="cell">Round</div>
                                        </li>
                                        <li>
                                            <div className="cell">Quantity</div>
                                            <div className="cell">24</div>
                                        </li>
                                        <li>
                                            <div className="cell">Average Total Carat</div>
                                            <div className="cell">0.18</div>
                                        </li>
                                        <li>
                                            <div className="cell">Average Color</div>
                                            <div className="cell">G-H</div>
                                        </li>
                                        <li>
                                            <div className="cell">Average Clarity</div>
                                            <div className="cell">VS2-SI1</div>
                                        </li>
                                    </ul>
                                </div> */}

                                        </div>
                                    </TabPanel>
                                    {/* <TabPanel className='info-dtls' value={value} index={1}>
                            <div className="row">
                                <div className="col-md-6">
                                     <span className="d-block mb-2">Ring Information</span>
                                    <ul className='dtlInfo'>
                                        <li>
                                            <div className="cell">Round</div>
                                            <div className="cell">0.50 - 6.00</div>
                                        </li>
                                        <li>
                                            <div className="cell">Oval</div>
                                            <div className="cell">0.50 - 6.00</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </TabPanel>*/}
                                </Box>
                            </div>
                        </div>
                        <div className='offer-banner'>
                            <div className='row'>
                                <div className='col-md-8 offer-text'>
                                    <h4>Your Order</h4>
                                    <h1>Includes</h1>
                                    <div className='d-flex offer-points'>
                                        <ul>
                                            <li>BAEMONT Exclusive Gift Packaging</li>
                                            <li>Diamond Certificate of Authenticity</li>
                                            <li>Product Lifetime Warranty</li>
                                        </ul>
                                        <ul className='mx-5'>
                                            <li>Lifetime Upgrade</li>
                                            <li>Free Engraving</li>
                                            <li>Free Shipping (Local Orders)</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-md-4 offer-img'>
                                    <img className='img-fluid' src='/assets/img/ring_engagement.png' alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <GoToTop />
        </>
    )
}

export default ProdWeddingBand
import React, {useEffect, useState} from "react";


import ReactPaginate from 'react-paginate';
 
function Wishlist() {
  const [jesorder, setJesorder] = useState([]);
  var customer_id = localStorage.getItem("customer_id");
  if(customer_id==null){
    window.location.href = window.location.origin + '/login';
  }
  const [jesdata, setJesda] = useState([]);
  let [jescnt, setJescnt] = useState([]);

  const [offset, setOffset] = useState(0);
  const [perPage] = useState(20);
  const [pageCount, setPageCount] = useState(0)

  const handlePageClick = async (e) => {
    const selectedPage = e.selected;
    console.log(selectedPage*perPage)
    setOffset(selectedPage*perPage)
  }
  var formData = new FormData();
  async function getOrdersData(){
    var requestdata = {
      customer_id: customer_id,
      offset: offset,
    };
    formData.append('customer', JSON.stringify(requestdata));
    let requestOptions = {
      method: 'POST',
      body: formData
    };
    var pulldata = await fetch("http://188.166.226.236/api/customers/fetchorders",requestOptions);
    var userdata = await pulldata.json();
    var user_response_code = userdata.status;

    setJesorder(userdata.data);
    console.log(userdata.data);

    var respcnt = await fetch("http://188.166.226.236/api/customers/count",requestOptions);
    var datacnt = await respcnt.json();
    setJescnt(datacnt.data);
    setPageCount(Math.ceil(jescnt/perPage))
    ///setSelected(userdata.data.gender);
  }
  useEffect(()=>{
   
    getOrdersData();
  },[jescnt,offset]);
 

  return (
    <div className='col-lg-8 m-auto'>
      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table orderTable">
              <thead>
              <tr>
                <th scope="col">Order No.</th>
                <th scope="col">Amount</th>
                <th scope="col">Order Status	</th>
                <th scope="col">Order Date</th>
                <th scope="col">Payment Mode</th>
                {/* <th scope="col">Est. ship date</th>
                                                <th scope="col">Insurance</th>
                                                <th scope="col">Actions</th> */}
              </tr>
              </thead>
              <tbody>
              {
                (jesorder.length>0) ?
                    jesorder.map( (jd,i) => (
                        <tr key={i}>
                          <th scope="row">{jd.order_id}</th>
                          <td>{jd.total_amount}</td>
                          <td>{jd.order_status}</td>
                          <td>{jd.date}</td>
                          <td>{jd.payment_mode}</td>
                          {/* <td>none</td>
                                                    <td>none</td>
                                                    <td>none</td> */}
                        </tr>
                    )):''}
              </tbody>
            </table>
            <div className="col-12">
              <nav className='d-flex justify-content-center'>
                <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={""}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    className={"pagination"}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    activeLinkClassName={"active"}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                />
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Wishlist
import React,{useState,useEffect} from 'react'
import { Link,useNavigate } from 'react-router-dom'

import {ToastContainer, toast} from "react-toastify";
import { FaThList, FaTh, FaSearchPlus, FaRegHeart, FaHeart, FaStar, FaPlus, FaTimes, FaAngleLeft, FaAngleRight } from "react-icons/fa"
import List from '@mui/material/List'
import { styled } from '@mui/material/styles'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

const  currency = localStorage.getItem('currency') ?? "RM";
 



const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    borderLeft: '0',
    borderRight: '0',
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<FaAngleRight sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'transparent'
        : 'transparent',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
    padding: 0,
    borderBottom: 0
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
      // borderTop: '1px solid rgba(0, 0, 0, .125)',
      paddingRight: 0,
      paddingLeft: 0,  //padding: theme.spacing(2),

  }));


function CartSummary(props) {

    const coupon_local =  localStorage.getItem('coupon_val') ?? "";
    var is_discounted = false;
    let [jesdada, setJesdata] = useState([]);
    const [price, setPrice] = useState([]);
    let [coupons,setCoupons] = useState();
    let [couponsDiscount,setCouponsDiscount] =useState(0);
    let [CouponsDiscountText,setCouponsDiscountText] =useState('');
    let [discount,setDiscount  ] = useState([]);
    let [error,setError] = useState();
    var formData = new FormData();
    const[taxamount,setTaxamout] = useState([]);
    let [fortax,setFortax] = useState(0);
    let navigate = useNavigate()
    const cancelCoupon =()=>{
        setPrice([]);
        setCoupons(0);
        setDiscount([]);
        setCouponsDiscount(0);
        setCouponsDiscountText('');
        // props.setcouponval('');
        document.getElementById('coupon_text').value = '';
        localStorage.removeItem("coupon_val");
        setFortax(0);
        check_calculation()
        console.log("coupon removed");
    }


    const processCheckout =()=>{

         navigate('/checkout');
    }

    const errorMessage = () => {
      return (
          <>
          {error ?
          <div className="messages">
            <div className="error text-danger  p-y2">
              {error}  {/*  <button type="button" className="btn-close ms-auto" data-bs-dismiss="alert" aria-label="Close"></button> */}
            </div>
          </div>
          :
          ''}
          </>
      )
      };

        // var tax_amount = total_price * taxval;
        // setTaxamout(taxamount);
  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };



    const[cartSubTotal,setCartSubTotal] = useState(0);
    const[cartTotal,setCartTotal] = useState(0);
    const[cartTax,setCartTax] = useState(0);

    async function applyCoupon(){
        var customer_id = localStorage.getItem("customer_id");
        var requestdata = {
            coupon: document.getElementById('coupon_text').value,
            sub_total: cartSubTotal,
            currency: currency,
        };

        formData.append('request', JSON.stringify(requestdata));
        let requestOptions = {
            method: 'POST',
            body: formData
        };
        var resp = await fetch(process.env.REACT_APP_API_URL+"/api/apply_coupon",requestOptions);
        var data = await resp.json();


        if(data.success == true){
            var response = data.data;
            var final_amount_before = cartSubTotal;
            var discountAmoun;
            if(response.type == "flat"){
                var final_amount = final_amount_before - response.value;
                setCouponsDiscount(response.value);
                setCouponsDiscountText(response.value+' Flat Discount Applied.');
                discountAmoun = response.value;
            }
            if(response.type == "percent") {
                var final_amount = final_amount_before - response.value;
                setCouponsDiscount(response.value);
                setCouponsDiscountText(response.value + ' % Discount Applied.');
                discountAmoun = final_amount;
            }

            if(jesdada){
                var taxval = parseFloat(jesdada)/100;
                var tax_amount = final_amount * taxval;
                tax_amount = (Math.round(tax_amount * 100) / 100).toFixed(2);
                setFortax(tax_amount);
                setTaxamout(taxamount);
                final_amount = final_amount + taxamount;
            }


            setPrice(final_amount);
            setCoupons(1);
            localStorage.setItem("coupon_val",document.getElementById('coupon_text').value)
            setDiscount(document.getElementById('coupon_text').value);
            //props.setcouponval(document.getElementById('coupon_text').value);
            toast.success(data.msg);
        }

        if(data.success == false){
            localStorage.removeItem("coupon_val")
            toast.error(data.msg);
        }

    }
    async function checkCoupon(){
        if(coupon_local) {
            var customer_id = localStorage.getItem("customer_id");
            var requestdata = {
                coupon: coupon_local,
                sub_total: cartSubTotal,
                currency: currency,
            };

            formData.append('request', JSON.stringify(requestdata));
            let requestOptions = {
                method: 'POST',
                body: formData
            };
            var resp = await fetch(process.env.REACT_APP_API_URL + "/api/apply_coupon", requestOptions);
            var data = await resp.json();


            if (data.success == true) {
                var response = data.data;
                var final_amount_before = cartSubTotal;
                var discountAmoun;
                if (response.type == "flat") {
                    var final_amount = final_amount_before - response.value;
                    setCouponsDiscount(response.value);
                    setCouponsDiscountText('Flat ' + currency + ' ' + response.value + ' Discount Applied.');
                    discountAmoun = response.value;
                }
                if (response.type == "percent") {
                    var final_amount = final_amount_before - response.value;
                    setCouponsDiscount(response.value);
                    setCouponsDiscountText(response.rate + '% Discount Applied.');
                    discountAmoun = final_amount;
                }

                if (jesdada) {
                    var taxval = parseFloat(jesdada.value) / 100;
                    var tax_amount = final_amount * taxval;
                    tax_amount = (Math.round(tax_amount * 100) / 100).toFixed(2);
                    setFortax(tax_amount);
                    //setTaxamout(taxamount);
                    //final_amount = final_amount + taxamount;
                }


                setPrice(final_amount);
                setCoupons(1);
                setDiscount(document.getElementById('coupon_text').value);

                // toast.success(data.msg);
            }

            if (data.success == false) {
                localStorage.removeItem("coupon_val")
                //   toast.error(data.msg);
            }
        }
    }
    async function getTax(){

        const tax_country = localStorage.getItem('billing_country') ?? "";
        if(tax_country) {
            var formData = new FormData();

            var requestdata = {
                country: tax_country,
            };
            formData.append("request", JSON.stringify(requestdata));
            var requestOptions = {
                method: "POST",
                body: formData
            };

            var response = await fetch(process.env.REACT_APP_API_URL + "/api/gettax", requestOptions);
            var data = await response.json();
            if(data.success == true) {
                setJesdata(data.data.tax_rate);
            }
            if(data.success == false){
                setJesdata("");
                setCartTax(0);
            }
        }
    }
    var existing_cart = localStorage.getItem('productsbag') == null ? [] : JSON.parse(atob(localStorage.getItem('productsbag')));
  function check_calculation() {

      var total_price = 0;
      var total_price_withtax = 0;
      var sub_total_price = 0;
      var total_tax = 0;
      var tax_amount = 0;

        console.log(existing_cart);

      for (var i = 0; i < existing_cart.length; i++) {
          var ringsizeprice = 0;
          if (existing_cart[i]['product_type'] == "compound") {

                  sub_total_price += ((existing_cart[i]['ring']['sell_price_val']) + (existing_cart[i]['diamond']['total_price_val']));
              total_price_withtax += ((existing_cart[i]['ring']['sell_price_val']) + (existing_cart[i]['diamond']['total_price_val']));
              total_tax += ((existing_cart[i]['ring']['tax_amount']) + (existing_cart[i]['diamond']['tax_amount']))


          } else if (existing_cart[i]['product_type'] == "simple") {

              sub_total_price += (existing_cart[i]['product_data']['sell_price_val']);
              total_price_withtax += (existing_cart[i]['product_data']['sell_price_val']);
              total_tax += (existing_cart[i]['product_data']['tax_amount']);


          }

      }


      if (jesdada) {
          var taxval = jesdada / 100;

          setCartTax(total_tax);
      }

      setCartSubTotal(sub_total_price);
      if (jesdada) {
          total_price +=  (parseFloat(sub_total_price) + parseFloat(tax_amount) - parseFloat(couponsDiscount));
      }else {
          total_price += (parseFloat(total_price_withtax) - parseFloat(couponsDiscount));
      }
      console.log(sub_total_price);
      console.log(tax_amount);
      //console.log(couponsDiscount);
      setCartTotal(total_price);
    //  console.log("calculation_done");

  }
  
    useEffect(()=>{
        getTax();

        if(coupon_local){
      //      console.log("Coupon start");
            checkCoupon()
        //    console.log("Coupon end ");
        }
        check_calculation();


    },[coupon_local,existing_cart,cartSubTotal,cartTotal,cartTax])

    
  return (
    <>
        <h3 className='fw-normal fs-4 mb-4 text-dark'>CART TOTALS</h3>
        <div className="card shadow cart-summary">
          <div className="card-body">

            <List className="p-0">
              <li className="d-flex justify-content-between align-items-start mb-2">
                <div className="me-auto">
                  Subtotal
                </div>
                <span className="">{currency} {(cartSubTotal>0 ? cartSubTotal: 0).toFixed(2)}

                </span>
              </li>


                {jesdada && jesdada > 0 ? (
                    <>
                        <li className={'d-none'}><hr /></li>
                    <li className="d-none justify-content-between align-items-start mb-2">
                        <div className="me-auto">
                            Tax {jesdada ? jesdada+"%" : ""}
                        </div>
                        <span className="">{currency} {fortax > 0 ? fortax : cartTax}
                            {/*{new Intl.NumberFormat('en-us', {}).format(fortax>0?fortax:tax_amount)}*/}

                </span>
                    </li>
                        </>
                ):""}

          {/*    <li className="d-flex justify-content-between align-items-start mb-2">
                <div className="me-auto">
                    Free Shipping
                </div>
                <span className="">Free</span>
              </li>*/}

                {coupons == 1 ? (
                    <>
                        <li ><hr /></li>

                        <li className='d-flex justify-content-between align-items-start mb-2'>

                            <div className="me-auto">
                                Discount
                            </div>
                            <span className="">- {currency} {couponsDiscount} </span>
                        </li>

                        <li className='my-4'>

                            <div className="d-flex">
                      <span className="coupon-pill">
                        {discount}
                          <input className="form-control rounded-0 me-2" type="hidden" name="coupon_text" defaultValue={coupon_local} id="coupon_text" />
                          <span className="btn-close invert-100 ms-3" onClick={cancelCoupon}>

                        </span>
                      </span>

                            </div>
                        </li>
                        <li className='d-flex alert alert-primary py-1'>
                            {CouponsDiscountText}
                        </li>
                        <li className='my-4'><hr /></li>
                    </>
                ): (
                    <>
                        <li className='my-4' id="coupon_container">

                            <Accordion expanded={expanded === 'promoCode'}
                                       onChange={handleChange('promoCode')}>
                                <AccordionSummary aria-controls="promoCoded-content" id="promoCoded-header">
                                    <Typography>Coupon Code</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {/* <small className='text-muted d-block mb-2'>Entering a new promo code will <b>replace</b> the current <b>25% Off sale discount</b>.</small>
*/}
                                    <div className="d-flex">
                                        <input className="form-control rounded-0 me-2" type="text" name="coupon_text" defaultValue={coupon_local} id="coupon_text" />
                                        <button className="btn btn-primary rounded-0" onClick={applyCoupon}>Apply</button>
                                    </div>

                                </AccordionDetails>
                            </Accordion>

                        </li>
                    </>
                )}





              <li className="d-flex justify-content-between align-items-start mb-3">
                <div className="me-auto">
                  <div className="fw-normal fs-5 text-dark">Total</div>
                </div>
                <span className="fw-bold fs-5 text-primary">{currency} {cartTotal.toFixed(2)}
                </span>
              </li>


                {props.checkoutbtn ? (
                    <>
                        <li className="d-flex flex-column">

                             <button type='button'className="btn btn-primary rounded-0 flex-fill mb-2" onClick={processCheckout}>Checkout</button>
                            {/*<Link className="btn btn-primary rounded-0 flex-fill mb-2" to={'/checkout'} }>PROCEED TO CHECKOUT</Link>*/}
                            {/* <span className='d-block text-center'>OR</span>
                <button className="btn btn-default flex-fill fw-bold mt-2">PayPal</button> */}
                        </li>
                    </>
                ):'' }

              
             
            </List>
            
          </div>
        </div>
    </>
  )
}

export default CartSummary

import React, {useState, useEffect} from "react"
import { Link, useParams, useSearchParams, useNavigate,Navigate,Route,useLocation } from 'react-router-dom'
function OrderReceived(props) {
    const {id} = useParams('id');
    
    const [orderId, setOrderId] = useState();
    const [orderHtml, setOrderHtml] = useState();
    const [stripHtml, setStripHtml] = useState('<div className="fa-3x"><i className="fas fa-circle-notch fa-spin"></i> <span>Processing</span></div>');

    let navigate = useNavigate()
    var customer_id = localStorage.getItem("customer_id");

    const [checkoutOrder, setCheckoutOrder] = useState(false);

    if(customer_id==null){
        navigate('/login');
    }

    const [searchParams, setSearchParams] = useSearchParams();

    const paymentIntent = searchParams.get("payment_intent");
 

    async function paymentIntentFn(){

            const stripe = require("stripe")(process.env.REACT_APP_Strip_secret_key);


        try {
            // Retrieve a payment intent by its ID
            const paymentIntentFetch = await stripe.paymentIntents.retrieve(paymentIntent);
            if (paymentIntentFetch.status === "succeeded") {
                setCheckoutOrder(true);
                if (checkoutOrder === true) {
                    checkout();
                }
            }
        } catch (e) {
            // Handle Stripe-specific errors
            if (e.code === 'authentication_required') {
                console.log("====authentication_required");
                setStripHtml(`<div class="fs-3 text-bg-danger py-3">Payment Error:<br/> ${e.code} - ${e.message}</div>`);
            } else if (e.code === 'payment_intent_unexpected_state') {
                console.log("====payment_intent_unexpected_state");
                setStripHtml(`<div class="fs-3 text-bg-danger py-3">Payment Error:<br/> ${e.code} - ${e.message}</div>`);
            } else {
                // Handle other specific error codes or a generic Stripe error
                console.log(`Stripe Error: ${e.code} - ${e.message}`);
                setStripHtml(`<div class="fs-3 text-bg-danger py-3">Payment Error:<br/> ${e.code} - ${e.message}</div>`);
            }
        }


    }

    async function checkout() {



            const price_data_send = localStorage.getItem("payment_data");

            if(price_data_send) {
                var formData = new FormData();
                formData.append("request", JSON.stringify({
                    "checkout": JSON.parse(price_data_send),
                    "paymentIntent": paymentIntent
                }));
                var requestOptions = {
                    method: "POST",
                    body: formData
                };

                var resp = await fetch(process.env.REACT_APP_API_URL + "/payment/checkout", requestOptions);

                var data = await resp.json();

                if (data.success == true) {
                    var order_id = btoa(data.data);
                    window.location.href = "/order-received/" + order_id;
                }
            } else{
                window.location.href = "/" ;
            }

    }
   async function get_order_data(){

       localStorage.removeItem("indexvalue");
       localStorage.removeItem("availableCartProductIds");
       localStorage.removeItem("productsbag");
       localStorage.removeItem("coupon_val");
       localStorage.removeItem("billing_country");
       localStorage.removeItem("payment_data");

       
        if(id) {
            const my_order_id = atob(id);
            setOrderId(my_order_id);

            var invoice_fetch = new FormData();
            invoice_fetch.append("request", JSON.stringify({
                order_id: my_order_id
            }));

            var invoice_fetch_res = await fetch(process.env.REACT_APP_API_URL + "/api/order/invoice", {
                method: "POST",
                body: invoice_fetch
            });
            var invoice_fetch_data = await invoice_fetch_res.json();

            if(invoice_fetch_data.data) {

                setOrderHtml(invoice_fetch_data.data)
            }
        }
    }

    useEffect(() => {
       if (paymentIntent) {
            paymentIntentFn();
        } else{
            get_order_data();
        }

    }, [checkoutOrder]);
    return (
        <>
            {customer_id ==null ? "": (
                <>
                    {paymentIntent ? (
                        <>
                            <div className="align-content-center d-flex  flex-column justify-content-center position-relative text-center w-100" style={{height:'500px'}}>
                                <div
                                    dangerouslySetInnerHTML={{__html: stripHtml}}
                                />
                                
                            </div>

                        </>
                    ):(
                        <section className='py-3'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-12 text-center text-success'>
                                        <div
                                            dangerouslySetInnerHTML={{__html: orderHtml}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>

                    )}

        </>
                )}
        </>
    )
}

export default OrderReceived
import React, {useRef, useState, useEffect} from "react";
import axios from "axios";

import {Link, useParams, useSearchParams, useNavigate, Navigate, Route, useLocation} from "react-router-dom";

import {FaThList, FaTh, FaSearchPlus, FaRegHeart, FaHeart, FaStar, FaTimes} from "react-icons/fa";
import {Card, CardActions, CardContent, Tab, Tabs, Typography, Box} from "@mui/material";

import ProductItem from "../parts/product_grid";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Btooltip from "react-bootstrap/Tooltip";

 
import {PropTypes} from "prop-types";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./Prodslide.css";
import {combineReducers} from "redux";
import magnificPopup from "magnific-popup";
import jQuery from "jquery";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
    FacebookShareButton,
    WhatsappShareButton,
    EmailShareButton,
} from "react-share";

import FilterSteps from "../parts/filter-steps";
// import required modules
import {FreeMode, Navigation, Thumbs} from "swiper";
import Settings from "./RingSettings";
import GoToTop from "../parts/GoToTop";

///import {Redirect} from 'react-router-dom';


import {ToastContainer, toast} from "react-toastify";
function magnify_pop(event) {
    event.preventDefault();
    const magnify_pop_src = event.target.dataset.url;
    console.log(magnify_pop_src);

    jQuery.magnificPopup.open({

        items: {
            src: magnify_pop_src
        },
        type: "image"

    });

}

function magnify_360(event) {
    event.preventDefault();

    const magnify_360_src = event.target.dataset.url;
    console.log(magnify_360_src);
    jQuery.magnificPopup.open({
        items: {
            src: magnify_360_src
        },
        type: "iframe"
    });
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}


let modalDesign = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    //width: 60 + '%',
    width: "100%",
    //bgcolor: 'background.paper',
    //border: '2px solid #000',
    //boxShadow: 24,
    borderRadius: 1.5,
    //m:1,
    //pt: 2,
    //px: 4,
    //px: '.2rem',
    //pb: 3,
};
let modalInner = {
    backgroundColor: "#ffffff",
};
let View3d = {
    height: "465px"
};

const ButtonMailto = ({mailto, label}) => {
    return (
        <Link
            to="#"
            onClick={(e) => {
                window.location.href = mailto;
                e.preventDefault();
            }}
        >
            {label}
        </Link>
    );
};


function RFRModal(props) {
    return (
        <Modal
            {...props}
            className="RFRModal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <button type="button" onClick={props.onHide} className="close">
                <svg viewBox="0 0 1792 1792">
                    <path
                        d="M1490 1322q0 40-28 68l-136 136q-28 28-68 28t-68-28l-294-294-294 294q-28 28-68 28t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294 294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68l-294 294 294 294q28 28 28 68z"></path>
                </svg>
            </button>
            <Modal.Body>
                <div>
                    <h2>The BAEMONT Guarantee</h2>
                    <div className="para-modal">
                        <h3>20/7 Customer Service</h3>
                        <p>We strive to provide the best possible response to our customers as our team of industry
                            experts stand by to serve with care.</p>
                    </div>

                    <div className="para-modal">
                        <h3>Product Lifetime Warranty</h3>
                        <p> All of our products are manufactured with the highest of quality and is carefully inspected
                            prior to shipment. We stand behind our products and warrant that all items will be free from
                            manufacturing defects at the time of delivery. </p>
                    </div>

                    <div className="para-modal">
                        <h3>Price-match Guarantee</h3>
                        <p> Our diamond prices are very competitive in our markets and we will offer to match the price
                            for a similar or identical piece of diamond sold elsewhere with lower pricing. </p>
                    </div>

                    <a href="/contact-us/"><em>Learn More...</em></a>
                </div>
            </Modal.Body>

        </Modal>
    );
}


function ProdDiamond(props) {

    const [modalShowRFT, setModalShowRFT] = React.useState(false);

    const {id} = useParams();

    const shareUrl = window.location.href;
    let [responsedata, setResponsedata] = useState([]);
    let [ProductData, setProductData] = useState([]);
    var formData = new FormData();
    let [thumbsSwiper, setThumbsSwiper] = useState();
    const [ringsize, setRingsize] = React.useState("");


    const handleChange = (event) => {
        setRingsize(event.target.value);
    };
    let [rating, setRating] = useState(null);
    let [hover, setHover] = useState(null);
    let [isActive, setActive] = useState("false");


    const user_login_check = JSON.parse(localStorage.getItem("customer_id")) ?? "";

    let [isWishlist, setWishlist] = useState(false);

    const wishListAdd = async (item) => {
        console.log("Added " + item);
        props.loader(true)
        var formData = new FormData();

        var requestData = {
            user_id: user_login_check,
            product_id: item,
            product_type: 'diamond',
            "action": "add"
        };
        formData.append("request", JSON.stringify(requestData));
        var requestOptions = {
            method: "POST",
            body: formData
        };
        var resp = await fetch(process.env.REACT_APP_API_URL + "/api/wishlist", requestOptions);

        var data = await resp.json();


        if (data.success == true) {
            toast.info(data.data.msg); 
            props.loader(false)
        }
        if (user_login_check) {
            //console.log(user_login_check);
            get_wishlist(user_login_check);

        }
    };

    const wishListRemove = async (item) => {
        console.log("Remove " + item);
        props.loader(true)
        var formData = new FormData();

        var requestData = {
            user_id: user_login_check,
            product_id: item,
            "action": "remove"
        };
        formData.append("request", JSON.stringify(requestData));
        var requestOptions = {
            method: "POST",
            body: formData
        };
        var resp = await fetch(process.env.REACT_APP_API_URL + "/api/wishlist", requestOptions);


        var data = await resp.json();
        if(data.success == true) {
            props.loader(false)
            toast.info(data.data.msg); 
        }
        if (user_login_check) {
            //console.log(user_login_check);
            get_wishlist(user_login_check);

        }
    };

    async function get_wishlist() {
        props.loader(true)
        var formData = new FormData();

        var requestData = {
            "user_id": user_login_check,
            "product_id": id,
            "action": "check"
        };
        formData.append("request", JSON.stringify(requestData));
        var requestOptions = {
            method: "POST",
            body: formData
        };
        var resp = await fetch(process.env.REACT_APP_API_URL + "/api/wishlist", requestOptions);

        var data = await resp.json();

        if (data.success == true) {
            setWishlist("fill");
        } else {
            setWishlist("unfill");
        }
        props.loader(false)
    }

    const renderWishlistTooltip = (props) => (
        <Btooltip id="button-tooltip" {...props}>
            Please login for wishlisting
        </Btooltip>
    );
    let wishToggle = () => {
        setActive(!isActive);
    };
    let [tabtext2, setTabtext2] = useState([]);
    let [tabcss2, setTabcss2] = useState([]);


    let [tabtext1, setTabtext1] = useState([]);
    let [tabcss1, setTabcss1] = useState([]);
    const [diamondSelected, setDiamondSelected] = useState([]);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [relatedProductsPara, setRelatedProductsPara] = useState();


    //Tabs
    const [value, setValue] = React.useState(0);

    const onTabChange = (event, newValue) => {
        setValue(newValue);
    };
    // end Tabs


    //Start Modal
    const [open, setModalstate] = React.useState(false);
    const modalOpen = () => {
        setModalstate(true);
    };
    const modalClose = () => {
        setModalstate(false);
    };

    async function getDiamondData() {
        props.loader(true)
        var diamondId = localStorage.getItem("diamondId");

        if (diamondId !== null) {

            var formData = new FormData();
            var requestdata = {
                id: diamondId,
            };
            formData.append("request", JSON.stringify(requestdata));
            var requestOptions = {
                method: "POST",
                body: formData
            };
            var resp = await fetch(process.env.REACT_APP_API_URL+"/api/product_details", requestOptions);

            var data = await resp.json();
            setDiamondSelected(data.data);
            props.loader(false)

        }
    }


    const removeDiamondData = (e) => {

        localStorage.removeItem("diamondId");
    };
    let navigate = useNavigate();
    const selectDiamond = (value) => {

        var existing_cart = localStorage.getItem('productsbag') == null ? [] : JSON.parse(atob(localStorage.getItem('productsbag')));

        var select_diamond = false
        if(existing_cart) {
            for (let i = 0; i < existing_cart.length; i++) {
                if (existing_cart[i].diamondId === value) {
                    select_diamond = true;
                }
            }
        }
       if(select_diamond == true){
           toast.error("Diamond is already in Cart.");
       }  else{
           localStorage.setItem("diamondId", value);

           var ringdata = localStorage.getItem("ringId");

           if (ringdata !== null) {
               navigate("/product-details");
           } else {
               navigate("/settings-ring/all");
           }
       }

        
    };



    const [ringSelected, setRingSelected] = useState([]);

    async function getRingData() {
        props.loader(true)
        var ringId = localStorage.getItem("ringId");

        if (ringId !== null) {
            var formData = new FormData();
            var requestdata = {
                id: ringId,
            };

            formData.append("json1", JSON.stringify(requestdata));

            var requestOptions = {
                method: "POST",
                body: formData
            };
            var resp = await fetch("http://188.166.226.236/api/server/ring_details", requestOptions);
            var data = await resp.json();
            setRingSelected(data.data);
            props.loader(false)
        }

    }

    const removeRingData = (e) => {

        localStorage.removeItem("diamondId");
    };

    const selectRing = (value) => {

        var ringdata = localStorage.getItem("ringId");

        if (ringdata != null) {
            navigate("/product-details");
        } else {
            navigate("/settings-ring/all");
        }

    };

    async function postRecentview() {
        var customer_id =  localStorage.getItem("customer_id");
        let uuid;
        if(customer_id){
            uuid = customer_id;
        } else{
            uuid = "-1";
        }

        var requestdata = {
            product_id: id,
            uuid: uuid,
            type: 'diamond',
            action:'add'
        };
        formData.append("request", JSON.stringify(requestdata));
        var requestOptions = {
            method: "POST",
            body: formData
        };

        var resp = await fetch(process.env.REACT_APP_API_URL+"/api/recent_view", requestOptions);

        var data = await resp.json();


    }
 async function getproductdetails() {
     props.loader(true)
            var requestdata = {
                id: id,
                type: 'compare',
                currency: localStorage.getItem('currency'),
            };
            formData.append("request", JSON.stringify(requestdata));
            var requestOptions = {
                method: "POST",
                body: formData
            };


            var resp = await fetch(process.env.REACT_APP_API_URL + "/api/product_details", requestOptions);
            var data = await resp.json();
              

               setResponsedata(data.data);
               const productData = {
                   productName: (data.data.shape + " " + data.data.carat + "ct " + " " + data.data.color + " Color  Diamond"),
                   productMeta: data.data.clarity + " Clarity | " + data.data.cut + " Cut",
                   stone_id: data.data.stone_id,
                   productSku: data.data.sku,
                   productTotal: data.data.total_price,
                   productStock: data.data.instock,
               };

               setProductData(productData);
               


         var requestdata = {
             product_id: id,
             "shape": data.data.shape,
             "cut": data.data.cut,
             "color": data.data.color,
             action: 'diamond_related'
         };
         formData.append("request", JSON.stringify(requestdata));
         var requestOptions = {
             method: "POST",
             body: formData
         };
                                
         var resp_rp = await fetch(process.env.REACT_APP_API_URL + "/api/product_related", requestOptions);

         var data_rp = await resp_rp.json();
         console.log(data_rp);
         setRelatedProducts(data_rp.data);
         //console.log(responsedata);
         //console.log(data_rp);
     props.loader(false)
     if(data.success ==  false) {
         
               navigate("/settings-diamond/all");
           }
    }
    


    const [vision_Show, setVisionShow] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const visionClose = function () {
        setVisionShow(false);       
        setLoaded(false);
    };
    const visionShow = () => setVisionShow(true);

    useEffect(() => {
        getproductdetails();
        postRecentview();

    },[]);
   /* useEffect(() => {
        getDiamondData();
        getRingData()
        getproductdetails();
        postRecentview();
        var ringdata = localStorage.getItem("ringId");
        if (ringdata != null) {

            setTabtext1("SETTING");
            setTabtext2("DIAMOND");
            setTabcss1("active--bcso0");
            setTabcss2("");
        } else {
            setTabtext2("SETTING");
            setTabtext1("DIAMOND");
            setTabcss1("");
            setTabcss2("active--bcso0");
        }
        // getproductdetails();
        // console.log(responsedata)
    }, [responsedata, ProductData, diamondSelected, ringSelected]);
    get_related_pro();*/
    return (
        <>

            <section className="filter-sec">
                <div className="container">

                    <div className="row">
                        <div className="col-12">

                            <FilterSteps active={1}/>


                        </div>
                    </div>
                </div>
                <div className="productdetail-inner">
                    <div className="container  py-5">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="gallery-inner position-relative">

                                    <Swiper 
                                        spaceBetween={10}
                                        navigation={true}
                                        thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}}
                                        modules={[FreeMode, Navigation, Thumbs]}
                                        className="mySwiper2"
                                    >
                                        {responsedata.front_image_url ? (
                                            <SwiperSlide>

                                                <img src={responsedata.front_image_url} alt=""/>
                                            </SwiperSlide>
                                        ) : (
                                            <SwiperSlide>

                                                <img src="/assets/img/static/product/baemont.png" alt=""/>
                                            </SwiperSlide>
                                        )}
                                        {responsedata.lab_pdf ? (
                                            <SwiperSlide>

                                                <img src={responsedata.lab_pdf} alt=""/>
                                            </SwiperSlide>
                                        ) : ""}

                                        {/* <SwiperSlide>
                                    <video>
                                        <source src={responsedata.video_url} type="video/mp4"/>
                                    </video>
                                </SwiperSlide>    */}
                                    </Swiper>

                                    <div className="product-additional-galleries">
                                        {responsedata.media_video ? (
                                            <button className="product-360-button wd-gallery-btn"
                                                    onClick={visionShow}><span>360 product view</span>
                                            </button>
                                        ) : ""}

                                        <button className="product-enlarge-button wd-gallery-btn"
                                                data-url={responsedata.front_image_url ? (responsedata.front_image_url) : ("/assets/img/static/product/baemont.png")}
                                                onClick={magnify_pop}><span>Click to enlarge</span></button>

                                    </div>
                                </div>
                                <Modal show={vision_Show} onHide={visionClose} centered>
                                    <button type="button" onClick={visionClose}
                                            className="btn-close btn-close-white end-0 opacity-100 p-4 position-fixed top-0"
                                            aria-label="Close"></button>
                                    <Modal.Body className={"text-center"}>
                                        <iframe width="400" height="400"
                                                scrolling="no"
                                                src={loaded ? "javascript:;" : responsedata.media_video}
                                                onLoad={() => {
                                                    setLoaded(true);
                                                }}
                                        ></iframe>
                                    </Modal.Body>

                                </Modal>
                                <Swiper
                                    onSwiper={setThumbsSwiper}
                                    spaceBetween={10}
                                    slidesPerView={4}
                                    freeMode={true}
                                    watchSlidesProgress={true}
                                    modules={[FreeMode, Navigation, Thumbs]}
                                    className="SwiperThumb mt-3"
                                >
                                    <SwiperSlide>
                                        {responsedata.front_image_url ? (


                                            <img src={responsedata.front_image_url} alt=""/>

                                        ) : (


                                            <img src="/assets/img/static/product/baemont.png" alt=""/>

                                        )}

                                    </SwiperSlide>
                                    {responsedata.lab_pdf ? (
                                        <SwiperSlide>
                                            <img src={responsedata.lab_pdf} alt=""/>
                                        </SwiperSlide>
                                    ) : ""}
                                    {/*
                                    <SwiperSlide>
                                        <video>
                                            <source src={responsedata.video_url} type="video/mp4"/>
                                        </video>
                                    </SwiperSlide>*/}
                                </Swiper>
                            </div>
                            <div className="col-md-6 prod-dtlCont">

                                <h4 className="fw-normal diamond-heading">{ProductData.productName} </h4>

                               
                                <div className="diamond-subtitle">
                                    {ProductData.productMeta}
                                </div>
                                <hr/>
                                <div className="product_meta d-block">
                                    <div>SKU: {ProductData.productSku}</div>

                                    <Button variant="default"
                                            className="riskfreeretail border-0 bg-transparent px-0 small"
                                            onClick={() => setModalShowRFT(true)}>
                                        Risk-free Retail
                                    </Button>

                                    <RFRModal
                                        show={modalShowRFT}
                                        onHide={() => setModalShowRFT(false)}
                                    />
 

                                    <div className="pricePack">
                                        <div className="total">
                                            {/*<span className="old-price">RM {responsedata.price_pc}</span>*/}
                                            <span
                                                className="now-price">{ProductData.productTotal}</span>
                                        </div>

                                    </div>
                                </div>

                                {ProductData.productStock == "1" ?
                                    (<p className="stock in-stock">{ProductData.productStock} in stock</p>)
                                    : ''}


                                <div className="d-flex">
                                    {ProductData.productStock == "1" ?(
                                    <button style={{fontSize: 13}}
                                            className="btn rounded-0 btn-default text-uppercase px-3 text-uppercase me-2"
                                            value={responsedata.stone_id} onClick={e => selectDiamond(e.target.value)}>SELECT
                                        THIS DIAMOND
                                    </button>
                                        ) : ( <button style={{fontSize: 13}}
                                                      className="btn rounded-0 btn-default text-uppercase px-3 text-uppercase me-2" disabled>Out of stock                                     </button>)}


                                    {user_login_check ? (
                                        <>
                                            {(isWishlist == "fill" ? (
                                                <button className="rounded-0 btn btn-default btn-addWishlist"
                                                        onClick={(e) => {
                                                            wishListRemove(ProductData.stone_id);
                                                        }}>
                                                    <FaHeart/>
                                                </button>

                                            ) : (
                                                <button className="rounded-0 btn btn-outline-default btn-addWishlist"
                                                        onClick={(e) => {
                                                            wishListAdd(ProductData.stone_id);
                                                        }}>
                                                    <FaRegHeart/>

                                                </button>
                                            ))}
                                        </>


                                    ) : (
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{show: 250, hide: 400}}
                                            overlay={renderWishlistTooltip}
                                        >
                                            <button className="rounded-0 btn btn-outline-default btn-addWishlist">
                                                <FaRegHeart/>
                                            </button>
                                        </OverlayTrigger>


                                    )}

                                 {/*
                                    <button className="rounded-0 btn btn-outline-default btn-addWishlist"
                                            onClick={wishToggle}>
                                        {isActive ?
                                            <FaRegHeart/>
                                            :
                                            <FaHeart/>
                                        }
                                    </button>*/}
                                </div>
                                <div className="d-flex product-share">
                                    <div className="product-share-label">Share:</div>
                                    <FacebookShareButton url={shareUrl} size={32}><i
                                        className="fa-brands fa-facebook"></i></FacebookShareButton>
                                    <WhatsappShareButton url={shareUrl} size={32}><i
                                        className="fa-brands fa-whatsapp"></i></WhatsappShareButton>
                                </div>
                                {/* <div className="d-block mt-3">
                        <Link to='/product-details'>Go to review</Link>
                    </div> */}

                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className="row mt-4">
                <div className="col-12">
                    <h5>Product Description</h5>
                    <span className="sku text-muted mb-3 d-block">SKU {responsedata.sku_code}</span>
                     <p>Classic and refined, this piece offers understated elegance and unsurpassed comfort while highlighting the center diamond or gemstone you select. Designed with smaller carat weight diamonds in mind, it is sophisticated and petite without sacrificing stability.</p>
                </div>
            </div>
*/}

                <div className="container py-5">
                    <div className="row ">
                        <div className="col-12">
                           

                            <div className="row mb-4">
                                <div className="col-md-12 text-center">
                                    <span className="d-block mb-2 table-heading">Specifications</span>
                                </div>
                                <div className="col-md-6 table-body">

                                    <ul className="dtlInfo">
                                        <li>
                                            <div className="cell">Shape</div>
                                            <div className="cell">{responsedata.shape}</div>
                                        </li>
                                        <li>
                                            <div className="cell">Carat</div>
                                            <div className="cell">{responsedata.carat}</div>
                                        </li>
                                        <li>
                                            <div className="cell">Clarity</div>
                                            <div className="cell">{responsedata.clarity}</div>
                                        </li>
                                        <li>
                                            <div className="cell">Colour</div>
                                            <div className="cell">{responsedata.color}</div>
                                        </li>
                                        <li>
                                            <div className="cell">Cut</div>
                                            <div className="cell">{responsedata.cut}</div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6 table-body">
                                    <ul className="dtlInfo">
                                        <li>
                                            <div className="cell">Depth (%)</div>
                                            <div className="cell">{responsedata.parameter_depth}</div>
                                        </li>
                                        <li>
                                            <div className="cell">Table (%)</div>
                                            <div className="cell">{responsedata.parameter_table}</div>
                                        </li>
                                        <li>
                                            <div className="cell">Fluorescence</div>
                                            <div className="cell">{responsedata.fluorescence}</div>
                                        </li>
                                        <li>
                                            <div className="cell">Polish</div>
                                            <div className="cell">{responsedata.polish}</div>
                                        </li>
                                        <li>
                                            <div className="cell">Symmetry</div>
                                            <div className="cell">{responsedata.symmetry}</div>
                                        </li>
                                    </ul>
                                </div>

                            </div>

                            <div className="row mb-4">
                                <div className="col-md-12 text-center">
                                    <span className="d-block mb-2 table-heading">Parameters</span>
                                </div>
                                <div className="col-md-6 table-body">

                                    <ul className="dtlInfo">
                                        <li>
                                            <div className="cell">Measurement</div>
                                            <div className="cell">L: {responsedata.measurement_length} x
                                                W: {responsedata.measurement_width} x H: {responsedata.measurement_height}</div>
                                        </li>
                                        <li>
                                            <div className="cell">Depth(%)</div>
                                            <div className="cell">{responsedata.parameter_depth}%</div>
                                        </li>
                                        <li>
                                            <div className="cell">Table(%)</div>
                                            <div className="cell">{responsedata.parameter_table}%</div>
                                        </li>
                                        <li>
                                            <div className="cell">Ratio</div>
                                            <div className="cell">{responsedata.parameter_ratio}</div>
                                        </li>

                                        <li>
                                            <div className="cell">Girdle</div>
                                            <div className="cell">{responsedata.girdle_percentage}</div>
                                        </li>

                                    </ul>

                                </div>
                                <div className="col-md-6 table-body">
                                    <ul className="dtlInfo">
                                        <li>
                                            <div className="cell">S Length</div>
                                            <div className="cell">{responsedata.parameter_s_length}</div>
                                        </li>
                                        <li>
                                            <div className="cell">Pavilion Angle</div>
                                            <div className="cell">{responsedata.parameter_pavilion_angle}°</div>
                                        </li>
                                        <li>
                                            <div className="cell">Pavilion Height</div>
                                            <div className="cell">{responsedata.parameter_pavilion_depth}°</div>
                                        </li>
                                        <li>
                                            <div className="cell">Crown Angle</div>
                                            <div className="cell">{responsedata.parameter_crown_angle}°</div>
                                        </li>
                                        <li>
                                            <div className="cell">Crown Height</div>
                                            <div className="cell">{responsedata.parameter_crown_depth}°</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <Card sx={{borderRadius: 0}}>
                                <Typography sx={{
                                    fontFamily: "\"Lato\", \"Helvetica Neue\", Arial, Helvetica, sans-serif",
                                    fontSize: 15,
                                    fontWeight: "600",
                                    textTransform: "uppercase",
                                    backgroundColor: "#8a2846",
                                    color: "#fff",
                                    px: 2.5,
                                    py: 2
                                }} color="text.secondary" gutterBottom>
                                    Our Assurance
                                </Typography>
                                <CardContent>
                                    <div className="row g-4 assurance-body">
                                        <div className="col-md-6">
                                            <h5>GIA Certified Diamonds</h5>
                                            <Typography sx={{fontSize: 14, color: "#393939"}}>
                                                All BAEMONT diamonds are graded and certified by GIA (Gemological
                                                Institute of America). GIA is a world-renowned institute that first
                                                established the standards of grading diamonds using the 4Cs.
                                            </Typography>
                                        </div>
                                        <div className="col-md-6">
                                            <h5>Price Matching (Diamonds Only)</h5>
                                            <Typography sx={{fontSize: 14, color: "#393939"}}>
                                                We believe that our diamond prices are very competitive in our markets.
                                                If found otherwise, we are happy to review and offer to match the price.
                                                Please reach out to our team at <ButtonMailto label="hello@baemont.com"
                                                                                              mailto="mailto:hello@baemont.com"/>.
                                            </Typography>
                                        </div>
                                        <div className="col-md-6">
                                            <h5>Quality and Craftmanship</h5>
                                            <Typography sx={{fontSize: 14, color: "#393939"}}>
                                                We strive to maintain a high standard of our design and craftmanship
                                                including sourcing of quality natural diamonds to the finest crafters
                                                and designers.
                                            </Typography>
                                        </div>
                                        <div className="col-md-6">
                                            <h5>Ethical Sourcing</h5>
                                            <Typography sx={{fontSize: 14, color: "#393939"}}>
                                                In our line of business, ethics are most highly regarded. We are
                                                committed in ensuring that our diamonds are sourced responsibly and are
                                                100% conflict-free.
                                            </Typography>
                                        </div>
                                        <div className="col-md-6">
                                            <h5>Detailed High-Definition Viewing</h5>
                                            <Typography sx={{fontSize: 14, color: "#393939"}}>
                                                We use cutting edge technology to provide a different experience of
                                                viewing diamonds up-close in 360 degree and displaying its clarity on
                                                your screen.
                                            </Typography>
                                        </div>
                                    </div>
                                </CardContent>
                                <CardActions>
                                    {/* <Button size="small">Learn More</Button> */}
                                </CardActions>
                            </Card>
                        </div>
                        <div className="col-12">
                            <Card sx={{borderRadius: 0}}>
                                <Typography sx={{
                                    fontFamily: "\"Lato\", \"Helvetica Neue\", Arial, Helvetica, sans-serif",
                                    fontSize: 15,
                                    fontWeight: "600",
                                    textTransform: "uppercase",
                                    backgroundColor: "#8a2846",
                                    color: "#fff",
                                    px: 2.5,
                                    py: 2
                                }} color="text.secondary" gutterBottom>
                                    What’s Included In Your Order?
                                </Typography>
                                <CardContent>
                                    <div className="row assurance-body">
                                        <div className="col-md-4">
                                            <h5>BAEMONT Exclusive Gift Packaging </h5>
                                        </div>
                                        <div className="col-md-4">
                                            <h5>Diamond Certificate of Authenticity</h5>
                                        </div>
                                        <div className="col-md-4">
                                            <h5>Product Lifetime Warranty </h5>
                                        </div>
                                        <div className="col-md-4">
                                            <h5>Lifetime Upgrade </h5>
                                        </div>
                                        <div className="col-md-4">
                                            <h5>Free Engraving </h5>
                                        </div>
                                        <div className="col-md-4">
                                            <h5> Free Shipping (Local Orders)</h5>
                                        </div>
                                    </div>
                                </CardContent>
                                <CardActions>
                                    {/* <Button size="small">Learn More</Button> */}
                                </CardActions>
                            </Card>
                        </div>
                    </div>
                    <div className="offer-banner d-none">
                        <div className="row">
                            <div className="col-md-8 offer-text">
                                <h4>Your Order</h4>
                                <h1>Includes</h1>
                                <div className="d-flex offer-points">
                                    <ul>
                                        <li>BAEMONT Exclusive Gift Packaging</li>
                                        <li>Diamond Certificate of Authenticity</li>
                                        <li>Product Lifetime Warranty</li>
                                    </ul>
                                    <ul className="mx-5">
                                        <li>Lifetime Upgrade</li>
                                        <li>Free Engraving</li>
                                        <li>Free Shipping (Local Orders)</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4 offer-img">
                                <img className="img-fluid" src="/assets/img/ring_engagement.png" alt=""/>
                            </div>
                        </div>
                    </div>



                </div>


                {(relatedProducts && relatedProducts.length > 0) ? (
                             <>
                    <div className="container border-top border-light py-5 related-products">
                    <div className="row">
                    <div className="col-12">
                    <h3 className="heading_line">Related Products</h3>
                    </div>

                    <div className="col-12 mt-5">
                    <div className="row">


                {relatedProducts.map((jd,index) => (


                    <div className="col-12 col-sm-6 col-md-3 position-relative" key={index} >
                    <ProductItem buttons={true} loader={props.loader} type="compare" product_id={jd.id}/>
                    </div>
                   


                    ))}


                    </div>
                    </div>
                    </div>
                    </div>
                    </>

                    ): ""}

            </section>
            <GoToTop/>
        </>
    );
}

export default ProdDiamond;
import React,{useState,useEffect} from 'react'
import { Link,useSearchParams,useNavigate } from 'react-router-dom'
import { FaThList, FaTh, FaSearchPlus, FaRegHeart, FaHeart } from "react-icons/fa"
 
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';


import './AcStyle.css'

import ReactPaginate from 'react-paginate';

import ProductItem from "../parts/product_grid";

import {ToastContainer, toast} from "react-toastify";
function TabPanel(props) {                                                              
    const { children, value, index, ...other } = props;
  
    return (
      <div
        className='col-md-9'
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
      className: 'align-items-start',
    };
  }




function Account(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    let navigate = useNavigate()
    const order_tab = searchParams.get("tab");

           
    var customer_id = localStorage.getItem("customer_id");
    if(customer_id==null){
        navigate('/login');
    }
    const [name, setName] = useState('');
    const [lname, setLName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState(''); 
    const [cpassword, setCpassword] = useState(''); 
    const [recentpassword, setRecentpassword] = useState(''); 
    const [jesdata, setJesda] = useState([]); 
    const [jesorder, setJesorder] = useState([]); 
    let [jescnt, setJescnt] = useState([]);
    const [value, setValue] = React.useState(0);
    const [error, setError] = useState(false);
    const [passworderror, setPassworderror] = useState(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
         
    };
    const sessout=()=>{
        localStorage.removeItem("customer_id");
        localStorage.removeItem("firstname");
        localStorage.removeItem("lastname");
        localStorage.removeItem("mobile");
        localStorage.removeItem("payment_data");
        localStorage.removeItem("billing_country");
        //localStorage.removeItem("availableCartProductIds");
        localStorage.removeItem("email");
        window.location.href = window.location.origin + '/login';
    }
    
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(20);
    const [pageCount, setPageCount] = useState(0)


    const [selected,setSelected] = useState();
    const [responsedata,setResponsedata]= useState();
    const mediumViewport = useMediaQuery('(min-width:767px)');
    const handlePageClick = async (e) => {
        const selectedPage = e.selected;
        console.log(selectedPage*perPage)
        setOffset(selectedPage*perPage)
    }
    var formData = new FormData();
    async function getData(){

        formData.append('request', JSON.stringify( {
            customer_id: customer_id,
            action:'fetchdetails'
        }));

        var pulldata = await fetch(process.env.REACT_APP_API_URL+"/api/myaccount",{
            method: 'POST',
            body: formData         
        });
        var userdata = await pulldata.json();          
        var user_response_code = userdata.status;

        if(userdata.data) {
            setJesda(userdata.data);
            setSelected(userdata.data.gender);
        }else{
            sessout();
        }
    }
    async function getOrdersData(){
        props.loader(true);
        var requestdata = {
            customer_id: customer_id,
            action:'fetchorders',
            offset: offset,
        };
        formData.append('request', JSON.stringify(requestdata));

        var pulldata = await fetch(process.env.REACT_APP_API_URL+"/api/myaccount",{
            method: 'POST',
            body: formData
        });
        var userdata = await pulldata.json();          



        if(userdata.success == true) {
            setJesorder(userdata.data.data);
            setJescnt(userdata.data.count);
            props.loader(false)
        }

        setPageCount(Math.ceil(jescnt/perPage));
    }
    useEffect(()=>{
        getData();
        getOrdersData();

         console.log();

      if(order_tab !== null){
            setValue(parseFloat(order_tab))
        }  else{
            setValue(0)
        }
    },[jescnt,offset,order_tab]);
    //console.log(jesdata)
    const handleSubmit = (e) => {
        e.preventDefault();
        var name = document.getElementById('fname').value;
        var lname = document.getElementById('lname').value;
        var email = document.getElementById('email').value;
        var mobile = document.getElementById('mobile_no').value;
        if (name === '' || lname === '' || email === '' || mobile ==='' ) {
            setError(true);
        }else{
            submitdata();
        }
    };
    const handlePassword = (e) => {
        setPassword(e.target.value);
    };
    const handleCpassword = (e) => {
        setCpassword(e.target.value);
    };
    const handleRecentassword = (e) => {
        setRecentpassword(e.target.value);
    };
    const changePassword = (e) => {
        if(password===cpassword){
            change_password();
        }else{
            setPassworderror("Password mismatch")
        }
    }    
    const errorMessage = () => {
        return (
            <>
            {error ?
            <div className="error alert d-flex align-items-center alert-danger">
                Please enter all the fields
            </div>
            :
            ''}
            </>
        );
    };
    async function  submitdata (){
        props.loader(true);
        var radios = document.getElementsByName('gender');
        for (var radio of radios)
        {
        if (radio.checked) {
        var gender =radio.value;
        }
        }
        var requestdata = {
            customer_id: customer_id,
            fname: document.getElementById('fname').value,
            lname: document.getElementById('lname').value,
            email: document.getElementById('email').value,
            mobile_no: document.getElementById('mobile_no').value,
            gender:gender,
            action:'update_profile'
        };
        var formData= new FormData();
        formData.append('request', JSON.stringify(requestdata));
        var requestOptions = {
            method: 'POST',
            body: formData
        }
        var resp = await fetch(process.env.REACT_APP_API_URL +"/api/myaccount",requestOptions);
        var data = await resp.json(); 

        if(data.success == true){
            toast.info(data.msg);
        } else{
            toast.error(data.msg);
        }
        setResponsedata(data.message);
        props.loader(false)
        // setResponsestatus(data.status);
    }
    async function change_password(){
        props.loader(true);
        var requestdata = {
            customer_id: customer_id,
            password:password,
            c_password:document.querySelector('[name="cpassword"]').value,
            recent_password:recentpassword,
            action:'change_password'
        };
        var formData= new FormData();
        formData.append('request', JSON.stringify(requestdata));
        var requestOptions = {
            method: 'POST',
            body: formData
        }
        var resp = await fetch(process.env.REACT_APP_API_URL +"/api/myaccount",requestOptions);
        var data = await resp.json();
        if(data.success == true){
            toast.info(data.msg);
            document.querySelector('#recent_password').value = "";
            document.querySelector('[name="password"]').value = "";
            document.querySelector('[name="cpassword"]').value = ""; 
        } else{
            toast.error(data.msg);
        }
        setPassworderror(data.message);
        props.loader(false)
        // setResponsestatus(data.status);
    }
    const handleChange1 = (event) => {
        setSelected(event.target.value)
    }

    var customer_id = localStorage.getItem("customer_id");
    if(customer_id==null){
        window.location.href = window.location.origin + '/login';
    }

    let [isWishlist, setWishlist] = useState();
    let [isWishlistCount, setWishlistCount] = useState();
    async  function    get_wishlist(userId){
        props.loader(true)
        var formData = new FormData();
        var requestData = {
            "user_id": userId,
            "action": "list"
        };
        formData.append("request", JSON.stringify(requestData));
        var requestOptions = {
            method: "POST",
            body: formData
        };
        var resp = await fetch(process.env.REACT_APP_API_URL + "/api/wishlist", requestOptions);

        var data = await resp.json();

        if(data.success == true) {
            setWishlistCount(data.data.count);
            setWishlist(data.data.list);
            props.loader(false)
        }

        if(data.success == false) {
            setWishlistCount('');
            setWishlist('');
            props.loader(false)
        }
        //console.log(isWishlist);

    }
    const wishListRemove = async (item) => {
        console.log("Remove " + item);
        props.loader(true);
        var formData = new FormData();

        var requestData = {
            user_id: customer_id,
            product_id: item,
            "action": "remove"
        };
        formData.append("request", JSON.stringify(requestData));
        var requestOptions = {
            method: "POST",
            body: formData
        };
        var resp = await fetch(process.env.REACT_APP_API_URL + "/api/wishlist", requestOptions);


        var data = await resp.json();
        if(data.success == true) {    
            toast.info(data.data.msg);
            if(customer_id) {
                get_wishlist(customer_id);
            }
            props.loader(false)
        }
    };

    useEffect(() => {
        if(customer_id){
            get_wishlist(customer_id);
            console.log("list reload")
        }

    },[]);
    useEffect(() => {
       
         
    },[isWishlist]);
  return (
    <>
        {customer_id ==null ? "": (
          <>
              <main>
                  <div className="my-account-wrapper section-padding">
                      <div className="container">
                          <div className="section-bg-color">
                              <div className="row">
                                  <div className="col-12">
                                      <Box className='row g-0' sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }} >
                                          <Tabs
                                              className='col-md-3'
                                              //orientation="vertical"
                                              orientation={mediumViewport ? "vertical" : "horizontal"}
                                              //variant="scrollable"
                                              variant={mediumViewport ? "" : "scrollable"}
                                              scrollButtons="auto"
                                              allowScrollButtonsMobile
                                              value={value}
                                              onChange={handleChange}
                                              aria-label="Section Account"
                                              sx={{ borderRight: 1, borderColor: 'divider' }}

                                          >
                                              <Tab label="My Details" {...a11yProps(0)} />
                                              <Tab label="My Orders" {...a11yProps(1)} />
                                              <Tab label="Wish List" {...a11yProps(2)} />
                                              <Tab label="Help And Support" {...a11yProps(3)} />

                                          </Tabs>
                                          <TabPanel value={value} index={0}>
                                              <div className="row g-3">
                                                  <div className="col-md-6">
                                                      <div className="card">
                                                          <div className="card-body">
                                                              <div className="row g-3">
                                                                  <h3 className='mb-3'>
                                                                      Profile Details
                                                                  </h3>
                                                                  <p>{responsedata}</p>
                                                                  <div className="col-md-6">
                                                                      <label htmlFor="inputName4" className="form-label">First name</label>
                                                                      <input type="text" className="form-control" name="fname" id="fname" defaultValue={jesdata.first_name}/>
                                                                  </div>
                                                                  <div className="col-md-6">
                                                                      <label htmlFor="inputname" className="form-label">Last name</label>
                                                                      <input type="name" className="form-control" name="lname" id="lname" defaultValue={jesdata.last_name}/>
                                                                  </div>
                                                                  <div className="col-md-6">
                                                                      <label htmlFor="inputEmail4" className="form-label">Email</label>
                                                                      <input type="email" disabled className="form-control" id="email" name="email" defaultValue={jesdata.email}/>
                                                                  </div>

                                                                  <div className="col-md-6">
                                                                      <label htmlFor="inputText" className="form-label">Mobile Number</label>
                                                                      <input type="tel" className="form-control" id="mobile_no" name="mobile_no" defaultValue={jesdata.mobile_no}/>
                                                                  </div>

                                                                  <div className="col-12">
                                                                      <label className="form-check-label me-3" htmlFor="gridCheck">
                                                                          Gender
                                                                      </label>
                                                                      <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                                          <input type="radio" className="btn-check" name="gender" id="male" checked={selected === 'male'} onChange={handleChange1} value="male" defaultChecked autoComplete="off"  />
                                                                          <label className="btn gender-btn" htmlFor="male">Male</label>

                                                                          <input type="radio" className="btn-check" checked={selected === 'female'} onChange={handleChange1} name="gender" id="female" value="female" autoComplete="off" />
                                                                          <label className="btn gender-btn" htmlFor="female">Female</label>
                                                                      </div>
                                                                  </div>
                                                                  <div className="col-12 text-center">
                                                                      <button onClick={handleSubmit} type="submit" className="btn submit-btn">Save</button>
                                                                  </div>
                                                                  {/* <div className="col-12">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="gridCheck" />
                                                            <label className="form-check-label" htmlFor="gridCheck">
                                                            Send me special offers
                                                            </label>
                                                        </div>
                                                    </div> */}
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>

                                                      <div className="col-md-6">
                                                          <div className="card">
                                                              <div className="card-body">
                                                                  <div className="row g-3">
                                                                      <h3 className='mb-3'>
                                                                          Change Password
                                                                      </h3>
                                                                      {passworderror}
                                                                      <div className="col-12">
                                                                          <label htmlFor="inputName4" className="form-label">Current Password</label>
                                                                          <input type="password" onChange={handleRecentassword} className="form-control" id="recent_password" />
                                                                      </div>
                                                                      <div className="col-12">
                                                                          <label htmlFor="inputname"  className="form-label">New Password</label>
                                                                          <input type="password"  onChange={handlePassword} className="form-control" id="paswword" name="password" />
                                                                      </div>
                                                                      <div className="col-12">
                                                                          <label htmlFor="inputEmail4" className="form-label">Confirm Password</label>
                                                                          <input type="password" className="form-control" onChange={handleCpassword} id="inputEmail4" name="cpassword"/>
                                                                      </div>
                                                                      <div className="col-12 text-center">
                                                                          <button type="submit" onClick={changePassword}  className="btn submit-btn">Change Password</button>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  

                                              </div>
                                          </TabPanel>
                                          <TabPanel value={value} index={1}>
                                              <div className="card">
                                                  <div className="card-body">

                                                      <div className="table-responsive">
                                                          <table className="table orderTable">
                                                              <thead>
                                                              <tr>
                                                                  <th scope="col">Order No.</th>
                                                                  <th scope="col">Amount</th>
                                                                  <th scope="col">Order Status	</th>
                                                                  <th scope="col">Order Date</th>
                                                                  <th scope="col">Payment Mode</th>
                                                                  <th scope="col">Action</th>

                                                              </tr>
                                                              </thead>
                                                              <tbody>
                                                              {
                                                                  (jesorder.length>0) ?
                                                                      jesorder.map( (jd,i) => (
                                                                          <tr key={i}>
                                                                              <th scope="row">
                                                                                  {"#"+jd.order_id}</th>
                                                                              <td>{jd.total_amount}</td>
                                                                              <td>{jd.order_status}</td>
                                                                              <td>{jd.date}</td>
                                                                              <td>{jd.payment_mode}<br/>{jd.payment_via}</td>
                                                                              <td><a href={'/order-received/'+btoa(jd.order_id)}>View</a> </td>

                                                                          </tr>
                                                                      )):''}
                                                              </tbody>
                                                          </table>
                                                          <div className="col-12">
                                                              <nav className='d-flex justify-content-center'>
                                                                  <ReactPaginate
                                                                      previousLabel={"Prev"}
                                                                      nextLabel={"Next"}
                                                                      breakLabel={"..."}
                                                                      breakClassName={"break-me"}
                                                                      pageCount={pageCount}
                                                                      marginPagesDisplayed={2}
                                                                      pageRangeDisplayed={5}
                                                                      onPageChange={handlePageClick}
                                                                      containerClassName={""}
                                                                      pageClassName={"page-item"}
                                                                      pageLinkClassName={"page-link"}
                                                                      className={"pagination"}
                                                                      previousClassName={"page-item"}
                                                                      nextClassName={"page-item"}
                                                                      subContainerClassName={"pages pagination"}
                                                                      activeClassName={"active"}
                                                                      activeLinkClassName={"active"}
                                                                      previousLinkClassName={"page-link"}
                                                                      nextLinkClassName={"page-link"}
                                                                  />
                                                              </nav>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </TabPanel>
                                          <TabPanel value={value} index={2}>
                                              {(isWishlistCount) ? (
                                                  <>
                                                      <h4 className="text-uppercase mb-4">Wish List <small className="text-muted text-capitalize">({isWishlistCount} Items)</small></h4>
                                                      <hr/>
                                                  </>
                                              ):''}
                                              <div className="row position-relative g-3">
                                                  
                                                  {(isWishlist && isWishlist.length > 0 ) ?
                                                      isWishlist.map((jd,index) => (

                                                          <div className='col-12 col-sm-6 col-md-6 col-lg-4 mb-3 position-relative'>
                                                              <ProductItem loader={props.loader} class="w-100" key={index} type={jd.product_type} product_id={jd.product_id}/>
                                                              <button className="btn btn-removeWishlist btn-primary btn-sm d-block w-100"
                                                                      onClick={(e) => {
                                                                          wishListRemove(jd.product_id);
                                                                      }}>
                                                                  Remove from list
                                                              </button>
                                                          </div>



                                                      ))
                                                      :(
                                                          <>
                                                              <div className="col-md-12 compare-page">
                                                                  <div className="entry-content text-black text-center">
                                                                      <div><i className="display-2 fa-heart fas opacity-25 text-muted"></i><h3
                                                                          className="display-5"> Your wishlist is empty</h3>
                                                                          <div className="empty-page-text text-muted">Create your first wishlist request.</div>
                                                                          <p className="return-to-shop"><a className="btn btn-primary" href="/"> Back to Home </a></p>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </>
                                                      )}




                                              </div>
                                          </TabPanel>
                                          <TabPanel value={value} index={3}>
                                              <div className="row my-5">
                                                  <div className="col-md-4">
                                                      <div className="card">
                                                          <div className="card-body suport-details">
                                                              <h4> Help Line Number :</h4>
                                                              <p className='mt-4'>
                                                                  +91 12456389
                                                              </p>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div className="col-md-4">
                                                      <div className="card">
                                                          <div className="card-body suport-details">
                                                              <h4> Email :</h4>
                                                              <p className='mt-4'>
                                                                  User@gmail.com
                                                              </p>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div className="col-md-4">
                                                      <div className="card">
                                                          <div className="card-body suport-details">
                                                              <h4> Address</h4>
                                                              <p className='mt-4'>
                                                                  7-19, Aurobindo Nagar Rd
                                                              </p>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>

                                          </TabPanel>
                                      </Box>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </main>
          </>
        ) }




    </>
  )
}

export default Account
import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { FaUserAlt, FaEnvelopeOpenText, FaEye, FaEyeSlash } from "react-icons/fa"
import validator from 'validator'


import './StyleAuth.css'

function Registration() {
    const [fname, setFName] = useState('');
    const [lname, setLName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [password_c, setPassword_c] = useState('');
    const [terms, setTerms] = useState('');
    let [passState, setPassState] = useState('false');
    let passToggle = () => {
        setPassState(!passState);
    }
    let [responsedata,setResponsedata] = useState([]);
    let [responsestatus,setResponsestatus] = useState([]);
    const [emailError, setEmailError] = useState('')
    const [passError, setPassError] = useState('')
    // States for checking the errors
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const [tncerror, setTncError] = useState(false);
    const handleName = (e) => {
        setFName(e.target.value);
        setSubmitted(false);
    };
    const handleLName = (e) => {
        setLName(e.target.value);
        setSubmitted(false);
    };
    const handleTerms = (e) => {
        if (e.target.checked) {
            console.log('✅ Checkbox is checked');
            setTerms(1);
        }else{
            console.log('⛔️ Checkbox is NOT checked');
            setTerms('');
        }

        setSubmitted(false);
    };

      // Handling the email change
    const handleEmail = (e) => {
    setEmail(e.target.value);
    if (validator.isEmail(e.target.value)) {
        setEmailError("");
    }else{
        setEmailError(<div className="error alert d-flex align-items-center alert-danger">Please enter valid email</div>)
    }
    setSubmitted(false);
    };
    const handleMobile = (e) => {
    setMobile(e.target.value);
    setSubmitted(false);
    };

    // Handling the password change
    const handlePassword = (e) => {
    setPassword(e.target.value);
    setSubmitted(false);
    };
    const handleRePassword = (e) => {

        if(password === e.target.value){
            setPassError("") 
        }else{
            setPassError(<div className="error alert d-flex align-items-center alert-danger">Password and confirm password mismatch</div>)
        }
    };

    // Handling the form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        if (fname === '' || lname === '' || email === '' || password === '' || mobile ==='' ) {
            setError(true);
        }else if(terms===''){
            setTncError(true);
        } else {

            if(emailError==='' && passError===''){
                setSubmitted(true);
                submitdata();
                setError(false);
                setTncError(false);
            }
        }
    };

    const errorMessage = () => {
    return (
        <>
        {error ?
        <div className="error alert d-flex align-items-center alert-danger">
            Please enter all the fields
        </div>
        :
        ''}
        </>
    );
    };
    const errorMessagetnc = () => {
    return (
        <>
        {tncerror ?
        <div className="error alert d-flex align-items-center alert-danger">
            Please check Terms of services
        </div>
        :
        ''}
        </>
    );
    };
      async function  submitdata (){
        //console.log("Nilanjan");

            var formData = new FormData();
            formData.append('request', JSON.stringify({
                first_name: fname,
                last_name: lname,
                email: email,
                password: password,
                password_c: password,
                mobile_no: mobile,
            }));
             
          var resp = await fetch(process.env.REACT_APP_API_URL+"/api/auth/signup",{
              method: 'POST',
              body: formData
          });
            var data = await resp.json();
          setResponsedata(data.msg);
          setResponsestatus(data.success);
            if(data.success === true){
                setPassword('');
                setMobile('');
                setFName('');
                setLName('');
                setEmail('');
                setTerms('');
                document.getElementById("name").value="";
                document.getElementById("lname").value="";
                document.getElementById("email").value="";
                document.getElementById("password").value="";
                document.getElementById("mobile").value="";
                document.getElementById("registrationform").reset();
            }
        }
    const loginstyle = "<style>#userPan{display: none}</style>";

  return (
    <>
        {/*<div*/}
        {/*    dangerouslySetInnerHTML={{__html: loginstyle}}*/}
        {/*/>*/}
    <div className="position-relative">
        <div className="row g-0">
            <div className="col-xl-6  d-xl-block d-none position-relative log-image1 overflow-hidden">
                <div className="register-cover cover-image min-vh-100 d-flex align-items-center justify-content-center h-100"></div>
                <div className="cont-main w-100 position-relative">
                    <div className="customlogin-imgcontent">
                        <h2 className="mb-3 fs-35 text-white">Welcome To Baemont</h2>
                     {/*   <p className="text-white-50">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                            dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                            culpa qui officia deserunt mollit anim id est laborum.</p>*/}
                    </div>
                </div>
            </div>
            <div className="col-xl-6 bg-white log-image1">
                <div className="w-75 m-auto">
                    <div className="customlogin-content py-5">
                        <div className="card-body">
                            <Link className="header-brand" to="/">
                                <img src={window.location.origin +"/assets/img/logo.png"} className="header-brand-img custom-logo" alt="Beamontlogo" />
                            </Link>
                        </div>
                        <div className="card-body">
                            <h2 className="mb-2">Register</h2>
                            {responsestatus == true ? (
                                <>
                                    {responsedata != ""  ?  (<div className="alert d-flex align-items-center alert-success">{responsedata}</div>):""}
                                </>

                                ): (
                                <>
                                    {responsedata != "" ?  (<div className="alert d-flex align-items-center alert-danger">{responsedata}</div>):""}
                                </>
                            )  }
                            {passError}
                            {emailError}
                            {errorMessage()}
                            {errorMessagetnc()}


                        </div>
                        <form id="registrationform">
                            <div className="card-body pt-3" id="register" name="register">
                                <div className="row">
                                <div className="form-group col-12 col-md-6">
                                    <label className="">Firstname</label>
                                    <div className="input-group mb-4">
                                        <div className="input-group">
                                            <span   className="input-group-text">
                                                <FaUserAlt />
                                            </span>
                                            <input className="form-control" onChange={handleName} value={fname} placeholder="" id="name" />
                                        </div>
                                    </div>
                                </div>
                                    <div className="form-group col-12 col-md-6">
                                    <label className="">Lastname</label>
                                    <div className="input-group mb-4">
                                        <div className="input-group">
                                            <span   className="input-group-text">
                                                <FaUserAlt />
                                            </span>
                                            <input className="form-control" onChange={handleLName} value={lname} placeholder="" id="lname"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-12 col-md-12">
                                    <label className="">Email or Username</label>
                                    <div className="input-group mb-4">
                                        <div className="input-group">
                                            <span className="input-group-text">
                                                <FaEnvelopeOpenText />
                                            </span>
                                            <input className="form-control" placeholder="" onChange={handleEmail} value={email} id="email"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-12 col-md-12">
                                    <label className="">Mobile No.</label>
                                    <div className="input-group mb-4">
                                        <div className="input-group">
                                            <span   className="input-group-text">
                                                <FaEnvelopeOpenText />
                                            </span>
                                            <input className="form-control" placeholder="" onChange={handleMobile} value={mobile} id="mobile"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-12 col-md-6">
                                    <label className="form-label">Password</label>
                                    <div className="input-group mb-4">
                                        <div className="input-group" id="Password-toggle">
                                            <button type='button' className="pass input-group-text" onClick={passToggle}>
                                                { passState ?
                                                <FaEye />
                                                :
                                                <FaEyeSlash />
                                                }
                                            </button>
                                            <input className="form-control" type={passState ? "password" : "text"} placeholder="" onChange={handlePassword} value={password} id="password"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-12 col-md-6">
                                    <label className="form-label">Confirm Password</label>
                                    <div className="input-group mb-4">
                                        <div className="input-group" id="Password-toggle">
                                            <button type='button' className="pass input-group-text" onClick={passToggle}>
                                                { passState ?
                                                <FaEye />
                                                :
                                                <FaEyeSlash />
                                                }
                                            </button>
                                            <input className="form-control" type={ passState ? "password" : "text"} placeholder="" onChange={handleRePassword}  id="rpassword"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-12 col-md-12">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckDefault"  onChange={handleTerms}/>
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                I agree to the <Link to="/baemont-terms-conditions" className="text-primary">Terms of services</Link> and <Link to="/privacy-policy" className="text-primary">Privacy policy</Link>
                                            </label>
                                    </div>


                                </div>
                                <div className="submit">
                                    {/* <Link className="btn btn-default mt-3 btn-block" to="/">Create Account</Link> */}
                                    <button onClick={handleSubmit} className=" text-uppercase rounded-0 btn btn-default mt-3 btn-block" type="submit">
                                        Register
                                    </button>
                                </div>

                                <div className="text-start my-4">
                                    <p className="text-dark mb-0">Already have an account?<Link className="text-primary ms-1" to="/login">LogIn</Link></p>
                                </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>


    </>
  )
}

export default Registration

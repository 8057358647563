import React from 'react'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import { Helmet } from 'react-helmet';
import { config, library } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { FaPlus, FaEquals } from "react-icons/fa"


const  SelectYourStyles  =
    [
        {"img": "Solitaire-1.jpg", "title":"Solitaire", "desc": "A classic and the most popular of choices. Simple and plain in style as it highlights your diamond of choice as the centrepiece of attention.", "href": "#"},
        {"img": "pave.png", "title":"Pave", "desc": "Lining the band with small diamonds, a Pave setting illuminates its beauty whilst hiding the metal. An elegant style that compliments any diamond shape.", "href": "#"},
        {"img": "channel-set-1.png", "title":"Channel Set", "desc": "A rugged design that embeds small diamonds in the grooves of the band. It creates a series of sparkles flushed with the shank.", "href": "#"},
        {"img": "tension-1.png", "title":"Tension", "desc": "The diamond is suspended between the two sides of the shank. A contemporary design fit for the modern woman.", "href": "#"},
        
        {"img": "vintage-1.png","title":"Vintage", "desc": "Side diamonds set on the band. Perfect to showcase the main centrepiece of your selected diamond.", "href": "#"},
        {"img": "halo.png", "title":"Halo", "desc": "Bold and elegant. Surrounding the centre stone with many tiny diamonds, together it brings an outstanding “wow” factor by making your ring appear bigger.", "href": "#"},
        {"img": "side-stone-1.png", "title":"Side Stone", "desc": "A design usually inspired by history. Perfect for those seeking to create an heirloom piece to be passed on for generations.", "href": "#"},
        {"img": "Three-stone.png", "title":"Three-Stone", "desc": "2 side diamonds each set respectively next to the main centrepiece. Usually same shape diamonds are used to emphasize on the size and brilliance of the centre stone.", "href": "#"}

]

const BrowseMetalTypes = [
    {"img": "Metal-Browser-YG.png", "title":"Yellow Gold", "href": "#"},
    {"img": "Metal-Browser-WG.png", "title":"White Gold", "href": "#"},
    {"img": "Metal-Browser-RG.png", "title":"Rose Gold", "href": "#"},
    {"img": "Metal-Browser-PT.png", "title":"Platinum", "href": "#"}
]

const RingStyleoptions = {
    responsive:{
        0:{ items:1 },
        480:{ items:2 },
        768:{ items:3 },
        980:{ items:3 },
        1200:{ items:4 },
        1500:{ items:4 }
    },
    autoplay : true,
    autoplayTimeout : 5000,
    autoplayHoverPause : true,
    smartSpeed : 200,
    fluidSpeed : 200,
    autoplaySpeed : 200,
    navSpeed : 200,
    dotsSpeed : 200,
    loop : true,
    nav : true, 
    dots : true,
    responsiveRefreshRate : 200,
    slideBy : 1,
    mergeFit : true,
    autoHeight : false,
    mouseDrag : false,
    touchDrag : true
};

function EngagementRingsPage() {
    return (
        <>
            <Helmet>
                <title>Baemont – Think Diamond, Think BAEMONT! | Engagement Rings </title>
            </Helmet>

        <section className='sp-page'>
            <div className='container-outter' id='er-page-header' style={{backgroundImage: `url("assets/img/static/engagement-rings/er-header.png")`}}>
            <div className='container-fluid'>
                <div className='row'>

                    <div className='d-block d-md-block d-lg-none p-0'>
                        <img className='img-fluid' src="assets/img/static/engagement-rings/er-header.png" alt="" style={{objectFit: 'cover'}} />
                    </div>
                    <div id='er-page-header-inner' className='col-12 col-lg-6 m-auto d-flex flex-column justify-content-center'>
                        <h2 className='text-center'>Engagement Rings</h2>

                        <p><span className="text-decoration-underline"><strong>37.6%</strong></span> of Proposals was done in a rush.</p>
                        <p>Rushing a proposal is <span className="text-decoration-underline"><strong>37.6%</strong></span> positively correlated with a higher price rings.</p>
                        <p>Don’t be part of the statistic.</p>
                        <p>Don’t be last minute on your proposal.</p>
                        <div className='text-center invert-100 er-breadcrumb mt-2 mb-4'>
                            <img className='img-fluid' src="assets/img/static/engagement-rings/wbc_breadcrumb_template_1_step_2-1.png" alt="" style={{objectFit: 'cover'}} />
                            <FaPlus />
                            <img className='img-fluid' src="assets/img/static/engagement-rings/wbc_breadcrumb_template_1_step_1-1.png" alt="" style={{objectFit: 'cover'}} />
                            <FaEquals />
                            <img className='img-fluid' src="assets/img/static/engagement-rings/wbc_breadcrumb_template_1_step_3-1.png" alt="" style={{objectFit: 'cover'}} />
                        </div>
                        <div className='text-center mt-5 '>
                        <a className="sp-button btn" href='/settings-ring/all'>START CUSTOMIZING NOW!</a>
                        </div>
                    </div>
                </div>
            </div>
            </div>

            <div className='container py-5' id='ring-style'>
                <div className='row'>
                    
                    <div className='col-12 text-center'>
                        <h2 className='h2-sec'>Select Your Style</h2>
                        <OwlCarousel className='owl-theme autohide-arrows'  {...RingStyleoptions}>

                            {SelectYourStyles.map(function(SelectYourStyle, i){

                                return <div className='ring-item item position-relative' key={i}>

                                    <div className='ring-style' style={{ backgroundImage:`url(${'assets/img/static/engagement-rings/style/'+SelectYourStyle.img})` }}> </div>
                                    <h3>{SelectYourStyle.title}</h3>
                                    <p>{SelectYourStyle.desc}</p>
                                    <a href={SelectYourStyle.href} className='stretched-link'></a>
                                </div>;
                            })}




                        </OwlCarousel>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row'>

                    <div className='col-12 text-center'>
                        <h2 className='h2-sec mb-5'>Browse Your Ideal Metal Type
                        </h2>

                    </div>
                </div>
                    <div className='row'>

                        {BrowseMetalTypes.map(function(BrowseMetalType, i){

                            return <div className='col-6 col-lg-3 pb-5 text-center' key={i}>
                                <div className='mb-2'> <img className='img-fluid' src={'assets/img/static/engagement-rings/type/'+BrowseMetalType.img} alt=""/></div>
                                <a className='metaltype-btn' href={BrowseMetalType.href}>{BrowseMetalType.title}</a>
                            </div>;
                        })}
                        
                </div>
            </div>

            <div className='container py-5'>
                <div className='row'>

                    <div className='col-12 col-lg-6 text-center mb-sm-5 '>
                        <img className='img-fluid w-100' src={'assets/img/static/engagement-rings/pexels-andrea-piacquadio.jpg'} alt="" style={{objectFit: 'cover'}} />
                    </div>
                    <div className='col-12 col-lg-6 text-center align-self-center'>
                        <h2 className='h2-primary'>Can't Find What You're Looking For?</h2>
                        <h3 className='h2-sec text-dark lh-1'>Customise It!</h3>
                        <p className='p-sec fs-5 px-md-5 mb-5'>Our customisation service is entirely personal to you. Share your design ideas with us. We will work with you to bring your creation to life</p>


                        <a className="sp-button pri" href='/#'>Customize your Own</a>
                    </div>
                </div>

            </div>

        </section>
        </>
    )
}
 
export default EngagementRingsPage
import React from 'react'

function TermsConditions() {
  return (
    <div>
        <section className="common-page-header">

            <div className="container">
                <div className="row pageTitle m0">
                    <div className="col-12  text-center">
                        <h1 className="display-3 text-white">Terms and Conditions</h1>
                        <ul className="breadcrumb text-center d-block">
                            <li className="d-inline-block "><a className="text-white" href="index.html">HOME</a><span className="mx-2">/</span></li>
                            <li className="d-inline-block"> Terms and Conditions</li>
                        </ul>

                    </div>
                </div>
            </div>
        </section>
       

<section id="contactRow" className=" contentRowPad">
    <div className="container">
        <div className="row">
            <div className='col-12'>

                <div className="elementor-text-editor elementor-clearfix">
                    <h1><b>Terms &amp; Conditions</b></h1>
                    <p>Anytime you interact with Baemont.com and/or BAE Capital Sdn Bhd (202001028034), such as when you
                        visit our website, when you purchase products and services, or when you call our sales or
                        support representatives you are agreeing to comply with and be bound by our terms and
                        conditions. Please review the following terms carefully. If you do not agree to these terms, you
                        should not use this website. The term Baemont or Baemont.com, “us” or “we” or “our” refers to
                        BAE Capital Sdn Bhd, a limited liability company incorporated in Malaysia and the owner of the
                        website. The term “you” refers to the user or viewer of our website.</p>
                    <h2><b>Acceptance of Agreement</b></h2>
                    <p>By using our site, Baemont.com, (the “Site”), you agree to be bound by these Terms and Conditions
                        (“<strong>Agreement</strong>“). This Agreement constitutes the entire and only agreement between
                        us and you, and supersedes all prior or contemporaneous agreements, representations, warranties
                        and understandings with respect to the Site, content, products or services provided by or
                        through the Site, and the subject matter of this Agreement. This Agreement may be amended at any
                        time by us from time to time without specific notice to you. The latest Agreement will be posted
                        on the Site, and you should review this Agreement prior to using the Site and making a purchase
                        from us.</p>
                    <h2><b>Natural Diamonds</b></h2>
                    <p>Baemont does not sell any enhanced or treated diamonds. All diamonds sold online are natural
                        diamonds that have not gone through any enhancements.</p>
                    <h2><b>Pricing</b></h2>
                    <p>All prices at checkout are in Ringgit Malaysia. We do not negotiate prices on our products and
                        all our prices are final. We make significant efforts to assure the pricing on our website is
                        accurate at the time of purchase. However, we do reserve the right to cancel any and all sales
                        when an inaccurate or erroneous price is displayed. We cannot honor inaccurate or erroneous
                        pricing. If we cancel a sale after checkout, we will refund the fee listed on the check out
                        receipt in the same manner as payment was made. For example, Mastercard payment will be credited
                        to the same Mastercard only. Our prices are also subject to change without notice. If a sale is
                        canceled, the refund price represents the sole and exclusive damages available to you.</p>
                    <p>Baemont offers a low price guarantee. If you find a lower price on an item of identical size
                        (dimensions and thickness) and quality in terms of the colour, cut, carat, clarity, we will beat
                        it. The customer must provide verifiable proof of the competitor’s price, such as a link to
                        their product or advertisement. It is up to the sole discretion of Baemont to determine if an
                        item is of identical size and quality. Please note, coupon code or offers are not valid on any
                        custom ring or jewellery item.</p>
                    <h2><b>Product Availability</b></h2>
                    <p>All products displayed are typically in stock and available. There are times however when an
                        inventory error occurs and an item is not available. Baemont reserves the right to cancel an
                        order or substitute another item of like appearance and value for the original item
                        purchased.</p>
                    <p>Our Stance on Conflict Diamonds</p>
                    <p>All diamonds sold on Baemont.com are purchased from legitimate sources not involved in funding
                        conflict diamonds, and in compliance with United Nations resolutions. Baemont herby guarantees
                        that these diamonds are conflict free, based on personal knowledge and/or written guarantees
                        provided by the supplier of the diamonds.</p>
                    <h2><b>Photography</b></h2>
                    <p>Item may appear larger or smaller than actual size due to photography methods or screen defaults.
                        Images might also appear larger than actual size in order to show detail, or smaller than actual
                        size in order to show an entire item. It is your responsibility to review the listed size and
                        weight of each stone and not rely on photographs when determining your purchase.</p>
                    <h2><b>Refund and Return Policy</b></h2>
                    <p>Our Return Policy, as it may change from time to time, is a part of this Agreement and reflected
                        fully on our website under “FAQ”. All returns must be accompanied by the original documents sent
                        to the customer. We do not accept any return of a diamond made without the original diamond
                        grading report. Please note, all insurance replacements are not covered through our Return
                        policy.</p>
                    <p>While Baemont is happy to try and accommodate rush orders, a 10% restocking fee will be charged
                        should the order be returned for any reason. The fee can be used as a credit towards any
                        exchange or for a future order and never expires.</p>
                    <h2><b>Payments</b></h2>
                    <p>You represent and warrant that if you are purchasing something from us or from Merchants that (i)
                        any credit information you supply is true and complete, (ii) charges incurred by you will be
                        honored by your credit card company, and (iii) you will pay the charges incurred by you at the
                        posted prices, including any applicable taxes and shipping.</p>
                    <h2><b>Lifetime Warranty</b></h2>
                    <p>Our lifetime warranty program protects you against any manufacturing defects on our products. Any
                        product sold by Baemont can be sent back to us in the event that a defect is found. If the
                        defect is determined to have occurred during the manufacturing process, we’ll replace or repair
                        the item at no cost to you. If Baemont determines that the damage was due to normal
                        wear-and-tear, we will repair the product at cost, including the cost of shipping the repaired
                        item back to you. It is up to the sole discretion of Baemont to determine if a defect is due to
                        a manufacturing fault. The customer is responsible for any shipping costs incurred when sending
                        products to us for inspection.</p>
                    <h2><b>Diamond Upgrades</b></h2>
                    <p>Our diamond upgrades program applies only to undamaged diamonds purchased from Baemont. It is up
                        to the sole discretion of Baemont to determine if a diamond is undamaged and suitable for
                        upgrade. The price of the original diamond must be less than or equal to RM20,000 at the time
                        that it was purchased, and the price of the new diamond must be at least twice the value of the
                        original diamond purchased. All diamonds returned for an upgrade must be accompanied by the
                        original diamond grading report or certificate. Coupon codes or discounts are not valid for
                        upgrades.</p>
                    <h2><b>Custom Jewellery</b></h2>
                    <p>Custom Jewellery are designs for Jewellery, belonging to and submitted by customers (“Custom
                        Design”). As such you hereby agree and certify that the Custom Design (i) are your designs and
                        do not belong to anybody else, and (ii) are not subject to any protectable intellectual property
                        rights (trademark, copyright, etc.) of others. Once a customer submits payment to initiate a
                        custom design draft, the design charge is not refundable. Custom jewellery items will not be
                        cast and manufactured until the customer approves the drawings in writing via certified mail or
                        email. Once the customer approves the drawings, the custom item is not eligible for return or
                        replacement. Due to the intricate and labor-intensive nature of many custom jewellery items, any
                        delivery estimate provided by a Baemont representative, whether oral or written delivered by any
                        medium, is merely an approximate date, and is not a guaranteed date for delivery. Actual
                        delivery dates may vary, up to 10 business days from the delivery estimate date. Custom
                        jewellery is covered by our lifetime warranty and care and maintenance programs. Baemont owns
                        all designs, renderings, drawings and models submitted to or created by our employees and/or our
                        designers. By submitting and casting your Custom Design, you hereby grant Baemont an
                        irrevocable, perpetual, nonexclusive, royalty-free, fully paid-up, worldwide license to use,
                        copy, modify, and display any portion of the Custom Design incorporated into any Custom
                        Jewellery developed and delivered by you. We reserve the right to market, publicize and share
                        any and all designs and images of the Custom Design, which website display may can differ from
                        the pictured stock images on the site. You hereby undertake that if Baemont gets charged or
                        accused of violating somebody else’s protected intellectual property as a result of Baemont’s
                        production of the Custom Design, you hereby agree to indemnify and hold harmless Baemont/BAE
                        Capital Sdn Bhd, its officers, directors, shareholders, employees or agents (the “Indemnified
                        Parties”) from any and all liabilities, losses, damages, claims, suits, judgments, costs and
                        expenses (including reasonable legal fees and costs of any investigation or action related
                        thereto) (“Losses”) suffered or incurred by the Indemnified Parties (i) as a result of the
                        Indemnifying Party’s failure to disclose the source of any protected Custom Design; or (ii) from
                        the breach or incorrectness of any representation about the source of the Custom Design made by
                        the Indemnifying Party. The Indemnifying Party must be promptly notified in writing of any Claim
                        within such time as is reasonably necessary to allow the Indemnifying Party to respond to the
                        claim prior to any legally-required deadline (and in any event, no later than thirty (30) days
                        after notice of such claim) for which indemnification is sought. The Indemnified Parties shall
                        have sole control over the defense and settlement of any claim for which indemnification is
                        sought and both Indemnified Parties and Indemnifying Parties shall reasonably cooperate with
                        each other and their representatives in the investigation and defense of the Claims.</p>
                    <h2><b>Ring Resizing</b></h2>
                    <p>Any ring purchased from Baemont can be sent back within 60 days from the date of purchase for a
                        free resizing. After 60 days, we will resize the item at cost, including the cost of shipping
                        the item back to you. The customer is responsible for any shipping charges incurred when
                        shipping the item to us for resizing.</p>
                    <h2><b>Use of Information</b></h2>
                    <p>We reserve the right, and you authorize us, to the use and assignment of all information
                        regarding Site uses by you and all information provided by you in any manner consistent with our
                        Privacy Policy. All remarks, suggestions, ideas, graphics, or other information communicated by
                        you to us (collectively, a “<strong>Submission</strong>“) will forever be our property. We will
                        not be required to treat any Submission as confidential, and will not be liable for any ideas
                        (including without limitation, product, service, or advertising ideas) and will not incur any
                        liability as a result of any similarities that may appear in our future products, services, or
                        operations. Without limitation, we will have exclusive ownership of all present and future
                        existing rights to the Submission of every kind and nature everywhere. We will be entitled to
                        use the Submission for any commercial or other purpose whatsoever, without compensation to you
                        or any other person sending the Submission. You acknowledge that you are responsible for
                        whatever material you submit, and you, not us, have full responsibility for the message,
                        including its legality, reliability, appropriateness, originality, and copyright.</p>
                    <h2><b>Privacy Policy</b></h2>
                    <p>Our Privacy Policy, as it may change from time to time, is a part of this Agreement. You may
                        review the privacy policy as currently stated on the Baemont.com website.</p>
                    <h2><b>Registration</b></h2>
                    <p>Certain sections of, or offerings from, the Site may require you to register. If registration is
                        requested, you agree to provide us with accurate, complete registration information. Your
                        registration must be done using your real name and accurate information. Each registration is
                        for your personal use only and not on behalf of any other person or entity. We do not permit (a)
                        any other person using the registered sections under your name; or (b) access through a single
                        name being made available to multiple users on a network. You are responsible for preventing
                        such unauthorised use.</p>
                    <h2><b>Errors, Corrections and Changes</b></h2>
                    <p>We do not represent or warrant that the Site will be error-free, free of viruses or other harmful
                        components, or that defects will be corrected. We do not represent or warrant that the
                        information available on or through the Site will be correct, accurate, timely or otherwise
                        reliable. We may make changes to the features, functionality or content of the Site at any time.
                        We reserve the right to correct or remove any and all errors when they occur. We reserve the
                        right in our sole discretion to edit or delete any documents, information or other content
                        appearing on the Site.</p>
                    <h2><b>Unlawful Activity, Compliance with Law, and Fraud Protection</b></h2>
                    <p>Baemont may collect and disclose any information, including personally identifiable information,
                        we deem necessary, in our sole discretion, to comply with any applicable law and regulation,
                        government request, or legal process. In addition, we may collect and share personally
                        identifiable information and any other information available to us in order to investigate,
                        prevent or take action regarding suspected fraud, illegal activities, and situations involving
                        potential threats to the physical safety of personal or as otherwise required by law. We may
                        also exchange information, including personally identifiable information, with other
                        organisations or law enforcement officials for credit fraud protection and risk reduction.</p>
                    <h2><b>Third Party Content</b></h2>
                    <p>Third party content may appear on the Site or may be accessible via links from the Site. We are
                        not responsible for and assume no liability for any mistakes, misstatements of law, defamation,
                        omissions, falsehood, obscenity, pornography or profanity in the statements, opinions,
                        representations or any other form of content on the Site. You understand that the information
                        and opinions in the third party content represent solely the thoughts of the author and is
                        neither endorsed by nor does it necessarily reflect our belief.</p>
                    <h2><b>Copyright</b></h2>
                    <p>The content, organisation, graphics, design, compilation, magnetic translation, digital
                        conversion and other matters related to the Site are protected under applicable copyrights and
                        other proprietary (including but not limited to intellectual property) rights. The copying,
                        redistribution, use or publication by you of any such matters or any part of the Site, except as
                        allowed herein, is strictly prohibited. You do not acquire ownership rights to any content,
                        document or other materials viewed through the Site. The posting of information or materials on
                        the Site does not constitute a waiver of any right in such information and materials. Some of
                        the content on the site is the copyrighted work of third parties. Baemont.com is registered
                        exclusively to BAE Capital Sdn Bhd.</p>
                    <p>We respect the intellectual property of others, and we ask you to do the same. If you believe
                        that your work has been copied in a way that constitutes copyright infringement, please provide
                        our Copyright Agent the following information:<br/>a. An electronic or physical signature of the
                            person authorised to act on behalf of the owner of the copyright interest;<br/>b. A
                                description of the copyrighted work that you claim has been infringed;<br/>c. A
                                    description of where the material that you claim is infringing is located on the
                                    Site;<br/>d. Your address, telephone number, and email address;<br/>e. A statement by
                                        you that you have a good faith belief that the disputed use is not authorized by
                                        the copyright owner, its agent, or the law; and<br/>f. A statement by you, made
                                            under penalty of perjury, that the above information in your Notice is
                                            accurate and that you are the copyright owner or authorized to act on the
                                            copyright owner’s behalf.<br/>Our Copyright Agent for Notice of claims of
                                                copyright infringement on the Site can be reached by directing an e-mail
                                                to the Copyright Agent at hello@baemont.com<u>.</u> .</p>
                    <h2><b>Limited License</b></h2>
                    <p>You are granted a non-exclusive, non-transferable, revocable license (a) to access and use the
                        Site strictly in accordance with this Agreement; (b) to use the Site solely for internal,
                        personal, non-commercial purposes; and (c) to print out discrete information from the Site
                        solely for internal, personal, non-commercial purposes and provided that you maintain all
                        copyright and other policies contained therein. No print out or electronic version of any part
                        of the Site or its contents may be used by you in any litigation or arbitration matter
                        whatsoever under any circumstances.</p>
                    <h2><b>Restrictions and Prohibitions on Use</b></h2>
                    <p>Your license for access and use of the Site and any information, materials or documents
                        (collectively defined as “<strong>Content and Materials</strong>“) therein are subject to the
                        following restrictions and prohibitions on use: You may not (a) copy, print (except for the
                        express limited purpose permitted herein), republish, display, distribute, transmit, sell, rent,
                        lease, loan or otherwise make available in any form or by any means all or any portion of the
                        Site or any Content and Materials retrieved therefrom; (b) use the Site or any materials
                        obtained from the Site to develop, of as a component of, any information, storage and retrieval
                        system, database, information base, or similar resource (in any media now existing or hereafter
                        developed), that is offered for commercial distribution of any kind, including through sale,
                        license, lease, rental, subscription, or any other commercial distribution mechanism; (c) create
                        compilations or derivative works of any Content and Materials from the Site; (d) use any Content
                        and Materials from the Site in any manner that may infringe any copyright, intellectual property
                        right, proprietary right, or property right of us or any third parties; (e) remove, change or
                        obscure any copyright notice or other proprietary notice or terms of use contained in the Site;
                        (f) make any portion of the Site available through any timesharing system, service bureau, the
                        Internet, or any other technology now existing or developed in the future; (g) remove,
                        decompile, disassemble or reverse engineer any Site software or use any network monitoring or
                        discovery software to determine the Site architecture; (h) use any automatic or manual process
                        to harvest information from the Site; (i) use the Site for the purpose of gathering information
                        for or transmitting (1) unsolicited commercial email; (2) email that makes use of headers,
                        invalid or nonexistent domain names, or other means of deceptive addressing; and (3) unsolicited
                        telephone calls or facsimile transmissions; (j) use the Site in a manner that violates any state
                        or federal law regulating email, facsimile transmissions or telephone solicitations; and (k)
                        export or re-export the Site or any portion thereof, or any software available on or through the
                        Site, in violation of the laws or regulations of Malaysia.</p>
                    <h2><b>Linking to the Site</b></h2>
                    <p>You may provide links to the Site, provided (a) that you do not remove or obscure, by framing or
                        otherwise, advertisements, the copyright notice, or other notices on the Site, (b) your site
                        does not engage in illegal or pornographic activities, and (c) you discontinue providing links
                        to the Site immediately upon request by us.</p>
                    <h2><b>Advertisers and Vendors</b></h2>
                    <p>The Site may contain advertising sponsorships and third party vendors. Advertisers and sponsors
                        are responsible for ensuring that material submitted for inclusion on the Site is accurate and
                        complies with applicable laws. We are not responsible for the illegality or any error,
                        inaccuracy or problem in the advertiser’s, sponsor’s, or vendor’s materials.</p>
                    <h2><b>Indemnification</b></h2>
                    <p>You agree to indemnify, defend and hold us and our partners, agents, officers, directors,
                        employees, subcontractors, successors, assigns, third party suppliers of information and
                        documents, attorneys, advertisers, product and service providers, and affiliates (collectively,
                        “Affiliated Parties”) harmless from any liability, loss, claim and expense, including reasonable
                        legal fees, related to your violation of this Agreement or use of the Site.</p>
                    <h2><b>Termination</b></h2>
                    <p>Regardless of the terms of this Agreement, Baemont reserves the right to terminate or block your
                        use of our Site or services at our sole discretion. This may include preventing your ability to
                        make purchases on our site or refusing to fulfill an order of our products made by you. In the
                        event that we do not fulfill an order, a refund will be issued to you in the same form that
                        payment was submitted.</p>
                    <h2><b>Disclaimer</b></h2>
                    <p>THE INFORMATION, CONTENT, AND DOCUMENTS FROM OR THROUGH THE SITE ARE PROVIDED “AS-IS,” “AS
                        AVAILABLE,” WITH “ALL FAULTS”, AND ALL WARRANTIES, EXPRESS OR IMPLIED, ARE DISCLAIMED (INCLUDING
                        BUT NOT LIMITED TO THE DISCLAIMER OF ANY IMPLIED WARRANTIES OF FITNESS FOR A PARTICULAR
                        PURPOSE). THE INFORMATION AND SERVICES MAY CONTAIN BUGS, ERRORS, PROBLEMS OR OTHER LIMITATIONS.
                        WE, OUR AFFILIATED PARTIES, AND THIRD PARTY VENDORS HAVE NO LIABILITY WHATSOEVER FOR YOUR USE OF
                        ANY INFORMATION OR SERVICE, EXCEPT AS PROVIDED HEREIN. IN PARTICULAR, BUT NOT AS A LIMITATION
                        THEREOF, WE AND OUR AFFILIATED PARTIES ARE NOT LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL OR
                        CONSEQUENTIAL DAMAGES (INCLUDING DAMAGES FOR LOSS OF BUSINESS, LOSS OF PROFITS, LITIGATION, OR
                        THE LIKE), WHETHER BASED ONBREACH OF CONTRACT, BREACH OF WARRANTY, TORT (INCLUDING NEGLIGENCE),
                        PRODUCT LIABILITY OR OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE NEGATION
                        AND LIMITATION OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN
                        BETWEEN US AND YOU. THIS SITE AND THE PRODUCTS, SERVICES, DOCUMENTS AND INFORMATION PRESENTED
                        WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS. NO ADVICE OR INFORMATION, WHETHER ORAL OR
                        WRITTEN, OBTAINED BY YOU FROM US THROUGH THE SITE OR OTHERWISE SHALL CREATE ANY WARRANTY,
                        REPRESENTATION OR GUARANTEE NOT EXPRESSLY STATED IN THIS AGREEMENT. ALL RESPONSIBILITY OR
                        LIABILITY FOR ANY DAMAGES CAUSED BY VIRUSES CONTAINED WITHIN THE ELECTRONIC FILE CONTAINING A
                        FORM OR DOCUMENT IS DISCLAIMED.</p>
                    <h2><b>Limitation of Liability</b></h2>
                    <p>We and any Affiliated Party shall not be liable for any loss, injury, claim, liability, or damage
                        of any kind resulting in any way from (a) any errors in or omissions from the Site or any
                        services or products obtainable therefrom, (b) the unavailability or interruption of the Site or
                        any features thereof, (c) your use of the Site, (d) the content contained on the Site, or (e)
                        any delay or failure in performance beyond the control of us or any Affiliated Party (f) damages
                        as a result of a cancelled order or refunded item. THE AGGREGATE LIABILITY OF US AND THE
                        AFFILIATED PARTIES IN CONNECTION WITH ANY CLAIM ARISING OUT OF OR RELATING TO THE SITE AND/OR
                        THE PRODUCTS, INFORMATION, DOCUMENTS AND SERVICES PROVIDED HEREIN OR HEREBY SHALL NOT EXCEED
                        RM100 AND THAT AMOUNT SHALL BE IN LIEU OF ALL OTHER REMEDIES WHICH YOU MAY HAVE AGAINST US AND
                        ANY AFFILIATED PARTY.</p>
                    <h2><b>Information</b></h2>
                    <p>The Site contains information and press releases about us. We disclaim any duty or obligation to
                        update this information or any press releases. Information about companies other than ours
                        contained in the press release or otherwise, should not be relied upon as being provided or
                        endorsed by us.</p>
                    <h2><b>Whole Agreement</b></h2>
                    <p>This Agreement (including all the policies and FAQs published on the Site) contains the whole
                        agreement between you and Baemont relating to its subject matter at the date hereof to the
                        exclusion of any terms implied by law which may be excluded by contract and supersedes any
                        previous written or oral agreement between the parties in relation to the matters dealt with in
                        this Agreement.</p>
                    <h2><b>Governing law</b></h2>
                    <p>This Agreement and the documents to be entered into pursuant to it (unless otherwise expressed in
                        such documents<strong>) </strong>shall be governed by and construed in accordance with the laws
                        of Malaysia.</p>
                    <h2><b>Jurisdiction</b></h2>
                    <p>You irrevocably agree that the courts of Malaysia are to have jurisdiction to settle any disputes
                        which may arise out of or in connection with this Agreement and the documents to be entered into
                        pursuant to it and that accordingly any proceedings arising out of or in connection with this
                        Agreement and such documents shall be brought in such courts.</p>
                    <p>You irrevocably submit to the jurisdiction of such courts and waive any objection to proceedings
                        in any such court on the ground of venue or on the ground that proceedings have been brought in
                        an inconvenient forum.</p></div>
            </div>
        </div>
    </div>
</section>

    </div>
  )
}

export default TermsConditions
import React, {useState} from "react";
import { Link,useNavigate } from 'react-router-dom'

import { FaThList, FaTh, FaSearchPlus, FaRegHeart, FaHeart, FaStar, FaPlus, FaTimes, FaAngleLeft, FaAngleRight } from "react-icons/fa"
import { styled } from '@mui/material/styles';
//import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import List from '@mui/material/List';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CartSummary from './CartSummary';


import {ToastContainer, toast} from "react-toastify";


function Cart(props) {

     var existing_cart = localStorage.getItem('productsbag') == null ? [] : JSON.parse(decodeURIComponent(escape(atob(localStorage.getItem('productsbag')))));
     var total_price = 0;
     let subtotal = 0;
     let navigate = useNavigate()

    const[couponval,setCouponval] = useState();
     
    const existing_cart_length =  existing_cart.length;
    const currency = localStorage.getItem("currency") ?? "RM";
     var sub_total_existing_cart = [];
     for(var i = 0; i <  existing_cart.length; i++)
     {
        if(existing_cart[i]['product_type']=="compound"){

            console.log(existing_cart[i]);
            
              total_price += (existing_cart[i].diamond.total_price_val+existing_cart[i].ring.sell_price_val);
         

        }else if(existing_cart[i]['product_type']=="simple"){

            total_price +=  parseFloat(existing_cart[i]['product_data']['sell_price_val']);

            
        }
         subtotal +=  total_price
     }
     const removecart = (index) => {
         var existItem = localStorage.getItem('productsbag') == null ? [] : JSON.parse(atob(localStorage.getItem('productsbag')));

         existItem.splice(index, 1);
         localStorage.setItem("productsbag",btoa(JSON.stringify(existItem)));


         props.setCartItem(['']);

         localStorage.removeItem("coupon_val");
  }
  const gotoring=(e,index) => {
      e.preventDefault();
      // var id_array = JSON.parse(atob(localStorage.getItem("availableCartProductIds")));
      var item_array = localStorage.getItem('productsbag') == null ? [] : JSON.parse(decodeURIComponent(escape(atob(localStorage.getItem('productsbag')))));
      // var strArray = id_array[index].split(":");
      
      localStorage.setItem("indexvalue",index)
      // if(item_array[index].diamond){
          localStorage.setItem("diamondId",item_array[index].diamondId)
          //console.log(strArray[0]);
          navigate('/product-diamond/'+item_array[index].diamondId)
          //navigate('/settings-diamond/all')
     /* }else{
          localStorage.setItem("gemsId",item_array[index].diamondId)
          //navigate('/settings-gemstone/all')
      }*/
      localStorage.setItem("ringId",item_array[index].ringId)
      //console.log(strArray[1]);
      localStorage.setItem("ringsize",item_array[index].ring_size)
      if(item_array[index].ring_text == null){
          localStorage.setItem("ringText",'')
      }else {
          localStorage.setItem("ringText",item_array[index].ring_text)
      }

      if(item_array[index].ring_text == null) {
          localStorage.setItem("ringfont", '')
      } else{
          localStorage.setItem("ringfont", item_array[index].ring_font)
      }
      navigate('/product-ring/'+item_array[index].ringId)
      //navigate('/settings-ring/all')
      //console.log(id_array[index])
    //console.log(index)
  }
  const gotodiamond=(e,index) => {
      e.preventDefault();
      //var id_array = JSON.parse(decodeURIComponent(escape(atob(localStorage.getItem("availableCartProductIds")))));
      var item_array = JSON.parse(decodeURIComponent(escape(atob(localStorage.getItem("productsbag")))));
      //var strArray = id_array[index].split(":");
      console.log(item_array[index].diamond.slug)
      localStorage.setItem("indexvalue",index)

      localStorage.setItem("ringId",item_array[index].ringId)
      localStorage.setItem("ringsize",item_array[index].ring_size)
      localStorage.setItem("ringText",item_array[index].ring_text ?? "")
      localStorage.setItem("ringfont",item_array[index].ring_font ?? "")

      // if(item_array[index].diamond){
          localStorage.setItem("diamondId",item_array[index].diamondId)
          navigate('/settings-diamond/all')
     /* }else{
          localStorage.setItem("gemsId",item_array[index].diamondId)
          navigate('/settings-gemstone/all')
      }*/

      //navigate('/settings-diamond/all')
      //console.log(id_array[index])
    //console.log(index)
  }

  const gotoprodtls=(e,index) => {
         console.log(index);
    e.preventDefault();
    //var id_array = JSON.parse(decodeURIComponent(escape(atob(localStorage.getItem("availableCartProductIds")))));
    var item_array = JSON.parse(decodeURIComponent(escape(atob(localStorage.getItem("productsbag")))));
    //var strArray = id_array[index].split(":");
 
    localStorage.setItem("indexvalue",index)
    localStorage.setItem("diamondId",item_array[index].diamondId)
    localStorage.setItem("ringId",item_array[index].ringId)
    localStorage.setItem("ringsize",item_array[index].ring_size)
    localStorage.setItem("ringText",item_array[index].ring_text ?? "")
    localStorage.setItem("ringfont",item_array[index].ring_font ?? "")
   navigate('/product-details')
}


const swapImage = (_this) => {
  // console.log(_this);
  var shortImageUrl = _this.target.attributes.src.value;
  var prevElementImageSrc = _this.target.previousElementSibling.attributes.src.value;
  _this.target.attributes.src.value = prevElementImageSrc;
  _this.target.previousElementSibling.attributes.src.value = shortImageUrl;
}

  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    
    <div>

        <div className='cart-header'>
            <ul>
                <li  key='shopping_i' className="step-cart step-active">
                    <a href="/cart/">
                        <span>Shopping cart</span>
                    </a>
                </li>
                <li key='checkout_i' className="step-checkout step-inactive">
                    <a href="/checkout/">
                        <span>Checkout</span>
                    </a>
                </li>
                <li key='order_i' className="step-complete step-inactive">
                    <span>Order complete</span>
                </li>
            </ul>
        </div>
        {  existing_cart_length > 0 ? '' :  (
            <div className="empty-cart text-center">

                <p className="cart-empty-heading">
                    <i className="fa-solid fa-cart-shopping"></i>
                    Your cart is currently empty. </p>
                <div className="cart-empty-page-text">Before proceed to checkout you must add some products to your
                    shopping cart.<br/> You will find a lot of interesting products on our "Shop" page.</div>
                <p className="return-to-shop mt-4">
                    <a className="btn btn-primary rounded-0 btn-lg" href="/">
                        Return to Home </a>
                </p>
            </div>
        )
        }


<section className="cartList py-5">
  <div className="container">
    <div className="row g-4">
      <div className="col-md-8"> 
      {
          existing_cart_length>0?

                        <h4 key={'my_cart'} className="text-uppercase mb-4 text-dark">My Cart</h4>
                     :''

            }
          {existing_cart.length>0?

        existing_cart.map( (item,index) => (

         <div data_index={index} key={index} >
         {item.product_type=="compound" ?
             <div   className='card  mb-3 yourcart-item shadow rounded-3'>
                 <div className="card-body">
                   <div className='card-header px-0 bg-transparent border-0 d-flex justify-content-between'>
                       <h3 className="cart-item-heading fw-normal text-dark fs-5 m-0">Engagement Ring (Completed)</h3>
                       <div className='cart-item-action'>
                            
                           <button className='me-2' type="button" onClick={(e) => gotoprodtls(e,index)}  data-bs-toggle="tooltip" data-bs-placement="top" title="View Engagement Ring" aria-label="view">View</button>
                           <button type="button" onClick={()=>removecart(index)} data-bs-toggle="tooltip" data-bs-placement="top" title="Delete from Cart" aria-label="remove">Remove</button>
                       </div>
                   </div>
                     <div className='row'>
                         <div className='col-md-4'>
                             <div className='cart-item-image shadow'>
                                 <img className="item-img img-fluid mask-1 rounded" src={item.ring.front_pic} alt=""/>
                                 <img className="item-img img-fluid mask-2 rounded" onClick={(e)=>{
                                     swapImage(e);
                                 }} src={item.diamond.media_default} alt=""/>
                             </div>
                         </div>
                         <div className='col-md-8'>
                             <ul className="dtl-list">
                                 <li className='mb-2'>
                                    <div className='cart-item-inner'>
                                        <svg className="card-icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 14.9 17.87"><path d="M7.59,2.91,4.64,0l-1,1.09L5.84,3.37a7.33,7.33,0,0,0,1.61,14.5A7.32,7.32,0,0,0,9.34,3.44l2.21-2.35L10.53,0,7.59,2.91ZM7.46,16A5.43,5.43,0,0,1,2,10.66,5.43,5.43,0,0,1,7.47,5.33,5.44,5.44,0,0,1,13,10.66,5.44,5.44,0,0,1,7.47,16Z"></path></svg>
                                        <div className="cart-item-list">
                                            <h5 className="cart-item-title mb-0">
                                                <a href={'/product-ring/'+item.ringId}>    {item.ring.product_name + " " }</a>
                                            </h5>

                                            <div className='cartitem-detail'>
                                                {item.ring.sku_code =! null?
                                                    <div>SKU: {item.ring.sku_code}</div>
                                                    :''}
                                                {item.ring.width =! null?
                                                    <div>Width: {item.ring.width}</div>
                                                    :''}
                                                {item.ring_size =! null?
                                                    <div>Size: {item.ring_size}</div>
                                                    :''}
                                                {item.ring.metal_type =! null?
                                                    <div>Metal: {item.ring.metal_type}</div>
                                                    :''}
                                                 
                                                    <div>Inscription: {item.ring_text}</div>
                                                

                                                <div>Price: <span className='text-primary'>{item.ring.sell_price}</span></div>
                                            </div>

                                        </div>
                                    </div>
                                     <button className='change-item position-absolute end-0 '  onClick={(e) => gotoring(e,index)}>Change</button>

                                </li>  <li className='mb-2'>
                                     <div className='cart-item-inner'>
                                         <svg className="card-icon" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.94 12.42"><path d="M13.46,0h-11L0,3.16l8,9.26,8-9.26L13.46,0ZM8,9.55,2.62,3.38,3.77,1.76h8.4l1.15,1.62Z"></path></svg>
                                         <div className="cart-item-list">
                                             <h5 className="cart-item-title mb-0">
                                                 <a href={'/product-diamond/'+item.diamond.stone_id}>
                                                     {item.diamond.diamond_type} {item.diamond.shape} | {item.diamond.carat}ct
                                                     | {item.diamond.color} | {item.diamond.clarity} {item.diamond.cut} cut
                                                     Diamond
                                                 </a>
                                             </h5>
                                             <div className='cartitem-detail'>
                                                 {item.diamond.sku =! null?
                                                     <div>SKU: {item.diamond.sku}</div>
                                                     :''}
                                                 {item.diamond.lab_company =! null?
                                                     <div>{item.diamond.lab_company}: {item.diamond.lab_certificate_number}</div>
                                                     :''}

                                                 {item.diamond.total_price =! null?

                                                     <div>Price: <span className='text-primary'>{item.diamond.total_price}</span></div>

                                                     :''}
                                             </div>

                                         </div>
                                         
                                     </div>
                                     <button className='change-item position-absolute end-0 '  onClick={(e) => gotodiamond(e,index)}>Change</button>

                                 </li>
                             </ul>
                                   
                         </div>
                     </div>
                     <div> 
                     </div>
                        <div className='float-end text-primary fw-bold'>
                          
                            {currency} {((item.diamond.total_price_val) + (item.ring.sell_price_val)).toFixed(2)}
                        </div>


                     
                 </div>
             </div>
             
        :

             <div   className='card  mb-3 yourcart-item shadow rounded-3'>
                 <div className="card-body">
                     <div className='card-header px-0 bg-transparent border-0 d-flex justify-content-md-between'>
                         <h5></h5>
                         <div className='cart-item-action'>

                             <button className='me-2' type="button" onClick={(e) => navigate(item.slug+item.product_data.sku)}  data-bs-toggle="tooltip" data-bs-placement="top" title="View" aria-label="view">View</button>
                             <button type="button" onClick={()=>removecart(index)} data-bs-toggle="tooltip" data-bs-placement="top" title="Delete from Cart" aria-label="remove">Remove</button>
                         </div>
                     </div>
                     <div className='row'>
                         <div className='col-md-4'>
                             <div className='cart-item-image shadow'>
                                 <img className="item-img img-fluid mask-1 rounded" src={item.product_data.front_pic} alt=""/>

                             </div>
                         </div>
                         <div className='col-md-8'>
                             <ul className="dtl-list">
                                 <li className='mb-2'>
                                     <div className='cart-item-inner'>
                                         <svg className="card-icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 14.9 17.87"><path d="M7.59,2.91,4.64,0l-1,1.09L5.84,3.37a7.33,7.33,0,0,0,1.61,14.5A7.32,7.32,0,0,0,9.34,3.44l2.21-2.35L10.53,0,7.59,2.91ZM7.46,16A5.43,5.43,0,0,1,2,10.66,5.43,5.43,0,0,1,7.47,5.33,5.44,5.44,0,0,1,13,10.66,5.44,5.44,0,0,1,7.47,16Z"></path></svg>
                                         <div className="cart-item-list">
                                             <h5 className="cart-item-title mb-0">
                                                 <a href={item.slug+item.product_data.sku}>    {item.product_data.product_name + " " }</a>
                                             </h5>

                                             <div className='cartitem-detail'>
                                                 {item.product_data.sku_code =! null ?
                                                     <div>SKU: {item.product_data.sku_code}</div>
                                                     :''}
                                                 {item.product_data.total_carat ?
                                                     <div>Carat: {item.product_data.total_carat}</div>
                                                     :''}
                                                 {item.ring_size  ?
                                                     <div>Size: {item.ring_size}</div>
                                                     :''}
                                                 {item.product_data.width =! null?
                                                     <div>Width: {item.product_data.width}</div>
                                                     :''}
                                                 {item.product_data.metal_type =! null ?
                                                     <div>Metal: {item.product_data.metal_type}</div>
                                                     :''}
                                                 {item.ring_text ?
                                                 <div>Inscription: {item.ring_text}</div>
                                                 :''}
                                                 <div>Price: <span className='text-primary'>{item.product_data.sell_price}</span></div>
                                             </div>
                                          

                                         </div>
                                     </div> 

                                 </li>
                             </ul>

                         </div>
                     </div>
                     <div>
                     </div>
                     <div className='float-end text-primary fw-bold'>

                         {currency} {(item.product_data.sell_price_val).toFixed(2)}
                     </div>



                 </div>
             </div>
        
        
        
        }

        </div>

        )):''
      }
      </div>


        {
            existing_cart_length>0?

                <div key={'my_cart_summary'}  className="col-md-4">
                    <CartSummary couponval={couponval}  setcouponval={setCouponval} checkoutbtn={true} />
                </div>   : '' }
       
    </div>


  </div>
</section>



    </div>
  )
}

export default Cart
import React, {Component, useEffect, useState} from "react";
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { DropzoneDialog, DropzoneArea } from "mui-file-dropzone";


import {ToastContainer, toast} from "react-toastify";


import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

//function CustomDesign() {
function CustomDesign() {
      
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [phoneno, setPhoneno] = useState('');
    const [message, setMessage] = useState('');
    const [captcha, setCaptcha] = useState('');

    const handleSubmit = async  (e) => {
        e.preventDefault();

        let user_captcha_value = document.getElementById('user_captcha_input').value;

        if (validateCaptcha(user_captcha_value, false)==true) {
            var formData = new FormData();
            formData.append('request', JSON.stringify({
                name: fullname,
                email: email,
                phone: phoneno,
                captcha: captcha,
                message: message,
                event: "custom_design",
            }));

            var resp = await fetch(process.env.REACT_APP_API_URL+"/api/contact",{
                method: 'POST',
                body: formData
            });
            var data = await resp.json();

            if (data.success === true) {
                toast.success(data.msg);
                setFullname('');
                setPhoneno('');
                setMessage('');
                setEmail('');
                setCaptcha('');
                loadCaptchaEnginge(6);
            } else{
                toast.error(data.msg);
            }
        }

        else {
            toast.error('Captcha does not match');
        }

        //  console.log('Email submitted:', email);
    };



    useEffect(() => {
    loadCaptchaEnginge(6);
    },[]);
        const reloadCaptch = async  (e) => {
            loadCaptchaEnginge(6);
        }
   

        return (
            <>
                <section id="breadcrumbRow">
                    <h2>Custom Design</h2>
                    <div className="container">
                        <div className="row pageTitle m0">
                            <div className="col-12">
                                <h4 className="fleft">Custom Design</h4>
                                <ul className="breadcrumb fright">
                                    <li><a href="index.html">Engagement Rings</a></li>
                                    <li className="active">Custom Design</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </section>
                <section id="" className="contentRowPad">
                    <div className="container">
                        <div className="row">
                            <div className='col-12'>
                                <h3 className="display-6 fs-3 text-center">Submit Your Customize Design</h3>
                            </div>
                            <div className="col-md-6 mx-auto">
                                <form  className="row g-3" onSubmit={handleSubmit}>
                                    <div className="col-12">
                                        <label htmlFor="uName" className="">Your Name</label>
                                        <input type="text" className="form-control" name="name" id="name" value={fullname}
                                               onChange={(e) => setFullname(e.target.value)}
                                               required  />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="uEMail" className="">Email</label>
                                        <input type="email" className="form-control" name="email" id="email" value={email}
                                               onChange={(e) => setEmail(e.target.value)}
                                               required  />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="uPhone" className="">Phone</label>
                                        <input type="tel" className="form-control" name="phone" id="phone" value={phoneno}
                                               onChange={(e) => setPhoneno(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="uRemarks" className="">Remarks</label>
                                        <textarea name="message"  cols="40" rows="5"  id="message" className="form-control" value={message}
                                                  onChange={(e) => setMessage(e.target.value)}
                                                  required  ></textarea>
                                    </div>
                                    <div className='col-12'>
                                        <div className='captcha'> <LoadCanvasTemplateNoReload />
                                            <button className='btn btn-sm p-0' type="button" onClick={(e)=> reloadCaptch()}>Reload Captcha</button>
                                            <div>      <input  id="user_captcha_input" name="user_captcha_input" className='form-control' value={captcha}  onChange={(e) => setCaptcha(e.target.value)} type="text" required/>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-auto">
                                        <button type="submit" className="btn btn-default mb-3">Submit Request</button>
                                    </div>
                                </form>
                            </div>
                            {/* <div className="col-md-6">
                                <DropzoneArea 
                                    onChange={this.handleChange.bind(this)} 
                                    acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                                />
                            </div> */}
                        </div>
                    </div>
                </section>
            </>
        )
    
}

export default CustomDesign
import React, {useState, useEffect, useRef} from "react";
import {Link, useParams} from "react-router-dom";
import {FaThList, FaTh, FaSearchPlus, FaRegHeart, FaHeart, FaTimes, FaGripLines, FaRedoAlt} from "react-icons/fa";
import "./Setting.css";
import {Slider, InputNumber, Radio} from "antd";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import ReactPaginate from "react-paginate";

import ProductItem from "../parts/product_grid";
import ProductItemList from "../parts/product_list";

import RecentProducts from "../parts/recently-products";
import NumericInput from "react-numeric-input";
//import { Slider, Range } from 'rc-slider'
import "rc-slider/assets/index.css";
import FilterSteps from "../parts/filter-steps";
const style = {margin: "40px 8px"};
const style1 = {margin: "40px 8px"};
const labelStyle = {minWidth: "60px", display: "inline-block"};


const marks = {
    0: {
        style: {
            transform: "translateX(-3px)",
        },
        label: "Excellent"
    },
    33.333333: "Very Good",
    66.6667: "Good",
    100: {
        style: {
            right: 0,
            left: "unset",
            transform: "translateX(3px)",
        },
        label: "Fair"
    },
};


/*== Start ResponsiveFilter ==*/

function addClass(arr, element) {
    if (arr.length > 0) {
        for (var i = 0; i < arr.length; i++) {
            document.querySelector(`${element}`).classList.add(arr[i]);
        }
    }
}

function removeClass(arr, element) {
    if (arr.length > 0) {
        for (var i = 0; i < arr.length; i++) {
            document.querySelector(`${element}`).classList.remove(arr[i]);
        }
    }
}


var angle = 0;
var half_of_height = (window.innerHeight / 2);
var pop_up = document.querySelector(".pop-up");

// $(pop_up).draggable();

function btn_click() {
    if (window.matchMedia("(max-width: 767px)").matches) {
        var postModalBody = document.querySelector(".popModal-body");
        //*******************//
        addClass(["filterPopMobile"], "body");
        var pop_up = document.querySelector(".pop-up");
        pop_up.classList.remove("pop-up-top-100");
        pop_up.classList.add("pop-up-bottom-0");
    } else {
        alert("your device width to high");
    }
}

function close_popup() {
    var pop_up = document.querySelector(".pop-up");
    pop_up.removeAttribute("style");
    pop_up.removeAttribute("data-x");
    pop_up.removeAttribute("data-y");
    pop_up.classList.add("pop-up-top-100");
    pop_up.classList.remove("pop-up-bottom-0");
    removeClass(["filterPopMobile"], "body");
}

/*== End ResponsiveFilter ==*/


/*****Filter Button mobile******/


function Promos(props) {




    const mobile_filter_btn =["Wedding Band Type", "Metal","Price"] ;
    const  currency = localStorage.getItem('currency') ?? "RM";
    const [mobilePopup, setMobilePopup] = useState("");

    const [offset, setOffset] = useState(0);
    const [perPage] = useState(20);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    let [orderby, setOrderby] = useState('id');

    let [catresmale, setCatresmale] = useState([]);
    let [catresfemale, setCatresfemale] = useState([]);
    let [metalres, setMetalres] = useState([]);
    let [jesdada, setJesdata] = useState([]);
    let [jescnt, setJescnt] = useState([]);

    //Sub Category
    let [category, setCategory] = useState('Classic');
    let [genderp, setGenderp] = useState('female');
    //Metal
    let [metal, setMetal] = useState('18K White Gold');
    //Set Price
    const [isPrice, setPrice] = useState([200,1000000]);
    const [minPrice, setMinPrice] = useState("200");
    const [maxPrice, setMaxPrice] = useState("1000000");
    const PriceMin = (value: number) => {
        setMinPrice(value);
        // filterTrigger();
    };
    const PriceMax = (value: number) => {
        setMaxPrice(value);
        // filterTrigger();
    };

    const PriceRangeChange = (value: number | [number, number]) => {
        setMinPrice(value[0]);
        setMaxPrice(value[1]);
    };

    const handlePageClick = async (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        //  console.log(selectedPage * perPage)
        setOffset(selectedPage * perPage);
        const scroll_top = document.getElementById("filter-slide");
        if (scroll_top) {

            scroll_top.scrollIntoView({block: "start", behavior: "smooth"});
        }
    };

    const {style} = useParams();
    const {gender} = useParams();
    const {metaltype} = useParams();
    const params = useParams();

    const [isStyleClicked, setStyleClicked] = useState(false);
    const [isGenderClicked, setGenderClicked] = useState(false);
    const [isMetalClicked, setMetalClicked] = useState(false);
    function resetpagination(){
        setCurrentPage(0)
        setOffset(0)
    }
    async function getRingdatas() {
        var flag = 0;
        props.loader(true);
        // var querystr = id.split("=");
        var formData = new FormData();
        var request_data = new FormData();



        request_data.currency = localStorage.getItem('currency');
        request_data.offset = offset;
        request_data.orderby = orderby;

        // /console.log(request_data);




        //Ring
        request_data.type = 'promos';
        formData.append('request',JSON.stringify(request_data));


            let   requestOptions = {
                method: "POST",
                body: formData
            };
            var response = await fetch(process.env.REACT_APP_API_URL+"/api/getproducts", requestOptions);
            var resp = await response.json();
            setJesdata(resp.data);
            setJescnt(resp.count)
            setPageCount(Math.ceil(jescnt / perPage));
            props.loader(false);

    }

    const resetFilter = (e) => {
        setCategory('Classic');
        setMetal('18K White Gold');
        setGenderp('female');
        setPrice(200,1000000)
        setMinPrice(200);
        setMaxPrice(1000000);
        resetpagination()

    };

    useEffect(() => {
        getRingdatas();
        var diamondId = localStorage.getItem("diamondId");
        var gemsId = localStorage.getItem("gemsId");

    }, [category,genderp, metal, isPrice, jescnt,offset]);

//    console.log(ringdada)

    let all_diamond_tab
    let comparison_tab
    let recently_view_tab
    if(window.innerWidth < 768){
        all_diamond_tab = "All"
        comparison_tab = "Compare"
        recently_view_tab = "Recent"
    }  else{
        all_diamond_tab =  "ALL Promos (" + jescnt + ")"
        comparison_tab =  "Comparison"
        recently_view_tab =  "Recently Viewed"
    }

    window.addEventListener('resize', function (){
        if(window.innerWidth < 768){
            all_diamond_tab = "All"
            comparison_tab = "Compare"
            recently_view_tab = "Recent"
        }  else{
            all_diamond_tab =  "ALL Promos"
            comparison_tab =  "Comparison"
            recently_view_tab =  "Recently Viewed"
        }
    })
    return (
        <>

            <section className="filter-sec">



                <div className="container">
                    <div className="row mt-3 d-none">
                        <div className="col-md-9 mx-auto text-center">
                            <h5 className="main-title-text fw-normal">
                                Promos <b>- Viewable In 360° HD</b>
                            </h5>
                            <small data-qa="description_results-gallery" className="description">
                                <p>Our selection of engagement ring settings includes every metal and every style.
                                    Yellow gold, white gold, platinum, and rose gold; vintage, modern, classic or
                                    trendy, Baemont has the perfect engagement ring setting for you. Whether you are
                                    choosing a timeless diamond or a colorful gemstone, we have the ideal engagement
                                    ring setting that will shine as bright. A momentous moment deserves nothing less
                                    than pure excellence, our collection of engagement ring settings ensures that you
                                    are able to present the ideal ring.</p>
                            </small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 fltrPlod-list position-relative">
                            <Tabs
                                defaultActiveKey="all"
                                id="settings-diamond-tabs"
                                className="mb-3"
                                mountOnEnter  unmountOnExit
                            >
                                <Tab eventKey="all" title={all_diamond_tab}>

                                    <Tabs  mountOnEnter  unmountOnExit defaultActiveKey="grid"
                                           id="diamond-tabs"
                                           className="grid-list-btn float-end mb-3 nav nav-tabs position-absolute end-0 top-0">
                                        <Tab eventKey="grid" title={(<FaTh/>)}>
                                            <div className="row">
                                                {(jesdada && jesdada.length > 0) ? jesdada.map((jd) => (

                                                    <div key={jd.sku} className="col-12 col-sm-6 col-md-3 position-relative" >
                                                        <ProductItem loader={props.loader} buttons={true} type="promos" product_id={jd.sku} />
                                                    </div>

                                                )) : (
                                                    <>
                                                        <div className="col-md-12 compare-page">
                                                            <div className="entry-content text-black text-center">
                                                                <div><i
                                                                    className="display-2 fa-regular fa-rings-wedding opacity-25 text-muted"></i>
                                                                    <h3 className="display-5"> We are sorry !</h3>
                                                                    <div className="empty-page-text text-muted">
                                                                        Do drop us a message and let us source the wedding band for you.
                                                                    </div>
                                                                    <p className="return-to-shop"><a
                                                                        className="btn btn-primary" href="/contact-us"> GET IN TOUCH WITH US</a></p></div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                <div className="col-12 mt-5">
                                                    <nav className="d-flex justify-content-center">
                                                        <ReactPaginate previousLabel={"<"} nextLabel={"→"}
                                                                       breakLabel={"..."} breakClassName={"break-me"}
                                                                       pageCount={pageCount} marginPagesDisplayed={2}
                                                                       pageRangeDisplayed={5}
                                                                       onPageChange={handlePageClick}
                                                                       forcePage={currentPage}
                                                                       containerClassName={""}
                                                                       pageClassName={"page-item"}
                                                                       pageLinkClassName={"page-link"}
                                                                       className={"pagination"}
                                                                       previousClassName={"page-item prev-page"}
                                                                       nextClassName={"page-item next-page"}
                                                                       subContainerClassName={"pages pagination"}
                                                                       activeClassName={"active"}
                                                                       activeLinkClassName={"active"}
                                                                       previousLinkClassName={"page-link"}
                                                                       nextLinkClassName={"page-link"}/>
                                                    </nav>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="list" title={(<FaThList/>)}>
                                            <div className="table-responsive">
                                                <table className="table ring-list-table">
                                                    <thead>
                                                    <tr >
                                                        <th>Actual Photo</th>
                                                        <th>Metal Type</th>
                                                        <th>Gender</th>
                                                        <th>Width</th>
                                                        <th>Weight</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {jesdada && jesdada.map((jd) => (
                                                        <ProductItemList loader={props.loader}  key={jd.sku} type="promos" product_id={jd.sku}/>
                                                    ))}
                                                    </tbody>

                                                </table>
                                                <div className="col-12 mt-5">
                                                    <nav className="d-flex justify-content-center">
                                                        <ReactPaginate previousLabel={"←"} nextLabel={"→"}
                                                                       breakLabel={"..."} breakClassName={"break-me"}
                                                                       pageCount={pageCount} marginPagesDisplayed={2}
                                                                       pageRangeDisplayed={5}
                                                                       onPageChange={handlePageClick}
                                                                       forcePage={currentPage}
                                                                       containerClassName={""}
                                                                       pageClassName={"page-item"}
                                                                       pageLinkClassName={"page-link"}
                                                                       className={"pagination"}
                                                                       previousClassName={"page-item prev-page"}
                                                                       nextClassName={"page-item next-page"}
                                                                       subContainerClassName={"pages pagination"}
                                                                       activeClassName={"active"}
                                                                       activeLinkClassName={"active"}
                                                                       previousLinkClassName={"page-link"}
                                                                       nextLinkClassName={"page-link"}/>
                                                    </nav>
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </Tab>

                                <Tab eventKey="recent_view" title={recently_view_tab}>
                                    <div className="row itemList-cont g-2">
                                        <RecentProducts loader={props.loader} type="promos"/>
                                    </div>
                                </Tab>
                            </Tabs>

                        </div>
                    </div>

                </div>
            </section>
        </>
    );
}

export default Promos;

import React, { useState} from 'react'
import {Routes, Route, Navigate,useParams} from 'react-router-dom'

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

 
import Error404 from './Components/pages/Error404'
import Home from './Components/pages/Home'
import Settings from './Components/pages/RingSettings'
import ProdDtls from './Components/pages/ProdDtls'
import About from './Components/pages/About'
import Contact from './Components/pages/Contact'
import ReturnsRefunds from './Components/pages/ReturnsRefunds'
import ShippingPolicy from './Components/pages/ShippingPolicy'
import TermsConditions from './Components/pages/TermsConditions'
import Faq from './Components/pages/Faq'
import Guarantee from './Components/pages/Guarantee'
import PrivacyPolicy from './Components/pages/PrivacyPolicy' 
import DiamondSetting from './Components/pages/DiamondSetting'
import ProdDiamond from './Components/pages/ProdDiamond'
import ProdRing from './Components/pages/ProdRing'
import Cart from './Components/pages/Cart' 
import Orders from './Components/pages/orders'
import Login from './Components/pages/Login'
import ResetPass from './Components/pages/resetpass'
import Registration from './Components/pages/Registration'
import Account from './Components/pages/Account'
import Header from './Components/parts/Header';
import Checkout from './Components/pages/Checkout'
import OrderReceived from './Components/pages/order-received'
import Failure from './Components/pages/Failure'
import FineJwellery from './Components/pages/FineJwellery'
import FineJwellerySearch from './Components/pages/FineJwellerySearch'
import ProdJewellery from './Components/pages/ProdJewellery'
import GemstoneSetting from './Components/pages/GemstoneSetting'
import ProdGems from './Components/pages/ProdGems'
import WeddingBandsSearch from './Components/pages/WeddingBandsSearch'
import ProdBands from './Components/pages/ProdBands'
import ProdPromo from './Components/pages/ProdPromo'
import WeddingBands from './Components/pages/WeddingBands'
import Promos from './Components/pages/Promos'
import Learn from './Components/pages/Learn'
import CustomDesign from './Components/pages/CustomDesign'


import LoadingSpinner from "./Components/parts/loader";


//Static Pages
import EngagementRingsPage from './Components/pages/EngagementRingsPage'
import WeddingBandsPage from './Components/pages/WeddingBandsPage'
import FineJewelryPage from './Components/pages/FineJewelryPage'
global.exports = global = {
    currency : 'RM'
}

function App() {

    const [cartitem, setCartItem] = useState([]) 
    const [updateItem, updateCartItem] = useState({index: "", item: ""})
    const myGlobalVariable = global.myGlobalVariable;
    const [isLoading, setIsLoading] = useState(false);

    const params = useParams();

    //megamenu
    window.onmousemove = e => {

        if (document.querySelectorAll('#header')[0].className == 'header header-1 megamenu-clicked') {
            document.querySelectorAll('#header')[0].classList.remove("megamenu-clicked");
        }

    }
    window.onclick = e => {
        if(e.target.tagName == "A"){

            if(e.target.href.includes('all')) {
                //console.log("reload");
                window.location.replace(e.target.href);

            }
            if(e.target.href.includes('shape')) {
                //console.log("reload");
                window.location.replace(e.target.href);
           
            }
            if(e.target.href.includes('style')) {
                //console.log("reload");
                window.location.replace(e.target.href);
            }

            if(e.target.href.includes('type')) {
                //console.log("reload");
                window.location.replace(e.target.href);
            }

            if(e.target.href.includes('metal')) {
                //console.log("reload");
                window.location.replace(e.target.href);
            }
            if(e.target.href.includes('male')) {
                //console.log("reload");
                window.location.replace(e.target.href);
            }
            if(e.target.href.includes('female')) {
                //console.log("reload");
                window.location.replace(e.target.href);
            }
            window.scrollTo(0, 0)
        }
        //console.log(e.target.className);  // to get the element

        if (e.target.className == 'mobile-parent') {
            document.querySelector('.btn-menu-close').click();
        }
        if (e.target.className == 'site-nav lvl-2') {
            //console.log("hide");
            document.querySelectorAll('#header')[0].classList.add("megamenu-clicked");
        }

        if (e.target.className == 'site-nav parent') {
            //console.log("hide");
            document.querySelectorAll('#header')[0].classList.add("megamenu-clicked");
        }

        if((e.target.parentElement.classList.contains('accordion-button') == true) && (e.target.classList.contains('action-button') == false)  && e.target.nextSibling == null)
        {

                 document.querySelector('.btn-menu-close').click();
         }
        

    }
    
    return (
        <>
            {isLoading ? <LoadingSpinner /> : '' }
        <>


            <Header loader={setIsLoading} cartitem={cartitem} setCartItem={setCartItem} updateItem={updateItem}
                    updateCartItem={updateCartItem}></Header>
            <Routes> 
                <Route path='/' element={<Home loader={setIsLoading}/>}/>
                <Route path='/custom-design' element={<CustomDesign loader={setIsLoading}/>}/>
                {/*Diamond*/}
                <Route path='/settings-diamond/:id' element={<DiamondSetting loader={setIsLoading}/>}/>
                <Route path='/settings-diamond/shape/:shape' element={<DiamondSetting loader={setIsLoading}/>}/>
                <Route path='/product-diamond/:id' element={<ProdDiamond loader={setIsLoading}/>}/>
                <Route path='/product-details'
                       element={<ProdDtls loader={setIsLoading} cartVal={cartitem}  setCartItem={setCartItem} updateItem={updateItem}
                                          updateCartItem={updateCartItem}/>}/>

                {/*Ring*/}
                <Route path='/settings-ring/:id' element={<Settings loader={setIsLoading}/>}/>
                <Route path='/settings-ring/style/:style' element={<Settings loader={setIsLoading}/>}/>
                <Route path='/settings-ring/metal/:metaltype' element={<Settings loader={setIsLoading}/>}/>
                <Route path='/product-ring/:id' element={<ProdRing loader={setIsLoading}/>}/>

                {/*Wedding Bands*/}
                <Route path='/wedding-bands/:gender/:style' element={<WeddingBands loader={setIsLoading}/>}/>
                <Route path='/wedding-bands/:id'
                       element={<WeddingBands loader={setIsLoading} />}/>
                {/*Promos*/}

                <Route path='/promos'
                       element={<Promos loader={setIsLoading} />}/>

                <Route path='/promo/:id'
                       element={<ProdPromo loader={setIsLoading} cartVal={cartitem} setCartItem={setCartItem}/>}/>
                <Route path='/wedding-bands/metal/:metaltype' element={<WeddingBands loader={setIsLoading}/>}/>
                <Route path='/wedding-band/:id' element={<ProdBands loader={setIsLoading}  cartVal={cartitem}  setCartItem={setCartItem} />}/>
                {/*Auth*/}
                <Route path='/login' element={<Login loader={setIsLoading}/>}/>
                <Route path='/reset-password/' element={<ResetPass loader={setIsLoading}/>}/>
                <Route path='/account' element={<Account loader={setIsLoading}/>}/>
                <Route path='/registration' element={<Registration loader={setIsLoading}/>}/>

                {/*Order*/}
                <Route path='/orders' element={<Orders loader={setIsLoading}/>}/>
                <Route path='/order-received/' element={<OrderReceived loader={setIsLoading} cartitem={cartitem} setCartItem={setCartItem}/>}/>
                <Route path='/order-received/:id' element={<OrderReceived loader={setIsLoading} cartitem={cartitem} setCartItem={setCartItem}/>}/>
                <Route path='/cart' element={<Cart loader={setIsLoading} cartitem={cartitem} setCartItem={setCartItem} updateItem={updateItem} updateCartItem={updateCartItem}/>}/>
                <Route path='/checkout' element={<Checkout loader={setIsLoading}/>}/>

                {/*Static Pages*/}
                <Route path='/about-us' element={<About loader={setIsLoading}/>}/>
                <Route path='/contact-us' element={<Contact loader={setIsLoading}/>}/>
                <Route path='/privacy-policy' element={<PrivacyPolicy loader={setIsLoading}/>}/>
                <Route path='/returns-n-refunds' element={<ReturnsRefunds loader={setIsLoading}/>}/>
                <Route path='/shipping-policy' element={<ShippingPolicy loader={setIsLoading}/>}/>
                <Route path='/baemont-terms-conditions' element={<TermsConditions loader={setIsLoading}/>}/>
                <Route path='/faqs' element={<Faq loader={setIsLoading}/>}/>
                <Route path='/guarantee/' render={<Guarantee loader={setIsLoading}/>}/>

                <Route path='/fine-jewellery/:id' element={<FineJwellery loader={setIsLoading}/>}/>
                <Route path='/fine-jewellery/type/:type' element={<FineJwellery loader={setIsLoading}/>}/>
                <Route path='/fine-jewellery/metal/:metaltype' element={<FineJwellery loader={setIsLoading}/>}/>
                <Route path='/learn' element={<Learn loader={setIsLoading}/>}/>
                <Route path='/fine-jewellery-search/:id' element={<FineJwellerySearch loader={setIsLoading}/>}/>
                <Route path='/product-jewellery/:id'
                       element={<ProdJewellery loader={setIsLoading} cartVal={cartitem} setCartItem={setCartItem}/>}/>
              {/*  <Route path='/settings-gemstone' element={<GemstoneSetting/>}/>
                <Route path='/product-gemstone/:id' element={<ProdGems/>}/>*/}
                <Route path='/wedding-bands-search/:id' element={<WeddingBandsSearch loader={setIsLoading}/>}/>
                <Route path='/product-bands/:id' element={<ProdBands loader={setIsLoading} cartVal={cartitem} setCartItem={setCartItem}/>}/>



                <Route path='/our-engagement-rings' element={<EngagementRingsPage loader={setIsLoading}/>}/>
                <Route path='/our-wedding-bands' element={<WeddingBandsPage loader={setIsLoading}/>}/>
                <Route path='/our-fine-jewelry' element={<FineJewelryPage loader={setIsLoading}/>}/>
                <Route path='*' element={<Error404/>}/>
                {/*<Route path='*' element={<Navigate to="/"/>}/>*/}
                 {/*<Route path='/test' element={<Tester />} />*/}
            </Routes>

            <ToastContainer />
        </>
            </>
            );


}

export default App;

import React, {useEffect, useState} from "react";
import {Link, useParams, useSearchParams, useNavigate, Navigate, Route, useLocation} from "react-router-dom";
import $ from 'jquery';



// Create your functional component:
function FilterStep(props) {
    const currency = localStorage.getItem("currency");

    function jquery_trigger(){
        $(function () {

        $(".ring_step_click ").on("click", function (e) {
            $(".ring_step_box").toggleClass("active");
        });
            $(".diamond_step_click").on("click", function (e) {
                $(".diamond_step_box").toggleClass("active");
            });
            
        $(document).on("click", function (e) {
            if ($(e.target).is(".ring_step_click, .ring_step_box") === false) {
                $(".ring_step_box").removeClass("active");
            }
            if ($(e.target).is(".diamond_step_click, .diamond_step_box") === false) {
                $(".diamond_step_box").removeClass("active");
            }
        });


        });
    }

    let navigate = useNavigate();
    const current_selected = props.active;
    const  [activeStep, SetActiveStep] = useState(current_selected)
    const [diamondSelected, setDiamondSelected] = useState([]);
    const [firstSelected, setFirstSelectedd] = useState('1');
    var diamondId = localStorage.getItem("diamondId");
    var ringId = localStorage.getItem("ringId");
    
    const [ringSelected, setRingSelected] = useState([]);
    async function getDiamondData() { 

        if (diamondId != null) {
            var formData = new FormData();
            var requestdata = {
                id: diamondId,
                type: 'compare',
                currency: currency,
            };
            formData.append("request", JSON.stringify(requestdata));
            var requestOptions = {
                method: "POST",
                body: formData
            };

            var resp = await fetch(process.env.REACT_APP_API_URL + "/api/product_details", requestOptions);

            var data = await resp.json();
            setDiamondSelected(data.data);

        }
    }
    const removeDiamondData = (e) => {
        localStorage.removeItem("diamondId");
        setDiamondSelected([]);

        setTimeout(function (){
            navigate("/settings-diamond/all");
        }, 2000);
        
    };
    const selectDiamond = (value) => {
        localStorage.setItem("diamondId", value);

        var ringdata = localStorage.getItem("ringId");
 
        if (ringdata !== null) {
            navigate("/product-details");
        } else {
            navigate("/settings-diamond/all");
        }
 
    };


    async function getRingData() {
        var ringId = localStorage.getItem("ringId");
        var ringsize = localStorage.getItem("ringsize") ?? 0;
        if (ringId !== null) {
            var formData = new FormData();
            var requestdata = {
                id: ringId,
                type: 'ring',
                currency: currency,
                size: ringsize,
            };
            formData.append("request", JSON.stringify(requestdata));
            var requestOptions = {
                method: "POST",
                body: formData
            };

            var resp = await fetch(process.env.REACT_APP_API_URL + "/api/product_details", requestOptions);

            var data = await resp.json(); 
            setRingSelected(data.data);

        }    

    }

    const removeRingData = (e) => {
        localStorage.removeItem("ringId");
        setRingSelected([]);
       setTimeout(function (){
           navigate("/settings-ring/all");
       }, 2000);
    };

    const selectRing = (value) => {

        var ringdata = localStorage.getItem("ringId");

        if (ringdata != null) {
            navigate("/product-details");
        } else {
            navigate("/settings-ring/all");
        }

    };

    const first_step_check = () => {
         const first_tab = localStorage.getItem("ChooseEng") ?? "1";
        setFirstSelectedd(first_tab);
    };



    useEffect(()=> {
        getDiamondData();
        getRingData();
        first_step_check();
    },[firstSelected])
    
    useEffect(()=> {
        jquery_trigger();
    },[diamondSelected, ringSelected])
    return (
        <>

            <div className="funnelCenter" pagetype="GalleryPage">
                <div className="funnelContainer" data-qa="funnel_container">
                    {firstSelected === "1" ? (
                     <>
                         <div className={activeStep === 1? 'funnelPart--Omb4B  stepOne--aNhFm active--bcso0':'funnelPart--Omb4B  stepOne--aNhFm'}
                              data-qa="funnel_step_stone">
                             <div className="funnelPartContent--Tenfk">
                                 <Link to={"/settings-diamond/all"} className="stepHead--mnSYI  stepOne--RZqJG">
                                     <div className="stepNumber--Vl0cC">1</div>
                                     <div className="stepTitle--VTN7H" data-qa="funnel_step_title_stone">
                                         <div className="stepTitleFirst--WuFot">Choose a</div>
                                         <div className="stepTitleSecond--I3t9t"
                                              data-qa="funnel_step_title_stone">Diamond</div>
                                     </div>
                                 </Link>
                                 <div className="stepData--lAuzk stone--biv4R">
                                     <div className="">

                                               <span>

                                                            {diamondId !== null ? (
                                                                <>
                                                                    <i className="diamond_step_click fa-solid fa-check"></i>
                                                                    <div className="diamond_step_box">
                                                                        <div className="d-inline-block align-top ">
                                                                            <div className="d-inline-block me-2 pt-1 text-end">
                                                                                <div className="step_box_price">
                                                                                    {diamondSelected.total_price}
                                                                                </div>

                                                                                <button onClick={(e) => {
                                                                                    navigate('/settings-diamond/all');
                                                                                }}
                                                                                        className="border-0 bg-transparent p-0 text-capitalize text-muted  ">Change
                                                                                </button>
                                                                                <span> | </span>
                                                                                <button onClick={(e) => {
                                                                                    removeDiamondData(e);
                                                                                }}
                                                                                        className="border-0 bg-transparent p-0 text-capitalize text-muted  ">Remove
                                                                                </button>
                                                                            </div>
                                                                            <div className="step_box_img d-inline-block align-top">
                                                                                <img style={{width: 48, height: 48}}
                                                                                     className="rounded-3 border shadow img-fluid list-img list-diamond"
                                                                                     src={diamondSelected.media_default}
                                                                                     alt={diamondSelected.stone_id}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <img className="img-fluid list-img list-diamond"
                                                                         src={window.location.origin + "/assets/img/static/steps/1.png"}
                                                                         alt=""/>
                                                                </>
                                                            )}

                                            </span>
                                     </div>
                                 </div>
                                 <div className="funnelRightChevron--pcUVq"></div>
                             </div>
                         </div>
                         <div className={activeStep === 2? 'funnelPart--Omb4B stepTwo--q5xNF active--bcso0':'funnelPart--Omb4B stepTwo--q5xNF'}                         data-qa="funnel_step_jewel">
                             <div className="funnelPartContent--Tenfk">
                                 <Link to={'/settings-ring/all'} className="stepHead--mnSYI stepTwo--IYEDY">
                                     <div className="stepNumber--Vl0cC">2</div>
                                     <div className="stepTitle--VTN7H" data-qa="funnel_step_title_jewel">
                                         <div className="stepTitleFirst--WuFot">Choose a</div>
                                         <div className="stepTitleSecond--I3t9t"
                                              data-qa="funnel_step_title_jewel">Setting</div>
                                     </div>
                                 </Link>
                                 <div className="stepData--lAuzk stepTwo--cP9f4">
                                     <div className="">

                                               <span>

                                             {ringId !== null  ? (
                                                 <>
                                                     <i className="ring_step_click fa-solid fa-check"></i>
                                                     <div className="ring_step_box">
                                                         <div
                                                             className="d-inline-block align-top ">
                                                             <div className="d-inline-block me-2 pt-1 text-end">
                                                                 <div className="step_box_price">
                                                                     {ringSelected.sell_price}
                                                                 </div>

                                                                 <button onClick={(e) => {
                                                                     navigate('/settings-ring/all');
                                                                 }}
                                                                         className="border-0 bg-transparent p-0 text-capitalize text-muted  ">Change
                                                                 </button>

                                                                 <span> | </span>
                                                                 <button
                                                                     onClick={(e) => {
                                                                         removeRingData(e);
                                                                     }}
                                                                     className="border-0 bg-transparent p-0 text-capitalize text-muted  ">Remove
                                                                 </button>
                                                             </div>
                                                             <div
                                                                 className="d-inline-block step_box_img align-top">
                                                                 <img
                                                                     style={{
                                                                         width: 48,
                                                                         height: 48
                                                                     }}
                                                                     className="rounded-3 border shadow img-fluid list-img list-diamond"
                                                                     src={ringSelected.front_pic}
                                                                     alt={ringSelected.sku}/>
                                                             </div>
                                                         </div>
                                                     </div>
                                                 </>
                                             ) : (
                                                 <>
                                                     <img
                                                         className="img-fluid list-img list-diamond"
                                                         src={window.location.origin + "/assets/img/static/steps/2.png"}
                                                         alt=""/>
                                                 </>
                                             )}
                                            </span>
                                     </div>
                                 </div>
                                 <div className="funnelRightChevron--pcUVq"></div>
                             </div>
                         </div>
                     </>
                    ): ""}
                    {firstSelected === '2' ? (
                    <>
                        <div className={activeStep === 2? 'funnelPart--Omb4B stepTwo--q5xNF active--bcso0':'funnelPart--Omb4B stepTwo--q5xNF'}                         data-qa="funnel_step_jewel">
                            <div className="funnelPartContent--Tenfk">
                                <Link to={'/settings-ring/all'} className="stepHead--mnSYI stepTwo--IYEDY">
                                    <div className="stepNumber--Vl0cC">1</div>
                                    <div className="stepTitle--VTN7H" data-qa="funnel_step_title_jewel">
                                        <div className="stepTitleFirst--WuFot">Choose a</div>
                                        <div className="stepTitleSecond--I3t9t"
                                             data-qa="funnel_step_title_jewel">Setting</div>
                                    </div>
                                </Link>
                                <div className="stepData--lAuzk stepTwo--cP9f4">
                                    <div className="">

                                               <span>

                                             {ringId !== null  ? (
                                                 <>
                                                     <i className="ring_step_click fa-solid fa-check"></i>
                                                     <div className="ring_step_box">
                                                         <div
                                                             className="d-inline-block align-top ">
                                                             <div className="d-inline-block me-2 pt-1 text-end">
                                                                 <div className="step_box_price">
                                                                     {ringSelected.sell_price}
                                                                 </div>

                                                                 <button onClick={(e) => {
                                                                     navigate('/settings-ring/all');
                                                                 }}
                                                                         className="border-0 bg-transparent p-0 text-capitalize text-muted  ">Change
                                                                 </button>

                                                                 <span> | </span>
                                                                 <button
                                                                     onClick={(e) => {
                                                                         removeRingData(e);
                                                                     }}
                                                                     className="border-0 bg-transparent p-0 text-capitalize text-muted  ">Remove
                                                                 </button>
                                                             </div>
                                                             <div
                                                                 className="d-inline-block step_box_img align-top">
                                                                 <img
                                                                     style={{
                                                                         width: 48,
                                                                         height: 48
                                                                     }}
                                                                     className="rounded-3 border shadow img-fluid list-img list-diamond"
                                                                     src={ringSelected.front_pic}
                                                                     alt={ringSelected.sku}/>
                                                             </div>
                                                         </div>
                                                     </div>
                                                 </>
                                             ) : (
                                                 <>
                                                     <img
                                                         className="img-fluid list-img list-diamond"
                                                         src={window.location.origin + "/assets/img/static/steps/2.png"}
                                                         alt=""/>
                                                 </>
                                             )}
                                            </span>
                                    </div>
                                </div>
                                <div className="funnelRightChevron--pcUVq"></div>
                            </div>
                        </div>
                        <div className={activeStep === 1? 'funnelPart--Omb4B  stepOne--aNhFm active--bcso0':'funnelPart--Omb4B  stepOne--aNhFm'}
                             data-qa="funnel_step_stone">
                            <div className="funnelPartContent--Tenfk">
                                <Link to={"/settings-diamond/all"} className="stepHead--mnSYI  stepOne--RZqJG">
                                    <div className="stepNumber--Vl0cC">2</div>
                                    <div className="stepTitle--VTN7H" data-qa="funnel_step_title_stone">
                                        <div className="stepTitleFirst--WuFot">Choose a</div>
                                        <div className="stepTitleSecond--I3t9t"
                                             data-qa="funnel_step_title_stone">Diamond</div>
                                    </div>
                                </Link>
                                <div className="stepData--lAuzk stone--biv4R">
                                    <div className="">

                                               <span>

                                                            {diamondId !== null ? (
                                                                <>
                                                                    <i className="diamond_step_click fa-solid fa-check"></i>
                                                                    <div className="diamond_step_box">
                                                                        <div className="d-inline-block align-top ">
                                                                            <div className="d-inline-block me-2 pt-1 text-end">
                                                                                <div className="step_box_price">
                                                                                    {diamondSelected.total_price}
                                                                                </div>

                                                                                <button onClick={(e) => {
                                                                                    navigate('/settings-diamond/all');
                                                                                }}
                                                                                        className="border-0 bg-transparent p-0 text-capitalize text-muted  ">Change
                                                                                </button>
                                                                                <span> | </span>
                                                                                <button onClick={(e) => {
                                                                                    removeDiamondData(e);
                                                                                }}
                                                                                        className="border-0 bg-transparent p-0 text-capitalize text-muted  ">Remove
                                                                                </button>
                                                                            </div>
                                                                            <div className="step_box_img d-inline-block align-top">
                                                                                <img style={{width: 48, height: 48}}
                                                                                     className="rounded-3 border shadow img-fluid list-img list-diamond"
                                                                                     src={diamondSelected.media_default}
                                                                                     alt={diamondSelected.stone_id}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <img className="img-fluid list-img list-diamond"
                                                                         src={window.location.origin + "/assets/img/static/steps/1.png"}
                                                                         alt=""/>
                                                                </>
                                                            )}

                                            </span>
                                    </div>
                                </div>
                                <div className="funnelRightChevron--pcUVq"></div>
                            </div>
                        </div></>
                    ): ""}
 

                    <div className={activeStep === 3? 'funnelPart--Omb4B stepThree--KVTLO notAvailable--_EGWR active--bcso0':'funnelPart--Omb4B stepThree--KVTLO notAvailable--_EGWR'}
                         data-qa="funnel_step_complete">
                        <div className="funnelPartContent--Tenfk overflow-hidden">
                            <div className="stepHead--mnSYI">
                                <div className="stepNumber--Vl0cC">3</div>
                                <div className="stepTitle--VTN7H" data-qa="funnel_step_title_complete">
                                    <div className="stepTitleFirst--WuFot">Complete</div>
                                    <div className="stepTitleSecond--I3t9t"
                                         data-qa="funnel_step_title_complete">Ring
                                    </div>
                                </div>
                            </div>
                            <div className="completeStepData--p92Ok">
                                <div className="">
                                            <span>
                                                           <img className="img-fluid list-img list-ring"
                                                                src={window.location.origin + "/assets/img/static/steps/3.png"}
                                                                alt=""/>
                                                </span>
                                </div>
                            </div>
                            <div className="funnelRightChevron--pcUVq"></div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    );
}


export default FilterStep
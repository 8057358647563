import React, {useEffect, useState} from "react";



import ProductItem from "../parts/product_grid";

//localStorage.removeItem("indexvalue")


function RecentProducts(props) {
    let [responsedata, setResponsedata] = useState([]);

    async function GetRecentProducts(){
        var formData = new FormData();
        var customer_id = localStorage.getItem('customer_id');
        //console.log(customer_id);
        let uuid_val;
        if(customer_id){
            uuid_val = customer_id;
        } else{
            uuid_val = -1;
        }
        var requestdata = {
            uuid: uuid_val,
            type: props.type,
            action: 'list',
        };
        formData.append("request", JSON.stringify(requestdata));
        var requestOptions = {
            method: "POST",
            body: formData
        };
        var resp = await fetch(process.env.REACT_APP_API_URL+"/api/recent_view", requestOptions);

        var data = await resp.json();
        setResponsedata(data.data);
        //console.log(data);
        //console.log(data.data);
        //console.log("recently view updated");
    }

    
    useEffect(() => {
        GetRecentProducts();
    });
    return (
        <>
        
            {(responsedata.length > 0) ? responsedata.map((jd,index) => (

                    <div  key={index}  className="col-md-3 position-relative" >
                    <ProductItem loader={props.loader} type={jd.type} product_id={jd.product_id} />
                    </div>

            )) : (
                <div className="col-md-12 compare-page">
                    <div className="entry-content text-black text-center">
                        <div>
                            <i className="display-2 fa-eye fas opacity-25 text-muted"></i>
                            <h3 className="display-5"> Recently no view.</h3>
                            <div className="empty-page-text text-muted">No products viewed You must view some
                                products.
                            </div>
                            <p className="return-to-shop"><a className="btn btn-primary" href="/"> Return to Home </a>
                            </p></div>
                    </div>
                </div> 
            )}
        </>
    )




}

export default RecentProducts
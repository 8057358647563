import React, {useState, useEffect} from "react";
import {Link, useSearchParams} from "react-router-dom";

import {Country, State} from "country-state-city";

import {
    FaThList,
    FaTh,
    FaSearchPlus,
    FaRegHeart,
    FaHeart,
    FaStar,
    FaPlus,
    FaTimes,
    FaAngleLeft,
    FaAngleRight
} from "react-icons/fa";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import {styled} from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from "@mui/material/Typography";

import CartSummary from "./CartSummary";
import PaymentInit from "../parts/stripeBtn";

import {Elements} from "@stripe/react-stripe-js";


import {loadStripe} from "@stripe/stripe-js";
import $ from "jquery";

const label = {inputProps: {"aria-label": "Checkbox demo"}};
const Android12Switch = styled(Switch)(({theme}) => ({
    padding: 8,
    width: 70,
    height: 40,
    "& .MuiSwitch-switchBase": {
        //margin: 1,
        //padding: 0,
        //transform: 'translateX(6px)',
        "&.Mui-checked": {
            right: "10px",
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#8b2845",
            },
        },
        "&:not(.Mui-checked)": {
            filter: "drop-shadow(0 0 1px #000)",
        },
    },
    "& .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#000" : "#8b2845",
        borderRadius: 22 / 1,
        width: "100%",
        "&:before, &:after": {
            //content: '""',
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            fontSize: "11px",
            fontWeight: "bold",
            width: 25,
            height: 16,
        },
        "&:before": {
            content: "\"ON\"",
            color: "#fff",
            textAlign: "left",
            /* backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
              theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`, */
            left: 13,
        },
        "&:after": {
            content: "\"OFF\"",
            color: "#fff",
            textAlign: "right",
            //backgroundColor: '#f00',
            /* backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
              theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`, */
            right: 11,
        },
    },
    "& .MuiSwitch-thumb": {
        backgroundColor: theme.palette.mode === "dark" ? "#001e3c" : "#ffffff",
        boxShadow: "none",
        //opacity: 0,
        width: 22,
        height: 22,
        margin: 0,
        marginLeft: 0,
    },
}));

function Checkout(props) {
    var customer_id = localStorage.getItem("customer_id");
    if (customer_id == null) {
        window.location.href = window.location.origin + "/login";
    }
    var preemail = localStorage.getItem("email");
    var premobile = localStorage.getItem("mobile");
    const [emailCheckout, setEmailCheckout] = useState();
    const [mobileCheckout, setMobileCheckout] = useState();
    const [signupforOffer, setSignupforOffer] = useState(true);

    const [finalemail, setFinalemail] = useState();
    const [finalmobile, setFinalmobile] = useState();

    const [billadd1, setBilladd1] = useState();
    const [billadd2, setBilladd2] = useState();
    const [billcounty, setBillcountry] = useState();
    const [billstate, setBillstate] = useState();
    const [billcity, setBillcity] = useState();
    const [billzip, setBillzip] = useState();

    const [shipadd1, setShipadd1] = useState();
    const [shipadd2, setShipadd2] = useState();
    const [shipcounty, setShipcountry] = useState();
    const [shipstate, setShipstate] = useState();
    const [shipcity, setShipcity] = useState();
    const [shipzip, setShipzip] = useState();

    const [paymentMode, setPaymentMode] = useState();
    const [customer, setCustomer] = useState();
    const [customername, setCustomername] = useState();
    const [customerfname, setCustomefrname] = useState();
    const [customerlname, setCustomelrname] = useState();
    const [deliveryguide, setDeliveryguide] = useState();
    const [couponval, setCouponval] = useState();
    const [taxnval, setTaxnval] = useState();
    let [disable, setDisable] = useState(false);
    const [contactinfo, setContactinfo] = useState(true);
    var emailval = "";
    var mobileval = "";

    function checkBlank() {

        emailval = $("#uEmail").val();
        mobileval = $("#uMobile").val();
        //console.log(emailval)
        var  contactinfo_error = 0;
        if (!mobileval) {
            contactinfo_error +=1;
            $("#uMobile").focus();
        }
        if (!emailval) {
            contactinfo_error +=1;
            $("#uEmail").focus();
        }
        
        if (contactinfo_error === 0) {
            $("#checkout_email").val(emailval);
            $("#checkout_mobile").val(mobileval);
            setFinalemail(emailval);
            setFinalmobile(mobileval);
            setContactinfo(false);
            setDisable(true)
        }


        setCustomer(customer_id);

        let firstname, lastname;
        if (localStorage.getItem("firstname") !== "null") {
            firstname = localStorage.getItem("firstname");
        } else {
            firstname = "";
        }
        if (localStorage.getItem("lastname") !== "null") {
            lastname = localStorage.getItem("lastname");
        } else {
            lastname = "";
        }

        setCustomername(firstname + " " + lastname);
        setCustomefrname(firstname);
        setCustomelrname(lastname);
        // if (!emailCheckout ==''){
        //     setDisable(true)
        //     setDisableEmail(false)
        // }
    }

    var existing_cart = localStorage.getItem("productsbag") == null ? [] : JSON.parse(decodeURIComponent(escape(atob(localStorage.getItem('productsbag')))));
    var total_price = 0;
    //console.log(JSON.stringify(existing_cart))
    var ids = [];
    var product_type = [];

    for (var i = 0; i < existing_cart.length; i++) {
        if (existing_cart[i]["product_type"] == "compound") {

            ids[i] = existing_cart[i]["ring"]["sku"] + "#" + existing_cart[i]["diamond"]["stone_id"] + "@" + existing_cart[i]["ring_size"] + "ringtext" + existing_cart[i]["ring_text"] + "styles" + existing_cart[i]["ring_font"];
            product_type[i] = "compound";
        } else {
            ids[i] = existing_cart[i]["product_data"]["sku"] + "@" + existing_cart[i]["ring_size"] + "ringtext" + existing_cart[i]["ring_text"] + "styles" + existing_cart[i]["ring_font"]+ "type" + existing_cart[i]["type"];
            product_type[i] = "simple";
        }
    }
    //console.log('JSON')
    //console.log(JSON.stringify(ids))
    /* const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
      setDisable(!disable);
    }; */
    let [billAdd, setBillAdd] = useState(false);
    let [address_check, setAddress_check] = useState(false);
    let [shippingdiff, setShippingdiff] = useState(false);
    let [checkout, setCheckout] = useState(false);


    //Address
    let [fieldCountrycode, setFieldCountrycode] = useState([]);
    let [fieldState, setFieldState] = useState([]);


    const Countries = Country.getAllCountries();

    // availabel_country
    let [store_country, setStoreCountry] = useState([]);
    const available_country = async () => {
        var CountryData = new FormData();
        CountryData.append("request", JSON.stringify({
            action: "available_country",
            currency: localStorage.getItem('currency') ?? "RM"
        }));

        var available_country_res = await fetch(process.env.REACT_APP_API_URL + "/api/setting", {
            method: "POST",
            body: CountryData
        });
        var available_country_json = await available_country_res.json();
        setStoreCountry(available_country_json.data);
    };


    useEffect(() => {
        available_country();

    }, []);
    /*
        const store_country = ["KH", "MY", "SG"];*/
    const store_country_data = [];

    for (var ci = 0; ci < Countries.length; ci++) {

        if (store_country.includes(Countries[ci].isoCode)) {
            store_country_data.push(Countries[ci]);
        }
    }

    const FetchStates = event => {

        const countryCode = event.currentTarget.value;
        setFieldCountrycode(countryCode);
        setFieldState(State.getStatesOfCountry(countryCode));
        localStorage.setItem("billing_country", countryCode);


    };


    const checkZipcode = event => {
        console.log(event.target.value);
        console.log(fieldCountrycode);
        //isValidZipcode('7521785-952-520', 'PT');

    };

    //Address

    //const showshipping = () => {
    function showshipping() {
        //console.log("nilanjan")
        //$('#shipping').show();
        setShippingdiff(!shippingdiff);
    }

    function StephandleChange() {
        var bill_address1 = $("#bill_address1").val();
        var bill_address2 = $("#bill_address2").val();
        var bill_country = $("#bill_country").val();
        var bill_state = $("#bill_state").val();
        var bill_city = $("#bill_city").val();
        var bill_zip = $("#bill_zip").val();

        if (bill_address1.length === 0) {
            $("#bill_address1").focus();
            return false;
        } else if (bill_country === "") {
            $("#bill_country").focus();
            return false;
        } else if (bill_state === "") {
            $("#bill_state").focus();
            return false;
        } else if (bill_city.length === 0) {
            $("#bill_city").focus();
            return false;
        } else if (bill_zip.length === 0) {
            $("#bill_zip").focus();
            return false;
        }


        var ship_address1 = $("#ship_address1").val();
        var ship_address2 = $("#ship_address2").val();
        var ship_country = $("#ship_country").val();
        var ship_state = $("#ship_state").val();
        var ship_city = $("#ship_city").val();
        var ship_zip = $("#ship_zip").val();
        var instruction = $("#instruction").val();


        setBilladd1(bill_address1);
        setBilladd2(bill_address2);
        setBillcountry(bill_country);
        setBillstate(bill_state);
        setBillcity(bill_city);
        setBillzip(bill_zip);

        if (shippingdiff) {

            if (ship_address1.length === 0) {
                $("#ship_address1").focus();
                return false;
            } else if (ship_country === "") {
                $("#ship_country").focus();
                return false;
            } else if (ship_state === "") {
                $("#ship_state").focus();
                return false;
            } else if (ship_city.length === 0) {
                $("#ship_city").focus();
                return false;
            } else if (ship_zip.length === 0) {
                $("#ship_zip").focus();
                return false;
            }


            setShipadd1(ship_address1);
            setShipadd2(ship_address2);
            setShipcountry(ship_country);
            setShipstate(ship_state);
            setShipcity(ship_city);
            setShipzip(ship_zip);


        } else {
            setShipadd1(bill_address2);
            setShipadd2(bill_address2);
            setShipstate(bill_state);
            setShipcity(bill_city);
            setShipzip(bill_zip);

        }


        setDeliveryguide(instruction);
        setDisable(!disable);
        setCheckout(true);

    }

    const [clientSecret, setClientSecret] = useState();

    async function payment_start(e) {
        props.loader(true)

        setPaymentMode(e.currentTarget.value);
        let payment_mode = e.currentTarget.value;

        const domain_url =  window.location.protocol + '//' + window.location.host;
        const price_data_send = {
            "domain_url": domain_url,
            "params": ids,
            "paymentIntent": clientSecret,
            "pay_mode": payment_mode,
            "bill_firstname": customerfname,
            "bill_lastname": customerlname,
            "customer_email": finalemail,
            "customer_mobile": finalmobile,
            "bill_address": billadd1,
            "bill_address2": billadd2,
            "bill_country": billcounty,
            "bill_state": billstate,
            "bill_city": billcity,
            "bill_zip": billzip,
            "ship_address": shipadd1,
            "ship_address2": shipadd2,
            "ship_country": shipcounty,
            "ship_state": shipstate,
            "ship_city": shipcity,
            "ship_zip": shipzip,
            "customer_id": customer,
            "ship_to_diffrent_address": shippingdiff,
            "product_type": product_type,
            "signupforOffer":signupforOffer,
            "instructions": deliveryguide,
            "payment_response": "",
            "currency": localStorage.getItem("currency") ?? "RM",
            "coupon_code": localStorage.getItem("coupon_val") ?? "",
            "tax": localStorage.getItem("billing_country") ?? "",

        };

        localStorage.setItem("payment_data", JSON.stringify(price_data_send));




        if (payment_mode === "bank") {
            props.loader(false)

            //console.log("bank");
        }
        if (payment_mode === "stripe") {
            const stripe = require("stripe")(process.env.REACT_APP_Strip_secret_key);

            var formData = new FormData();
            formData.append("request", JSON.stringify({
                "stripe_checkout": price_data_send
            }));
            var requestOptions = {
                method: "POST",
                body: formData
            };
            var resp = await fetch(process.env.REACT_APP_API_URL+"/payment/stripe", requestOptions);

            var data = await resp.json();

            props.loader(false)
            const YOUR_DOMAIN =  window.location.protocol + '//' + window.location.host;
            const session = await stripe.checkout.sessions.create(data);
            localStorage.setItem("strip_session_id", session.id);

            const paymentIntent = await stripe.paymentIntents.create({
                amount: data.line_items[0].price_data.unit_amount,
                currency: data.line_items[0].price_data.currency,
                automatic_payment_methods: {enabled: true},
            });

           // console.log(paymentIntent.client_secret);
            setClientSecret(paymentIntent.client_secret);
        }

    }

    const stripePromise = loadStripe(process.env.REACT_APP_Strip_pub_key);

    const options = { 
        // passing the client secret obtained in step 3
        clientSecret: clientSecret,

    };

    const [searchParams, setSearchParams] = useSearchParams();

 

    async function paymentViaBank(e) {
        props.loader(true)
        const price_data_send = {
            "params": ids,
            "paymentIntent": clientSecret,
            "pay_mode": "bank",
            "bill_firstname": customerfname,
            "bill_lastname": customerlname,
            "customer_email": finalemail,
            "customer_mobile": finalmobile,
            "bill_address": billadd1,
            "bill_address2": billadd2,
            "bill_country": billcounty,
            "bill_state": billstate,
            "bill_city": billcity,
            "bill_zip": billzip,
            "ship_address": shipadd1,
            "ship_address2": shipadd2,
            "ship_country": shipcounty,
            "ship_state": shipstate,
            "ship_city": shipcity,
            "ship_zip": shipzip,
            "customer_id": customer,
            "ship_to_diffrent_address": shippingdiff,
            "product_type": product_type,
            "signupforOffer":signupforOffer,
            "instructions": deliveryguide,
            "payment_response": document.getElementById('bank_transfer_ref').value,
            "currency": localStorage.getItem("currency") ?? "RM",
            "coupon_code": localStorage.getItem("coupon_val") ?? "",
            "tax": localStorage.getItem("billing_country") ?? "",

        };

        var formData = new FormData();
        formData.append("request", JSON.stringify({
            "checkout": price_data_send
        }));

        var requestOptions = {
            method: "POST",
            body: formData
        };


        var resp = await fetch(process.env.REACT_APP_API_URL + "/payment/checkout", requestOptions);

        var data = await resp.json();

        //console.log(data);

         if(data.success == true) {
           var order_id = btoa(data.data);
            props.loader(false)
         window.location.href = "/order-received/" + order_id
        }
    }

    return (
        <>
          
                <>
                    {existing_cart && existing_cart.length ? (
                        <div>
                            <section className="bg-light py-2">
                                <div className="container">
                                    <div className="row pageTitle m0">
                                        <div className="col-12">
                                            <Link className="theme-color text-uppercase mb-2" to="/cart"><small
                                                className="d-flex align-items-center"><FaAngleLeft/>&nbsp; Back to cart</small></Link>
                                            <h4 className="text-uppercase mb-0">SECURE CHECKOUT</h4>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="cartList py-5">
                                <div className="container">
                                    <div className="row g-4">
                                        <div className="col-md-8">
                                            <div className="row g-4">
                                                <div className="col-12">
                                                    <div className="card shadow-default border-0">
                                                        <div className="card-body">
                                                            <h5 className="text-uppercase text-muted mb-0">Contact
                                                                information
                                                               {/* {(contactinfo) ? "" : (
                                                                    <button className="btn float-end p-0" onClick={(e) => {
                                                                        setContactinfo(true);
                                                                        setDisable(false);
                                                                        setCheckout(false);
                                                                    }}><i
                                                                        className="fa-light fa-pen-to-square"></i>
                                                                    </button>
                                                                )}*/}

                                                            </h5>
                                                            {(contactinfo) ? (
                                                                <>
                                                                    <p>Please provide an email address to receive your
                                                                        order confirmation email.</p>

                                                                    <div className="mb-3">
                                                                        <label htmlFor="uEmail" className="form-label">Email
                                                                            address</label>
                                                                        <input type="email" className="form-control"
                                                                               id="uEmail" name="uEmail"
                                                                               defaultValue={preemail}
                                                                               placeholder="name@example.com"
                                                                               onChange={(ev) => {
                                                                                   setEmailCheckout(ev.target.value);
                                                                               }}/>
                                                                    </div>


                                                                    <div className="mb-3">
                                                                        <label htmlFor="uMobile" className="form-label">Mobile
                                                                            no</label>
                                                                        <input type="tel" className="form-control"
                                                                               id="uMobile" name="uMobile"
                                                                               defaultValue={premobile} placeholder=""
                                                                               onChange={(ev) => {
                                                                                   setMobileCheckout(ev.target.value);
                                                                               }}/>
                                                                    </div>


                                                                    <FormControlLabel className="" control={<Checkbox
                                                                        defaultChecked={signupforOffer}/>}
                                                                                      onChange={(ev) => {
                                                                                          setSignupforOffer(ev.target.value);
                                                                                      }}
                                                                                      label="Sign up for special offers"/>

                                                                    <small className="text-muted d-block mt-3 mb-4">* By
                                                                        signing up you confirm that you have read
                                                                        the <Link target="_blank" to="/privacy-policy/">Privacy
                                                                            Policy</Link> and agree that your email and
                                                                        the provided information will be collected and
                                                                        used by Baemont for the purposes of sending
                                                                        news, promotions and updates via email. You can
                                                                        withdraw your consent at any time by
                                                                        unsubscribing or contacting us via <Link
                                                                            data-ajax-mode="disabled"
                                                                            to="mailto:hello@baemont.com">hello@baemont.com</Link>.</small>
                                                                    <div className="d-flex justify-content-end">
                                                                        <Button className="btn btn-outline-default"
                                                                                onClick={checkBlank}>Continue</Button>
                                                                    </div>

                                                                </>
                                                            ) : ""}

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="card shadow-default border-0">
                                                        <div className="card-body">
                                                            <h5 className="text-uppercase text-muted mb-0">Billing & Shipping Detail
                                                               {/* {(disable) ? "" : (
                                                                    <button className="btn float-end p-0" onClick={(e) => {
                                                                        setContactinfo(false);
                                                                        setDisable(true);
                                                                        setCheckout(false);
                                                                    }}><i
                                                                        className="fa-light fa-pen-to-square"></i>
                                                                    </button>
                                                                )}*/}

                                                            </h5>
                                                            {disable ?
                                                                <>
                                                                    
                                                                    <p>Please provide an address where someone over 18
                                                                        or older will be able to accept and sign for the
                                                                        delivery.</p>
                                                                    {/*<p><b>Unfortunately, we are unable to ship to P.O. boxes.</b></p>*/}

                                                                    <div className="row g-3">

                                                                        <div className="col-12">
                                                                            <label htmlFor="inputAddress"
                                                                                   className="form-label">Customer
                                                                                Name</label>
                                                                            <input type="text" className="form-control"
                                                                                   id="billing_customer_name"
                                                                                   defaultValue={customername}
                                                                                   name="billing_customer_name"
                                                                                   placeholder=""/>
                                                                        </div>


                                                                        <div className="row g-3">
                                                                            <div className="bill_address1">
                                                                                <label htmlFor="address"
                                                                                       className="form-label">Address</label>
                                                                                <input type="text"
                                                                                       className="form-control"
                                                                                       defaultValue={billadd1}
                                                                                       id="bill_address1" placeholder=""
                                                                                       required=""/>

                                                                            </div>

                                                                            <div className="col-12">
                                                                                <label htmlFor="bill_address2"
                                                                                       className="form-label">Address
                                                                                    2 <span
                                                                                        className="text-muted">(Optional)</span></label>
                                                                                <input type="text"
                                                                                       className="form-control"
                                                                                       id="bill_address2"
                                                                                       defaultValue={billadd2}
                                                                                       placeholder=""/>
                                                                            </div>

                                                                            <div className="col-md-3">
                                                                                <label htmlFor="bill_country"
                                                                                       className="form-label">Country</label>
                                                                                
                                                                                <select className="form-select"
                                                                                        id="bill_country" required=""
                                                                                        defaultValue={billcounty}
                                                                                        onChange={FetchStates}>
                                                                                    <option value="">
                                                                                        Choose Country
                                                                                    </option>
                                                                                    {store_country_data.map((country, index) => (
                                                                                        <option key={index}
                                                                                                value={country.isoCode}>
                                                                                            {country.name}
                                                                                        </option>
                                                                                    ))}

                                                                                </select>

                                                                            </div>

                                                                            <div className="col-md-3">
                                                                                <label htmlFor="bill_state"
                                                                                       className="form-label">State</label>

                                                                                <select className="form-select"
                                                                                        id="bill_state" required=""
                                                                                        defaultValue={billstate}>
                                                                                    <option value="">
                                                                                        Choose State
                                                                                    </option>
                                                                                    {fieldState.map((state, index) => (
                                                                                        <option key={index}
                                                                                                value={state.isoCode}>
                                                                                            {state.name}
                                                                                        </option>
                                                                                    ))}

                                                                                </select>

                                                                            </div>

                                                                            <div className="col-md-3">
                                                                                <label htmlFor="bill_city"
                                                                                       className="form-label">City</label>
                                                                                <input type="text"
                                                                                       className="form-control"
                                                                                       defaultValue={billcity}
                                                                                       id="bill_city" placeholder=""
                                                                                       required=""/>

                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <label htmlFor="bill_zip"
                                                                                       className="form-label">Zip</label>
                                                                                <input type="text"
                                                                                       className="form-control"
                                                                                       id="bill_zip"
                                                                                       onInput={checkZipcode}
                                                                                       defaultValue={billzip}
                                                                                       placeholder=""
                                                                                       required=""/>

                                                                            </div>
                                                                        </div>


                                                                    </div>


                                                                    <ul className="list-group list-group-flush border border-start-0 border-end-0 mt-4">
                                                                       {/* <li className="list-group-item">
                                                                            <FormControlLabel
                                                                                className="ms-0 justify-content-between w-100"
                                                                                control={<Android12Switch
                                                                                    defaultChecked/>}
                                                                                defaultValue="0"
                                                                                labelPlacement="start"
                                                                                label="Include price on receipt"
                                                                            />
                                                                        </li>*/}
                                                                        <li className="list-group-item">
                                                                            <FormControlLabel
                                                                                className="ms-0 justify-content-between w-100"
                                                                                control={<Android12Switch
                                                                                    checked={shippingdiff}
                                                                                    onChange={showshipping}/>}
                                                                                //defaultValue="0"
                                                                                labelPlacement="start"
                                                                                label="Use diffrent address for Shipping"
                                                                                //defaultChecked

                                                                            />
                                                                        </li>
                                                                    </ul>
                                                                    {shippingdiff ?
                                                                        (
                                                                            <>
                                                                                <div className="row g-3 my-auto">
                                                                                    <div className="row g-3">
                                                                                        <div className="col-12">
                                                                                            <label
                                                                                                htmlFor="ship_address1"
                                                                                                className="form-label">Address</label>
                                                                                            <input type="text"
                                                                                                   className="form-control"
                                                                                                   id="ship_address1"
                                                                                                   defaultValue={shipadd1}
                                                                                                   placeholder=""
                                                                                                   required=""/>
                                                                                        </div>

                                                                                        <div className="col-12">
                                                                                            <label
                                                                                                htmlFor="ship_address2"
                                                                                                className="form-label">Address
                                                                                                2 <span
                                                                                                    className="text-muted">(Optional)</span></label>
                                                                                            <input type="text"
                                                                                                   className="form-control"
                                                                                                   id="ship_address2"
                                                                                                   defaultValue={shipadd2}
                                                                                                   placeholder=""/>
                                                                                        </div>

                                                                                        <div className="col-md-3">
                                                                                            <label
                                                                                                htmlFor="ship_country"
                                                                                                className="form-label">Country</label>
                                                                                            <select
                                                                                                className="form-select"
                                                                                                id="ship_country"
                                                                                                required=""
                                                                                                onChange={FetchStates}
                                                                                                defaultValue={shipcounty}>
                                                                                                <option value="">
                                                                                                    Choose Country
                                                                                                </option>
                                                                                                {store_country_data.map((country, index) => (
                                                                                                    <option key={index}
                                                                                                            value={country.isoCode}>
                                                                                                        {country.name}
                                                                                                    </option>
                                                                                                ))}

                                                                                            </select>
                                                                                        </div>

                                                                                        <div className="col-md-3">
                                                                                            <label htmlFor="ship_state"
                                                                                                   className="form-label">State</label>

                                                                                            <select
                                                                                                className="form-select"
                                                                                                id="ship_state"
                                                                                                required=""
                                                                                                defaultValue={shipstate}>
                                                                                                <option value="">
                                                                                                    Choose State
                                                                                                </option>
                                                                                                {fieldState.map((state, index) => (
                                                                                                    <option key={index}
                                                                                                            value={state.stateCode}>
                                                                                                        {state.name}
                                                                                                    </option>
                                                                                                ))}

                                                                                            </select>

                                                                                        </div>

                                                                                        <div className="col-md-3">
                                                                                            <label htmlFor="ship_city"
                                                                                                   className="form-label">City</label>
                                                                                            <input type="text"
                                                                                                   className="form-control"
                                                                                                   id="ship_city"
                                                                                                   defaultValue={shipcity}
                                                                                                   placeholder=""
                                                                                                   required=""/>

                                                                                        </div>
                                                                                        <div className="col-md-3">
                                                                                            <label htmlFor="ship_zip"
                                                                                                   className="form-label">Zip</label>
                                                                                            <input type="text"
                                                                                                   className="form-control"
                                                                                                   id="ship_zip"
                                                                                                   defaultValue={shipzip}
                                                                                                   onInput={checkZipcode}
                                                                                                   placeholder=""
                                                                                                   required=""/>

                                                                                        </div>
                                                                                    </div>

                                                                                </div>


                                                                            </>
                                                                        )
                                                                        :
                                                                        ""}
                                                                    <details className="my-3">
                                                                        <summary className="mb-2 fs-6 list-unstyled"><span
                                                                            className="text-muted ms-2">Have a Special
                                                                            Instructions?</span></summary>
                                                                        <textarea name="instruction" id="instruction"
                                                                                  className="form-control"
                                                                                  rows="4"></textarea>
                                                                    </details>
                                                                    <div className="d-flex justify-content-end">
                                                                        <Button className="btn btn-outline-default"
                                                                                disabled={address_check}
                                                                                onClick={StephandleChange}>Continue</Button>
                                                                    </div>
                                                                </>
                                                                :
                                                                ""
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="card  shadow-default border-0 user-payments">
                                                        <div className="card-body">

                                                                <h5 className="text-uppercase text-muted mb-0">Payment
                                                                    {/*{(checkout) ? "" : (
                                                                        <button className="btn float-end p-0" onClick={(e) => {
                                                                            setContactinfo(false);
                                                                            setDisable(false);
                                                                            setCheckout(true);
                                                                        }}><i
                                                                            className="fa-light fa-pen-to-square"></i>
                                                                        </button>
                                                                    )}*/}

                                                                </h5>
 
                                                            {checkout ?
                                                                (
                                                                    <>
                                                                        <div className='mt-3'>
                                                                        <div className="form-check mb-3">
                                                                            <input className="form-check-input me-2"
                                                                                   type="radio"
                                                                                   name="pay_mode" id="bank"
                                                                                   value="bank"
                                                                                   checked={paymentMode == 'bank' ? true : false}
                                                                                   onChange={e => payment_start(e)}/>
                                                                            <label className="form-check-label"
                                                                                   htmlFor="bank">               
                                                                                Direct bank transfer
                                                                            </label>
                                                                            {paymentMode == 'bank' ? (
                                                                                <>
                                                                                    <p>Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order will not be shipped until the funds have cleared in our account.</p>
                                                                                    <textarea className='d-none form-control' rows="3" id="bank_transfer_ref" name='bank_transfer_ref' placeholder='Enter funds tramsfer detail'>
                                                                            </textarea>
                                                                                    <Button
                                                                                        className="w-100 mt-3 btn btn-default flex-fill"
                                                                                        onClick={e => paymentViaBank(e)}>
                                                                                        Pay 
                                                                                    </Button>
                                                                                </>
                                                                            ):""}

                                                                        </div>
                                                                        <div className="form-check mb-3">
                                                                            <input className="form-check-input me-2"
                                                                                   type="radio"
                                                                                   name="pay_mode" id="stripe"
                                                                                   value="stripe"
                                                                                   checked={paymentMode == 'stripe' ? true : false}
                                                                                   onChange={e => payment_start(e)}/>
                                                                            <label className="form-check-label"
                                                                                   htmlFor="stripe">
                                                                                Stripe
                                                                            </label>
                                                                            {/*   <Button className='w-100 btn btn-outline-default flex-fill' onClick={e=> payment_start(e)}>
                                                                          Pay via Stripe
                                                                      </Button>*/}

                                                                            {paymentMode === "stripe" ? (
                                                                                    <>
                                                                                    {clientSecret ? (
                                                                                        <Elements stripe={stripePromise}
                                                                                                  options={options}>
                                                                                            <PaymentInit 
                                                                                                options={options}/>
                                                                                        </Elements>


                                                                                    ) : ""}
                                                                                    </>
                                                                            ):""}
                                                                        </div>

                                                                       {/* {paymentMode === "online" ? (
                                                                            <>

                                                                                <Button
                                                                                    className="w-100 btn btn-outline-default flex-fill"
                                                                                    type="submit">
                                                                                    Pay Online
                                                                                </Button>
                                                                            </>

                                                                        ) : ""}*/}








                                                                        <>
                                                                            <input type="hidden" name="params"
                                                                                   defaultValue={JSON.stringify(ids)}></input>
                                                                            <input type="hidden" name="email"
                                                                                   id="checkout_email"
                                                                                   defaultValue={finalemail}></input>
                                                                            <input type="hidden" name="email"
                                                                                   id="checkout_mobile"
                                                                                   defaultValue={finalmobile}></input>


                                                                            <input type="hidden" name="bill_address"
                                                                                   id="bill_address"
                                                                                   defaultValue={billadd1}></input>
                                                                            <input type="hidden" name="bill_address2"
                                                                                   id="bill_address2"
                                                                                   defaultValue={billadd2}></input>
                                                                            <input type="hidden" name="bill_country"
                                                                                   id="bill_country"
                                                                                   defaultValue={billcounty}></input>
                                                                            <input type="hidden" name="bill_state"
                                                                                   id="bill_state"
                                                                                   defaultValue={billstate}></input>
                                                                            <input type="hidden" name="bill_city"
                                                                                   id="bill_city"
                                                                                   defaultValue={billcity}></input>
                                                                            <input type="hidden" name="bill_zip"
                                                                                   id="bill_zip"
                                                                                   defaultValue={billzip}></input>
                                                                            {shippingdiff ?
                                                                                (
                                                                                    <>
                                                                                        <input type="hidden"
                                                                                               name="ship_address"
                                                                                               id="ship_address"
                                                                                               defaultValue={shipadd1}></input>
                                                                                        <input type="hidden"
                                                                                               name="ship_address2"
                                                                                               id="ship_address2"
                                                                                               defaultValue={shipadd2}></input>
                                                                                        <input type="hidden"
                                                                                               name="ship_country"
                                                                                               id="ship_country"
                                                                                               defaultValue={shipcounty}></input>
                                                                                        <input type="hidden"
                                                                                               name="ship_state"
                                                                                               id="ship_state"
                                                                                               defaultValue={shipstate}></input>
                                                                                        <input type="hidden"
                                                                                               name="ship_city"
                                                                                               id="ship_city"
                                                                                               defaultValue={shipcity}></input>
                                                                                        <input type="hidden"
                                                                                               name="ship_zip"
                                                                                               id="ship_zip"
                                                                                               defaultValue={shipzip}></input>
                                                                                    </>

                                                                                ) : (
                                                                                    <>
                                                                                        <input type="hidden"
                                                                                               name="ship_address"
                                                                                               id="ship_address"
                                                                                               defaultValue={billadd1}></input>
                                                                                        <input type="hidden"
                                                                                               name="ship_address2"
                                                                                               id="ship_address2"
                                                                                               defaultValue={billadd2}></input>
                                                                                        <input type="hidden"
                                                                                               name="ship_country"
                                                                                               id="ship_country"
                                                                                               defaultValue={billcounty}></input>
                                                                                        <input type="hidden"
                                                                                               name="ship_state"
                                                                                               id="ship_state"
                                                                                               defaultValue={billstate}></input>
                                                                                        <input type="hidden"
                                                                                               name="ship_city"
                                                                                               id="ship_city"
                                                                                               defaultValue={billcity}></input>
                                                                                        <input type="hidden"
                                                                                               name="ship_zip"
                                                                                               id="ship_zip"
                                                                                               defaultValue={billzip}></input>

                                                                                    </>


                                                                                )}


                                                                            <input type="hidden" name="customer_id"
                                                                                   id="customer_id"
                                                                                   defaultValue={customer}></input>
                                                                            <input type="hidden" name="customername"
                                                                                   id="customername"
                                                                                   defaultValue={customername}></input>
                                                                            <input type="hidden" name="product_type"
                                                                                   id="product_type"
                                                                                   defaultValue={JSON.stringify(product_type)}></input>
                                                                            <input type="hidden" name="instructions"
                                                                                   id="instructions"
                                                                                   defaultValue={deliveryguide}></input>
                                                                            <input type="hidden" name="coupon_code"
                                                                                   id="coupon_code"
                                                                                   defaultValue={couponval}></input>

                                                                        </>
                                                                        </div>
                                                                    </>
                                                                )
                                                                :
                                                                ""}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <CartSummary/>
                                        </div>

                                    </div>
                                </div>
                            </section>

                        </div>
                    ) : (
                        <div className="empty-cart text-center">

                            <p className="cart-empty-heading">
                                <i className="fa-solid fa-cart-shopping"></i>
                                Your cart is currently empty. </p>
                            <div className="cart-empty-page-text">Before proceed to checkout you must add some products
                                to your
                                shopping cart.<br/> You will find a lot of interesting products on our "Shop" page.
                            </div>
                            <p className="return-to-shop mt-4">
                                <a className="btn btn-primary rounded-0 btn-lg" href="/">
                                    Return to Home </a>
                            </p>
                        </div>
                    )}
                </>




        </>
    );
}

export default Checkout;
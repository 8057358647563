import React, {useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
 
function CurrencySwitch() {
    const [initCurrency, setInitCurrency] = useState(localStorage.getItem('init_currency') ?? false);
    const [currency, setCurrency] = useState(localStorage.getItem('currency') ?? "$");
    const [currencyflag, setCurrencyflag] = useState(localStorage.getItem('currencyflag') ?? "USD");
    const [currencyALL, setCurrencyALL] = useState([]);

    let navigate = useNavigate()

    const default_currency = async ()=>{
        if(initCurrency == false) {
            localStorage.setItem('init_currency',true);
            var clouddata = await fetch(process.env.REACT_APP_API_URL + "/cdn-cgi/trace", {
                method: "GET"
            });
            var coluddata_text = await clouddata.text();
            let arr = coluddata_text.trim().split('\n').map(e => e.split('='))
            const coluddata_json = Object.fromEntries(arr);

            if (coluddata_json.loc == 'MY') {
                setCurrency('RM');
                setCurrencyflag('MYR');
            }
            if (coluddata_json.loc == 'SG') {
                setCurrency('S$');
                setCurrencyflag('SGD');

            }

            window.location.reload();
            localStorage.removeItem("indexvalue");
            localStorage.removeItem("availableCartProductIds");
            localStorage.removeItem("productsbag");
            localStorage.removeItem("coupon_val");
            localStorage.removeItem("billing_country");
            localStorage.removeItem("payment_data");
        }
    }


    const available_currency = async () => {
        var CountryData = new FormData();
        CountryData.append("request", JSON.stringify({
            action: "available_currency"
        }));

        var available_country_res = await fetch(process.env.REACT_APP_API_URL + "/api/setting", {
            method: "POST",
            body: CountryData
        });
        var available_country_json = await available_country_res.json();
        //console.log(available_country_json);
        setCurrencyALL(available_country_json.data);



    };


   function changeCurrency(e){
       Swal.fire({
           title: 'Switching currency? ',
           text: "Items in your cart will be removed. Please add items again.",
           icon: 'warning',
           showCancelButton: true,
           confirmButtonColor: '#9A1C43',
           confirmButtonText: 'Proceed!'
       }).then((result) => {
           if (result.isConfirmed) {

               localStorage.setItem('currency',e.symbol);
               localStorage.setItem('currencyflag',e.title);
               localStorage.removeItem("indexvalue");
               localStorage.removeItem("availableCartProductIds");
               localStorage.removeItem("productsbag");
               localStorage.removeItem("coupon_val");
               localStorage.removeItem("billing_country");
               localStorage.removeItem("payment_data");


               window.location.reload();
           }
       })


   }

    useEffect(() => {
        available_currency();
        default_currency();
        let  currency_val = currency ?? "$";
        let  currency_flag = currencyflag ?? "USD";
    localStorage.setItem('currency',currency_val);
    localStorage.setItem('currencyflag',currency_flag);
    // localStorage.setItem('productsbag', 'W10=');

    }, [currency]);
/*

    const [storageChange, setStorageChange] = useState(null);

    useEffect(() => {
        // console.log("Toolbar hi from useEffect")
        setStorageChange(localStorage.getItem('currency') || null)
        window.addEventListener('storage', storageEventHandler, false);

    }, []);

    function storageEventHandler() {
        console.log("hi from storageEventHandler")
        localStorage.setItem('currency', "RM");

        localStorage.removeItem("availableCartProductIds");
        localStorage.removeItem("productsbag");
        localStorage.removeItem("coupon_val");
        localStorage.removeItem("billing_country");
        localStorage.removeItem("payment_data");

        window.location.reload();
    }
*/



   
    return (
        <>
 
            <Dropdown>
                <Dropdown.Toggle variant="none" className="btn btn-sm btn-light" id="dropdown-currency">
                    <img className='img-fluid me-2' src={process.env.REACT_APP_API_URL_ASSETS+"/assets/currency_flag/"+currencyflag+".png"}/>  <span>{currencyflag}</span>
                </Dropdown.Toggle>

                <Dropdown.Menu className='dropdown-currency-menu dropdown-menu-end'>
                    {currencyALL.length>0?  currencyALL.map( (item,index) => (
                    <Dropdown.Item  key={index} onClick={(e)=> changeCurrency(item)} >
                        <img className='img-fluid me-2' src={process.env.REACT_APP_API_URL_ASSETS+"/assets/currency_flag/"+item.title+".png"}/>
                        <span>{item.title}</span>
                    </Dropdown.Item>
                        ))
                        :""}
                </Dropdown.Menu>
            </Dropdown>

        </>
    )
}

export default CurrencySwitch
import React from 'react'

function About() {
  return (
    <div>

        <section className="common-page-header">

            <div className="container">
                <div className="row pageTitle m0">
                    <div className="col-12  text-center">
                        <h1 className="display-3 text-white">About Us</h1>
                        <ul className="breadcrumb text-center d-block">
                            <li className="d-inline-block "><a className="text-white" href="index.html">HOME</a><span className="mx-2">/</span></li>
                            <li className="d-inline-block"> About Us</li>
                        </ul>

                    </div>
                </div>
            </div>
        </section>

        <section id="contactRow" className=" contentRowPad">
            <div className="container">
                <div className="row">
                    <div className='col-12'>

                        <div className='about-page-head'>
                            We are <b><u>‘that’</u></b> friend of yours, <br/><b><u>the     
                            expert</u></b> and <b>the person you can count</b>on anything    <br/>
                            and everything about the diamond and fine jewellery industry and we’re taking you on our journey with<br/>
                            us.
                        </div>
                        <div className='about-page-content'>
                            <p><strong><br />BAEMONT</strong> was built with a simple brainchild: to transform the traditional shopping of jewellery at brick-and-mortar to online mode of retailing, the matchmaker of science and art in diamond jewellers and designers, the promoter of modern business networks and partnerships and more importantly, the focal of an ultimate great buying experience for you &ndash; <strong><em>the Customer.<br /><br /></em></strong></p>

                            <p>Partnering alongside fellow international technology enabled diamond companies who think alike, our <strong><em>conflict-free</em></strong> diamonds are <strong><em>ethically sourced</em></strong> directly from diamond companies around the world and 100% authenticated and certified by world-renowned grading entity &ndash; <strong><em>Gemological Institute of America (GIA)</em></strong>. As avid believers of radical transparency, we ensure that we would be able to offer you the <strong><em>most affordable and unbeatable value</em></strong> piece of jewellery. The diamonds listed on our online platform are <strong><em>real-time inventory with live pricing</em></strong>, bringing every diamond &ldquo;fresh from the oven&rdquo;, anywhere in the world.&nbsp;</p>
                            <p>All of our jewellery is handcrafted by skilled craftsmen with at least 30 years of experience and are obsessive with attention to details. Our <strong><em>&ldquo;Designed By You, Made by Us&rdquo;</em></strong> bespoke jewellery service allows you to bring your imagination of your desired jewellery to life and ensuring that the particular piece of jewellery, be it an engagement ring or wedding band or fine jewellery, is a treasure which would last a lifetime. </p>
                            <p>Just like you, we believe the power of choice comes from your own hands hence with every ounce of our effort, we would want our online platform to be catering to your every needs. And if you may, a little investment of your time to comment, advise or &ldquo;rant&rdquo; to us, would definitely help us to serve you better as we share the same end goals in mind &ndash;<strong><em>to heighten the level of &lsquo;worth&rsquo; </em></strong>for what you&rsquo;re paying for.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>




    </div>
  )
}

export default About
import React, {useState, useEffect} from "react";
import {Link, useLocation,useNavigate, useParams} from "react-router-dom";
import {Slider, InputNumber, Radio} from "antd";
import type { RadioChangeEvent } from 'antd';
import axios from 'axios';

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';


import Collapse from "react-bootstrap/Collapse";
import "antd/dist/reset.css";
import "./filter.scss";


import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import FilterSteps from "../parts/filter-steps";
import ProductItem from "../parts/product_grid";
import ProductItemList from "../parts/product_list";
import CompareProductList from "../parts/compare-list";
import RecentProducts from "../parts/recently-products";
import {FaThList, FaTh,FaGem,FaMicroscope, FaSearchPlus, FaRegHeart, FaHeart, FaTimes, FaGripLines, FaRedoAlt} from "react-icons/fa";
import ReactPaginate from "react-paginate";

function DiamondSettings(props) { 
    //Diamond Type
     const [isDiamondType, setDiamondType] = useState('diamond');
 
    //Shapes
    const [isShape, setShape] = useState("round");
    const [isShapeClicked, setShapeClicked] = useState(false);
    const all_shape = [
        {"icon": "round.png", "name": "round"},
        {"icon": "princess.png", "name": "princess"},
        {"icon": "cushion.png", "name": "cushion"},
        {"icon": "emerald.png", "name": "emerald"},
        {"icon": "oval.png", "name": "oval"},
        {"icon": "rediant.png", "name": "rediant"},
        {"icon": "asscher.png", "name": "asscher"},
        {"icon": "marquise.png", "name": "marquise"},
        {"icon": "heart.png", "name": "heart"},
        {"icon": "pear.png", "name": "pear"}
    ];
    //Cut
    const [isCut, setCut] = useState([0, 3]);

  const CutMarks_diamond =  { 0: { style: { transform: "translateX(-3px)", }, label: "Excellent" }, 1: {label: "Very Good"}, 2: {label: "Good"}, 3: { style: { right: 0, left: "unset", transform: "translateX(3px)", }, label: "Fair" }, };
  const CutMarks_labdiamond =  { 0: { style: { transform: "translateX(-3px)", }, label: "Ideal" }, 1: {label: "Excellent"}, 2: {label: "Very Good"}, 3: {label: "Good"}, 4: { style: { right: 0, left: "unset", transform: "translateX(3px)", }, label: "Fair" }, }




    //Clarity
    const [isClarity, setClarity] = useState([0, 7]);

    const ClarityMarks = {
        0: {
            style: {
                transform: "translateX(-3px)",
            }, label: "FL"
        },                   

        1: {label:"IF"},
        2: {label:"VVS1"},
        3: {label:"VVS2"},
        4: {label:"VS1"},
        5: {label:"VS2"},
        6: {label:"SI1"},
        7: {
            style: {
                right: 0, left: "unset", transform: "translateX(3px)",
            }, label: "SI2"
        },
    };
    //Color
    const [isColor, setColor] = useState([0, 6]);

    const ColorMarks = {
        0: {
            style: {
                transform: "translateX(-3px)",
            }, label: "D"
        },


        1: {label:"E"},
        2: {label:"F"},
        3: {label:"G"},
        4: {label:"H"},
        5: {label:"I"},
        6: {
            style: {
                right: 0, left: "unset", transform: "translateX(3px)",
            }, label: "J"
        },
    };
    //Carat

    const [isCarat, setCarat] = useState([1,7]);
    const [minCarat, setMinCarat] = useState("1");
    const [maxCarat, setMaxCarat] = useState("7");
    const CaratMin = (value: number) => {
        setMinCarat(value); 
        // filterTrigger();
    };
    const CaratMax = (value: number) => {
        setMaxCarat(value);
        // filterTrigger();
    };

    const CaratRangeChange = (value: number | [number, number]) => {
        setMinCarat(value[0]);
        setMaxCarat(value[1]);
    };

    //Price
    const [isPrice, setPrice] = useState([100,1000000]);
    const [minPrice, setMinPrice] = useState("100");
    const [maxPrice, setMaxPrice] = useState("1000000");
    const PriceMin = (value: number) => {
        setMinPrice(value);
        // filterTrigger();
    };
    const PriceMax = (value: number) => {
        setMaxPrice(value);
        // filterTrigger();
    };

    const PriceRangeChange = (value: number | [number, number]) => {
        setMinPrice(value[0]);
        setMaxPrice(value[1]);
    };


    //Advance Option
    const [openAdvance, setOpenAdvance] = useState(false);

    //Polish
    const [isPolish, setPolish] = useState([0, 2]);

    const PolishMarks = {
        0: {
            style: {
                transform: "translateX(-3px)",
            }, label: "Excellent"
        },

        1: {label:"Very Good"},
        2: {
            style: {
                right: 0, left: "unset", transform: "translateX(3px)",
            }, label: "Good"
        },
    };

    //Symmetry
    const [isSymmetry, setSymmetry] = useState([0, 3]);

    const SymmetryMarks =  {
        0: {
            style: {
                transform: "translateX(-3px)",
            }, label: "Excellent"
        },
        1:  {label:"Very Good"},
        2:  {label:"Good"},
        3: {
            style: {
                right: 0, left: "unset", transform: "translateX(3px)",
            }, label: "Fair"
        },
    };

    //Fluorescence
    const [isFluorescence, setFluorescence] = useState([0, 4]);

    const FluorescenceMarks =  {
        0: {
            style: {
                transform: "translateX(-3px)",
            }, label: "None"
        },

        1:  {label:"Faint"},
        2:  {label:"Medium"},
        3:  {label:"Strong"},
        4: {
            style: {
                right: 0, left: "unset", transform: "translateX(3px)",
            }, label: "Very Strong"
        },
    };

    //Lab
    const [isLab, setLab] = useState('GIA');
    const Labs = [
        { label: 'GIA', value: 'GIA' },
        // { label: 'AGS', value: 'AGS' },
        { label: 'IGI', value: 'IGI' },
    ];
    //Depth
    const [isDepth, setDepth] = useState([0.01,85]);
    const [minDepth, setMinDepth] = useState("0.01");
    const [maxDepth, setMaxDepth] = useState("85");
    const DepthMin = (value: number) => {
        setMinDepth(value);
        // filterTrigger();
    };
    const DepthMax = (value: number) => {
        setMaxDepth(value);
        // filterTrigger();
    };

    const DepthRangeChange = (value: number | [number, number]) => {
        setMinDepth(value[0]);
        setMaxDepth(value[1]);
    };


    //Table
    const [isTable, setTable] = useState([0.01,85]);
    const [minTable, setMinTable] = useState("0.01");
    const [maxTable, setMaxTable] = useState("85");
    const TableMin = (value: number) => {
        setMinTable(value);
        // filterTrigger();
    };
    const TableMax = (value: number) => {
        setMaxTable(value);
        // filterTrigger();
    };

    const TableRangeChange = (value: number | [number, number]) => {
        setMinTable(value[0]);
        setMaxTable(value[1]);
    };

  


    //API Trigger
    const resetFilter = (e) => {
        setDiamondType('diamond');
        setShape("round");
        setCut([0,3]);
        setClarity([0,6]);
        setColor([0,6]);

        setCarat([1,7])
        setMinCarat(0.01);
        setMaxCarat(7);

        setPrice(200,1000000)
        setMinPrice(200);
        setMaxPrice(1000000);

        setPolish([0,2]);
        setSymmetry([0,3]);
        setFluorescence([0,4]);
        setLab("GIA");


        setDepth(0.01,85)
        setMinDepth(0.01);
        setMaxDepth(85);

        setTable(0.01,85)
        setMinTable(0.01);
        setMaxTable(85);
        resetpagination();


    };
    const filterTrigger = (e) => {
        console.log('onAfterChange: ', e);

        getProd();
    };


    /*****Filter Button mobile******/

    const mobile_filter_btn =["Shape", "Cut", "Clarity", "Color", "Carat", "Price", "Polish", "Symmetry", "Fluorescence", "Lab", "Depth", "Table"] ;

    const [mobilePopup, setMobilePopup] = useState("");


    /****API Data***/
    let [jescnt, setJescnt] = useState([]);
    let [jesdada, setJesdata] = useState([]);
    let [orderby, setOrderby] = useState('total_price');
    const [perPage] = useState(20); 
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const handlePageClick = async (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        //  console.log(selectedPage * perPage)
        setOffset(selectedPage * perPage);
        const scroll_top = document.getElementById("filter-slide");
        if (scroll_top) {

            scroll_top.scrollIntoView({block: "start", behavior: "smooth"});
        }
    };
    const {shape} = useParams();
    const params = useParams();

    function resetpagination(){
        setCurrentPage(0)
        setOffset(0)
    }

    async function getProd() {
        props.loader(true)
        var formData = new FormData();
        var request_data = new FormData(); 
        var flag = 0;
        if (isShapeClicked == false) {
            if (params.shape) {
                setShape(params.shape);
            }
        }

         if (isShape) {
             flag = 1;

             request_data.shape = isShape;
         }

        if (isDiamondType.length > 0) {
            flag = 1;
            request_data.type = isDiamondType;
        }

        if (isCut.length > 0) {
            flag = 1;
            request_data.cut = isCut;
        }
        if (isColor.length > 0) {
            flag = 1;
            request_data.color = isColor;
        }
        if (isCarat.length > 0) {
            flag = 1;
            request_data.carat = isCarat;
        }
        if (isClarity.length > 0) {
            flag = 1;

            request_data.clarity = isClarity;
        }

        if (isPrice.length > 0) {
            flag = 1;
            request_data.price = isPrice;
        }
        if (isPolish.length > 0) {
            flag = 1;
            request_data.polish = isPolish;
        }
        if (isSymmetry.length > 0) {
            flag = 1;
            request_data.symmetry = isSymmetry ;
        }
        if (isFluorescence.length > 0) {
            flag = 1;
            request_data.fluorescence = isFluorescence;
        }

        if (isLab.length > 0) {
            flag = 1;
            request_data.lab =isLab;
        }
        if (isDepth.length > 0) {
            flag = 1;
            request_data.depth = isDepth;
        }
        if (isTable.length > 0) {
            flag = 1;
            request_data.table = isTable;
        }

        
        request_data.currency = localStorage.getItem('currency');
        request_data.offset = offset;
        request_data.orderby = orderby;

       // console.log(isShape+" - "+isCut+" - "+isClarity+" - "+isColor+" - "+isPolish+" - "+isSymmetry+" - "+isFluorescence+" - "+isLab+" - "+minCarat+" - "+maxCarat+" - "+minPrice+" - "+maxPrice+" - "+minTable+" - "+maxTable+" - "+minDepth+" - "+maxDepth);

        formData.append('request',JSON.stringify(request_data))

        axios.post(process.env.REACT_APP_API_URL+'/api/getproducts', formData )
            .then(function (response) { 

                setJesdata(response.data.data);
                setJescnt(response.data.count);
                setPageCount(Math.ceil(jescnt / perPage));

              setTimeout(function (){
                  props.loader(false);
              },1000);
            })
            .catch(function (error) {
                console.log(error);
            });     


 

    }
    
    let all_diamond_tab
    let comparison_tab
    let recently_view_tab
    if(window.innerWidth < 768){
        all_diamond_tab = "All"
        comparison_tab = "Compare"
        recently_view_tab = "Recent"
    }  else{
        //all_diamond_tab =  "ALL Diamonds (" + jescnt + ")"
        all_diamond_tab =  "ALL Diamonds"
        comparison_tab =  "Comparison"
        recently_view_tab =  "Recently Viewed"
    }


    window.addEventListener('resize', function (){
        if(window.innerWidth < 768){
            all_diamond_tab = "All"
            //all_diamond_tab = "All (" + jescnt + ")"
            comparison_tab = "Compare"
            recently_view_tab = "Recent"
        }  else{
            //all_diamond_tab =  "ALL Diamonds (" + jescnt + ")"
            all_diamond_tab =  "ALL Diamonds"
            comparison_tab =  "Comparison"
            recently_view_tab =  "Recently Viewed"
        }
    })


    const popover_cut = (
        <Popover  className="filter_info">
            <Popover.Body>
                <iframe scrolling="no" width="100%" height="400" className="overflow-hidden" src='https://4cs.gia.edu/interactive-4cs//standalone/cut.html'></iframe>
            </Popover.Body>
        </Popover>
    );
    const popover_clarity = (
        <Popover  className="filter_info">
            <Popover.Body>
                <iframe scrolling="no" width="100%" height="400" className="overflow-hidden" src='https://4cs.gia.edu/interactive-4cs//standalone/clarity.html'></iframe>
            </Popover.Body>
        </Popover>
    );

    const popover_color = (
        <Popover  className="filter_info">
            <Popover.Body>
                <iframe scrolling="no" width="100%" height="400" className="overflow-hidden" src='https://4cs.gia.edu/interactive-4cs//standalone/color.html'></iframe>
            </Popover.Body>
        </Popover>
    );
    const popover_carat = (
        <Popover  className="filter_info">
            <Popover.Body>
                <iframe scrolling="no" width="100%" height="400" className="overflow-hidden" src='https://4cs.gia.edu/interactive-4cs//standalone/carat-weight.html'></iframe>
            </Popover.Body>
        </Popover>
    );
    
    useEffect(() => { 
        getProd();



    }, [isDiamondType,isShape,isCut,isClarity,isColor,isPolish,isSymmetry,isCarat,isFluorescence,isLab,isPrice,isTable,isDepth,jescnt, offset]);








    return (
        <>
            {/*Desktop Filter*/}

            <div className="container pt-3">
                <div className="row">
                    <div className="col-12">

                        
                        <FilterSteps active={1}/>

                    </div>
                </div>
            </div>
            <div className="container desktop-filter">

                <div className="row">

                        <div className="col-lg-12 text-center filter_control diamond_type" id="diamond_type">
                           
                            <div className="diamond_type_control_list w-100">
                                
                                <Radio.Group   size="large" onChange={(e) => {
                                    setDiamondType(e.target.value);
                                    if (e.target.value === 'diamond') {
                                        setCut([0,3]);
                                    }if (e.target.value === 'lab_diamond') {
                                        setLab('IGI');setCut([0,4]);
                                    }
                                }}  value={isDiamondType}>
                                    <Radio.Button value="diamond"><FaGem/><span className='ms-2'>Natural Diamonds</span></Radio.Button>
                                    <Radio.Button value="lab_diamond"><FaMicroscope/><span className='ms-2'>Lab-Created Diamonds</span></Radio.Button>
                                </Radio.Group> 
                        </div>
                    </div>
                    <div className="col-lg-6 filter_control shape_main" id="shape_data">
                        <label>Shape</label>
                        <div className="shape_control_list">
                            {(all_shape.length > 0) ? all_shape.map((shape, index) => (
                                <div className="shape_control_item" key={index}>
                                    {shape.name === isShape}


                                    <input onChange={(e) => {
                                        setShape(shape.name);
                                        setOrderby('shape');
                                        setShapeClicked(true);
                                        resetpagination();
                                    }} className="form-checkbox" type="radio" name="shape" value={shape.name}
                                           id={"filter_" + shape.name} checked={(shape.name === isShape) ? true:""}/>
                                    <label htmlFor={"filter_" + shape.name}>


                                        <div className="shape_item_img">
                                            <img className="img-fluid"
                                                 src={window.location.origin + "/assets/img/static/shapes/" + shape.icon}
                                                 alt={shape.name}/>
                                        </div>
                                        <div className="shape_item_label text-center">{shape.name}</div>
                                    </label>
                                </div>
                            )) : ""}
                        </div>
                    </div>
                    <div className="col-lg-6 filter_control cut_main" id="cut_data">
                        <label>Cut<OverlayTrigger rootClose trigger="click" placement="bottom" overlay={popover_cut}>
                            <Button className="question_mark"><i className="fas fa-question-circle"></i></Button>
                        </OverlayTrigger></label>
                        <div className="cut_control_list">

                            {isDiamondType == 'lab_diamond' ? (
                                    <Slider range step={1} tooltip={{formatter: null}} min={0} max={4} defaultValue={isCut}
                                            draggableTrack={true} marks={CutMarks_labdiamond} onAfterChange={(e: number | [number, number]) => {
                                        setCut(e);setOrderby('cut');
                                        resetpagination();

                                    }} />
                            ):''}
                            {isDiamondType == 'diamond' ? (
                                <Slider range step={1} tooltip={{formatter: null}} min={0} max={3} defaultValue={isCut}
                                        draggableTrack={true} marks={CutMarks_diamond} onAfterChange={(e: number | [number, number]) => {
                                    setCut(e);setOrderby('cut');
                                    resetpagination();

                                }} />
                           ):''}
                        </div>
                    </div>
                    <div className="col-lg-6 filter_control clarity_main" id="clarity_data">
                        <label>Clarity<OverlayTrigger rootClose trigger="click" placement="bottom" overlay={popover_clarity}>
                            <Button className="question_mark"><i className="fas fa-question-circle"></i></Button>
                        </OverlayTrigger></label>
                        <div className="clarity_control_list">

                            <Slider range step={1} tooltip={{formatter: null}} min={0} max={7} defaultValue={isClarity}
                                    draggableTrack={true} marks={ClarityMarks} onAfterChange={(e: number | [number, number]) => {
                                setClarity(e); setOrderby('clarity');  resetpagination();
                            }} />


                        </div>
                    </div>
                    <div className="col-lg-6 filter_control color_main" id="color_data">
                        <label>Color<OverlayTrigger rootClose trigger="click" placement="bottom" overlay={popover_color}>
                            <Button className="question_mark"><i className="fas fa-question-circle"></i></Button>
                        </OverlayTrigger></label>
                        <div className="color_control_list">

                            <Slider range step={1} tooltip={{formatter: null}} min={0} max={6} defaultValue={isColor}
                                    draggableTrack={true} marks={ColorMarks} onAfterChange={(e: number | [number, number]) => {
                                setColor(e);  setOrderby('color');   resetpagination();
                            }} />

                             
                        </div>
                    </div>
                    <div className="col-lg-6 filter_control carat_main" id="carat_data">
                        <label>Carat<OverlayTrigger rootClose trigger="click" placement="bottom" overlay={popover_carat}>
                            <Button className="question_mark"><i className="fas fa-question-circle"></i></Button>
                        </OverlayTrigger></label>
                        <div className="carat_control_list">
                            <div className="row">
                                <div className="col-6 text-start">
                                    <InputNumber controls={true} min={0.01} step={0.01} max={7} value={minCarat}
                                                 onStep={(e)=>{  setCarat([e.target.value,maxCarat]);resetpagination();  }}
                                                 onPressEnter={(e)=>{  setCarat([e.target.value,maxCarat]);setOrderby('carat'); resetpagination();}} onChange={CaratMin}/>

                                </div>
                                <div className="col-6 text-end">
                                    <InputNumber controls={true} min={0.01} step={0.01} max={7} value={maxCarat}
                                                 onStep={(e)=>{  setCarat([minCarat,e.target.value]);resetpagination();  }}
                                                 onPressEnter={(e)=>{  setCarat([minCarat,e.target.value]);setOrderby('carat'); resetpagination(); }} onChange={CaratMax}/>
                                </div>
                            </div>
                            <Slider range value={[minCarat, maxCarat]} step={0.01} min={0.01} max={7}
                                    tooltip={{formatter: null}} draggableTrack={true} onChange={CaratRangeChange}
                                    onAfterChange={(e: number | [number, number]) => {
                                        setCarat(e);setOrderby('carat');  resetpagination();
                                    }}/>
                          
                        </div>
                    </div>
                    <div className="col-lg-6 filter_control price_main" id="price_data">
                        <label>Price</label>
                        <div className="price_control_list">
                            <div className="row">
                                <div className="col-6 text-start">
                                    <InputNumber className="price_control_input" controls={false} min={200} step={1}
                                                 max={1000000} prefix={localStorage.getItem('currency')}
                                                 formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                 value={minPrice}
                                                 onStep={(e)=>{  setPrice([e.target.value,maxPrice]);resetpagination(); }}
                                                 onPressEnter={(e)=>{  setPrice([e.target.value,maxPrice]);setOrderby('total_price');  resetpagination(); }}
                                                 onChange={PriceMin}/>

                                </div>
                                <div className="col-6 text-end">
                                    <InputNumber className="price_control_input" controls={false} min={200} step={1}
                                                 max={1000000} prefix={localStorage.getItem('currency')}
                                                 formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                 value={maxPrice}
                                                 onStep={(e)=>{  setPrice([minPrice,e.target.value]);resetpagination(); }}
                                                 onPressEnter={(e)=>{  setPrice([minPrice,e.target.value]);setOrderby('total_price'); resetpagination(); }}
                                                 onChange={PriceMax}/>
                                </div>
                            </div>
                            <Slider range value={[minPrice, maxPrice]} step={1} min={200} max={1000000}
                                    tooltip={{formatter: null}} draggableTrack={true} onChange={PriceRangeChange}
                                    onAfterChange={(e: number | [number, number]) => {
                                        setPrice(e);setOrderby('total_price'); resetpagination();
                                    }}/>

                        </div>
                    </div>

                    <div className="col-md-12 mt-3">
                        <div className="d-block align-items-center panAdvance">


                            <Collapse in={openAdvance}>
                                <div id="panAdvanceInner">
                                    <div className="row">
                                        <div className="col-lg-6 filter_control polish_main" id="polish_data">
                                            <label>Polish</label>
                                            <div className="polish_control_list">

                                                <Slider range step={1} tooltip={{formatter: null}} min={0} max={2} defaultValue={isPolish}
                                                        draggableTrack={true} marks={PolishMarks}
                                                        onAfterChange={(e: number | [number, number]) => {
                                                            setPolish(e);  setOrderby('polish');      resetpagination();
                                                        }}/> 
                                            </div>
                                        </div>
                                        <div className="col-lg-6 filter_control symmetry_main" id="symmetry_data">
                                            <label>Symmetry</label>
                                            <div className="symmetry_control_list">
                                                <Slider range step={1} tooltip={{formatter: null}} min={0} max={3} defaultValue={isSymmetry}
                                                        draggableTrack={true} marks={SymmetryMarks}
                                                        onAfterChange={(e: number | [number, number]) => {
                                                            setSymmetry(e);  setOrderby('symmetry'); resetpagination();
                                                        }}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 filter_control fluorescence_main" id="fluorescence_data">
                                            <label>Fluorescence</label>
                                            <div className="fluorescence_control_list">
                                                <Slider range step={1} tooltip={{formatter: null}} min={0} max={4} defaultValue={isFluorescence}
                                                        draggableTrack={true} marks={FluorescenceMarks}
                                                        onAfterChange={(e: number | [number, number]) => {
                                                            setFluorescence(e);   setOrderby('fluorescence');   resetpagination();
                                                        }}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 filter_control lab_main" id="lab_data">
                                            <label>Lab</label>
                                            <div className="lab_control_list">
                                                <Radio.Group
                                                    options={Labs}
                                                    onChange={(e) => {
                                                        setLab(e.target.value);
                                                        setOrderby('lab_company');
                                                        resetpagination();
                                                    }}
                                                    value={isLab}
                                                    optionType="button"
                                                    buttonStyle="solid"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 filter_control depth_main" id="depth_data">
                                            <label>Depth %</label>
                                            <div className="depth_control_list">
                                                <div className="row">
                                                    <div className="col-6 text-start">
                                                        <InputNumber controls={true} min={0.01} step={0.01} max={85} value={minDepth}
                                                                     onStep={(e)=>{  setDepth([e.target.value,maxDepth]);resetpagination(); }}
                                                                     onPressEnter={(e)=>{  setDepth([e.target.value,maxDepth]);setOrderby('parameter_depth');resetpagination();  }}
                                                                     onChange={DepthMin}/>

                                                    </div>
                                                    <div className="col-6 text-end">
                                                        <InputNumber controls={true} min={0.01} step={0.01} max={85} value={maxDepth}
                                                                     onStep={(e)=>{  setDepth([minDepth,e.target.value]); resetpagination();  }}
                                                                     onPressEnter={(e)=>{  setDepth([minDepth,e.target.value]);setOrderby('parameter_depth'); resetpagination(); }}
                                                                     onChange={DepthMax}/>
                                                    </div>
                                                </div>
                                                <Slider range value={[minDepth, maxDepth]} step={0.01} min={0.01} max={85}
                                                        tooltip={{formatter: null}} draggableTrack={true} onChange={DepthRangeChange}
                                                    onAfterChange={(e: number | [number, number]) => {
                                                    setDepth(e);
                                                    setOrderby('parameter_depth');
                                                        resetpagination();
                                                }}/>

                                            </div>
                                        </div>
                                        <div className="col-lg-6 filter_control table_main" id="table_data">
                                            <label>Table %</label>
                                            <div className="table_control_list">
                                                <div className="row">
                                                    <div className="col-6 text-start">
                                                        <InputNumber controls={true} min={0.01} step={0.01} max={85} value={minTable}
                                                                     onStep={(e)=>{  setTable([e.target.value,maxTable]);resetpagination();  }}
                                                                     onPressEnter={(e)=>{  setTable([e.target.value,maxTable]);setOrderby('parameter_table');resetpagination();  }}
                                                                     onChange={TableMin}/>

                                                    </div>
                                                    <div className="col-6 text-end">
                                                        <InputNumber controls={true} min={0.01} step={0.01} max={85} value={maxTable}
                                                                     onStep={(e)=>{  setTable([minTable,e.target.value]);resetpagination();  }}
                                                                     onPressEnter={(e)=>{  setTable([minTable,e.target.value]);setOrderby('parameter_table'); resetpagination();  }}
                                                                     onChange={TableMax}/>
                                                    </div>
                                                </div>
                                                <Slider range value={[minTable, maxTable]} step={0.01} min={0.01} max={85}
                                                        tooltip={{formatter: null}} draggableTrack={true} onChange={TableRangeChange}
                                                        onAfterChange={(e: number | [number, number]) => {
                                                    setTable(e);
                                                            setOrderby('parameter_table');  resetpagination();
                                                }}/>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>


                            <div className="row">
                                <div className="col-6 text-start">
                                    <button
                                        onClick={resetFilter}
                                        className=" btn btn-dark btnReset mt-3 rounded-0 text-uppercase"> Reset Filters
                                    </button>
                                </div>
                                <div className="col-6 text-end">
                                    <button
                                        onClick={() => setOpenAdvance(!openAdvance)}
                                        className="btn btn-primary btnAdvance rounded-0 mt-3">
                                        Advanced Options {openAdvance ? <i className="fas fa-angle-double-up ms-2"></i> :
                                        <i className="fas fa-angle-double-down ms-2"></i>}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            {/*Mobile Filter*/}

           <div className="container mobile-filter">
                <div className="row">
                    <div className="col-lg-12 text-center filter_control diamond_type" id="diamond_type">

                        <div className="diamond_type_control_list w-100">

                            <Radio.Group   size="large" onChange={(e) => {
                                setDiamondType(e.target.value);
                                if (e.target.value === 'lab_diamond') {
                                    setLab('IGI');
                                }
                            }}  defaultValue={isDiamondType}>
                                <Radio.Button value="diamond"><FaGem/><span className='ms-2'>Natural Diamonds</span></Radio.Button>
                                <Radio.Button value="lab_diamond"><FaMicroscope/><span className='ms-2'>Lab Created Diamonds</span></Radio.Button>
                            </Radio.Group>



                        </div>
                    </div>
                    {(mobile_filter_btn.length > 0) ? mobile_filter_btn.map((mobile_filter_btn, index) => (
                        <div className="col-6 col-sm-4 mb-2" key={index}>
                            <button type="button" onClick={(e)=>{setMobilePopup(mobile_filter_btn)}} className="btn btn-block w-100 btn-white border-1 border-dark border-opacity-25 btn_filter">
                                <span className="filter_name">{mobile_filter_btn}</span>
                                <div className="filter_selected">
                                    {mobile_filter_btn == "Shape"? isShape:""}
                                    {isDiamondType == 'lab_diamond' ?  mobile_filter_btn == "Cut"? CutMarks_labdiamond[isCut[0]].label+"-"+CutMarks_labdiamond[isCut[1]].label:"" :''}
                                    {isDiamondType == 'diamond' ?                                         mobile_filter_btn == "Cut"? CutMarks_diamond[isCut[0]].label+"-"+CutMarks_diamond[isCut[1]].label:"" :''}


                                    {mobile_filter_btn == "Clarity"? ClarityMarks[isClarity[0]].label+"-"+ClarityMarks[isClarity[1]].label:""}
                                    {mobile_filter_btn == "Color"? ColorMarks[isColor[0]].label+"-"+ColorMarks[isColor[1]].label:""}
                                    {mobile_filter_btn == "Carat"? minCarat+"-"+maxCarat:""}
                                    {mobile_filter_btn == "Price"? "RM "+minPrice+" - "+" RM "+maxPrice:""}

                                    {mobile_filter_btn == "Polish"? PolishMarks[isPolish[0]].label+"-"+PolishMarks[isPolish[1]].label:""}
                                    {mobile_filter_btn == "Symmetry"? SymmetryMarks[isSymmetry[0]].label+"-"+SymmetryMarks[isSymmetry[1]].label:""}
                                    {mobile_filter_btn == "Fluorescence"? FluorescenceMarks[isFluorescence[0]].label+"-"+FluorescenceMarks[isFluorescence[1]].label:""}
                                    {mobile_filter_btn == "Lab"? isLab:""}
                                    {mobile_filter_btn == "Depth"? minDepth+"-"+maxDepth:""}
                                    {mobile_filter_btn == "Table"? minTable+"-"+maxTable:""}

                                </div>
                            </button>
                        </div>
                    )) : ""}

                    {mobilePopup !== "" ? (
                        <>
                            <div className="mobilefilterPop">
                                <div className="mobilefilterPop-overlay" onClick={(e)=>{setMobilePopup("")}}></div>
                                <div className="mobilefilterPop-inner">
                                    <div className="row mb-4">
                                        <div className="col-6 text-start">
                                            <button className="mobilef_close_btn" onClick={(e)=>{setMobilePopup("")}}><i className="fas fa-times"></i> Close</button>
                                        </div>
                                        <div className="col-6 text-end">
                                            <button className="mobilef_reset_btn" onClick={(e)=>{resetFilter("");setMobilePopup("")}}><i
                                                className="fas fa-redo-alt"></i> Reset</button></div>
                                    </div>
                                    {mobilePopup == "Shape"? (
                                        <>
                                            <div className="filter_control shape_main" id="shape_data_mobile">
                                                <label>Shape</label>
                                                <div className="shape_control_list">
                                                    {(all_shape.length > 0) ? all_shape.map((shape, index) => (
                                                        <div className="shape_control_item" key={index}>
                                                            {shape.name === isShape}


                                                            <input onChange={(e) => {
                                                                setShape(shape.name);
                                                                setShapeClicked(true);
                                                                resetpagination();
                                                            }} className="form-checkbox" type="radio" name="shape" value={shape.name}
                                                                   id={"filter_" + shape.name} checked={(shape.name === isShape) ? true:""}/>
                                                            <label htmlFor={"filter_" + shape.name}>


                                                                <div className="shape_item_img">
                                                                    <img className="img-fluid"
                                                                         src={window.location.origin + "/assets/img/static/shapes/" + shape.icon}
                                                                         alt={shape.name}/>
                                                                </div>
                                                                <div className="shape_item_label text-center">{shape.name}</div>
                                                            </label>
                                                        </div>
                                                    )) : ""}
                                                </div>
                                            </div>

                                        </>
                                    ):""}


                                    {mobilePopup == "Cut"? (
                                        <>
                                            <div className="filter_control cut_main" id="cut_data_mobile">
                                                <label>Cut</label>
                                                <div className="cut_control_list">
                                                    <Slider range step={1} tooltip={{formatter: null}} min={0} max={3} defaultValue={isCut}
                                                            draggableTrack={true} marks={CutMarks_diamond} onAfterChange={(e: number | [number, number]) => {
                                                        setCut(e);  resetpagination();
                                                    }} />
                                                </div>
                                            </div>

                                        </>
                                    ):""}
                                    {mobilePopup == "Clarity"? (
                                        <>
                                            <div className="filter_control clarity_main" id="clarity_data_mobile">
                                                <label>Clarity</label>
                                                <div className="clarity_control_list">
                                                    <Slider range step={1} tooltip={{formatter: null}} min={0} max={7} defaultValue={isClarity}
                                                            draggableTrack={true} marks={ClarityMarks} onAfterChange={(e: number | [number, number]) => {
                                                        setClarity(e);   resetpagination();
                                                    }} />


                                                </div>
                                            </div>

                                        </>
                                    ):""}
                                    {mobilePopup == "Color"? (
                                        <>
                                            <div className="filter_control color_main" id="color_data_mobile">
                                                <label>Color</label>
                                                <div className="color_control_list">

                                                    <Slider range step={1} tooltip={{formatter: null}} min={0} max={6} defaultValue={isColor}
                                                            draggableTrack={true} marks={ColorMarks} onAfterChange={(e: number | [number, number]) => {
                                                        setColor(e);      resetpagination();
                                                    }} />


                                                </div>
                                            </div>

                                        </>
                                    ):""}
                                    {mobilePopup == "Carat"? (
                                        <>
                                            <div className=" filter_control carat_main" id="carat_data_mobile">
                                                <label>Carat</label>
                                                <div className="carat_control_list">
                                                    <div className="row">
                                                        <div className="col-6 text-start">
                                                            <InputNumber controls={true} min={0.01} step={0.01} max={7} value={minCarat}
                                                                         onStep={(e)=>{  setCarat([e.target.value,maxCarat]);resetpagination();  }}
                                                                         onPressEnter={(e)=>{  setCarat([e.target.value,maxCarat]);resetpagination();  }} onChange={CaratMin}/>

                                                        </div>
                                                        <div className="col-6 text-end">
                                                            <InputNumber controls={true} min={0.01} step={0.01} max={7} value={maxCarat}
                                                                         onStep={(e)=>{  setCarat([minCarat,e.target.value]);resetpagination();  }}
                                                                         onPressEnter={(e)=>{  setCarat([minCarat,e.target.value]);resetpagination();  }} onChange={CaratMax}/>
                                                        </div>
                                                    </div>
                                                    <Slider range value={[minCarat, maxCarat]} step={0.01} min={0.01} max={7}
                                                            tooltip={{formatter: null}} draggableTrack={true} onChange={CaratRangeChange}
                                                            onAfterChange={(e: number | [number, number]) => {
                                                                setCarat(e);resetpagination();
                                                            }}/>

                                                </div>
                                            </div>

                                        </>
                                    ):""}
                                    {mobilePopup == "Price"? (
                                        <>
                                            <div className="filter_control price_main" id="price_data_mobile">
                                                <label>Price</label>
                                                <div className="price_control_list">
                                                    <div className="row">
                                                        <div className="col-6 text-start">
                                                            <InputNumber className="price_control_input" controls={false} min={200} step={1}
                                                                         max={1000000} prefix="RM"
                                                                         formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                         value={minPrice}
                                                                         onStep={(e)=>{  setPrice([e.target.value,maxPrice]);resetpagination();  }}
                                                                         onPressEnter={(e)=>{  setPrice([e.target.value,maxPrice]);resetpagination();  }}
                                                                         onChange={PriceMin}/>

                                                        </div>
                                                        <div className="col-6 text-end">
                                                            <InputNumber className="price_control_input" controls={false} min={200} step={1}
                                                                         max={1000000} prefix="RM"
                                                                         formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                         value={maxPrice}
                                                                         onStep={(e)=>{  setPrice([minPrice,e.target.value]);resetpagination();  }}
                                                                         onPressEnter={(e)=>{  setPrice([minPrice,e.target.value]);resetpagination(); }}
                                                                         onChange={PriceMax}/>
                                                        </div>
                                                    </div>
                                                    <Slider range value={[minPrice, maxPrice]} step={1} min={200} max={1000000}
                                                            tooltip={{formatter: null}} draggableTrack={true} onChange={PriceRangeChange}
                                                            onAfterChange={(e: number | [number, number]) => {
                                                                setPrice(e);resetpagination();
                                                            }}/>

                                                </div>
                                            </div>

                                        </>
                                            ):""}
                                    {mobilePopup == "Polish"? (
                                        <>
                                            <div className="filter_control polish_main" id="polish_data_mobile">
                                                <label>Polish</label>
                                                <div className="polish_control_list">

                                                    <Slider range step={1} tooltip={{formatter: null}} min={0} max={2}
                                                            defaultValue={isPolish}
                                                            draggableTrack={true} marks={PolishMarks}
                                                            onAfterChange={(e: number | [number, number]) => {
                                                                setPolish(e);
                                                                setOrderby("polish");
                                                                resetpagination();
                                                            }}/>
                                                </div>
                                            </div>
                                        </>
                                    ) : ""}
                                    {mobilePopup == "Symmetry" ? (
                                        <>
                                            <div className="filter_control symmetry_main" id="symmetry_data_mobile">
                                                <label>Symmetry</label>
                                                <div className="symmetry_control_list">
                                                    <Slider range step={1} tooltip={{formatter: null}} min={0} max={3}
                                                            defaultValue={isSymmetry}
                                                            draggableTrack={true} marks={SymmetryMarks}
                                                            onAfterChange={(e: number | [number, number]) => {
                                                                setSymmetry(e); resetpagination();
                                                            }}/>
                                                </div>
                                            </div>
                                        </>
                                    ):""}
                                    {mobilePopup == "Fluorescence"? (
                                        <>
                                            <div className="filter_control fluorescence_main" id="fluorescence_data_mobile">
                                                <label>Fluorescence</label>
                                                <div className="fluorescence_control_list">
                                                    <Slider range step={1} tooltip={{formatter: null}} min={0} max={4} defaultValue={isFluorescence}
                                                            draggableTrack={true} marks={FluorescenceMarks}
                                                            onAfterChange={(e: number | [number, number]) => {
                                                                setFluorescence(e);  resetpagination();
                                                            }}/>
                                                </div>
                                            </div>
                                        </>
                                    ):""}
                                    {mobilePopup == "Lab"? (
                                        <>
                                            <div className="filter_control lab_main" id="lab_data_mobile">
                                                <label>Lab</label>
                                                <div className="lab_control_list">
                                                    <Radio.Group
                                                        options={Labs}
                                                        onChange={(e) => {
                                                            setLab(e.target.value);
                                                        }}
                                                        value={isLab}
                                                        optionType="button"
                                                        buttonStyle="solid"
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    ):""}
                                    {mobilePopup == "Depth"? (
                                        <>
                                            <div className="filter_control depth_main" id="depth_data_mobile">
                                                <label>Depth %</label>
                                                <div className="depth_control_list">
                                                    <div className="row">
                                                        <div className="col-6 text-start">
                                                            <InputNumber controls={true} min={0.01} step={0.01} max={85} value={minDepth}
                                                                         onStep={(e)=>{  setDepth([e.target.value,maxDepth]);resetpagination(); }}
                                                                         onPressEnter={(e)=>{  setDepth([e.target.value,maxDepth]);resetpagination();  }}
                                                                         onChange={DepthMin}/>

                                                        </div>
                                                        <div className="col-6 text-end">
                                                            <InputNumber controls={true} min={0.01} step={0.01} max={85} value={maxDepth}
                                                                         onStep={(e)=>{  setDepth([minDepth,e.target.value]);resetpagination();  }}
                                                                         onPressEnter={(e)=>{  setDepth([minDepth,e.target.value]);resetpagination();  }}
                                                                         onChange={DepthMax}/>
                                                        </div>
                                                    </div>
                                                    <Slider range value={[minDepth, maxDepth]} step={0.01} min={0.01} max={85}
                                                            tooltip={{formatter: null}} draggableTrack={true} onChange={DepthRangeChange}
                                                            onAfterChange={(e: number | [number, number]) => {
                                                                setDepth(e);resetpagination();
                                                            }}/>

                                                </div>
                                            </div>
                                            </>
                                            ):""}
                                    {mobilePopup == "Table"? (
                                        <>
                                            <div className="filter_control table_main" id="table_data_mobile">
                                                <label>Table %</label>
                                                <div className="table_control_list">
                                                    <div className="row">
                                                        <div className="col-6 text-start">
                                                            <InputNumber controls={true} min={0.01} step={0.01} max={85} value={minTable}
                                                                         onStep={(e)=>{  setTable([e.target.value,maxTable]);resetpagination();  }}
                                                                         onPressEnter={(e)=>{  setTable([e.target.value,maxTable]);resetpagination();  }}
                                                                         onChange={TableMin}/>

                                                        </div>
                                                        <div className="col-6 text-end">
                                                            <InputNumber controls={true} min={0.01} step={0.01} max={85} value={maxTable}
                                                                         onStep={(e)=>{  setTable([minTable,e.target.value]);resetpagination(); }}
                                                                         onPressEnter={(e)=>{  setTable([minTable,e.target.value]);resetpagination();  }}
                                                                         onChange={TableMax}/>
                                                        </div>
                                                    </div>
                                                    <Slider range value={[minTable, maxTable]} step={0.01} min={0.01} max={85}
                                                            tooltip={{formatter: null}} draggableTrack={true} onChange={TableRangeChange}
                                                            onAfterChange={(e: number | [number, number]) => {
                                                                setTable(e); resetpagination();
                                                            }}/>

                                                </div>
                                            </div>
                                        </>
                                    ):""}
                                </div>
                            </div>
                        </>

                    ) :""}
                </div>

            </div>
            {/*Debugger*/}
            {/* <div className="container d-none py-5">
                <div className="row">
                    <div className="col-12">

                        <div className="col-lg-12">
                            =====Debug======
                            <div className="Result">

                                {JSON.stringify(ClarityMarks[isClarity[0]].label)}
                                Shape: {JSON.stringify(isShape)} <br/>
                                Cut: {JSON.stringify(isCut)} <br/>
                                Clarity: {JSON.stringify(isClarity)} <br/>
                                Color: {JSON.stringify(isColor)} <br/>
                                Carat: {JSON.stringify([minCarat, maxCarat])} <br/>
                                Price: {JSON.stringify([minPrice, maxPrice])} <br/>
                                Polish: {JSON.stringify(isPolish)} <br/>
                                Symmetry: {JSON.stringify(isSymmetry)} <br/>
                                Fluorescence: {JSON.stringify(isFluorescence)} <br/>
                                Lab: {JSON.stringify(isLab)} <br/>
                                Depth: {JSON.stringify([minDepth, maxDepth])} <br/>
                                Table: {JSON.stringify([minTable, maxTable])} <br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>*/}
            {/*Layout*/}
            <div className="container position-relative">
                <div className="row">
                    <div className="col-md-12 fltrPlod-list position-relative">
                        <Tabs
                            defaultActiveKey="all"
                            id="settings-diamond-tabs"
                            className="mb-3"
                            mountOnEnter  unmountOnExit
                        >
                            <Tab eventKey="all" title={all_diamond_tab}>

                                <Tabs mountOnEnter  unmountOnExit defaultActiveKey="grid"
                                      id="diamond-tabs"
                                      className="grid-list-btn float-end mb-3 nav nav-tabs position-absolute  top-0">

                                    <Tab eventKey="list" className="position-relative" title={(<FaThList/>)}>

                                        <div className="table-responsive">

                                            <table className="table diamond-list-table">
                                                <thead>
                                                <tr >
                                                    <th>Actual Photo</th>
                                                    <th>Shape</th>
                                                    <th>Carat</th>
                                                    <th>Color</th>
                                                    <th>Clarity</th>
                                                    <th>Cut</th>
                                                    <th>Lab</th>

                                                </tr>
                                                </thead>
                                                <tbody>
                                                {jesdada && jesdada.map((jd) => (


                                                    <ProductItemList key={jd.id} loader={props.loader} type={isDiamondType} product_id={jd.stone_id}/>
                                               

                                                ))}
                                                </tbody>

                                            </table>
                                            <div className="col-12 mt-5">
                                                <nav className="d-flex justify-content-center">
                                                    <ReactPaginate previousLabel={"←"} nextLabel={"→"}
                                                                   breakLabel={"..."} breakClassName={"break-me"}
                                                                   pageCount={pageCount} marginPagesDisplayed={2}
                                                                   pageRangeDisplayed={5}
                                                                   onPageChange={handlePageClick}
                                                                   forcePage={currentPage}
                                                                   containerClassName={""}

                                                                   pageClassName={"page-item"}
                                                                   pageLinkClassName={"page-link"}
                                                                   className={"pagination"}
                                                                   previousClassName={"page-item prev-page"}
                                                                   nextClassName={"page-item next-page"}
                                                                   subContainerClassName={"pages pagination"}
                                                                   activeClassName={"active"}
                                                                   activeLinkClassName={"active"}
                                                                   previousLinkClassName={"page-link"}
                                                                   nextLinkClassName={"page-link"}/>
                                                </nav>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="grid" title={(<FaTh/>)}>
                                        <div className="row position-relative">

                                             {(jesdada.length > 0) ? jesdada.map((jd) => (


                                                 <ProductItem loader={props.loader}   buttons={true} type={isDiamondType} class=" col-12 col-sm-6 col-md-6 col-lg-3 mb-3 position-relative"  key={jd.id} product_id={jd.stone_id}/>


                                             )) : (
                                                 <>
                                                     <div className="col-md-12 compare-page">
                                                         <div className="entry-content text-black text-center">
                                                             <div><i
                                                                 className="display-2 fa-regular fa-rings-wedding opacity-25 text-muted"></i>
                                                                 <h3 className="display-5"> We are sorry !</h3>
                                                                 <div className="empty-page-text text-muted">
                                                                     Do drop us a message and let us source the diamond for you.
                                                                 </div>
                                                                 <p className="return-to-shop"><a
                                                                     className="btn btn-primary" href="/contact-us"> GET IN TOUCH WITH US</a></p></div>
                                                         </div>
                                                     </div>
                                                 </>
                                             )}



                                            <div className="col-12 mt-5">
                                                <nav className="d-flex justify-content-center">
                                                    <ReactPaginate previousLabel={"←"} nextLabel={"→"}
                                                                   breakLabel={"..."} breakClassName={"break-me"}
                                                                   pageCount={pageCount} marginPagesDisplayed={2}
                                                                   pageRangeDisplayed={4}
                                                                   onPageChange={handlePageClick}
                                                                   forcePage={currentPage}
                                                                   containerClassName={""}
                                                                   pageClassName={"page-item"}
                                                                   pageLinkClassName={"page-link"}
                                                                   className={"pagination"}
                                                                   previousClassName={"page-item prev-page"}
                                                                   nextClassName={"page-item next-page"}
                                                                   subContainerClassName={"pages pagination"}
                                                                   activeClassName={"active"}
                                                                   activeLinkClassName={"active"}
                                                                   previousLinkClassName={"page-link"}
                                                                   nextLinkClassName={"page-link"}/>
                                                </nav>
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </Tab>
                            <Tab eventKey="comparison" title={comparison_tab}>
                                <div className="col-md-12 compare-page">
                                    <div className="entry-content">

                                        <CompareProductList/>
                                    </div>

                                </div>
                            </Tab>
                            <Tab eventKey="recent_view" title={recently_view_tab}>
                                <div className="row itemList-cont g-2">
                                    <RecentProducts loader={props.loader} type="diamond"/>
                                </div>
                            </Tab>
                        </Tabs>

                    </div>
                </div>
            </div>
        </>
    );
}

export default DiamondSettings;

import React, {useEffect, useState} from "react";
import { Link,useNavigate } from 'react-router-dom'

import { FaThList, FaTh, FaSearchPlus, FaRegHeart, FaHeart, FaStar, FaPlus, FaTimes, FaAngleLeft, FaAngleRight } from "react-icons/fa"


//localStorage.removeItem("indexvalue")


function Compare(props) {

   
    let [compareList, setCompareList] =  useState();
    let [ProductData, setProductData] = useState();
    let [ProductFlag, setProductFlag] = useState(false);
    async function getproductdetails(stone_id) {
        var formData = new FormData();
        var requestdata = {
            id: stone_id,
            type:'compare',       
            currency: localStorage.getItem('currency')
        };
        formData.append("request", JSON.stringify(requestdata));
        var requestOptions = {
            method: "POST",
            body: formData
        };
        var resp = await fetch(process.env.REACT_APP_API_URL+"/api/product_details", requestOptions);

        var data = await resp.json();
 

        const productData = {
            productName: (data.data.shape.charAt(0).toUpperCase() + data.data.shape.slice(1).toLowerCase() + " " + data.data.carat + "ct " + " " + data.data.color + " " + data.data.clarity + " " + data.data.cut),
            productMeta: data.data.clarity + " Clarity | " + data.data.cut + " Cut",
            productSku: data.data.sku_code,
            productTotal: data.data.total_price,
            productStock: data.data.stock,
        };
       var add_item = data.data
        if(ProductData) {
            setProductData(add_item);
            setProductFlag(true)
        }else{
            setProductData(add_item);
            setProductFlag(true)
        }
     
    }


    let navigate = useNavigate()
    const selectDiamond = (value) => {
        localStorage.setItem("diamondId", value);
        console.log(value);
        var ringdata = localStorage.getItem("ringId");
        console.log(ringdata)
        if (ringdata != null) {
            navigate('/product-details');
        } else {
            navigate('/settings-ring/all');
        }

        //navigate('/settings-ring');
        // navigate(path)
    };
     
    useEffect(() => {
        getproductdetails(props.stone_id)                                    
 
    }, [compareList]);


    return (
        <>
            {ProductFlag ? (
                <div className=""  id={'compare_product_'+ProductData.stone_id}>
                    <div className='compare-product-img-wrap' style={{height: 350}}>
                        <div className="text-center compare-product-img">

                            <a href={"/product-diamond/" + ProductData.stone_id}>
                                <img className="img-fluid" src={ProductData.front_image_url}/>

                                <div className="product-title">{(ProductData.shape.charAt(0).toUpperCase() + ProductData.shape.slice(1).toLowerCase() + " " + ProductData.carat + "ct " + " " + ProductData.color + " " + ProductData.clarity + " " + ProductData.cut)}</div>
                            </a>
                            <div
                                className="product-price">{ProductData.total_price}</div>
                            <button style={{fontSize: 13}}
                                    className="btn compare-select-btn rounded-0 btn-default text-uppercase px-3 text-uppercase my-2"
                                    value={ProductData.stone_id}
                                    onClick={e => selectDiamond(e.target.value)}>SELECT THIS DIAMOND
                            </button>
                        </div>
                    </div>
                    <ul>
                        <li ><span className="product-sku">{ProductData.sku}</span></li>
                        <li>{ProductData.instock == 1   ? (<p className="stock in-stock">1 in stock</p>) : (<p className="stock">Out of Stock</p>)}</li>
                        <li>{ProductData.carat}</li>
                        <li>{ProductData.clarity}</li>
                        <li>{ProductData.color}</li>
                        <li>{ProductData.cut}</li>
                        <li>{ProductData.polish}</li>
                        <li>{ProductData.symmetry}</li>
                        <li>{ProductData.fluorescence}</li>
                    </ul>

                </div>
            ):'' }
        </>
    )
   
   


}

export default Compare
import React from 'react'
import { Link } from 'react-router-dom'

function Error404() {
  return (
    <div className='container-fluid error404 '>
      <div className="row justify-content-center">
          <div className="site-content position-relative col-12" role="main">

              <header className="page-header">
                  <h3 className="page-title">Not Found</h3>
              </header>

              <div className="page-wrapper">
                  <div className="page-content">
                      <h2>This is somewhat embarrassing, isn’t it?</h2>
                      <p>It looks like nothing was found at this location. Maybe try a search?</p>

                      <Link className="btn btn-outline-dark mt-3" to="/">Return Home</Link>
                  </div>
              </div> 

          </div>

           
      </div>
    </div>
  )
}

export default Error404

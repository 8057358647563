import React from "react";

export default function LoadingSpinner() {

    const  cssstyle = "<style>body{pointer-events: none;-webkit-user-select: none;-ms-user-select: none; user-select: none; overflow: hidden}</style>";

    return (
            <>    <div className="loader-container loader-container-full" style={{zIndex: '99999999'}} >
                <div className="loader"></div>
            </div>

                <div
                    dangerouslySetInnerHTML={{__html: cssstyle}}
                />
            </>



    );
}
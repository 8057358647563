import React, {useRef, useState, useEffect} from "react";
import {Routes, Route, useLocation} from "react-router-dom";
import {Link, useNavigate} from "react-router-dom";

import {FaThList, FaTh, FaSearchPlus, FaRegHeart, FaHeart, FaStar, FaPlus, FaTimes} from "react-icons/fa";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import {PropTypes} from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";


import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";


import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./Prodslide.css";
import {CartProvider, useCart} from "react-use-cart";
import Cart from "./Cart";
// import required modules
import {FreeMode, Navigation, Thumbs} from "swiper";
import GoToTop from "../parts/GoToTop";

import FilterSteps from "../parts/filter-steps";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function ProdDtls(props) {

    //console.log(props.val+"Hello would")
    let [responsedata, setResponsedata] = useState([]);
    let [responsedataring, setResponsedataring] = useState([]);
    let [thumbsSwiper, setThumbsSwiper] = useState();
    const [ringsize, setRingsize] = React.useState("");
    const handleChange = (event) => {
        setRingsize(event.target.value);
    };
    let [rating, setRating] = useState(null);
    let [hover, setHover] = useState(null);
    let [isActive, setActive] = useState("false");
    let wishToggle = () => {
        setActive(!isActive);
    };
    let [ringtext, setRingtext] = useState([]);
    let [ringfont, setRingfont] = useState([]);
    let [ringsizeprice, setRingsizeprice] = useState([0]);
    let [ringsizes, setRingsizes] = useState([0]);

    let [tabtext, setTabtext] = useState([]);
    // const { addItem } = useCart();
    const [cart, setCart] = useState([]);
    const [showCart, setShowCart] = useState(false);
    let navigate = useNavigate();
    var diamond = localStorage.getItem("diamondId");
    var gemsId = localStorage.getItem("gemsId");
    if (diamond != null) {
        tabtext = "DIAMOND";
    } else {
        tabtext = "GEMSTONE";
        //alert("redirect")
    }
    var get_index_value = localStorage.getItem("indexvalue");
    // const addToCart=(productdiamond,productring=null) => {
    //     let selectedChkbox = {
    //         diamond : productdiamond,
    //         ring : productring,
    //         product_type : "compound",
    //         ring_size : localStorage.getItem("ringsize"),
    //         ring_price : localStorage.getItem("ringprice"),
    //         ring_font : localStorage.getItem("ringfont"),
    //         ring_text : localStorage.getItem("ringText"),
    //         diamondId : localStorage.getItem("diamondId"),
    //         ringId : localStorage.getItem("ringId"),
    //     };
    //     if(get_index_value == null)
    //     {
    //         props.setCartItem([...props.cartVal, selectedChkbox])
    //     }
    //     else
    //     {
    //         var updateItem = {
    //             index : get_index_value,
    //             item : selectedChkbox
    //         }
    //         props.updateCartItem(updateItem);
    //     }
    //
    //     //   console.log(selectedChkbox);
    //
    //     localStorage.removeItem("ringsize")
    //     localStorage.removeItem("ringText")
    //     localStorage.removeItem("ringfont")
    //     localStorage.removeItem("diamondId")
    //     localStorage.removeItem("gemsId")
    //     localStorage.removeItem("ringId")
    //     localStorage.removeItem("ringprice")
    //     navigate('/cart')
    //
    // }

    const addToCart = (productdiamond, productring = null) => {


        let selectedChkbox = {
            diamond: productdiamond,
            ring: productring,
            product_type: "compound",
            ring_size: localStorage.getItem("ringsize"),
            ring_price: localStorage.getItem("ringprice"),
            ring_font: localStorage.getItem("ringfont"),
            ring_text: localStorage.getItem("ringText"),
            diamondId: localStorage.getItem("diamondId"),
            ringId: localStorage.getItem("ringId"),

        };
        var upindex = 0;
        var existItem = localStorage.getItem('productsbag')== null ? [] : JSON.parse(decodeURIComponent(escape(atob(localStorage.getItem('productsbag')))));
        if (get_index_value == null) {

            if(existItem.length > 0) {
                upindex = parseFloat(existItem.length);
            } else{

                upindex = 0;
            }
            
            existItem[upindex] = selectedChkbox;

            var bagaddproduct = JSON.stringify(existItem);
            var encodedData = btoa(unescape(encodeURIComponent(bagaddproduct)));
            localStorage.setItem("productsbag", encodedData);
            localStorage.removeItem("indexvalue");
            console.log("add");
//            props.setCartItem([...props.cartVal, selectedChkbox]);






        } else {
            const upindex = parseFloat(get_index_value) ;


            existItem[upindex] = selectedChkbox;
            var bagaddproduct = JSON.stringify(existItem);
            var encodedData = btoa(unescape(encodeURIComponent(bagaddproduct)));
            localStorage.setItem("productsbag", encodedData);
            localStorage.removeItem("indexvalue");

        }
        props.setCartItem(['']);
          localStorage.removeItem("ringsize");
        localStorage.removeItem("ringText");
        localStorage.removeItem("ringfont");
        localStorage.removeItem("diamondId");
        localStorage.removeItem("gemsId");
        localStorage.removeItem("ringId");
        localStorage.removeItem("ringprice");
        navigate("/cart"); 


    };

    const [diamondSelected, setDiamondSelected] = useState([localStorage.getItem("diamondId")]);

    async function getDiamondData() {
        var diamondId = localStorage.getItem("diamondId");

        if (diamondId != null) {
            var formData = new FormData();

            var requestdata = {
                id: diamondId,
                type: "ccmpare",
                currency: localStorage.getItem("currency") ?? "RM",
            };
            formData.append("request", JSON.stringify(requestdata));
            var requestOptions = {
                method: "POST",
                body: formData
            };

            var resp = await fetch(process.env.REACT_APP_API_URL + "/api/product_details", requestOptions);

            var data = await resp.json();
            if (data.success == true) {
                console.log(data.data);
                setDiamondSelected(data.data);
            }

        }
    }

    const removeDiamondData = (e) => {

        localStorage.removeItem("diamondId");
    };

    const selectDiamond = (value) => {
        localStorage.setItem("diamondId", value);
        console.log(value);
        var ringdata = localStorage.getItem("ringId");
        console.log(ringdata);
        if (ringdata != null) {
            navigate("/product-details");
        } else {
            navigate("/settings-diamond/all");
        }

    };
    const [ringSelected, setRingSelected] = useState([]);

    async function getRingData() {
        var ringId = localStorage.getItem("ringId");

        //    console.log(ringId)
        if (ringId !== null) {
            var formData = new FormData();

            var requestdata = {
                id: ringId,
                type: "ring",
                currency: localStorage.getItem("currency") ?? "RM",
            };
            formData.append("request", JSON.stringify(requestdata));
            var requestOptions = {
                method: "POST",
                body: formData
            };

            var resp = await fetch(process.env.REACT_APP_API_URL + "/api/product_details", requestOptions);

            var data = await resp.json();


            setRingSelected(data.data);

        }

    }

    const removeRingData = (e) => {

        localStorage.removeItem("diamondId");
    };

    const selectRing = (value) => {

        var ringdata = localStorage.getItem("ringId");

        if (ringdata != null) {
            navigate("/product-details");
        } else {
            navigate("/settings-ring/all");
        }

    };
    //Tabs
    const [value, setValue] = React.useState(0);

    const onTabChange = (event, newValue) => {
        setValue(newValue);
    };
    // end Tabs

    // Start Custom Tooltip
    const [openTooltip, setTooltipOpen] = React.useState(false);
    let [fontInscrip, setFontInscrip] = useState(false);
    const handleTooltipClose = (e) => {
        e.stopPropagation();
        setTooltipOpen(false);
    };
    const handleTooltipOpen = () => {
        setTooltipOpen(true);
    };
    let fontToggle = () => {
        setFontInscrip(!fontInscrip);
    };


    async function getproductdetails() {
        var formData = new FormData();
        var id = localStorage.getItem("diamondId");
        var gemsId = localStorage.getItem("gemsId");
        if (gemsId != null && gemsId != undefined) {
            id = gemsId;
        }
        if (id) {
            var formData = new FormData();

            var requestdata = {
                id: id,
                type: "compare",
                currency: localStorage.getItem("currency") ?? "RM",
            };
            formData.append("request", JSON.stringify(requestdata));
            var requestOptions = {
                method: "POST",
                body: formData
            };

            var resp = await fetch(process.env.REACT_APP_API_URL + "/api/product_details", requestOptions);

            var data = await resp.json();

            console.log(data);

            setResponsedata(data.data);
        }
    }

    async function getringtdetails() {
        var formData = new FormData();
        var id = localStorage.getItem("ringId");
        if (id) {
            var requestdata = {
                id: id,
            };
            var formData = new FormData();

            var requestdata = {
                id: id,
                type: "ring",
                currency: localStorage.getItem("currency") ?? "RM",
                size: localStorage.getItem("ringsize") ?? "",

            };
            formData.append("request", JSON.stringify(requestdata));
            var requestOptions = {
                method: "POST",
                body: formData
            };

            var resp = await fetch(process.env.REACT_APP_API_URL + "/api/product_details", requestOptions);

            var data = await resp.json();

            setResponsedataring(data.data);
        }
    }


    //+responsedataring['0'].id

    const HtmlTooltip = styled(({className, ...props}) => (
        <Tooltip {...props} classes={{popper: className}}/>
    ))(({theme}) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#f5f5f9",
            color: "rgba(0, 0, 0, 0.87)",
            maxWidth: 50,
            minWidth: 25 + "em",
            fontSize: theme.typography.pxToRem(12),
            border: "1px solid #dadde9",
        },
    }));
    var ringprice = 0;
    var productprice = 0;
    var old_diamond_price = 0;
    if (responsedataring) {
        ringprice = parseInt(responsedataring.sell_price_val);
    }
    if (responsedata) {
        productprice = parseFloat(responsedata.total_price_val);
    }
    // End Custom Tooltip
    //var total_price = (Number(ringprice)+Number(productprice) + Number(ringsizeprice)).toFixed(2);
    var total_price = (productprice + ringprice).toFixed(2);


    let [tabtext1, setTabtext1] = useState([]);
    let [tabcss1, setTabcss1] = useState([]);
    let [tabtext2, setTabtext2] = useState([]);
    let [tabcss2, setTabcss2] = useState([]);


    useEffect(() => {
        getproductdetails();
        getringtdetails();


        var inceptionText = localStorage.getItem("ringText");
        var inceptionringfont = localStorage.getItem("ringfont");
        var ringsize = localStorage.getItem("ringsize");
        var ringprice = localStorage.getItem("ringprice");
        var get_index_value = localStorage.getItem("indexvalue");
        setRingsizes(ringsize);
        var ringdata = localStorage.getItem("ringId");


    }, [diamondSelected, ringSelected]);

    // useEffect(()=>{
    //     getDiamondData()
    //     getRingData()
    //     getproductdetails();
    //     getringtdetails();
    //     var inceptionText = localStorage.getItem("ringText");
    //     var inceptionringfont = localStorage.getItem("ringfont");
    //     var ringsize = localStorage.getItem("ringsize");
    //     var ringprice = localStorage.getItem("ringprice");
    //     var get_index_value = localStorage.getItem("indexvalue");
    //     setRingsizes(ringsize);
    //     var ringdata = localStorage.getItem("ringId");
    //     if (ringdata != null) {
    //         setTabtext1("SETTING");
    //         setTabtext2("DIAMOND");
    //         setTabcss1("active--bcso0");
    //         setTabcss2("");
    //     } else {
    //         setTabtext2("SETTING");
    //         setTabtext1("DIAMOND");
    //         setTabcss1("");
    //         setTabcss2("active--bcso0");
    //     }
    //     setRingsizeprice(ringprice);
    //     setRingtext(inceptionText);
    //     setRingfont(inceptionringfont);
    // },[diamondSelected, ringSelected]);

    return (
        <>
            <section className="filter-sec">
                <div className="container">

                    <div className="row">
                        <div className="col-12">

                            <FilterSteps active={3}/>
                        </div>
                    </div>

                    <div className="row g-4">
                        <div className="col-md-7">
                            <div className="row g-1">

                                <div className="col-md-10 order-md-2">

                                    <Swiper
                                        spaceBetween={10}
                                        navigation={true}
                                        thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}}
                                        modules={[FreeMode, Navigation, Thumbs]}
                                        className="mySwiper2"
                                    >
                                        <SwiperSlide>
                                            <img src={responsedata.media_default} alt=""/>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src={responsedataring.front_pic} alt=""/>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src={responsedataring.side_pic} alt=""/>
                                        </SwiperSlide>


                                    </Swiper>
                                </div>
                                <div className="col-md-2 order-md-1">
                                    <Swiper
                                        onSwiper={setThumbsSwiper}
                                        spaceBetween={10}
                                        slidesPerView={4}
                                        freeMode={true}
                                        watchSlidesProgress={true}
                                        modules={[FreeMode, Navigation, Thumbs]}
                                        direction={"vertical"}
                                        className="mySwiperBottom mySwiperVerThumb">
                                        <SwiperSlide>
                                            <img src={responsedata.media_default} alt=""/>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src={responsedataring.front_pic} alt=""/>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src={responsedataring.side_pic} alt=""/>
                                        </SwiperSlide>

                                    </Swiper>

                                </div>
                            </div>


                        </div>
                        <div className="col-md-5 prod-dtlCont">

                            <h4 className="fw-normal diamond-heading">
                                Engagement Ring

                            </h4>

                            <hr/>
                            <ul className="choosenList-dtl p-0">

                                <li className="choosenItem">

                                    {localStorage.getItem("ringId") ? (
                                        <div className="main-cont">

                                            <Link className="item-title" to="/"> <i
                                                className="fa-regular fa-rings-wedding"></i> {responsedataring.product_name}
                                            </Link>
                                            <div className="itemDtl-customize">
                                    <span className="price">
                                        <span className="buy-price">{responsedataring.sell_price}</span>
                                    </span>
                                                <Link
                                                    to={"/product-ring/" + localStorage.getItem("ringId")}>Change</Link>


                                            </div>
                                        </div>
                                    ) : (
                                        <Link to="/settings-ring/all">Choose a Setting </Link>
                                    )}

                                </li>

                                <li className="choosenItem">

                                    {localStorage.getItem("diamondId") ? (
                                        <div className="main-cont">
                                            <Link className="item-title" to="/settings-diamond"> <i
                                                className="fa-regular fa-gem"></i> {responsedata.diamond_type} {responsedata.shape} | {responsedata.carat}ct
                                                | {responsedata.color} | {responsedata.clarity} {responsedata.cut} cut
                                                Diamond</Link>
                                            <div className="itemDtl-customize">

                                    <span className="price">
                                        <span className="buy-price">{responsedata.total_price}</span>
                                    </span>
                                                <Link
                                                    to={"/product-diamond/" + localStorage.getItem("diamondId")}>Change</Link>

                                            </div>
                                        </div>
                                    ) : (
                                        <Link to="/settings-diamond/all">Choose a Diamond </Link>
                                    )}

                                </li>
                                <li className="choosenItem">
                                    <div className="itemDtl-customize">
                                <span className="price">

                                    {/* <span className="buy-price">$1,440</span> */}
                                    <span
                                        className={"inscriptionText " + ringfont}>Inscription: {localStorage.getItem("ringText") ?? ""}</span>
                                </span>
                                    </div>
                                </li>
                                <li className="choosenItem">
                                    <div className="itemDtl-customize">
                                <span className="price">


                                    <span
                                        className={"inscriptionText " + ringfont}>Ring Size: {localStorage.getItem("ringsize") ?? ""} </span>

                                </span>
                                    </div>
                                </li>
                            </ul>


                            <div className="pricePack d-block">
                                <div>Total</div>
                                <div className="total">
                                    {/* <span className="old-price">$2,010</span> */}
                                    <span
                                        className="now-price">{localStorage.getItem("currency")} {new Intl.NumberFormat("en-IN", {maximumSignificantDigits: 10}).format(total_price)}</span>
                                </div>
                                {/*<span>(Subtotal)</span>*/}
                            </div>
                            {/* <Cart cart={cart}></Cart> */}
                            <div className="d-flex">
                                {/* <Link className="btn btn-primary text-uppercase flex-fill me-2" to='#' >Add To Cart</Link> */}
                                <button className="btn btn-primary text-uppercase flex-fill me-2"
                                        onClick={() => addToCart(responsedata, responsedataring)}>{get_index_value == null ? "Add to cart" : "Update cart"}</button>
                                <button className="btn btn-outline-dark btn-addWishlist" onClick={wishToggle}>
                                    {isActive ?
                                        <FaRegHeart/>
                                        :
                                        <FaHeart color={"#8b2845"}/>
                                    }
                                </button>
                            </div>

                        </div>

                    </div>

                    {/* <div className="row mt-4">
                <div className="col-12">
                    <h5>Product Description</h5>
                    <span className="sku text-muted mb-3 d-block">SKU 17740W14</span>
                    <p>Classic and refined, this piece offers understated elegance and unsurpassed comfort while highlighting the center diamond or gemstone you select. Designed with smaller carat weight diamonds in mind, it is sophisticated and petite without sacrificing stability.</p>
                </div>
            </div> */}

                    <div className="row mt-4">
                        <div className="col-12">
                            <Box className="infos" sx={{width: "100%"}}>
                                <Box className="info-tabs" sx={{borderBottom: 1, borderColor: "divider"}}>
                                    <Tabs value={value} onChange={onTabChange} aria-label="basic tabs example">
                                        <Tab label="Product Details" {...a11yProps(0)} />
                                        <Tab label="Can Be Set With" {...a11yProps(1)} />
                                    </Tabs>
                                </Box>
                                <TabPanel className="info-dtls" value={value} index={0}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <span className="d-block mb-2">Ring Information</span>
                                            <ul className="dtlInfo">
                                                <li>
                                                    <div className="cell">Metal</div>
                                                    <div
                                                        className="cell">{responsedataring.length > 0 ? responsedataring["0"].metal_type : ""}</div>
                                                </li>
                                                <li>
                                                    <div className="cell">Width</div>
                                                    <div
                                                        className="cell">{responsedataring.length > 0 ? responsedataring["0"].width : ""}</div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6">
                                            <span className="d-block mb-2">Diamond</span>
                                            <ul className="dtlInfo">
                                                <li>
                                                    <div className="cell">Shape</div>
                                                    <div
                                                        className="cell">{responsedata.length > 0 ? responsedata.shape : ""}</div>
                                                </li>
                                                <li>
                                                    <div className="cell">Quantity</div>
                                                    <div
                                                        className="cell">{responsedata.length > 0 ? responsedata.stock : ""}</div>
                                                </li>
                                                <li>
                                                    <div className="cell">Average Total Carat</div>
                                                    <div
                                                        className="cell">{responsedata.length > 0 ? responsedata.carat : ""}</div>
                                                </li>
                                                <li>
                                                    <div className="cell">Average Color</div>
                                                    <div
                                                        className="cell">{responsedata.length > 0 ? responsedata.color : ""}</div>
                                                </li>
                                                <li>
                                                    <div className="cell">Average Clarity</div>
                                                    <div
                                                        className="cell">{responsedata.length > 0 ? responsedata.clarity : ""}</div>
                                                </li>
                                            </ul>
                                        </div>


                                    </div>
                                </TabPanel>
                                <TabPanel className="info-dtls" value={value} index={1}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            {/* <span className="d-block mb-2">Ring Information</span> */}
                                            <ul className="dtlInfo">
                                                <li>
                                                    <div className="cell">Round</div>
                                                    <div className="cell">0.50 - 6.00</div>
                                                </li>
                                                <li>
                                                    <div className="cell">Oval</div>
                                                    <div className="cell">0.50 - 6.00</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </TabPanel>
                            </Box>
                        </div>
                    </div>
                    <div className="offer-banner">
                        <div className="row">
                            <div className="col-md-8 offer-text">
                                <h4>Your Order</h4>
                                <h1>Includes</h1>
                                <div className="d-flex offer-points">
                                    <ul>
                                        <li>BAEMONT Exclusive Gift Packaging</li>

                                        <li>Diamond Certificate of Authenticity</li>
                                        <li>Product Lifetime Warranty</li>
                                    </ul>
                                    <ul className="mx-5">
                                        <li>Lifetime Upgrade</li>
                                        <li>Free Engraving</li>
                                        <li>Free Shipping (Local Orders)</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4 offer-img">
                                <img className="img-fluid" src="/assets/img/ring_engagement.png" alt=""/>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <GoToTop/>
        </>
    );
}

export default ProdDtls;
